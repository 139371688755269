import { useDatabaseHookProps } from '../../utils';
import { useCommonConfig, BigqueryFormDataType, getDefaultEmptyAuthValues } from './CommonConfig';
import { formDistribution, initialValues, formFields } from './dwhFormFields';

export type { BigqueryFormDataType };

export const useDatabaseHook = ({
  formData,
  env,
  source,
  isEditable,
  isAdditionalUser,
  isNewConnection,
}: useDatabaseHookProps) => {
  const commonConfigs = useCommonConfig({
    formData,
    env,
    source,
    isEditable,
    isAdditionalUser,
    isNewConnection,
  });

  return {
    ...commonConfigs,
    initialFormData: (
      getDefaultEmptyAuthValues(
        commonConfigs.selectedAuthMethod,
        initialValues(env, formData),
      )
    ),
    formDistribution: formDistribution({
      env,
      selectedAuthMethod: commonConfigs.selectedAuthMethod,
      showEditButton: commonConfigs.showEditButton,
    }),
    form: formFields(
      commonConfigs.selectedAuthMethod,
      commonConfigs.setSelectedAuthMethod,
      commonConfigs.selectedDatasetLocation,
      commonConfigs.setSelectedDatasetLocation,
      commonConfigs.showEditButton,
      commonConfigs.isEditingServiceAccountJson,
      commonConfigs.setIsEditingServiceAccountJson,
      commonConfigs.isEditingClientSecret,
      commonConfigs.setIsEditingClientSecret,
      env,
    ),
  };
};
