import React, { FC, useEffect, useState } from 'react';
import ZeroState from '@paradime-io/pragma-ui-kit/lib/components/ZeroState';
import { useHeaderContext } from '../useHeaderContext';
import { subtitleTypes } from '../Header';

const LoadingModelsZeroState: FC = () => {
  const [progressText, setProgressText] = useState('');

  useHeaderContext({
    subtitleType: subtitleTypes.BLANK,
    buttonType: '',
    buttonIsVisible: false,
    showHeader: false,
  });

  useEffect(() => {
    setProgressText('Fetching models');

    setTimeout(() => setProgressText('Parsing models'), 3000);
  }, []);

  return (
    <ZeroState
      showProgressAnimation
      text={progressText}
    />
  );
};

export default LoadingModelsZeroState;
