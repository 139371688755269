import { gql } from '@apollo/client';
import { ColumnType } from '@paradime-io/pragma-ui-kit/lib/components/PrimeReactDataTable';
import { getTableChips, runStatuses, getStatusColour } from '../utils';

export interface modelNamesForDropdownReturnType {
  cube: {
    modelRunStatistics: {
      modelName: string,
    }
  }[]
}

export const modelNamesForDropdownQuery = gql`
  query modelNamesForDropdownQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {model_run_statistics: {calendar_date: {inDateRange: $timeframe}}}
    ) {
      modelRunStatistics: model_run_statistics {
        modelName: model_name
      }
    }
  }
`;

export const formatModelNamesForDropdown = (data?: modelNamesForDropdownReturnType) => {
  if (!data) return [];

  return data.cube
    .map(({ modelRunStatistics }) => ({
      label: modelRunStatistics.modelName,
      value: modelRunStatistics.modelName,
    }));
};

export interface successModelDurationReturnType {
  cube: {
    modelExecutionTimeTimeline: {
      executionTime: number,
      boltScheduleRunStartDttm: {
        minute: string,
      }
    }
  }[]
}

export const successModelDurationQuery = gql`
  query successModelDurationQuery($timeframe: [String], $modelName: String) {
    cube(
      limit: 5000
      where: {model_execution_time_timeline: {model_name: {equals: $modelName}, run_result_status: {equals: "success"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      modelExecutionTimeTimeline: model_execution_time_timeline(orderBy: {bolt_schedule_run_start_dttm: asc}) {
        executionTime: execution_time
        boltScheduleRunStartDttm: bolt_schedule_run_start_dttm {
          minute
        }
      }
    }
  }
`;

export const formatSuccessModelDurationForChart = (data?: successModelDurationReturnType) => {
  if (!data) return [];

  return [data.cube.map(({ modelExecutionTimeTimeline }) => ({
    x: modelExecutionTimeTimeline.boltScheduleRunStartDttm.minute,
    y: modelExecutionTimeTimeline.executionTime,
  }))];
};

export const getSuccessModelDurationXAxisLabels = (data?: successModelDurationReturnType) => {
  if (!data) return [];

  const modelNames = data.cube
    .map(({ modelExecutionTimeTimeline }) => (
      modelExecutionTimeTimeline.boltScheduleRunStartDttm.minute
    ));
  return Array.from(new Set(modelNames));
};

export interface successModelAverageTimeReturnType {
  cube: {
    modelExecutionTimeStats: {
      avgExecutionTime: number,
    }
  }[]
}

export const successModelAverageTimeQuery = gql`
  query successModelAverageTimeQuery($timeframe: [String], $modelName: String) {
    cube(
      limit: 5000
      where: {model_execution_time_stats: {model_name: {equals: $modelName}, run_result_status: {equals: "success"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      modelExecutionTimeStats: model_execution_time_stats {
        avgExecutionTime: avg_execution_time
      }
    }
  }
`;

export interface modelDailyCountReturnType {
  cube: {
    modelDailyRunStatusCount: {
      distinctBoltScheduleRunCount: number,
      runResultStatus: 'success' | 'error' | 'skipped',
      calendarDate: {
        day: string,
      }
    }
  }[]
}

export const modelDailyCountQuery = gql`
  query modelDailyCountQuery($timeframe: [String], $modelName: String) {
    cube(
      limit: 5000
      where: {model_daily_run_status_count: {model_name: {equals: $modelName}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      modelDailyRunStatusCount: model_daily_run_status_count(orderBy: {calendar_date: asc}) {
        distinctBoltScheduleRunCount: distinct_bolt_schedule_run_count
        runResultStatus: run_result_status
        calendarDate: calendar_date {
          day
        }
      }
    }
  }
`;

export const formatModelDailyCountForChart = (data?: modelDailyCountReturnType) => {
  if (!data) return { data: [], statuses: [], colours: [] };

  const dataToReturn: { x: string, y: number }[][] = [];
  const legendLabels: string[] = [];
  const legendColours: string[] = [];

  runStatuses.forEach((status) => {
    const tests = data.cube
      .filter(({ modelDailyRunStatusCount }) => modelDailyRunStatusCount.runResultStatus === status)
      .map(({ modelDailyRunStatusCount }) => ({
        x: modelDailyRunStatusCount.calendarDate.day,
        y: modelDailyRunStatusCount.distinctBoltScheduleRunCount,
      }));

    if (tests.length > 0) {
      dataToReturn.push(tests);
      legendLabels.push(status);
      legendColours.push(getStatusColour(status));
    }
  });

  return { data: dataToReturn, statuses: legendLabels, colours: legendColours };
};

export const getModelDailyCountXAxisLabels = (data?: modelDailyCountReturnType) => {
  if (!data) return [];

  const modelNames = data.cube
    .map(({ modelDailyRunStatusCount }) => modelDailyRunStatusCount.calendarDate.day);
  return Array.from(new Set(modelNames));
};

export interface averageModelDurationReturnType {
  cube: {
    modelExecutionTimeByHour: {
      avgExecutionTime: number,
      boltScheduleRunHourWindow: number,
    }
  }[]
}

export const averageModelDurationQuery = gql`
  query averageModelDurationQuery($timeframe: [String], $modelName: String) {
    cube(
      limit: 5000
      where: {model_execution_time_by_hour: {run_result_status: {equals: "success"}, model_name: {equals: $modelName}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      modelExecutionTimeByHour: model_execution_time_by_hour(orderBy: {bolt_schedule_run_hour_window: asc}) {
        avgExecutionTime: avg_execution_time
        boltScheduleRunHourWindow: bolt_schedule_run_hour_window
      }
    }
  }
`;

export const formatAverageModelDurationForChart = (data?: averageModelDurationReturnType) => {
  if (!data) return [];

  return [data.cube.map(({ modelExecutionTimeByHour }) => ({
    x: modelExecutionTimeByHour.boltScheduleRunHourWindow.toString(),
    y: modelExecutionTimeByHour.avgExecutionTime,
  }))];
};

export const getAverageModelDurationXAxisLabels = (data?: averageModelDurationReturnType) => {
  if (!data) return [];

  const hourlySlots = data.cube
    .map(({ modelExecutionTimeByHour }) => (
      modelExecutionTimeByHour.boltScheduleRunHourWindow.toString()
    ));
  return Array.from(new Set(hourlySlots));
};

export interface latestModelRunReturnType {
  cube: {
    modelLatestScheduleRuns: {
      executionTime: number,
      boltScheduleName: string,
      boltScheduleNameCommandsJson: string,
      boltScheduleCommandCommand: string,
      runResultArgsWhich: string,
      runResultArgsExclude: string,
      runResultArgsSelect: string,
      boltScheduleNameScheduleCron: string,
      boltScheduleRunStatus: string,
      boltScheduleRunStartDttm: {
        value: string,
      }
      boltScheduleRunEndDttm: {
        value: string,
      }
    }
  }[]
}

// Fields to be added in later when types are resolved:
// runResultArgsExclude: run_result_args_exclude
// runResultArgsSelect: run_result_args_select
export const latestModelRunQuery = gql`
  query latestModelRunQuery($timeframe: [String], $modelName: String) {
    cube(
      limit: 5000
      where: {model_latest_schedule_runs: {is_schedule_current_status: {equals: "true"}, is_schedule_last_run: {equals: "true"}, model_name: {equals: $modelName}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      modelLatestScheduleRuns: model_latest_schedule_runs(orderBy: {bolt_schedule_name: asc}) {
        executionTime: execution_time
        boltScheduleName: bolt_schedule_name
        boltScheduleNameCommandsJson: bolt_schedule_name_commands_json
        boltScheduleCommandCommand: bolt_schedule_command_command
        runResultArgsWhich: run_result_args_which
        boltScheduleNameScheduleCron: bolt_schedule_name_schedule_cron
        boltScheduleRunStatus: bolt_schedule_run_status
        boltScheduleRunStartDttm: bolt_schedule_run_start_dttm {
          value
        }
        boltScheduleRunEndDttm: bolt_schedule_run_end_dttm {
          value
        }
      }
    }
  }
`;

export const latestModelRunColumns = [
  { field: 'boltScheduleName', header: 'Schedule name' },
  { field: 'boltScheduleCommandCommand', header: 'Schedule executed commands' },
  { field: 'runResultArgsWhich', header: 'dbt invocation commands which' },
  // { field: 'runResultArgsExclude', header: 'dbt invocation commands exclude' },
  // { field: 'runResultArgsSelect', header: 'dbt invocation commands select' },
  { field: 'boltScheduleNameScheduleCron', header: 'Schedule cron config' },
  { field: 'executionTime', header: 'Schedule execution time' },
  { field: 'boltScheduleRunStatus', header: 'Schedule run status', type: ColumnType.CHIP },
  { field: 'boltScheduleRunStartDttm', header: 'Schedule start time' },
  { field: 'boltScheduleRunEndDttm', header: 'Schedule end time' },
].map((col) => ({ ...col, sortable: true }));

export const formatLatestModelRunForChart = (data?: latestModelRunReturnType) => {
  if (!data) return [];

  return data.cube.map(({ modelLatestScheduleRuns }) => ({
    ...modelLatestScheduleRuns,
    boltScheduleRunStartDttm: modelLatestScheduleRuns.boltScheduleRunStartDttm.value,
    boltScheduleRunEndDttm: modelLatestScheduleRuns.boltScheduleRunEndDttm.value,
    boltScheduleRunStatus: getTableChips(modelLatestScheduleRuns.boltScheduleRunStatus),
  }));
};

export interface completedModelCountReturnType {
  cube: {
    modelRunCountByStatus: {
      totalRuns: number,
    }
  }[]
}

export const completedModelCountQuery = gql`
  query completedModelCountQuery($timeframe: [String], $modelName: String) {
    cube(
      limit: 5000
      where: {model_run_count_by_status: {model_name: {equals: $modelName}, run_result_status: {notIn: "skipped"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      modelRunCountByStatus: model_run_count_by_status {
        totalRuns: total_runs
      }
    }
  }
`;

export interface succeededModelCountReturnType {
  cube: {
    modelRunCountByStatus: {
      totalRuns: number,
    }
  }[]
}

export const succeededModelCountQuery = gql`
  query succeededModelCountQuery($timeframe: [String], $modelName: String) {
    cube(
      limit: 5000
      where: {model_run_count_by_status: {model_name: {equals: $modelName}, run_result_status: {equals: "success"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      modelRunCountByStatus: model_run_count_by_status {
        totalRuns: total_runs
      }
    }
  }
`;

export interface erroredModelCountReturnType {
  cube: {
    modelRunCountByStatus: {
      totalRuns: number,
    }
  }[]
}

export const erroredModelCountQuery = gql`
  query erroredModelCountQuery($timeframe: [String], $modelName: String) {
    cube(
      limit: 5000
      where: {model_run_count_by_status: {model_name: {equals: $modelName}, run_result_status: {equals: "error"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      modelRunCountByStatus: model_run_count_by_status {
        totalRuns: total_runs
      }
    }
  }
`;

export interface modelSuccessRateReturnType {
  cube: {
    modelSuccessRate: {
      successRate: number,
    }
  }[]
}

export const modelSuccessRateQuery = gql`
  query modelSuccessRateQuery($timeframe: [String], $modelName: String) {
    cube(
      limit: 5000
      where: {model_success_rate: {model_name: {equals: $modelName}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      modelSuccessRate: model_success_rate {
        successRate: success_rate
      }
    }
  }
`;
