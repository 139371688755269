import {
  GetDwhCostConfigQuery,
  GetDwhMetadataConfigQuery,
  GetOrganisationDbConfigsQuery,
} from '../../../client/generated/service';
import allWarehouses, {
  Bigquery,
  BigqueryCosts,
  BigqueryMetadata,
  Redshift,
  RedshiftMetadata,
  Snowflake,
  SnowflakeCosts,
  SnowflakeMetadata,
  Firebolt,
  FireboltMetadata,
  Databricks,
  MotherDuck,
} from './Definitions';
import { WarehouseComponentType } from './warehouseFactory/types';
import bigqueryLogo from './images/bigquery.svg';
import redshiftLogo from './images/redshift.svg';
import snowflakeLogo from './images/snowflake.svg';
import fireboltLogo from './images/firebolt.svg';
import databricksLogo from './images/databricks.svg';
import motherDuckLogo from './images/motherDuck.svg';
// import duckDbLogo from './images/duckdb.svg';
import { initialFormDataType } from './useGetInitialWarehouseData';
import { WareHouseModalSource } from '.';

export type apolloFunction<T> = ({ variables }: { variables: T }) => void

export interface useDatabaseHookProps {
  formData?: initialFormDataType,
  env: WarehouseEnv,
  source: WareHouseModalSource,
  isEditable: boolean,
  isAdditionalUser: boolean,
  isNewConnection: boolean,
}

export interface WarehouseType<T> {
  useDatabaseHook: ({
    formData,
    env,
    source,
    isEditable,
    isAdditionalUser,
    isNewConnection,
  }: useDatabaseHookProps) => ({
    initialFormData: T,
    onboarded?: boolean,
    errorMessage: string,
    formDistribution: string,
    form: WarehouseComponentType[],
    submitButtonText: string,
    onSubmitButtonClick: (formData: any) => void,
    dataIsBeingChecked?: boolean,
  }),
}

export type snowflakeConfig = GetDwhMetadataConfigQuery['getDwhMetadataConfig']['snowflakeConfig']
export type bigqueryConfig = GetDwhMetadataConfigQuery['getDwhMetadataConfig']['bigqueryConfig']
export type redshiftConfig = GetDwhMetadataConfigQuery['getDwhMetadataConfig']['redshiftConfig']

export type bigqueryOrgConfig = NonNullable<GetOrganisationDbConfigsQuery['getOrganisationDbConfigs']['incomplete'][number]['configuration']>['bigqueryOrgConfig']
export type redshiftOrgConfig = NonNullable<GetOrganisationDbConfigsQuery['getOrganisationDbConfigs']['incomplete'][number]['configuration']>['redshiftOrgConfig']
export type snowflakeOrgConfig = NonNullable<GetOrganisationDbConfigsQuery['getOrganisationDbConfigs']['incomplete'][number]['configuration']>['snowflakeOrgConfig']

export type databaseType = GetDwhMetadataConfigQuery['getDwhMetadataConfig']['databaseType']

export enum WarehouseEnv {
  'DEV' = 'dev',
  'PROD' = 'prod',
  'COSTS' = 'costs',
  'METADATA' = 'metadata',
}

export enum Warehouse {
  'BIGQUERY' = 'bigquery',
  'SNOWFLAKE' = 'snowflake',
  'REDSHIFT' = 'redshift',
  'FIREBOLT' = 'firebolt',
  'DATABRICKS' = 'databricks',
  'DUCK_DB' = 'duckdb',
  'MOTHERDUCK' = 'motherduck',
  'UNKNOWN' = 'unknown'
}

export type MetadataConnection = NonNullable<GetDwhMetadataConfigQuery['getDwhMetadataConfig']['snowflakeConfig']>
export type CostConnection = NonNullable<GetDwhCostConfigQuery['getDwhCostConfig']['snowflakeConfig']>
export type warehouseCompleteConfig = GetOrganisationDbConfigsQuery['getOrganisationDbConfigs']['configurations']
export type warehouseIncompleteConfig = GetOrganisationDbConfigsQuery['getOrganisationDbConfigs']['incomplete']

export type ValueOf<T> = T[keyof T];

type anyWarehouse = ValueOf<typeof allWarehouses>

export type WarehouseOptions = {
  name: Warehouse,
  warehouse: anyWarehouse,
}[];

export type BigButtonsOptions = {
  name: Warehouse,
  image: string,
  conditions: Warehouse[],
  disabled?: boolean
}[]

export const toBase64 = (file: File) => new Promise<string>((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result as string);
  reader.onerror = (error) => reject(new Error(`${error}, blue-cat`));
});

// eslint-disable-next-line max-len
export const useGetDevProdWarehousesButtons = (): BigButtonsOptions => [
  {
    name: Warehouse.BIGQUERY,
    image: bigqueryLogo,
    conditions: [Warehouse.UNKNOWN, Warehouse.BIGQUERY],
  },
  {
    name: Warehouse.DATABRICKS,
    image: databricksLogo,
    conditions: [Warehouse.UNKNOWN, Warehouse.DATABRICKS],
  },
  // {
  //   name: Warehouse.DUCK_DB,
  //   image: duckDbLogo,
  //   conditions: [Warehouse.UNKNOWN, Warehouse.DUCK_DB],
  // },
  {
    name: Warehouse.FIREBOLT,
    image: fireboltLogo,
    conditions: [Warehouse.UNKNOWN, Warehouse.FIREBOLT],
  },
  {
    name: Warehouse.MOTHERDUCK,
    image: motherDuckLogo,
    conditions: [Warehouse.UNKNOWN, Warehouse.MOTHERDUCK],
  },
  {
    name: Warehouse.REDSHIFT,
    image: redshiftLogo,
    conditions: [Warehouse.UNKNOWN, Warehouse.REDSHIFT],
  },
  {
    name: Warehouse.SNOWFLAKE,
    image: snowflakeLogo,
    conditions: [Warehouse.UNKNOWN, Warehouse.SNOWFLAKE],
  },
];

export const DevProdWarehousesButtonsJest: BigButtonsOptions = [
  {
    name: Warehouse.SNOWFLAKE,
    image: snowflakeLogo,
    conditions: [Warehouse.UNKNOWN, Warehouse.SNOWFLAKE],
  },
  {
    name: Warehouse.REDSHIFT,
    image: redshiftLogo,
    conditions: [Warehouse.UNKNOWN, Warehouse.REDSHIFT],
  },
  {
    name: Warehouse.FIREBOLT,
    image: fireboltLogo,
    conditions: [Warehouse.UNKNOWN, Warehouse.FIREBOLT],
  },
  {
    name: Warehouse.BIGQUERY,
    image: bigqueryLogo,
    conditions: [Warehouse.UNKNOWN, Warehouse.BIGQUERY],
  },
  {
    name: Warehouse.DUCK_DB,
    image: motherDuckLogo,
    conditions: [Warehouse.UNKNOWN, Warehouse.DUCK_DB],
  },
];

export const devProdWarehouseOptions: WarehouseOptions = [
  {
    name: Warehouse.BIGQUERY,
    warehouse: Bigquery,
  },
  {
    name: Warehouse.SNOWFLAKE,
    warehouse: Snowflake,
  },
  {
    name: Warehouse.REDSHIFT,
    warehouse: Redshift,
  },
  {
    name: Warehouse.FIREBOLT,
    warehouse: Firebolt,
  },
  {
    name: Warehouse.DATABRICKS,
    warehouse: Databricks,
  },
  {
    name: Warehouse.MOTHERDUCK,
    warehouse: MotherDuck,
  },
  {
    name: Warehouse.DUCK_DB,
    warehouse: MotherDuck,
  },
];

export const metadataWarehousesButtons: BigButtonsOptions = [
  {
    name: Warehouse.BIGQUERY,
    image: bigqueryLogo,
    conditions: [Warehouse.UNKNOWN, Warehouse.BIGQUERY],
  },
  {
    name: Warehouse.FIREBOLT,
    image: fireboltLogo,
    conditions: [Warehouse.UNKNOWN, Warehouse.FIREBOLT],
  },
  {
    name: Warehouse.REDSHIFT,
    image: redshiftLogo,
    conditions: [Warehouse.UNKNOWN, Warehouse.REDSHIFT],
  },
  {
    name: Warehouse.SNOWFLAKE,
    image: snowflakeLogo,
    conditions: [Warehouse.UNKNOWN, Warehouse.SNOWFLAKE],
  },
  {
    name: Warehouse.DATABRICKS,
    image: databricksLogo,
    conditions: [Warehouse.UNKNOWN, Warehouse.DATABRICKS],
    disabled: true,
  },
  {
    name: Warehouse.DUCK_DB,
    image: motherDuckLogo,
    conditions: [Warehouse.UNKNOWN, Warehouse.DUCK_DB],
    disabled: true,
  },
];

export const costsWarehousesButtons: BigButtonsOptions = [
  {
    name: Warehouse.SNOWFLAKE,
    image: snowflakeLogo,
    conditions: [Warehouse.UNKNOWN, Warehouse.SNOWFLAKE],
  },
  {
    name: Warehouse.BIGQUERY,
    image: bigqueryLogo,
    conditions: [Warehouse.UNKNOWN, Warehouse.BIGQUERY],
  },
  {
    name: Warehouse.DATABRICKS,
    image: databricksLogo,
    conditions: [Warehouse.UNKNOWN, Warehouse.DATABRICKS],
    disabled: true,
  },
  {
    name: Warehouse.FIREBOLT,
    image: fireboltLogo,
    conditions: [Warehouse.UNKNOWN, Warehouse.FIREBOLT],
    disabled: true,
  },
  {
    name: Warehouse.REDSHIFT,
    image: redshiftLogo,
    conditions: [Warehouse.UNKNOWN, Warehouse.REDSHIFT],
    disabled: true,
  },
  {
    name: Warehouse.DUCK_DB,
    image: motherDuckLogo,
    conditions: [Warehouse.UNKNOWN, Warehouse.DUCK_DB],
    disabled: true,
  },
];

export const metadataProdWarehouseOptions: WarehouseOptions = [
  {
    name: Warehouse.BIGQUERY,
    warehouse: BigqueryMetadata,
  },
  {
    name: Warehouse.SNOWFLAKE,
    warehouse: SnowflakeMetadata,
  },
  {
    name: Warehouse.REDSHIFT,
    warehouse: RedshiftMetadata,
  },
  {
    name: Warehouse.FIREBOLT,
    warehouse: FireboltMetadata,
  },
];

export const costsProdWarehouseOptions: WarehouseOptions = [
  {
    name: Warehouse.BIGQUERY,
    warehouse: BigqueryCosts,
  },
  {
    name: Warehouse.SNOWFLAKE,
    warehouse: SnowflakeCosts,
  },
  {
    name: Warehouse.REDSHIFT,
    warehouse: Redshift,
  },
];
