import styled from 'styled-components';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { Chips } from '@paradime-io/pragma-ui-kit';

const CodeTypography = styled(Typography)`
& > code {
  font-family: 'Courier New', Courier, monospace;
  background-color: var(--grey5);
  color: var(--grey100);
  font-weight: 500;
  padding: 2px 4px;
  border-radius: 4px;
}
`;

const DialogCodeBoxWrapper = styled.div`
  height: 100%;
  width: 100%;
  & > div > div {
      height: 100%;
    }
`;

export const CommandCodeBoxWrapper = styled.div`
  & > div > div > div::-webkit-scrollbar-track {
    background: var(--grey0);
  }

  & > div > div > div::-webkit-scrollbar-corner {
    background: var(--grey0);
  }
`;

const SmallIconChip = styled(Chips)`
  &[class*="tag"] span[icon]:first-of-type > svg {
    width: 12px;
    height: 12px;
  }
`;

export { CodeTypography, DialogCodeBoxWrapper, SmallIconChip };
