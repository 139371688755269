import { gql } from '@apollo/client';
import { key } from '../../../utilis';
import { runStatuses, getStatusColour } from '../utils';

export interface modelMaterializationCountReturnType {
  cube: {
    modelMaterializationCount: {
      distinctModelNameCount: number,
      modelConfigMaterialized: string,
    }
  }[]
}

export const modelMaterializationCountQuery = gql`
  query modelMaterializationCountQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {model_materialization_count: {is_dbt_model_current_status: {equals: "true"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      modelMaterializationCount: model_materialization_count(orderBy: {distinct_model_name_count: desc}) {
        distinctModelNameCount: distinct_model_name_count
        modelConfigMaterialized: model_config_materialized
      }
    }
  }
`;

export const formatModelMaterializationCountForChart = (
  data?: modelMaterializationCountReturnType,
) => {
  if (!data) return [];

  return [data.cube
    .map(({ modelMaterializationCount }) => ({
      x: modelMaterializationCount.modelConfigMaterialized,
      y: modelMaterializationCount.distinctModelNameCount,
    }))];
};

export const getModelMaterializationCountXAxisLabels = (
  data?: modelMaterializationCountReturnType,
) => {
  if (!data) return [];

  const cronStrings = data.cube
    .map(({ modelMaterializationCount }) => modelMaterializationCount.modelConfigMaterialized);
  return Array.from(new Set(cronStrings));
};

export interface modelRunStatusReturnType {
  cube: {
    modelCurrentStatusCount: {
      distinctModelNameCount: number,
      runResultStatus: 'success' | 'error' | 'skipped',
    }
  }[]
}

export const modelRunStatusQuery = gql`
  query modelRunStatusQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {model_current_status_count: {is_dbt_model_current_status: {equals: "true"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      modelCurrentStatusCount: model_current_status_count {
        distinctModelNameCount: distinct_model_name_count
        runResultStatus: run_result_status
      }
    }
  }
`;

export const formatModelRunStatusForChart = (data?: modelRunStatusReturnType) => {
  if (!data) return { data: [], statuses: [], colours: [] };

  const dataToReturn: { x: string, y: number }[] = [];
  const legendLabels: string[] = [];
  const legendColours: string[] = [];

  runStatuses.forEach((status) => {
    const models = data.cube
      .filter(({ modelCurrentStatusCount }) => modelCurrentStatusCount.runResultStatus === status)
      .map(({ modelCurrentStatusCount }) => ({
        x: modelCurrentStatusCount.runResultStatus,
        y: modelCurrentStatusCount.distinctModelNameCount,
      }));

    if (models.length > 0) {
      dataToReturn.push(...models);
      legendLabels.push(status);
      legendColours.push(getStatusColour(status));
    }
  });

  return { data: dataToReturn, statuses: legendLabels, colours: legendColours };
};

export interface modelStatusCountReturnType {
  cube: {
    modelCurrentStatusCount: {
      distinctModelNameCount: number,
      runResultStatus: 'success' | 'error' | 'skipped',
      calendarDate: {
        day: string,
      }
    }
  }[]
}

export const modelStatusCountQuery = gql`
  query modelStatusCountQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {model_current_status_count: {calendar_date: {inDateRange: $timeframe}}}
    ) {
      modelCurrentStatusCount: model_current_status_count(orderBy: {calendar_date: asc}) {
        distinctModelNameCount: distinct_model_name_count
        runResultStatus: run_result_status
        calendarDate: calendar_date {
          day
        }
      }
    }
  }
`;

export const formatModelStatusCountForChart = (data?: modelStatusCountReturnType) => {
  if (!data) return { data: [], statuses: [], colours: [] };

  const dataToReturn: { x: string, y: number }[][] = [];
  const legendLabels: string[] = [];
  const legendColours: string[] = [];

  runStatuses.forEach((status) => {
    const models = data.cube
      .filter(({ modelCurrentStatusCount }) => modelCurrentStatusCount.runResultStatus === status)
      .map(({ modelCurrentStatusCount }) => ({
        x: modelCurrentStatusCount.calendarDate.day,
        y: modelCurrentStatusCount.distinctModelNameCount,
      }));

    if (models.length > 0) {
      dataToReturn.push(models);
      legendLabels.push(status);
      legendColours.push(getStatusColour(status));
    }
  });

  return { data: dataToReturn, statuses: legendLabels, colours: legendColours };
};

export const getModelStatusCountXAxisLabels = (data?: modelStatusCountReturnType) => {
  if (!data) return [];

  const datesList = data.cube
    .map(({ modelCurrentStatusCount }) => modelCurrentStatusCount.calendarDate.day);
  return Array.from(new Set(datesList));
};

export interface modelTimeStatsReturnType {
  cube: {
    modelExecutionTimeStats: {
      avgExecutionTime: number,
      maxExecutionTime: number,
      minExecutionTime: number,
      modelName: string,
    }
  }[]
}

export const modelTimeStatsQuery = gql`
  query modelTimeStatsQuery($timeframe: [String]) {
    cube(
      limit: 10
      where: {model_execution_time_stats: {run_result_status: {equals: "success"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      modelExecutionTimeStats: model_execution_time_stats(orderBy: {avg_execution_time: desc}) {
        avgExecutionTime: avg_execution_time
        maxExecutionTime: max_execution_time
        minExecutionTime: min_execution_time
        modelName: model_name
      }
    }
  }
`;

export const formatModelTimeStatsForChart = (data?: modelTimeStatsReturnType) => {
  if (!data) return [];

  const minExecution = data.cube
    .map(({ modelExecutionTimeStats }) => ({
      x: modelExecutionTimeStats.modelName,
      y: modelExecutionTimeStats.minExecutionTime,
    }));

  const avgExecution = data.cube
    .map(({ modelExecutionTimeStats }) => ({
      x: modelExecutionTimeStats.modelName,
      y: modelExecutionTimeStats.avgExecutionTime,
    }));

  const maxExecution = data.cube
    .map(({ modelExecutionTimeStats }) => ({
      x: modelExecutionTimeStats.modelName,
      y: modelExecutionTimeStats.maxExecutionTime,
    }));

  return [minExecution, avgExecution, maxExecution];
};

export const getModelTimeStatsXAxisLabels = (data?: modelTimeStatsReturnType) => {
  if (!data) return [];

  const datesList = data.cube
    .map(({ modelExecutionTimeStats }) => modelExecutionTimeStats.modelName);
  return Array.from(new Set(datesList));
};

export interface modelDailyStatusReturnType {
  cube: {
    modelDailyRunStatusCount: {
      distinctBoltScheduleRunCount: number,
      modelName: string,
    }
  }[]
}

export const modelDailyStatusQuery = gql`
  query modelDailyStatusQuery($timeframe: [String]) {
    cube(
      limit: 10
      where: {model_daily_run_status_count: {run_result_status: {equals: "error"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      modelDailyRunStatusCount: model_daily_run_status_count(orderBy: {distinct_bolt_schedule_run_count: desc}) {
        distinctBoltScheduleRunCount: distinct_bolt_schedule_run_count
        modelName: model_name
      }
    }
  }
`;

export const formatModelDailyStatusForChart = (
  data?: modelDailyStatusReturnType,
) => {
  if (!data) return [];

  return [data.cube
    .map(({ modelDailyRunStatusCount }) => ({
      x: modelDailyRunStatusCount.modelName,
      y: modelDailyRunStatusCount.distinctBoltScheduleRunCount,
    }))];
};

export const getModelDailyStatusXAxisLabels = (
  data?: modelDailyStatusReturnType,
) => {
  if (!data) return [];

  const datesList = data.cube
    .map(({ modelDailyRunStatusCount }) => modelDailyRunStatusCount.modelName);
  return Array.from(new Set(datesList));
};

export interface modelStatsReturnType {
  cube: {
    modelRunStatistics: {
      distinctBoltScheduleNameCount: number,
      boltScheduleName: string,
      modelPackageName: string,
      modelName: string,
      runResultUniqueId: string,
      modelConfigMaterialized: string,
    }
  }[]
}

export const modelStatsQuery = gql`
  query modelStatsQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {model_run_statistics: {is_schedule_current_status: {equals: "true"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      modelRunStatistics: model_run_statistics(orderBy: {distinct_bolt_schedule_name_count: desc}) {
        distinctBoltScheduleNameCount: distinct_bolt_schedule_name_count
        boltScheduleName: bolt_schedule_name
        modelPackageName: model_package_name
        modelName: model_name
        runResultUniqueId: run_result_unique_id
        modelConfigMaterialized: model_config_materialized
      }
    }
  }
`;

export const modelStatsColumns = [
  { field: 'modelName', header: 'dbt™ model name', expander: true },
  { field: 'runResultUniqueId', header: 'dbt™ model unique id' },
  { field: 'modelPackageName', header: 'Package name' },
  { field: 'modelConfigMaterialized', header: 'Model type' },
  { field: 'distinctBoltScheduleNameCount', header: 'Count schedules' },
].map((col) => ({ ...col, sortable: true }));

export const formatModelStatsForChart = (data?: modelStatsReturnType) => {
  if (!data) return [];

  const modelNames = Array.from(new Set(
    data.cube.map(({ modelRunStatistics }) => modelRunStatistics.modelName),
  ));

  const dataToReturn: { key: string, data: any, children?: any[] }[] = [];

  modelNames.forEach((modelName) => {
    const dataInThisGroup = data?.cube
      .filter(({ modelRunStatistics }) => modelRunStatistics.modelName === modelName);

    const distinctBoltScheduleNameCount = dataInThisGroup?.reduce((currentTotal, datum) => (
      currentTotal + datum.modelRunStatistics.distinctBoltScheduleNameCount
    ), 0) || 0;

    const modelPackageName = Array.from(new Set(
      dataInThisGroup.map(({ modelRunStatistics }) => (
        modelRunStatistics.modelPackageName
      )),
    )).join(', ');

    const runResultUniqueId = Array.from(new Set(
      dataInThisGroup.map(({ modelRunStatistics }) => (
        modelRunStatistics.runResultUniqueId
      )),
    )).join(', ');

    const modelConfigMaterialized = Array.from(new Set(
      dataInThisGroup.map(({ modelRunStatistics }) => (
        modelRunStatistics.modelConfigMaterialized
      )),
    )).join(', ');

    const childRows = dataInThisGroup
      .map(({ modelRunStatistics }) => ({
        key: key(modelRunStatistics.runResultUniqueId),
        data: {
          modelName: modelRunStatistics.boltScheduleName,
        },
      }));

    const formattedData = {
      key: key(modelName),
      data: {
        modelName,
        distinctBoltScheduleNameCount,
        modelPackageName,
        runResultUniqueId,
        modelConfigMaterialized,
      },
      children: childRows,
    };

    dataToReturn.push(formattedData);
  });

  return dataToReturn;
};
