import create from 'zustand';
import { combine } from 'zustand/middleware';
import { BannerItemType, AppVideoItemType, ChangelogItemType } from '../components/Home/hooks/useGetHomeStaticData';
import { WorkspaceTeamDisplayDataType } from '../components/Home/hooks/useGetTeamData';

export const homeStore = create(
  combine(
    {
      changelog: undefined as ChangelogItemType[] | undefined,
      banner: undefined as BannerItemType | undefined,
      videos: undefined as AppVideoItemType[] | undefined,
      modelsCount: undefined as string | number | undefined,
      testsCount: undefined as string | number | undefined,
      macrosCount: undefined as string | number | undefined,
      schedulesCount: undefined as string | number | undefined,
      workspaceTeamDisplayData: undefined as WorkspaceTeamDisplayDataType | undefined,
    },
    (set) => ({
      setChangelog: (changelog: ChangelogItemType[]) => set(() => ({ changelog })),
      setBanner: (banner: BannerItemType) => set(() => ({ banner })),
      setVideos: (videos: AppVideoItemType[]) => set(() => ({ videos })),
      setModelsCount: (modelsCount: string | number) => set(() => ({ modelsCount })),
      setTestsCount: (testsCount: string | number) => set(() => ({ testsCount })),
      setMacrosCount: (macrosCount: string | number) => set(() => ({ macrosCount })),
      setSchedulesCount: (schedulesCount: string | number) => set(() => ({ schedulesCount })),
      setWorkspaceTeamDisplayData: (
        workspaceTeamDisplayData: WorkspaceTeamDisplayDataType,
      ) => set(() => ({ workspaceTeamDisplayData })),
    }),
  ),
);
