import WarehouseConnectionRowItem from '@paradime-io/pragma-ui-kit/lib/components/WarehouseConnectionRowItem';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import React, { FC, useContext, useState } from 'react';
import {
  getConnectionLogo,
  getCompleteParameters,
  getViewEditCompleteFormData,
  warehouseCompleteConfigItem,
} from '../utils';
import {
  Warehouse as WarehouseType,
  WarehouseEnv,
} from '../../Common/Warehouses/utils';
import { EditableContext } from '.';
import { GqlParadimeAccountType } from '../../../client/generated/service';
import { NewConnectionModalContext } from './ConnectionFactory';

export interface CompleteConnectionRowProps {
  datum: warehouseCompleteConfigItem,
  accessLevel: GqlParadimeAccountType,
  env: WarehouseEnv,
}

const CompleteConnectionRow: FC<CompleteConnectionRowProps> = ({
  datum,
  accessLevel,
  env,
}) => {
  const [isEditable, setIsEditable] = useState(false);

  const {
    setShowNewConnectionModal, setInitialModalData,
  } = useContext(NewConnectionModalContext);

  const connectionLogo = getConnectionLogo(
    datum.configuration?.databaseType as WarehouseType,
  );

  const parameters = getCompleteParameters({
    ...datum.configuration!,
    accessLevel,
    env,
  });

  const viewEditFormData = getViewEditCompleteFormData(datum.configuration!);

  const openModal = () => {
    setShowNewConnectionModal(true);
    setInitialModalData({
      initialDatabaseType: datum.configuration?.databaseType,
      viewEditFormData,
      viewEditPrefilled: accessLevel !== GqlParadimeAccountType.Admin,
      credentialId: datum.credentialId,
      isDefault: datum.isDefault,
      connectionName: parameters.find((i) => i.name === 'target name')?.value,
      newConnection: false,
    });
  };

  return (
    <EditableContext.Provider value={{ isEditable, setIsEditable }}>
      <div data-testid="CompleteConnectionRow">
        <WarehouseConnectionRowItem
          logo={connectionLogo}
          onEdit={() => {}}
          onView={() => {
            openModal();
          }}
          parameters={parameters}
          isDefault={datum.isDefault}
          state="connected"
          isAdmin={false} // To hide the "edit" button
          eventContext={Contexts.ACCOUNT_SETTINGS}
        />
      </div>
    </EditableContext.Provider>
  );
};

export default CompleteConnectionRow;
