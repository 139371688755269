import React, { FC } from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import * as S from '../../Common/Layouts/AppPage.styles';
import Schedule from './Schedule';
import ScheduleList from './ScheduleList';
import NewSchedule from './NewSchedule';
import EditSchedule from './EditSchedule';
import Run from './Run';
import TemplateSchedules from './TemplateSchedules';

export enum BoltTab {
  BOLT = 'bolt',
  THOUGHTSPOT = 'thoughtSpot',
}

const Bolt: FC = () => {
  const { path } = useRouteMatch();
  return (
    <>
      <S.Body>
        <AutoLayout
          direction="vertical"
          padding="expanded"
          horizontalGap="dense"
          distribution="packed"
          alignment="top-left"
        >
          <Switch>
            <Route exact path={path} component={ScheduleList} />
            <Route exact path={`${path}/new-schedule`} component={NewSchedule} />
            <Route exact path={`${path}/new-schedule/from/:scheduleId`} component={NewSchedule} />
            <Route exact path={`${path}/template-schedules`} component={TemplateSchedules} />
            <Route exact path={`${path}/:scheduleId`} component={Schedule} />
            <Route exact path={`${path}/:scheduleId/edit`} component={EditSchedule} />
            <Route exact path={`${path}/run_id/:runId`} component={Run} />
            <Route exact path={`${path}/:scheduleId/:runUuid`} component={Run} />
          </Switch>
        </AutoLayout>
      </S.Body>
    </>
  );
};
export default Bolt;
