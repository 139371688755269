import { useFlow } from '@frigade/react';

export const useMarkStepCompleted = (flowId: string) => {
  const { flow } = useFlow(flowId);

  const markStepCompleted = (stepId: string) => (
    flow?.steps?.get(stepId)?.complete()
  );

  return { markStepCompleted };
};
