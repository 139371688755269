import { useDatabaseHookProps } from '../../utils';
import { useCommonConfig, DatabricksFormDataType } from './CommonConfig';
import { formDistribution, initialValues, formFields } from './dwhFormFields';

export type { DatabricksFormDataType };

export const useDatabaseHook = ({
  formData,
  env,
  source,
  isEditable,
  isAdditionalUser,
  isNewConnection,
}: useDatabaseHookProps) => {
  const commonConfigs = useCommonConfig({
    formData,
    env,
    source,
    isEditable,
    isAdditionalUser,
    isNewConnection,
  });

  return {
    ...commonConfigs,
    initialFormData: initialValues(env, formData),
    formDistribution: formDistribution(commonConfigs.showEditButton),
    form: formFields(
      env,
      commonConfigs.showEditButton,
      commonConfigs.isEditingToken,
      commonConfigs.setIsEditingToken,
    ),
  };
};
