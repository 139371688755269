import React, { FC, MutableRefObject, ReactNode } from 'react';
import { CSSProperties } from 'styled-components';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import * as S from '../../Common/UserInsights/UserInsights.styled';
import ZeroStateErrorFetchingData from '../../Common/UserInsights/ZeroStateErrorFetchingData';

export interface MetricCardProps {
  title?: string,
  content: ReactNode,
  hasError: boolean,
  dense?: boolean,
  ref?: MutableRefObject<null>,
  style?: CSSProperties,
}

const MetricCard: FC<MetricCardProps> = ({
  title,
  content,
  hasError,
  dense,
  ref,
  style,
}) => (
  <S.MetricCard ref={ref} style={style}>
    <AutoLayout
      direction="vertical"
      padding="none"
      verticalGap="dense"
      distribution="packed"
      alignment="top"
      style={{
        gridTemplateRows: dense ? 'auto' : 'min-content auto',
        height: '100%',
      }}
    >
      {title && (
        <Typography
          font="inter"
          type="body-bold"
          color="default"
          colorStep="100"
          style={{ padding: '8px', borderBottom: '1px solid var(--grey10)' }}
        >
          {title}
        </Typography>
      )}
      {hasError
        ? <ZeroStateErrorFetchingData />
        : <div style={{ padding: dense ? '16px' : '24px', height: '100%' }}>{content}</div>}
    </AutoLayout>
  </S.MetricCard>
);

export default MetricCard;
