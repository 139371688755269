import React, { FC, useState } from 'react';
import { gql } from '@apollo/client';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { IntegrationItemCard, IntegrationState } from '@paradime-io/pragma-ui-kit/lib/components/IntegrationItemCard';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import Callout from '@paradime-io/pragma-ui-kit/lib/components/Callout';
import { FilterButtonsList } from './FilterButtonsList';
import { useHasuraQuery } from '../../../app/hooks/useHasuraQuery';

interface webhookTemplateQueryResponse {
  categoryData: { category: string }[],
  templateData: TemplateItem[],
}

interface TemplateItem {
  id: string,
  category: string,
  title: string,
  description: string,
  image_path: string, // eslint-disable-line camelcase
  action_url: string, // eslint-disable-line camelcase
}

const webhookTemplatesQuery = gql`
  query WebhookTemplatesQuery @cached(ttl: 600) {
    categoryData: sheets_load_paradime_webhooks_templates (distinct_on: category, order_by: { category: asc }) {
      category
    }
    templateData: sheets_load_paradime_webhooks_templates {
      id
      category
      title
      description
      image_path
      action_url
    }
  }
`;

const ExploreTemplates: FC = () => {
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [templates, setTemplates] = useState<TemplateItem[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [hasError, setHasError] = useState(false);

  const { loading } = useHasuraQuery<webhookTemplateQueryResponse>({
    query: webhookTemplatesQuery,
    onCompleted: ({ data, error }) => {
      if (error) {
        setHasError(true);
      } else {
        setHasError(false);

        const { categoryData, templateData } = data;
        setTemplates(templateData);

        const templateCategories = categoryData.map(({ category }) => category);
        setCategories(['all', ...templateCategories]);
      }
    },
  });

  const filterCategories = categories.map((category) => ({
    label: category,
    onClick: () => setSelectedCategory(category),
  }));

  if (loading) return <Spinner />;

  if (hasError) {
    return (
      <Callout
        dense
        view="smooth"
        color="danger"
        icon="error"
        title="Unable to fetch templates"
        content="Something went wrong while processing your request, please try again later."
      />
    );
  }

  return (
    <AutoLayout
      direction="horizontal"
      padding="none"
      horizontalGap="normal"
      distribution="packed"
      style={{ gridTemplateColumns: '200px calc(100% - 200px - 24px)' }}
    >
      <FilterButtonsList list={filterCategories} selectedFilter={selectedCategory} />
      <AutoLayout
        direction="vertical"
        padding="none"
        verticalGap="dense"
        distribution="packed"
      >
        {templates
          .filter((template) => {
            if (selectedCategory === 'all') return true;
            return template.category === selectedCategory;
          })
          .map((categoryItem) => (
            <IntegrationItemCard
              key={categoryItem.id}
              icon={categoryItem.image_path}
              name={categoryItem.title}
              category={categoryItem.category}
              description={categoryItem.description}
              buttonState={IntegrationState.viewDetails}
              onClick={() => window.open(categoryItem.action_url, '_blank')}
            />
          ))}
      </AutoLayout>
    </AutoLayout>
  );
};

export default ExploreTemplates;
