import React, { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { StackedBarChart, PieChart, SingleValueChart } from '@paradime-io/pragma-ui-kit/lib/components/VictoryCharts';
import { useCubeLazyQuery } from '../../../app/hooks/useCubeQuery';
import {
  bugCommitsQuery,
  bugCommitsReturnType,
  featureCommitsQuery,
  featureCommitsReturnType,
  countCommitTypesQuery,
  countCommitTypesReturnType,
  formatCountCommitTypesForChart,
  weeklyCommitsQuery,
  weeklyCommitsReturnType,
  formatWeeklyCommitsForChart,
  getWeeklyCommitsXAxisLabels,
  topFileContributionsQuery,
  topFileContributionsReturnType,
  formatTopFileContributionsForChart,
  getTopFileContributionsXAxisLabels,
  topBugContributionsQuery,
  topBugContributionsReturnType,
  formatTopBugContributionsForChart,
  getTopBugContributionsXAxisLabels,
} from './CommitsCubeQueries';
import {
  longerTimeframes,
  HalfPageChartSection,
  getSingleValueChartString,
  getFormattedYAxisLabel,
  singleValueChartHeight,
} from '../utils';
import FilterRow from '../FilterRow';
import { useGetFiltersFromUrl } from '../hooks/useGetFiltersFromUrl';

const CommitsTab: FC = () => {
  const { getInitialFilterValue } = useGetFiltersFromUrl();

  const [timeframe, setTimeframe] = useState(getInitialFilterValue('timeframe', longerTimeframes[0].value)!);

  const [
    getBugCommits, { data: bugCommitsData, loading: isLoadingBugCommits, error: bugCommitsError },
  ] = useCubeLazyQuery<bugCommitsReturnType>({
    query: bugCommitsQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getFeatureCommits,
    { data: featureCommitsData, loading: isLoadingFeatureCommits, error: featureCommitsError },
  ] = useCubeLazyQuery<featureCommitsReturnType>({
    query: featureCommitsQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getCountCommitTypes, {
      data: countCommitTypesData,
      loading: isLoadingCountCommitTypes,
      error: countCommitTypesError,
    },
  ] = useCubeLazyQuery<countCommitTypesReturnType>({
    query: countCommitTypesQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getWeeklyCommits,
    { data: weeklyCommitsData, loading: isLoadingWeeklyCommits, error: weeklyCommitsError },
  ] = useCubeLazyQuery<weeklyCommitsReturnType>({
    query: weeklyCommitsQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getTopFileContributions, {
      data: topFileContributionsData,
      loading: isLoadingTopFileContributions,
      error: topFileContributionsError,
    },
  ] = useCubeLazyQuery<topFileContributionsReturnType>({
    query: topFileContributionsQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getTopBugContributions, {
      data: topBugContributionsData,
      loading: isLoadingTopBugContributions,
      error: topBugContributionsError,
    },
  ] = useCubeLazyQuery<topBugContributionsReturnType>({
    query: topBugContributionsQuery,
    variables: { timeframe: timeframe.value },
  });

  const refetchAllData = () => {
    getBugCommits();
    getFeatureCommits();
    getCountCommitTypes();
    getWeeklyCommits();
    getTopFileContributions();
    getTopBugContributions();
  };

  useEffect(() => {
    refetchAllData();
  }, [timeframe]);

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      verticalGap="dense"
      distribution="packed"
    >
      <FilterRow
        dropdowns={[
          {
            value: timeframe,
            options: longerTimeframes,
            onChange: setTimeframe,
            name: 'timeframe',
            title: 'Select date range',
          },
        ]}
      />
      <div style={{
        display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: '16px',
      }}
      >
        <HalfPageChartSection height={singleValueChartHeight}>
          <SingleValueChart
            title="Total number of Bug Fix commits"
            subtitle="Monitor the total number of Bug Fix commits in selected time window."
            value={(
              getSingleValueChartString(
                bugCommitsData?.cube?.[0]?.githubDailyCommitsByType?.commitCount || 0,
              )
            )}
            isLoading={isLoadingBugCommits}
            hasNoData={bugCommitsError || bugCommitsData?.cube?.length === 0}
          />
        </HalfPageChartSection>
        <HalfPageChartSection height={singleValueChartHeight}>
          <SingleValueChart
            title="Total number of Feature commits"
            subtitle="Monitor the total number of Features commits in selected time window."
            value={(
              getSingleValueChartString(
                featureCommitsData?.cube?.[0]?.githubDailyCommitsByType?.commitCount || 0,
              )
            )}
            isLoading={isLoadingFeatureCommits}
            hasNoData={featureCommitsError || featureCommitsData?.cube?.length === 0}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <PieChart
            title="Commits count by type"
            subtitle="Track the percentage of commits created between bug fix and features over the selected time frame."
            data={formatCountCommitTypesForChart(countCommitTypesData)}
            isLoading={isLoadingCountCommitTypes}
            hasNoData={countCommitTypesError || countCommitTypesData?.cube?.length === 0}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Weekly commits by type"
            subtitle="Monitor how many commits are created in relations to bug fixes or new features."
            data={formatWeeklyCommitsForChart(weeklyCommitsData)}
            xAxisLabels={getWeeklyCommitsXAxisLabels(weeklyCommitsData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Calendar date"
            yLabel="Count code changes"
            isLoading={isLoadingWeeklyCommits}
            hasNoData={weeklyCommitsError || weeklyCommitsData?.cube?.length === 0}
            xAxisDomain={[
              0.5,
              getWeeklyCommitsXAxisLabels(weeklyCommitsData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Top 10 files with most contributions"
            subtitle="Monitor the files in your repository with most commits in the selected time period."
            data={formatTopFileContributionsForChart(topFileContributionsData)}
            xAxisLabels={getTopFileContributionsXAxisLabels(topFileContributionsData)}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="File name"
            yLabel="Count commits"
            isLoading={isLoadingTopFileContributions}
            hasNoData={topFileContributionsError || topFileContributionsData?.cube?.length === 0}
            maxCharsAxisX={15}
            xAxisDomain={[
              0.5,
              getTopFileContributionsXAxisLabels(topFileContributionsData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Top 10 files with most bug fix contributions"
            subtitle="Monitor the files in your repository with most commits of type bug fix in the selected time period."
            data={formatTopBugContributionsForChart(topBugContributionsData)}
            xAxisLabels={getTopBugContributionsXAxisLabels(topBugContributionsData)}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="File name"
            yLabel="Count commits"
            isLoading={isLoadingTopBugContributions}
            hasNoData={topBugContributionsError || topBugContributionsData?.cube?.length === 0}
            maxCharsAxisX={12}
            xAxisDomain={[
              0.5,
              getTopBugContributionsXAxisLabels(topBugContributionsData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </HalfPageChartSection>
      </div>
    </AutoLayout>
  );
};

export default CommitsTab;
