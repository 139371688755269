import React, { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import {
  StackedBarChart,
  PieChart,
  SingleValueChart,
  TableChart,
  ScatterChart,
} from '@paradime-io/pragma-ui-kit/lib/components/VictoryCharts';
import CodeSnippetCopy from '@paradime/common/lib/common/helpers/CodeSnippetCopy';
import { useCubeLazyQuery } from '../../../app/hooks/useCubeQuery';
import {
  scheduleNamesForDropdownQuery,
  scheduleNamesForDropdownReturnType,
  formatScheduleNamesForDropdown,
  successRateQuery,
  successRateReturnType,
  completedRunsCountQuery,
  completedRunsCountReturnType,
  successfulRunsCountQuery,
  successfulRunsCountReturnType,
  erroredRunsCountQuery,
  erroredRunsCountReturnType,
  runDurationsCountQuery,
  runDurationsReturnType,
  formatRunDurationsCountForChart,
  topTenDurationQuery,
  topTenDurationReturnType,
  formatTopTenDurationsForChart,
  getTopTenDurationsXAxisLabels,
  currentScheduleStatusQuery,
  currentScheduleStatusReturnType,
  currentScheduleStatusColumns,
  formatCurrentScheduleStatusForChart,
  durationByCommandQuery,
  durationByCommandReturnType,
  formatDurationByCommandForChart,
  durationByModelQuery,
  durationByModelReturnType,
  formatDurationByModelForChart,
  modelsInScheduleQuery,
  modelsInScheduleReturnType,
  modelsInScheduleColumns,
  formatModelsInScheduleForChart,
} from './DetailsCubeQueries';
import {
  timeframes,
  HalfPageChartSection,
  QuarterPageChartSection,
  FullPageChartSection,
  getSingleValueChartString,
  getFormattedYAxisLabel,
  singleValueChartHeight,
} from '../utils';
import FilterRow from '../FilterRow';
import { useGetFiltersFromUrl } from '../hooks/useGetFiltersFromUrl';

const DetailsTab: FC = () => {
  const { getInitialFilterValue } = useGetFiltersFromUrl();

  const [timeframe, setTimeframe] = useState(getInitialFilterValue('timeframe', timeframes[2].value)!);
  const [scheduleName, setScheduleName] = useState(getInitialFilterValue('scheduleName', ' ')!);

  const [
    getScheduleNamesForDropdown,
    { data: scheduleNamesForDropdown, loading: isLoadingScheduleNamesForDropdown },
  ] = useCubeLazyQuery<scheduleNamesForDropdownReturnType>({
    query: scheduleNamesForDropdownQuery,
    variables: { timeframe: timeframe.value },
    onCompleted: ({ data }) => {
      const firstOption = data.cube?.[0]?.scheduleRecentRuns?.boltScheduleName;
      const urlVal = getInitialFilterValue('scheduleName', firstOption);
      if (urlVal) setScheduleName(urlVal);
    },
  });

  const [
    getSuccessRate,
    { data: successRateData, loading: isLoadingSuccessRate, error: successRateError },
  ] = useCubeLazyQuery<successRateReturnType>({
    query: successRateQuery,
    variables: { timeframe: timeframe.value, scheduleName: scheduleName.value },
  });
  const [
    getCompletedCount,
    { data: completedCountData, loading: isLoadingCompletedCount, error: completedCountError },
  ] = useCubeLazyQuery<completedRunsCountReturnType>({
    query: completedRunsCountQuery,
    variables: { timeframe: timeframe.value, scheduleName: scheduleName.value },
  });
  const [
    getSuccessfulCount,
    { data: successfulCountData, loading: isLoadingSuccessfulCount, error: successfulCountError },
  ] = useCubeLazyQuery<successfulRunsCountReturnType>({
    query: successfulRunsCountQuery,
    variables: { timeframe: timeframe.value, scheduleName: scheduleName.value },
  });
  const [
    getErroredCount,
    { data: erroredCountData, loading: isLoadingErroredCount, error: erroredCountError },
  ] = useCubeLazyQuery<erroredRunsCountReturnType>({
    query: erroredRunsCountQuery,
    variables: { timeframe: timeframe.value, scheduleName: scheduleName.value },
  });
  const [
    getRunDurations,
    { data: runDurationsData, loading: isLoadingRunDurations, error: runDurationsError },
  ] = useCubeLazyQuery<runDurationsReturnType>({
    query: runDurationsCountQuery,
    variables: { timeframe: timeframe.value, scheduleName: scheduleName.value },
  });
  const [
    getTopTenDurations,
    { data: topTenDurationsData, loading: isLoadingTopTenDurations, error: topTenDurationsError },
  ] = useCubeLazyQuery<topTenDurationReturnType>({
    query: topTenDurationQuery,
    variables: { timeframe: timeframe.value, scheduleName: scheduleName.value },
  });
  const [
    getCurrentScheduleStatus, {
      data: currentScheduleStatusData,
      loading: isLoadingCurrentScheduleStatus,
      error: currentScheduleStatusError,
    },
  ] = useCubeLazyQuery<currentScheduleStatusReturnType>({
    query: currentScheduleStatusQuery,
    variables: { timeframe: timeframe.value, scheduleName: scheduleName.value },
  });
  const [
    getDurationByCommand, {
      data: durationByCommandData,
      loading: isLoadingDurationByCommand,
      error: durationByCommandError,
    },
  ] = useCubeLazyQuery<durationByCommandReturnType>({
    query: durationByCommandQuery,
    variables: { timeframe: timeframe.value, scheduleName: scheduleName.value },
  });
  const [
    getDurationByModel,
    { data: durationByModelData, loading: isLoadingDurationByModel, error: durationByModelError },
  ] = useCubeLazyQuery<durationByModelReturnType>({
    query: durationByModelQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getModelsInSchedule, {
      data: modelsInScheduleData,
      loading: isLoadingModelsInSchedule,
      error: modelsInScheduleError,
    },
  ] = useCubeLazyQuery<modelsInScheduleReturnType>({
    query: modelsInScheduleQuery,
    variables: { timeframe: timeframe.value },
  });

  const refetchAllData = () => {
    getSuccessRate();
    getCompletedCount();
    getSuccessfulCount();
    getErroredCount();
    getRunDurations();
    getTopTenDurations();
    getCurrentScheduleStatus();
    getDurationByCommand();
    getDurationByModel();
    getModelsInSchedule();
  };

  useEffect(() => {
    if (scheduleName.value !== ' ') {
      refetchAllData();
    }
  }, [timeframe, scheduleName]);

  useEffect(() => {
    getScheduleNamesForDropdown();
  }, [timeframe]);

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      verticalGap="dense"
      distribution="packed"
    >
      <FilterRow
        dropdowns={[
          {
            value: timeframe,
            options: timeframes,
            onChange: setTimeframe,
            name: 'timeframe',
            title: 'Select date range',
          },
          {
            value: scheduleName,
            options: formatScheduleNamesForDropdown(scheduleNamesForDropdown),
            onChange: setScheduleName,
            isLoading: isLoadingScheduleNamesForDropdown,
            name: 'scheduleName',
            title: 'Select schedule',
          },
        ]}
      />
      <div style={{
        display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: '16px',
      }}
      >
        <QuarterPageChartSection height={singleValueChartHeight}>
          <SingleValueChart
            title="Schedule Success Rate"
            value={(
              getSingleValueChartString(
                successRateData?.cube?.[0]?.scheduleRunSuccessRate?.boltScheduleRunSuccessRate || 0,
                'percent',
              )
            )}
            isLoading={isLoadingSuccessRate}
            hasNoData={successRateError || successRateData?.cube?.length === 0}
          />
        </QuarterPageChartSection>
        <QuarterPageChartSection height={singleValueChartHeight}>
          <SingleValueChart
            title="Count Completed Runs"
            value={(
              getSingleValueChartString(
                completedCountData?.cube?.[0]?.scheduleTotalRuns?.countBoltScheduleRunId || 0,
              )
            )}
            isLoading={isLoadingCompletedCount}
            hasNoData={completedCountError || completedCountData?.cube?.length === 0}
          />
        </QuarterPageChartSection>
        <QuarterPageChartSection height={singleValueChartHeight}>
          <SingleValueChart
            title="Count Succeeded Runs"
            value={(
              getSingleValueChartString(
                successfulCountData?.cube?.[0]
                  ?.scheduleTotalSuccessfulRuns?.countSuccessBoltScheduleRun || 0,
              )
            )}
            isLoading={isLoadingSuccessfulCount}
            hasNoData={successfulCountError || successfulCountData?.cube?.length === 0}
          />
        </QuarterPageChartSection>
        <QuarterPageChartSection height={singleValueChartHeight}>
          <SingleValueChart
            title="Count Errored Runs"
            value={(
              getSingleValueChartString(
                erroredCountData?.cube?.[0]
                  ?.scheduleTotalErrorRuns?.countErrorBoltScheduleRun || 0,
              )
            )}
            isLoading={isLoadingErroredCount}
            hasNoData={erroredCountError || erroredCountData?.cube?.length === 0}
          />
        </QuarterPageChartSection>
        <HalfPageChartSection>
          <ScatterChart
            title="Schedule executed runs duration"
            data={formatRunDurationsCountForChart(runDurationsData).data}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            legendLabels={formatRunDurationsCountForChart(runDurationsData).statuses}
            xLabel="Run completed at (UTC)"
            yLabel="Run duration (minutes)"
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            customColourScale={formatRunDurationsCountForChart(runDurationsData).colours}
            isLoading={isLoadingRunDurations}
            hasNoData={runDurationsError || runDurationsData?.cube?.length === 0}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
            showDetailsButton
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Top 10 models by average run time"
            data={formatTopTenDurationsForChart(topTenDurationsData)}
            xAxisLabels={getTopTenDurationsXAxisLabels(topTenDurationsData)}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Model name"
            yLabel="Avg. run time (mins)"
            isLoading={isLoadingTopTenDurations}
            hasNoData={topTenDurationsError || topTenDurationsData?.cube?.length === 0}
            maxCharsAxisX={12}
            xAxisDomain={[0.5, getTopTenDurationsXAxisLabels(topTenDurationsData).length + 1]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
            showDetailsButton
          />
        </HalfPageChartSection>

        <FullPageChartSection style={{ height: '100%' }}>
          <TableChart
            title="Schedule current status"
            isLoading={isLoadingCurrentScheduleStatus}
            hasNoData={currentScheduleStatusError || currentScheduleStatusData?.cube?.length === 0}
            columns={currentScheduleStatusColumns}
            data={formatCurrentScheduleStatusForChart(currentScheduleStatusData)}
            rowExpansionTemplate={(row) => (
              <CodeSnippetCopy codeSnippet={row.boltScheduleNameCommandsJson} language="json" />
            )}
            rowHasExpander={(data) => data?.boltScheduleNameCommandsJson}
            rowsPerPage={5}
          />
        </FullPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Schedule execution time by dbt™ invoked commands"
            horizontal
            data={formatDurationByCommandForChart(durationByCommandData).data}
            xAxisLabels={formatDurationByCommandForChart(durationByCommandData).commandNames}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="dbt™ command"
            yLabel="Executed command runtime (minutes)"
            isLoading={isLoadingDurationByCommand}
            hasNoData={durationByCommandError || durationByCommandData?.cube?.length === 0}
            maxCharsAxisX={8}
            xAxisDomain={[
              0.5,
              formatDurationByCommandForChart(durationByCommandData).commandNames.length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
            showDetailsButton
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <PieChart
            title="Schedule execution time by dbt™ models"
            data={formatDurationByModelForChart(durationByModelData)}
            isLoading={isLoadingDurationByModel}
            hasNoData={durationByModelError || durationByModelData?.cube?.length === 0}
            showLegend={false}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </HalfPageChartSection>
        <FullPageChartSection style={{ height: '100%' }}>
          <TableChart
            title="Executed models in selected schedule"
            isLoading={isLoadingModelsInSchedule}
            hasNoData={modelsInScheduleError || modelsInScheduleData?.cube?.length === 0}
            columns={modelsInScheduleColumns}
            data={formatModelsInScheduleForChart(modelsInScheduleData)}
            rowsPerPage={5}
          />
        </FullPageChartSection>
      </div>
    </AutoLayout>
  );
};

export default DetailsTab;
