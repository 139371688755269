import styled from 'styled-components';

const AppPortalWrapper = styled.div`
  & > div {
    border-radius: 0;
  }

  & > div > iframe {
    border: none;
  }
`;

export { AppPortalWrapper };
