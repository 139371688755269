import React, { FC } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import useGetSchedulesAndBranches from '../hooks/useGetSchedulesAndBranches';
import useGetScheduleName from '../hooks/useGetScheduleName';
import ScheduleForm from '../ScheduleForm';
import BoltSchedulesUpgrade from '../../Common/Upgrade/BoltSchedulesUpgrade';
import { useWaitForLDToBeReady } from '../../hooks/useWaitForLDToBeReady';

const EditSchedule: FC = () => {
  const history = useHistory();
  const { scheduleId } = useParams<{ scheduleId: string }>();

  const { ldIsReady } = useWaitForLDToBeReady();
  const { flagAppBolt } = useFlags();

  const {
    isLoading,
    scheduleNamesList,
    gitBranchList,
    defaultBranch,
    isTurboCiDefined,
  } = useGetSchedulesAndBranches();
  const { isLoading: isScheduleLoading, scheduleFormData } = useGetScheduleName(scheduleId);

  const handleEditSchedule = () => {
    history.push('/bolt');
  };

  if (ldIsReady && !flagAppBolt) {
    return <BoltSchedulesUpgrade />;
  }

  if (isLoading && isScheduleLoading) {
    return <Spinner position="center" />;
  }

  return (
    <ScheduleForm
      scheduleNameEntryId={scheduleFormData?.entryId}
      initialData={scheduleFormData}
      scheduleNamesList={scheduleNamesList}
      isTurboCiDefined={isTurboCiDefined}
      branches={gitBranchList}
      defaultBranch={defaultBranch}
      onSubmit={handleEditSchedule}
      onCancel={() => history.push(`/bolt/${scheduleId}`)}
      onDelete={() => history.push('/bolt')}
    />
  );
};

export default EditSchedule;
