import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';

export interface TextCardContentProps {
  topSubtitle: string,
  mainText: string,
  bottomSubtitle: string,
}

const TextCardContent: FC<TextCardContentProps> = ({ topSubtitle, mainText, bottomSubtitle }) => (
  <AutoLayout
    direction="vertical"
    padding="none"
    verticalGap="dense"
    distribution="packed"
    alignment="center"
  >
    <Typography
      font="inter"
      type="body-bold-small"
      color="default"
      colorStep="100"
      style={{ textAlign: 'center' }}
    >
      {topSubtitle}
    </Typography>
    <Typography
      font="inter"
      type="h3"
      color="default"
      colorStep="100"
      style={{ textAlign: 'center' }}
    >
      {mainText}
    </Typography>
    <Typography
      font="inter"
      type="body-bold-small"
      color="default"
      colorStep="100"
      style={{ textAlign: 'center' }}
    >
      {bottomSubtitle}
    </Typography>
  </AutoLayout>
);

export default TextCardContent;
