/* eslint-disable no-param-reassign */
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { EnvName } from '../../../../../client/service.graphql';
import {
  useDwhCheckDuckdbLazyQuery,
  useSetupDwhDuckdbMutation,
  useCompanyOnboardingDuckdbMutation,
  useCheckProfileAddDuckdbLazyQuery,
  useProfileAddDuckdbMutation,
  useSetupDwhProductionDuckdbMutation,
  useSetProductionEnvironmentVariableMutation,
  useSetEnvironmentVariableMutation,
} from '../../../../../client/generated/service';
import useOnboardIfChecked from '../hooks/useOnboardIfChecked';
import { WarehouseEnv } from '../../../../Common/Warehouses/utils';
import {
  getButtonDefinition,
  BUTTON_STATE_TEXT,
  onSubmitButtonClickProps,
  getInitialButtonText,
  useCommonConfigProps,
  isEditButtonShown,
} from '../CommonConfig';
import { WareHouseModalSource } from '../..';

export interface MotherDuckFormDataType {
  connectionName: string,
  path: string,
  profileSetup: string,
  datasetName: string,
  threads: number,
  motherDuckAccessToken: string,
}

export const useCommonConfig = ({
  formData,
  env,
  source,
  isEditable,
  isAdditionalUser,
  isNewConnection,
}: useCommonConfigProps) => {
  const [onboarded, setOnboarded] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState('');
  const [currentButtonText, setCurrentButtonText] = useState(
    getInitialButtonText({
      source,
      isEditable,
      isAdditionalUser,
    }),
  );
  const [checkInProgress, setCheckInProgress] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [accessTokenSecret, setAccessTokenSecret] = useState('');

  const history = useHistory();

  const onCompletedSaveEnvVar = (isOk?: boolean) => {
    if (isOk) {
      setOnboarded(true);
      setCheckInProgress(false);
    } else {
      setErrorMessage('We were unable to save your MotherDuck access token as an environment variable. Please try again, or contact support.');
      setOnboarded(false);
      setCheckInProgress(false);
    }
  };

  const [saveProdEnvironmentVariable] = useSetProductionEnvironmentVariableMutation({
    onCompleted: (data) => {
      onCompletedSaveEnvVar(data.setProductionEnvironmentVariable?.ok);
    },
  });
  const [saveDevEnvironmentVariable] = useSetEnvironmentVariableMutation({
    onCompleted: (data) => {
      onCompletedSaveEnvVar(data.setEnvironmentVariable?.ok);
    },
  });

  const saveEnvVar = () => {
    const variables = { name: 'motherduck_token', value: accessTokenSecret };

    if (env === WarehouseEnv.PROD) {
      saveProdEnvironmentVariable({ variables });
    } else {
      saveDevEnvironmentVariable({ variables });
    }
  };

  const { checkQueryFunction: checkOnboardMotherDuck } = useOnboardIfChecked({
    checkQuery: useDwhCheckDuckdbLazyQuery, // @ts-ignore
    triggerQuery: useCompanyOnboardingDuckdbMutation,
    setErrorMessage,
    setOnboarded,
    variables: formData as { [key: string]: any },
    setIsChecked,
    setCheckInProgress,
    postConnectionCheckTest: saveEnvVar,
  });

  const { checkQueryFunction: checkUpdateMotherDuck } = useOnboardIfChecked({
    checkQuery: useDwhCheckDuckdbLazyQuery,
    triggerQuery: useSetupDwhDuckdbMutation,
    setErrorMessage,
    setOnboarded,
    variables: formData as { [key: string]: any },
    setIsChecked,
    setCheckInProgress,
    postConnectionCheckTest: saveEnvVar,
  });

  const { checkQueryFunction: checkAddMotherDuck } = useOnboardIfChecked({
    checkQuery: useCheckProfileAddDuckdbLazyQuery,
    triggerQuery: useProfileAddDuckdbMutation,
    setErrorMessage,
    setOnboarded,
    variables: formData as { [key: string]: any },
    setIsChecked,
    setCheckInProgress,
    postConnectionCheckTest: saveEnvVar,
  });

  const { checkQueryFunction: onboardProductionMotherDuck } = useOnboardIfChecked({
    checkQuery: useDwhCheckDuckdbLazyQuery, // @ts-ignore
    triggerQuery: useSetupDwhProductionDuckdbMutation,
    setErrorMessage,
    setOnboarded,
    variables: { ...formData, productionEnvName: EnvName.PRODUCTION },
    setIsChecked,
    setCheckInProgress,
    postConnectionCheckTest: saveEnvVar,
  });

  const testTheConnection = (
    variables: onSubmitButtonClickProps['formData'],
    credentialId: onSubmitButtonClickProps['credentialId'],
  ) => {
    const { motherDuckAccessToken } = variables as unknown as MotherDuckFormDataType;
    setAccessTokenSecret(motherDuckAccessToken);

    if (isAdditionalUser) {
      checkAddMotherDuck({
        variables: {
          ...variables,
          credentialId: credentialId!,
        },
      });
    } else {
      switch (env) {
        case WarehouseEnv.DEV:
          if (source === WareHouseModalSource.ACCOUNT_SETTINGS) {
            checkUpdateMotherDuck({ variables });
          } else {
            checkOnboardMotherDuck({ variables });
          }
          break;
        case WarehouseEnv.PROD:
          onboardProductionMotherDuck({
            variables: {
              ...variables,
              productionEnvName: EnvName.PRODUCTION,
            },
          });
          break;
        case WarehouseEnv.METADATA:
        default:
          break;
      }
    }
  };

  const submitButtonDetails = getButtonDefinition({
    state: currentButtonText,
    isAdditionalUser,
    testTheConnection,
    setCurrentButtonText,
    env,
    source,
    isEditable,
    history,
  });

  useEffect(() => {
    if (isChecked && !checkInProgress) {
      if (onboarded) {
        if (submitButtonDetails?.nextState) {
          setCurrentButtonText(submitButtonDetails.nextState.success);
        }
      } else {
        setCurrentButtonText(
          submitButtonDetails?.nextState?.failure || ('' as BUTTON_STATE_TEXT),
        );
      }
    }
  }, [onboarded, submitButtonDetails, checkInProgress]);

  return {
    onboarded,
    errorMessage,
    submitButtonText: submitButtonDetails?.submitButtonText,
    onSubmitButtonClick: submitButtonDetails?.onSubmitButtonClick,
    dataIsBeingChecked: checkInProgress,
    showEditButton: isEditButtonShown({ source, isNewConnection, env }),
  };
};
