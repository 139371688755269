import React, { FC } from 'react';
import ZeroState, { ParadimeImages } from '@paradime-io/pragma-ui-kit/lib/components/ZeroState';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { useHistory } from 'react-router-dom';
import { useHeaderContext } from '../useHeaderContext';
import { subtitleTypes } from '../Header';

const NotConnectedZeroState: FC = () => {
  const history = useHistory();

  useHeaderContext({
    subtitleType: subtitleTypes.GET_STARTED,
    buttonType: '',
    buttonIsVisible: false,
    showHeader: true,
  });

  return (
    <ZeroState
      image={ParadimeImages.ERROR_LIGHT}
      text={(
        <AutoLayout
          padding="none"
          direction="vertical"
          verticalGap="super-ultra-dense"
          alignment="center"
          wrapperStyle={{ justifyContent: 'center' }}
          style={{ width: '300px' }}
        >
          <Typography
            type="body-bold-small"
            style={{ textAlign: 'center' }}
            color="default"
            colorStep="100"
          >
            ThoughtSpot not connected
          </Typography>
          <Typography
            type="caption"
            style={{ textAlign: 'center' }}
            color="default"
            colorStep="70"
          >
            Please connect this integration in your account settings to use syncs in your workspace.
          </Typography>
        </AutoLayout>
      )}
      buttons={[{
        onClick: () => history.push('/account-settings/integrations'),
        text: 'Set up in account settings',
        dense: true,
        eventContext: Contexts.LOGS,
        eventObject: 'setupThoughtSpotInAccountSettings',
        eventAction: Actions.CLICKED,
      }]}
    />
  );
};

export default NotConnectedZeroState;
