import { gql } from '@apollo/client';
import { ColumnType } from '@paradime-io/pragma-ui-kit/lib/components/PrimeReactDataTable';
import { getTableChips, testStatuses, getStatusColour } from '../utils';

export interface countPassReturnType {
  cube: {
    testStatusSummary: {
      distinctTestCount: number,
    }
  }[]
}

export const countPassQuery = gql`
  query countPassQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {test_status_summary: {is_test_current_status: {equals: "true"}, test_result_status: {equals: "pass"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      testStatusSummary: test_status_summary {
        distinctTestCount: distinct_test_count
      }
    }
  }
`;

export interface countWarningsReturnType {
  cube: {
    testStatusSummary: {
      distinctTestCount: number,
    }
  }[]
}

export const countWarningsQuery = gql`
  query countWarningsQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {test_status_summary: {is_test_current_status: {equals: "true"}, test_result_status: {equals: "warn"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      testStatusSummary: test_status_summary {
        distinctTestCount: distinct_test_count
      }
    }
  }
`;

export interface countErrorsReturnType {
  cube: {
    testStatusSummary: {
      distinctTestCount: number,
    }
  }[]
}

export const countErrorsQuery = gql`
  query countErrorsQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {test_status_summary: {is_test_current_status: {equals: "true"}, test_result_status: {equals: "error"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      testStatusSummary: test_status_summary {
        distinctTestCount: distinct_test_count
      }
    }
  }
`;

export interface countFailedReturnType {
  cube: {
    testStatusSummary: {
      distinctTestCount: number,
    }
  }[]
}

export const countFailedQuery = gql`
  query countFailedQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {test_status_summary: {is_test_current_status: {equals: "true"}, test_result_status: {equals: "fail"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      testStatusSummary: test_status_summary {
        distinctTestCount: distinct_test_count
      }
    }
  }
`;

export interface dailyTestsReturnType {
  cube: {
    testStatusSummary: {
      distinctTestCount: number,
      testResultStatus: 'error' | 'fail' | 'pass' | 'warn',
      calendarDate: {
        day: string,
      }
    }
  }[]
}

export const dailyTestsQuery = gql`
  query dailyTestsQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {test_status_summary: {calendar_date: {inDateRange: $timeframe}}}
    ) {
      testStatusSummary: test_status_summary(orderBy: {calendar_date: asc}) {
        distinctTestCount: distinct_test_count
        testResultStatus: test_result_status
        calendarDate: calendar_date {
          day
        }
      }
    }
  }
`;

export const formatDailyTestsForChart = (data?: dailyTestsReturnType) => {
  if (!data) return { data: [], statuses: [], colours: [] };

  const dataToReturn: { x: string, y: number }[][] = [];
  const legendLabels: string[] = [];
  const legendColours: string[] = [];

  testStatuses.forEach((status) => {
    const runs = data.cube
      .filter(({ testStatusSummary }) => testStatusSummary.testResultStatus === status)
      .map(({ testStatusSummary }) => ({
        x: testStatusSummary.calendarDate.day,
        y: testStatusSummary.distinctTestCount,
      }));

    if (runs.length > 0) {
      dataToReturn.push(runs);
      legendLabels.push(status);
      legendColours.push(getStatusColour(status));
    }
  });

  return { data: dataToReturn, statuses: legendLabels, colours: legendColours };
};

export const getDailyTestsXAxisLabels = (data?: dailyTestsReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ testStatusSummary }) => testStatusSummary.calendarDate.day);
  return Array.from(new Set(dates));
};

export interface currentTestsReturnType {
  cube: {
    testStatusSummary: {
      distinctTestCount: number,
      testResultStatus: 'error' | 'fail' | 'pass' | 'warn',
    }
  }[]
}

export const currentTestsQuery = gql`
  query currentTestsQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {test_status_summary: {is_test_current_status: {equals: "true"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      testStatusSummary: test_status_summary(orderBy: {calendar_date: asc}) {
        distinctTestCount: distinct_test_count
        testResultStatus: test_result_status
      }
    }
  }
`;

export const formatCurrentTestsForChart = (data?: currentTestsReturnType) => {
  if (!data) return { data: [], statuses: [], colours: [] };

  const dataToReturn: { x: string, y: number }[] = [];
  const legendLabels: string[] = [];
  const legendColours: string[] = [];

  testStatuses.forEach((status) => {
    const runs = data.cube
      .filter(({ testStatusSummary }) => testStatusSummary.testResultStatus === status)
      .map(({ testStatusSummary }) => ({
        x: testStatusSummary.testResultStatus,
        y: testStatusSummary.distinctTestCount,
      }));

    if (runs.length > 0) {
      dataToReturn.push(...runs);
      legendLabels.push(status);
      legendColours.push(getStatusColour(status));
    }
  });

  return { data: dataToReturn, statuses: legendLabels, colours: legendColours };
};

export interface countBadResultsReturnType {
  cube: {
    testsFailedDetails: {
      packageName: string,
      databaseName: string,
      schemaName: string,
      testRelatedModelName: string,
      testRelatedColumnName: string,
      impactedRows: number | null,
      testResultStatus: string,
    }
  }[]
}

export const countBadResultsQuery = gql`
  query countBadResultsQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {tests_failed_details: {is_test_current_status: {equals: "true"}, test_result_status: {notIn: "pass"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      testsFailedDetails: tests_failed_details(orderBy: {impacted_rows: desc}) {
        packageName: package_name
        databaseName: database_name
        schemaName: schema_name
        testRelatedModelName: test_related_model_name
        testRelatedColumnName: test_related_column_name
        impactedRows: impacted_rows
        testResultStatus: test_result_status
      }
    }
  }
`;

export const countBadResultsColumns = [
  { field: 'packageName', header: 'Package name' },
  { field: 'databaseName', header: 'Database name' },
  { field: 'schemaName', header: 'Schema name' },
  { field: 'testRelatedModelName', header: 'Test related model name' },
  { field: 'testRelatedColumnName', header: 'Test related column name' },
  { field: 'impactedRows', header: 'Impacted rows count' },
  { field: 'testResultStatus', header: 'Test execution status', type: ColumnType.CHIP },
].map((col) => ({ ...col, sortable: true }));

export const formatCountBadResultsForChart = (data?: countBadResultsReturnType) => {
  if (!data) return [];

  return data.cube.map(({ testsFailedDetails }) => ({
    ...testsFailedDetails,
    impactedRows: testsFailedDetails.impactedRows || 0,
    testResultStatus: getTableChips(testsFailedDetails.testResultStatus),
  }));
};

export interface topTenWarnReturnType {
  cube: {
    testStatusByModel: {
      distinctTestCount: number,
      testRelatedModelName: string,
    }
  }[]
}

export const topTenWarnQuery = gql`
  query topTenWarnQuery($timeframe: [String]) {
    cube(
      limit: 10
      where: {test_status_by_model: {test_result_status: {equals: "warn"}, is_test_current_status: {equals: "true"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      testStatusByModel: test_status_by_model(orderBy: {distinct_test_count: desc}) {
        distinctTestCount: distinct_test_count
        testRelatedModelName: test_related_model_name
      }
    }
  }
`;

export const formatTopTenWarnForChart = (data?: topTenWarnReturnType) => {
  if (!data) return [];

  return [data.cube
    .map(({ testStatusByModel }) => ({
      x: testStatusByModel.testRelatedModelName,
      y: testStatusByModel.distinctTestCount,
    }))];
};

export const getTopTenWarnXAxisLabels = (data?: topTenWarnReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ testStatusByModel }) => testStatusByModel.testRelatedModelName);
  return Array.from(new Set(dates));
};

export interface topTenErrorReturnType {
  cube: {
    testStatusByModel: {
      distinctTestCount: number,
      testRelatedModelName: string,
    }
  }[]
}

export const topTenErrorQuery = gql`
  query topTenErrorQuery($timeframe: [String]) {
    cube(
      limit: 10
      where: {test_status_by_model: {test_result_status: {equals: "error"}, is_test_current_status: {equals: "true"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      testStatusByModel: test_status_by_model(orderBy: {distinct_test_count: desc}) {
        distinctTestCount: distinct_test_count
        testRelatedModelName: test_related_model_name
      }
    }
  }
`;

export const formatTopTenErrorForChart = (data?: topTenErrorReturnType) => {
  if (!data) return [];

  return [data.cube
    .map(({ testStatusByModel }) => ({
      x: testStatusByModel.testRelatedModelName,
      y: testStatusByModel.distinctTestCount,
    }))];
};

export const getTopTenErrorXAxisLabels = (data?: topTenErrorReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ testStatusByModel }) => testStatusByModel.testRelatedModelName);
  return Array.from(new Set(dates));
};
