import styled from 'styled-components';

export const Box = styled.div<{ hasPermissionToClick: boolean }>`
  background: var(--white);
  border-radius: 8px;
  border: 1px solid var(--grey20);
  cursor: ${(props) => (props.hasPermissionToClick ? 'pointer' : 'default')};
`;

export const RowTotalsBox = styled.div`
  border-radius: 8px;
  border: 1px solid var(--grey10);
  height: 100%;
  width: 100%;
`;

export interface ChipProps {
  color: string,
}

export const Chip = styled.div<ChipProps>`
  background: ${(props) => `var(--${props.color}5)`};
  border-radius: 4px;
  width: max-content;
`;

export const Card = styled.div`
  width: 100%;
  height: 100%;
  background: var(--white);
  border-radius: 8px;

  // One extra day was added to the chart for padding reasons - but we don't want to see that label
  #chart-axis-2-tickLabels-0 {
    display: none;
  }
`;

export const Dot = styled.div<{ color: string }>`
  width: 12px;
  height: 12px;
  background-color: ${(props) => props.color};
  border-radius: 8px;
`;
