import React, { FC } from 'react';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import ToastFactory from '../../Common/Alerts/ToastFactory';
import warningBell from '../../../images/bells/warningBell.svg';

const PullRequestFailedToast : FC = () => (
  <ToastFactory
    icon={warningBell}
    text="Make sure to push your local branch before creating a new pull request."
    title={(
      <Typography
        type="h6"
        tagName="span"
        color="warning"
        colorStep="80"
        style={{
          margin: 'auto 0px',
        }}
      >
        Pull request
      </Typography>
)}
  />
);

export default PullRequestFailedToast;
