import styled from 'styled-components';

const SuperDenseDropdownWrapper = styled.div`
  display: flex;
  width: 300px;
  button[class*="dropdown"] {
    height: 20px;
    font-size: var(--font_size_caption);
    align-items: center;
  }

  button[class*="dropdown"] > span[class*="icon-caret-down"] {
    margin: unset;
  }

  button[class*="dropdown"] > span {
    font-size: var(--font_size_caption);
  }

`;

export { SuperDenseDropdownWrapper };
