import React, { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { StackedBarChart } from '@paradime-io/pragma-ui-kit/lib/components/VictoryCharts';
import { useCubeLazyQuery } from '../../../app/hooks/useCubeQuery';
import {
  dailyExploreCountQuery,
  dailyExploreCountReturnType,
  formatDailyExploreCountForChart,
  getDailyExploreCountXAxisLabels,
  top10ModelSpendQuery,
  top10ModelSpendReturnType,
  formatTop10ModelSpendForChart,
  getTop10ModelSpendXAxisLabels,
  top10ExploreSpendQuery,
  top10ExploreSpendReturnType,
  formatTop10ExploreSpendForChart,
  getTop10ExploreSpendXAxisLabels,
  dailyModelSpendQuery,
  dailyModelSpendReturnType,
  formatDailyModelSpendForChart,
  getDailyModelSpendXAxisLabels,
  dailyExploreSpendQuery,
  dailyExploreSpendReturnType,
  formatDailyExploreSpendForChart,
  getDailyExploreSpendXAxisLabels,
} from './ExploresCubeQueries';
import { timeframes, HalfPageChartSection, FullPageChartSection } from '../utils';
import FilterRow from '../FilterRow';
import { useGetFiltersFromUrl } from '../hooks/useGetFiltersFromUrl';

const ExploresTab: FC = () => {
  const { getInitialFilterValue } = useGetFiltersFromUrl();

  const [timeframe, setTimeframe] = useState(getInitialFilterValue('timeframe', timeframes[2].value)!);

  const [
    getDailyExploreCount, {
      data: dailyExploreCountData,
      loading: isLoadingDailyExploreCount,
      error: dailyExploreCountError,
    },
  ] = useCubeLazyQuery<dailyExploreCountReturnType>({
    query: dailyExploreCountQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getTop10ModelSpend,
    { data: top10ModelSpendData, loading: isLoadingTop10ModelSpend, error: top10ModelSpendError },
  ] = useCubeLazyQuery<top10ModelSpendReturnType>({
    query: top10ModelSpendQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getTop10ExploreSpend, {
      data: top10ExploreSpendData,
      loading: isLoadingTop10ExploreSpend,
      error: top10ExploreSpendError,
    },
  ] = useCubeLazyQuery<top10ExploreSpendReturnType>({
    query: top10ExploreSpendQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getDailyModelSpend,
    { data: dailyModelSpendData, loading: isLoadingDailyModelSpend, error: dailyModelSpendError },
  ] = useCubeLazyQuery<dailyModelSpendReturnType>({
    query: dailyModelSpendQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getDailyExploreSpend, {
      data: dailyExploreSpendData,
      loading: isLoadingDailyExploreSpend,
      error: dailyExploreSpendError,
    },
  ] = useCubeLazyQuery<dailyExploreSpendReturnType>({
    query: dailyExploreSpendQuery,
    variables: { timeframe: timeframe.value },
  });

  const refetchAllData = () => {
    getDailyExploreCount();
    getTop10ModelSpend();
    getTop10ExploreSpend();
    getDailyModelSpend();
    getDailyExploreSpend();
  };

  useEffect(() => {
    refetchAllData();
  }, [timeframe]);

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      verticalGap="dense"
      distribution="packed"
    >
      <FilterRow
        dropdowns={[
          {
            value: timeframe,
            options: timeframes,
            onChange: setTimeframe,
            name: 'timeframe',
            title: 'Select date range',
          },
        ]}
      />
      <div style={{
        display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: '16px',
      }}
      >
        <HalfPageChartSection>
          <StackedBarChart
            title="Daily Looker spend by explore"
            data={formatDailyExploreSpendForChart(dailyExploreSpendData).data}
            xAxisLabels={getDailyExploreSpendXAxisLabels(dailyExploreSpendData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            legendLabels={formatDailyExploreSpendForChart(dailyExploreSpendData).exploreNames}
            xLabel="Calendar date"
            yLabel="Total query cost in $"
            isLoading={isLoadingDailyExploreSpend}
            hasNoData={dailyExploreSpendError || dailyExploreSpendData?.cube?.length === 0}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Daily Looker spend by model"
            data={formatDailyModelSpendForChart(dailyModelSpendData).data}
            xAxisLabels={getDailyModelSpendXAxisLabels(dailyModelSpendData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            legendLabels={formatDailyModelSpendForChart(dailyModelSpendData).modelNames}
            xLabel="Calendar date"
            yLabel="Total query cost in $"
            isLoading={isLoadingDailyModelSpend}
            hasNoData={dailyModelSpendError || dailyModelSpendData?.cube?.length === 0}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Top 10 Looker explores by query spend"
            horizontal
            data={formatTop10ExploreSpendForChart(top10ExploreSpendData)}
            xAxisLabels={getTop10ExploreSpendXAxisLabels(top10ExploreSpendData)}
            xLabel="Explore name"
            yLabel="Total query cost in $"
            isLoading={isLoadingTop10ExploreSpend}
            hasNoData={top10ExploreSpendError || top10ExploreSpendData?.cube?.length === 0}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Top 10 Looker Models by query spend"
            horizontal
            data={formatTop10ModelSpendForChart(top10ModelSpendData)}
            xAxisLabels={getTop10ModelSpendXAxisLabels(top10ModelSpendData)}
            xLabel="Model name"
            yLabel="Total query cost in $"
            isLoading={isLoadingTop10ModelSpend}
            hasNoData={top10ModelSpendError || top10ModelSpendData?.cube?.length === 0}
          />
        </HalfPageChartSection>
        <FullPageChartSection>
          <StackedBarChart
            title="Daily Looker Explores by query count"
            data={formatDailyExploreCountForChart(dailyExploreCountData).data}
            xAxisLabels={getDailyExploreCountXAxisLabels(dailyExploreCountData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            legendLabels={formatDailyExploreCountForChart(dailyExploreCountData).exploreNames}
            xLabel="Calendar date"
            yLabel="Total query count"
            isLoading={isLoadingDailyExploreCount}
            hasNoData={dailyExploreCountError || dailyExploreCountData?.cube?.length === 0}
          />
        </FullPageChartSection>
      </div>
    </AutoLayout>
  );
};

export default ExploresTab;
