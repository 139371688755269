import React, { FC } from 'react';
import { Icon, IconName } from '@blueprintjs/core';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';

export interface HorizontalTextCardContentProps {
  icon: IconName,
  heading: string,
  savingAmount: string,
}

const HorizontalTextCardContent: FC<HorizontalTextCardContentProps> = ({
  icon,
  heading,
  savingAmount,
}) => (
  <AutoLayout
    direction="horizontal"
    padding="none"
    distribution="space-between"
  >
    <AutoLayout
      direction="horizontal"
      padding="none"
      horizontalGap="dense"
      distribution="packed"
    >
      <Icon icon={icon} size={16} color="var(--violet70)" />
      <Typography
        font="inter"
        type="body-small"
        color="default"
        colorStep="100"
      >
        {heading}
      </Typography>
    </AutoLayout>
    <Typography
      font="inter"
      type="h5"
      color="default"
      colorStep="100"
    >
      {savingAmount}
    </Typography>
  </AutoLayout>
);

export default HorizontalTextCardContent;
