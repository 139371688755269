import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import CodeSnippetCopy from '../../../Common/Components/CodeSnippetCopy';
import { CodeTypography } from '../../Bolt.styles';
import { AccountSettingsTab } from '../../../NavBar';
import pypi from './images/pypi.svg';

interface PythonProps {
  scheduleName: string,
}

const Python:FC<PythonProps> = ({
  scheduleName,
}) => {
  const history = useHistory();

  const scheduleCode = `
# First party modules
from paradime import Paradime

# Create a Paradime client with your API credentials
paradime = Paradime(
  api_endpoint="API_ENDPOINT",
  api_key="API_KEY",
  api_secret="API_SECRET"
)

# Name of the Bolt schedule to trigger
BOLT_SCHEDULE_NAME = "${scheduleName}"

# Trigger a run of the Bolt schedule and get the run ID
run_id = paradime.bolt.trigger_run(BOLT_SCHEDULE_NAME)

# Get the run status
run_status = paradime.bolt.get_run_status(run_id)
`;

  return (
    <>
      <CodeTypography type="body-small" color="default" colorStep="50" tagName="span">
        <br />
        Example code to trigger this schedule using the Python SDK.
      </CodeTypography>

      <Typography type="body-small" color="default" colorStep="50" tagName="span">
        Generate API credentials in
        {' '}
        <strong>Account Settings &gt; Workspaces.</strong>
        {' '}
        and keep it safe, you will need to use them in code.
      </Typography>

      <DefaultButton
        dense
        view="smooth"
        text="Generate API Key"
        eventContext={Contexts.APP}
        eventObject="generateApiKey"
        eventAction={Actions.CLICKED}
        onClick={() => {
          history.push(`/account-settings/${AccountSettingsTab.WORKSPACE}`);
        }}
        style={{ width: 'fit-content' }}
      />

      <CodeTypography type="body-small" color="default" colorStep="50" tagName="span">
        As a best practice, your API credentials should be set as environment variables
        and should not be checked into source control.
        <br />
        <br />
        Use the API credentials generated and set the environment variables for
        {' '}
        <code>API_KEY</code>
        {' '}
        <code>API_SECRET</code>
        {' and '}
        <code>API_ENDPOINT</code>
        .
      </CodeTypography>
      <CodeSnippetCopy codeSnippet={scheduleCode} language="python" />
      <CodeTypography type="body-small" color="default" colorStep="50" tagName="span">
        We have more examples of how to use the SDK
        {' '}
        <a href="https://github.com/paradime-io/paradime-python-sdk/tree/main/examples/bolt">here</a>
        {' '}
        that you use to customize your setup using our SDK.
      </CodeTypography>
      <AutoLayout
        direction="horizontal"
        padding="none"
        distribution="packed"
        horizontalGap="very-dense"
      >
        <DefaultButton
          dense
          view="outlined"
          icon={<img src={pypi} height={20} alt="pypi registry" />}
          text="Python SDK"
          eventContext={Contexts.APP}
          eventObject="goToPyPi"
          eventAction={Actions.CLICKED}
          onClick={() => window.open(
            'https://pypi.org/project/paradime-io/',
            '_blank',
          )}
          style={{ width: 'fit-content', textTransform: 'none' }}
        />
      </AutoLayout>
    </>
  );
};

export default Python;
