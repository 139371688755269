import React, { FC, useEffect, useState } from 'react';
import BoltDetailsCard from '@paradime-io/pragma-ui-kit/lib/components/BoltDetailsCard';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { Link } from 'react-router-dom';
import {
  formatDateTimeToLocalString, getHumanReadableCron,
} from '../../../utilis';
import CommandCodeBox from './CommandCodeBox';
import { getOnCompletionRunInfoText } from './utils';
import { InfoType } from './Schedule';
import { GetScheduleNameQuery, GqlScheduleName } from '../../../client/generated/service';

export interface ScheduleDetailsCardProps {
  info: InfoType,
  scheduleData?: GetScheduleNameQuery,
}

type DeferredDetailsType = {
  id: string,
  name: string,
  runOn: boolean,
};

const ScheduleDetailsCard: FC<ScheduleDetailsCardProps> = ({ info, scheduleData }) => {
  const [deferredData, setDeferredData] = useState<DeferredDetailsType>();
  const getTriggerType = () => {
    if (scheduleData?.getScheduleName?.scheduleTrigger) return 'On run completion';
    if (scheduleData?.getScheduleName?.triggerOnMerge) return 'On merge';
    return 'On schedule';
  };

  const getTriggerConfig = () => {
    if (scheduleData?.getScheduleName?.scheduleTrigger) {
      return {
        header: 'On completion configuration',
        text: getOnCompletionRunInfoText(
          scheduleData.getScheduleName.scheduleTrigger,
        ),
        type: 'textWithHeader' as const,
        textStyle: 'caption' as const,
        singleLine: true,
        withTooltip: true,
        tooltipConfig: {
          content: getOnCompletionRunInfoText(
            scheduleData.getScheduleName.scheduleTrigger,
          ),
        },
      };
    }

    if (scheduleData?.getScheduleName?.triggerOnMerge) {
      return {
        header: 'On merge branch',
        text: scheduleData?.getScheduleName?.gitBranch || '-',
        type: 'textWithHeader' as const,
        textStyle: 'caption' as const,
        singleLine: true,
        withTooltip: true,
        tooltipConfig: {
          content: scheduleData?.getScheduleName?.gitBranch || '-',
        },
      };
    }

    return {
      header: 'Cron configuration',
      text: info.schedule ? getHumanReadableCron(info.schedule) : '-',
      type: 'textWithHeader' as const,
      textStyle: 'caption' as const,
      singleLine: true,
      withTooltip: true,
      tooltipConfig: {
        content: info.schedule ? getHumanReadableCron(info.schedule) : '-',
      },
    };
  };

  const hasDeferredData = scheduleData?.getScheduleName?.deferredSchedule?.enabled
    || scheduleData?.getScheduleName?.turboCi?.enabled;

  useEffect(() => {
    if (hasDeferredData) {
      const { deferredSchedule, turboCi } = scheduleData.getScheduleName as GqlScheduleName;

      const scheduleNameUuid = deferredSchedule?.deferredScheduleNameUuid
        || turboCi?.deferredScheduleNameUuid;

      const scheduleName = deferredSchedule?.deferredScheduleName
        || turboCi?.deferredScheduleName;

      const deferredRunOn = !!deferredSchedule?.successfulRunOnly
        || !!turboCi?.successfulRunOnly;

      if (scheduleName && scheduleNameUuid && deferredRunOn !== undefined) {
        setDeferredData({
          id: scheduleNameUuid,
          name: scheduleName,
          runOn: deferredRunOn,
        });
      }
    }
  }, []);

  return (
    <BoltDetailsCard
      id="schedule-details-card"
      height={500}
      tabIndex={-1}
      components={[
        {
          type: 'row',
          colsMaxWidth: 240,
          items: [
            {
              type: 'column',
              items: [
                {
                  header: 'Next Run',
                  text: scheduleData?.getScheduleName?.suspended ? '-' : formatDateTimeToLocalString({ dateTimeString: info.nextRun }),
                  type: 'textWithHeader',
                  textStyle: 'caption',
                },
                {
                  header: 'Branch',
                  text: info.branch || 'default',
                  type: 'textWithHeader',
                  textStyle: 'caption',
                },
              ],
            },
            {
              type: 'column',
              items: [
                {
                  header: 'Owner',
                  text: info.owner || '-',
                  type: 'textWithHeader',
                  textStyle: 'caption',
                  singleLine: true,
                  withTooltip: true,
                  tooltipConfig: {
                    content: info.owner || '-',
                  },
                },
                {
                  header: 'dbt™ Version',
                  text: info.dbtVersion || '-',
                  type: 'textWithHeader',
                  textStyle: 'caption',
                },
              ],
            },
            {
              type: 'column',
              items: [
                {
                  header: 'Trigger type',
                  text: getTriggerType(),
                  type: 'textWithHeader',
                  textStyle: 'caption',
                },
                getTriggerConfig(),
              ],
            },
          ],
        },
        {
          type: 'row',
          showDivider: true,
          items: [
            {
              type: 'column',
              items: [
                {
                  header: 'Notify',
                  text: info.toNotify ? info.toNotify.filter((v) => v !== '').join(', ') : '-',
                  type: 'textWithHeader',
                  textStyle: 'caption',
                },
                {
                  header: 'Status',
                  text: scheduleData?.getScheduleName?.suspended ? 'Paused' : 'Active',
                  type: 'textWithHeader',
                  textStyle: 'caption',
                },
              ],
            },
            {
              type: 'column',
              items: [
                {
                  header: 'Deferred',
                  text: deferredData ? (
                    <AutoLayout
                      direction="vertical"
                      distribution="packed"
                      padding="none"
                      verticalGap="super-ultra-dense"
                    >
                      <Typography tagName="span" type="caption" colorStep="100">
                        From:
                        {' '}
                        <Link
                          to={`/bolt/${deferredData.id}`}
                          style={{ color: 'var(--deeplink-blue)' }}
                          target="_blank"
                        >
                          {deferredData.name}
                        </Link>
                      </Typography>
                      <Typography tagName="span" type="caption">
                        Run On:
                        {' '}
                        <strong>
                          {deferredData.runOn ? 'Last successful run' : 'Last run'}
                        </strong>
                      </Typography>
                    </AutoLayout>
                  ) : '-',
                  type: 'textWithHeader',
                  textStyle: 'caption',
                },
              ],
            },
          ],
        },
        {
          type: 'row',
          showDivider: true,
          items: [
            {
              type: 'column',
              items: [
                {
                  fill: true,
                  type: 'reactNode',
                  reactNode: (
                    <CommandCodeBox
                      lines={scheduleData?.getScheduleName?.commands?.map((l) => `${l}`) || []}
                    />
                  ),
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};

export default ScheduleDetailsCard;
