import React, { FC } from 'react';
import { Checklist, FrigadeJS } from '@frigade/react';
import { useHistory } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Header from './Header';
import CreateCDJobStep from './CreateCDJobStep';
import GenerateApiKeysStep from './GenerateAPIKeysStep';
import CreateGithubActionStep from './CreateGithubActionStep';
import AddGithubSecretsStep from './AddGithubSecretsStep';
import GetWrokspaceIdStep from './GetWorkspaceIdStep';
import { BoltFlows } from '../../Common/Frigade/flows';
import { AccountSettingsTab } from '../../NavBar';

const enum TurboCdSteps {
  CREATE_CD_JOB = 'create-cd-bolt-schedule',
  GENERATE_API_KEYS = 'generate-api-keys',
  GET_WORKSPACE_ID = 'get-workspace-id',
  ADD_GITHUB_SECRETS = 'add-github-secrets',
  CREATE_GITHUB_ACTION = 'create-github-action',
}

const TurboCi:FC = () => {
  const history = useHistory();

  const handleFlowButtonClick = (step: FrigadeJS.FlowStep) => {
    switch (step.id) {
      case TurboCdSteps.GENERATE_API_KEYS:
        history.push(`/account-settings/${AccountSettingsTab.WORKSPACE}`);
        break;
      default:
        break;
    }
    return Promise.resolve(true);
  };

  return (
    <AutoLayout
      direction="vertical"
      padding="expanded"
      horizontalGap="dense"
      distribution="packed"
      alignment="top-left"
      wrapperStyle={{ gridArea: 'b', overflowY: 'auto' }}
      width="full"
      style={{ maxWidth: '1280px' }}
    >
      <Header showHelp />
      <Typography tagName="span" type="body-small">
        Configure Continuous Deployment (CD) in this workspace in 5 easy steps.
        Continuous Deployment ensures that your cloud warehouse always has freshest data after
        every time a Pull Request is merged in PR.
        <br />
        <br />
        This does have an impact on costs as you write to your warehouse more often after every run.
      </Typography>
      <Checklist.Collapsible
        flowId={BoltFlows.TURBO_CD_FLOW}
        type="inline"
        checklistStyle="condensed"
        autoExpandNextStep={false}
        style={{
          boxShadow: 'none',
          border: '1px solid var(--grey5)',
        }}
        customStepTypes={{
          createCdJob: CreateCDJobStep,
          generateAPIKeys: GenerateApiKeysStep,
          getWorkspaceId: GetWrokspaceIdStep,
          addGithubSecrets: AddGithubSecretsStep,
          createGithubAction: CreateGithubActionStep,
        }}
        onPrimary={handleFlowButtonClick}
        forceMount
      />
    </AutoLayout>
  );
};

export default TurboCi;
