import React, { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { getRelativeTime } from '@paradime/common/lib/common/helpers/time';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import {
  useGetScheduleParseLazyQuery,
  useTriggerScheduleParseMutation,
} from '../../../client/generated/service';

const ParseSchedules:FC = () => {
  const scheduleParseVariables = {
    offset: 0,
    limit: 1000,
    showInactive: false,
  };
  const [lastParsed, setLastParsed] = useState('-');
  const [isScheduleParsing, setIsScheduleParsing] = useState(false);
  const [getScheduleParse, { loading }] = useGetScheduleParseLazyQuery({
    variables: scheduleParseVariables,
    onCompleted: (data) => {
      if (data.getScheduleNames?.lastParse) {
        setLastParsed(
          getRelativeTime(DateTime.fromISO(data.getScheduleNames.lastParse, { zone: 'utc' })),
        );
      }
    },
  });

  const [triggerScheduleParse] = useTriggerScheduleParseMutation({
    onCompleted: () => {
      setTimeout(() => {
        getScheduleParse();
        setIsScheduleParsing(false);
      }, 3000);
    },
  });

  useEffect(() => {
    getScheduleParse();
  }, []);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifySelf: 'flex-start' }}>
        <Spinner width={16} height={16} thin />
      </div>
    );
  }

  return (
    <AutoLayout
      direction="horizontal"
      padding="none"
      distribution="packed"
      horizontalGap="very-dense"
    >
      <Typography type="caption" colorStep="50">
        Last updated:
        {' '}
        {lastParsed}
      </Typography>
      {
        isScheduleParsing ? (
          <AutoLayout
            direction="horizontal"
            distribution="packed"
            padding="none"
            horizontalGap="ultra-dense"
          >
            <Spinner width={16} height={16} thin />
            <Typography tagName="span" type="caption" color="primary" colorStep="30">
              Parsing ...
            </Typography>
          </AutoLayout>
        ) : (
          <DefaultButton
            view="smooth"
            type="standard"
            color="primary"
            dense
            icon="diagram-tree"
            text="Parse schedules"
            eventContext={Contexts.BOLT}
            eventObject="triggerScheduleParse"
            eventAction={Actions.CLICKED}
            onClick={() => {
              setIsScheduleParsing(true);
              triggerScheduleParse();
            }}
          />
        )
      }
    </AutoLayout>
  );
};

export default ParseSchedules;
