import React, { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import {
  StackedBarChart,
  TreeTableChart,
  PieChart,
} from '@paradime-io/pragma-ui-kit/lib/components/VictoryCharts';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { useCubeLazyQuery } from '../../../app/hooks/useCubeQuery';
import {
  userEmailsForDropdownQuery,
  userEmailsForDropdownReturnType,
  formatUserEmailsForDropdown,
  projectIdsForDropdownQuery,
  projectIdsForDropdownReturnType,
  formatProjectIdsForDropdown,
  topCostModelsQuery,
  topCostModelsReturnType,
  topCostModelsColumns,
  formatTopCostModelsForChart,
  dailyProdSpendQuery,
  dailyProdSpendReturnType,
  formatDailyProdSpendForChart,
  getDailyProdSpendXAxisLabels,
  dailyDbtSpendQuery,
  dailyDbtSpendReturnType,
  formatDailyDbtSpendForChart,
  getDailyDbtSpendXAxisLabels,
  topCostQueriesQuery,
  topCostQueriesReturnType,
  topCostQueriesColumns,
  formatTopCostQueriesForChart,
  spendPerUserQuery,
  spendPerUserReturnType,
  formatSpendPerUserForChart,
  queriesPerHourQuery,
  queriesPerHourReturnType,
  formatQueriesPerHourForChart,
  getQueriesPerHourXAxisLabels,
  slotUsageQuery,
  slotUsageReturnType,
  formatSlotUsageForChart,
  getSlotUsageXAxisLabels,
  hourlySpendQuery,
  hourlySpendReturnType,
  formatHourlySpendForChart,
  getHourlySpendXAxisLabels,
  spendPerTypeQuery,
  spendPerTypeReturnType,
  formatSpendPerTypeForChart,
  getSpendPerTypeXAxisLabels,
  dailyProjectSpendQuery,
  dailyProjectSpendReturnType,
  formatDailyProjectSpendForChart,
  getDailyProjectSpendXAxisLabels,
} from './BigqueryCubeQueries';
import FilterRow from '../FilterRow';
import {
  timeframes,
  HalfPageChartSection,
  FullPageChartSection,
  getFormattedYAxisLabel,
} from '../utils';
import { useGetFiltersFromUrl } from '../hooks/useGetFiltersFromUrl';

export const selectAllProjects = { label: 'All BigQuery projects', value: 'allProjects' };
export const selectAllUsers = { label: 'All BigQuery users', value: 'allUsers' };

const BigqueryTab: FC = () => {
  const { getInitialFilterValue } = useGetFiltersFromUrl();

  const [timeframe, setTimeframe] = useState(getInitialFilterValue('timeframe', timeframes[2].value)!);
  const [userEmail, setUserEmail] = useState(getInitialFilterValue('userEmail', selectAllUsers.value)!);
  const [projectId, setProjectId] = useState(getInitialFilterValue('projectId', selectAllProjects.value)!);

  const isSelectAllEmails = userEmail.value === selectAllUsers.value;
  const isSelectAllProjectIds = projectId.value === selectAllProjects.value;

  const variables = {
    timeframe: timeframe.value,
    userEmail: userEmail.value,
    projectId: projectId.value,
  };

  const [
    getUserEmailsForDropdown,
    { data: userEmailsForDropdown, loading: isLoadingUserEmailsForDropdown },
  ] = useCubeLazyQuery<userEmailsForDropdownReturnType>({
    query: userEmailsForDropdownQuery,
    variables: { timeframe: timeframe.value },
    onCompleted: () => {
      const urlVal = getInitialFilterValue('userEmail', selectAllUsers.value);
      if (urlVal) setUserEmail(urlVal);
    },
  });

  const [
    getProjectIdsForDropdown,
    { data: projectIdsForDropdown, loading: isLoadingProjectIdsForDropdown },
  ] = useCubeLazyQuery<projectIdsForDropdownReturnType>({
    query: projectIdsForDropdownQuery,
    variables: { timeframe: timeframe.value },
    onCompleted: () => {
      const urlVal = getInitialFilterValue('projectId', selectAllProjects.value);
      if (urlVal) setProjectId(urlVal);
    },
  });

  const [
    getTopCostModels,
    { data: topCostModelsData, loading: isLoadingTopCostModels, error: topCostModelsError },
  ] = useCubeLazyQuery<topCostModelsReturnType>({
    query: topCostModelsQuery(isSelectAllEmails, isSelectAllProjectIds),
    variables,
  });
  const [
    getDailyProdSpend,
    { data: dailyProdSpendData, loading: isLoadingDailyProdSpend, error: dailyProdSpendError },
  ] = useCubeLazyQuery<dailyProdSpendReturnType>({
    query: dailyProdSpendQuery(isSelectAllEmails, isSelectAllProjectIds),
    variables,
  });
  const [
    getDailyDbtSpend,
    { data: dailyDbtSpendData, loading: isLoadingDailyDbtSpend, error: dailyDbtSpendError },
  ] = useCubeLazyQuery<dailyDbtSpendReturnType>({
    query: dailyDbtSpendQuery(isSelectAllEmails, isSelectAllProjectIds),
    variables,
  });
  const [
    getTopCostQueries,
    { data: topCostQueriesData, loading: isLoadingTopCostQueries, error: topCostQueriesError },
  ] = useCubeLazyQuery<topCostQueriesReturnType>({
    query: topCostQueriesQuery(isSelectAllEmails, isSelectAllProjectIds),
    variables,
  });
  const [
    getSpendPerUser,
    { data: spendPerUserData, loading: isLoadingSpendPerUser, error: spendPerUserError },
  ] = useCubeLazyQuery<spendPerUserReturnType>({
    query: spendPerUserQuery(isSelectAllEmails, isSelectAllProjectIds),
    variables,
  });
  const [
    getQueriesPerHour,
    { data: queriesPerHourData, loading: isLoadingQueriesPerHour, error: queriesPerHourError },
  ] = useCubeLazyQuery<queriesPerHourReturnType>({
    query: queriesPerHourQuery(isSelectAllEmails, isSelectAllProjectIds),
    variables,
  });
  const [
    getSlotUsage, { data: slotUsageData, loading: isLoadingSlotUsage, error: slotUsageError },
  ] = useCubeLazyQuery<slotUsageReturnType>({
    query: slotUsageQuery(isSelectAllEmails, isSelectAllProjectIds),
    variables,
  });
  const [
    getHourlySpend,
    { data: hourlySpendData, loading: isLoadingHourlySpend, error: hourlySpendError },
  ] = useCubeLazyQuery<hourlySpendReturnType>({
    query: hourlySpendQuery(isSelectAllEmails, isSelectAllProjectIds),
    variables,
  });
  const [
    getSpendPerType,
    { data: spendPerTypeData, loading: isLoadingSpendPerType, error: spendPerTypeError },
  ] = useCubeLazyQuery<spendPerTypeReturnType>({
    query: spendPerTypeQuery(isSelectAllEmails, isSelectAllProjectIds),
    variables,
  });
  const [
    getDailyProjectSpend, {
      data: dailyProjectSpendData,
      loading: isLoadingDailyProjectSpend,
      error: dailyProjectSpendError,
    },
  ] = useCubeLazyQuery<dailyProjectSpendReturnType>({
    query: dailyProjectSpendQuery(isSelectAllEmails, isSelectAllProjectIds),
    variables,
  });

  const refetchAllData = () => {
    getTopCostModels();
    getDailyProdSpend();
    getDailyDbtSpend();
    getTopCostQueries();
    getSpendPerUser();
    getQueriesPerHour();
    getSlotUsage();
    getHourlySpend();
    getSpendPerType();
    getDailyProjectSpend();
  };

  useEffect(() => {
    refetchAllData();
  }, [timeframe, userEmail, projectId]);

  useEffect(() => {
    getUserEmailsForDropdown();
    getProjectIdsForDropdown();
  }, [timeframe]);

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      verticalGap="dense"
      distribution="packed"
    >
      <FilterRow
        dropdowns={[
          {
            value: timeframe,
            options: timeframes,
            onChange: setTimeframe,
            name: 'timeframe',
            title: 'Select date range',
          },
          {
            value: userEmail,
            options: formatUserEmailsForDropdown(userEmailsForDropdown),
            onChange: setUserEmail,
            isLoading: isLoadingUserEmailsForDropdown,
            name: 'userEmail',
            title: 'Select user',
          },
          {
            value: projectId,
            options: formatProjectIdsForDropdown(projectIdsForDropdown),
            onChange: setProjectId,
            isLoading: isLoadingProjectIdsForDropdown,
            name: 'projectId',
            title: 'Select project',
          },
        ]}
      />
      <div style={{
        display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: '16px',
      }}
      >
        <HalfPageChartSection>
          <StackedBarChart
            title="Daily project spend"
            data={formatDailyProjectSpendForChart(dailyProjectSpendData).data}
            xAxisLabels={getDailyProjectSpendXAxisLabels(dailyProjectSpendData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            legendLabels={formatDailyProjectSpendForChart(dailyProjectSpendData).projectIds}
            xLabel="Calendar date"
            yLabel="Estimated cost ($)"
            isLoading={isLoadingDailyProjectSpend}
            hasNoData={dailyProjectSpendError || dailyProjectSpendData?.cube?.length === 0}
            xAxisDomain={[
              0.5,
              getDailyProjectSpendXAxisLabels(dailyProjectSpendData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Daily spend by type"
            data={formatSpendPerTypeForChart(spendPerTypeData).data}
            xAxisLabels={getSpendPerTypeXAxisLabels(spendPerTypeData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            legendLabels={formatSpendPerTypeForChart(spendPerTypeData).jobTypes}
            xLabel="Calendar date"
            yLabel="Estimated cost ($)"
            isLoading={isLoadingSpendPerType}
            hasNoData={spendPerTypeError || spendPerTypeData?.cube?.length === 0}
            xAxisDomain={[
              0.5,
              getSpendPerTypeXAxisLabels(spendPerTypeData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Average hourly spend"
            data={formatHourlySpendForChart(hourlySpendData).data}
            xAxisLabels={getHourlySpendXAxisLabels(hourlySpendData)}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            legendLabels={formatHourlySpendForChart(hourlySpendData).projectIds}
            xLabel="Hour of the day"
            yLabel="Average spend ($)"
            isLoading={isLoadingHourlySpend}
            hasNoData={hourlySpendError || hourlySpendData?.cube?.length === 0}
            xAxisDomain={[
              0.5,
              getHourlySpendXAxisLabels(hourlySpendData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Average slot usage"
            data={formatSlotUsageForChart(slotUsageData).data}
            xAxisLabels={getSlotUsageXAxisLabels(slotUsageData)}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            legendLabels={formatSlotUsageForChart(slotUsageData).userEmails}
            xLabel="Hour of the day"
            yLabel="Average slots used"
            isLoading={isLoadingSlotUsage}
            hasNoData={slotUsageError || slotUsageData?.cube?.length === 0}
            xAxisDomain={[
              0.5,
              getSlotUsageXAxisLabels(slotUsageData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Average # of queries started per hour"
            data={formatQueriesPerHourForChart(queriesPerHourData)}
            xAxisLabels={getQueriesPerHourXAxisLabels(queriesPerHourData)}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Hour of the day"
            yLabel="Average # of queries started"
            isLoading={isLoadingQueriesPerHour}
            hasNoData={queriesPerHourError || queriesPerHourData?.cube?.length === 0}
            xAxisDomain={[
              0.5,
              getQueriesPerHourXAxisLabels(queriesPerHourData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <PieChart
            title="Spend per user"
            data={formatSpendPerUserForChart(spendPerUserData)}
            isLoading={isLoadingSpendPerUser}
            hasNoData={spendPerUserError || spendPerUserData?.cube?.length === 0}
          />
        </HalfPageChartSection>
        <FullPageChartSection style={{ height: '100%' }}>
          <TreeTableChart
            title="Top costly queries"
            isLoading={isLoadingTopCostQueries}
            hasNoData={topCostQueriesError || topCostQueriesData?.cube?.length === 0}
            columns={topCostQueriesColumns}
            data={formatTopCostQueriesForChart(topCostQueriesData)}
            rowsPerPage={5}
            togglerIconRowCollapsed="caret-right"
            togglerIconRowExpanded="caret-down"
            togglerTooltipText="Expand to see more"
          />
        </FullPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Daily dbt™ spend"
            data={formatDailyDbtSpendForChart(dailyDbtSpendData).data}
            xAxisLabels={getDailyDbtSpendXAxisLabels(dailyDbtSpendData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            legendLabels={formatDailyDbtSpendForChart(dailyDbtSpendData).userEmails}
            xLabel="Calendar date"
            yLabel="Estimated daily balance in $"
            isLoading={isLoadingDailyDbtSpend}
            hasNoData={dailyDbtSpendError || dailyDbtSpendData?.cube?.length === 0}
            xAxisDomain={[
              0.5,
              getDailyDbtSpendXAxisLabels(dailyDbtSpendData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Daily production spend"
            data={formatDailyProdSpendForChart(dailyProdSpendData).data}
            xAxisLabels={getDailyProdSpendXAxisLabels(dailyProdSpendData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            legendLabels={formatDailyProdSpendForChart(dailyProdSpendData).scheduleNames}
            xLabel="Calendar date"
            yLabel="Estimated daily balance in $"
            isLoading={isLoadingDailyProdSpend}
            hasNoData={dailyProdSpendError || dailyProdSpendData?.cube?.length === 0}
            xAxisDomain={[
              0.5,
              getDailyProdSpendXAxisLabels(dailyProdSpendData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </HalfPageChartSection>
        <FullPageChartSection style={{ height: '100%' }}>
          <TreeTableChart
            title="Top costly dbt™ models"
            isLoading={isLoadingTopCostModels}
            hasNoData={topCostModelsError || topCostModelsData?.cube?.length === 0}
            columns={topCostModelsColumns}
            data={formatTopCostModelsForChart(topCostModelsData)}
            rowsPerPage={5}
            togglerIconRowCollapsed="caret-right"
            togglerIconRowExpanded="caret-down"
            togglerTooltipText="Expand to see more"
          />
        </FullPageChartSection>
      </div>
    </AutoLayout>
  );
};

export default BigqueryTab;
