import React, { FC } from 'react';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import ToastFactory from '../../Alerts/ToastFactory';
import success from '../../../../images/bells/success.svg';

interface EditDeleteSuccessfulToastProps {
  operation: string,
}

const EditDeleteSuccessfulToast: FC<EditDeleteSuccessfulToastProps> = ({
  operation,
}) => (
  <ToastFactory
    icon={success}
    title={(
      <Typography
        type="body-bold"
        tagName="span"
        color="success"
        colorStep="80"
        style={{
          margin: 'auto 0px',
        }}
      >
        {operation}
      </Typography>
    )}
  />
);

export default EditDeleteSuccessfulToast;
