import React, { FC } from 'react';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import UpgradeTemplate from './UpgradeTemplate';
import { userAuthStore } from '../../../stores';
import { userHasRadarAdminAccess } from '../../../utilis/PermissionsService';
import RadarImage from './images/Radar.svg';

export interface RadarUpgradeProps {
  upgradePlanName?: string,
  imageSrc?: string,
  pageId?: string,
}

const RadarUpgrade: FC<RadarUpgradeProps> = ({
  upgradePlanName = 'Radar',
  imageSrc = RadarImage,
  pageId,
}) => {
  const { currentUser: { accessLevel } } = userAuthStore.getState();
  const isAdmin = userHasRadarAdminAccess(accessLevel);

  return (
    <div style={{ width: '80%', margin: 'auto', marginTop: '60px' }}>
      <UpgradeTemplate
        isAdmin={isAdmin}
        upgradePlanName={upgradePlanName}
        header="Measure, control, and improve what matters"
        bodyText={(
          <>
            Use Radar to measure and analyse 60+ metrics of schedules, models, sources, and
            tests from production dbt runs.
            <br />
            <br />
            Get insights into team productivity and performance to drive better business outcomes.
            <br />
            <br />
            Measure and save data warehouse cost.
          </>
        )}
        buttonClickEventContext={Contexts.RADAR}
        featureText={pageId ? `${upgradePlanName}-${pageId}` : upgradePlanName}
        docsUrl="https://docs.paradime.io/app-help/radar/writing-a-query"
        upgradeImage={imageSrc}
      />
    </div>
  );
};

export default RadarUpgrade;
