import React, {
  ChangeEvent,
  FC,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { CSSProperties } from 'styled-components';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import {
  Actions,
  Contexts,
} from '@paradime-io/pragma-ui-kit/lib/components/Events';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import Divider from '@paradime-io/pragma-ui-kit/lib/components/Divider';
import Callout from '@paradime-io/pragma-ui-kit/lib/components/Callout';
import flagEU from '../images/flag-eu.svg';
import flagUS from '../images/flag-us.svg';
import useOrganisation, {
  OrganisationReturnType,
  OrganisationData,
  LocationType,
  ErrorMessage,
} from '../../hooks/useOrganisation';
import KnownOrganisations from './KnownOrganisations';
import LocationSelector from './LocationSelector';
import { ValidationRules } from '../../../utilis/useCheckValidation';
import * as S from './OrganisationSelector.styles';
import { ONBOARDING_PAGE, OnboardingRoutePrefix } from '../../Onboarding';

const translateErrorMessage = (
  errorMessage?: ErrorMessage,
) => {
  switch (errorMessage) {
    case 'invalid':
      return 'The organization you entered is invalid';
    case 'internal':
      return 'An internal error occurred';
    case 'not_found':
      return '';
    case 'existing':
      return 'That organization name is not available';
    default:
      return undefined;
  }
};

interface getSubtitleTextProps {
  hasKnownOrganisations: boolean,
  needsToCreateOrg: boolean,
}
const getSubtitleText = ({
  hasKnownOrganisations,
  needsToCreateOrg,
}: getSubtitleTextProps) => {
  if (needsToCreateOrg) {
    return (
      <span>
        Choose an
        {' '}
        <strong>organization name</strong>
        {' '}
        and
        {' '}
        <strong>hosting region</strong>
        {' '}
        for your Paradime instance
      </span>
    );
  }
  if (hasKnownOrganisations) {
    return (
      <span>
        Select or enter your
        {' '}
        <strong>organization name</strong>
        {' '}
        to continue
      </span>
    );
  }
  return (
    <span>
      Enter your
      {' '}
      <strong>organization name</strong>
      {' '}
      to continue
    </span>
  );
};

export interface LocationItem {
  key: LocationType;
  icon: any;
  title: string;
  subtitle: string;
}
const Locations: LocationItem[] = [
  {
    key: 'us',
    icon: flagUS,
    title: 'United States',
    subtitle: 'us-east-1 (N. Virginia)',
  },
  {
    key: 'eu',
    icon: flagEU,
    title: 'European Union',
    subtitle: 'eu-west-2 (London)',
  },
];

export interface OrganisationSelectorProps {
  style?: CSSProperties,
}

const OrganisationSelector: FC<OrganisationSelectorProps> = ({ style }) => {
  const [orgResponse, setOrgResponse] = useState<OrganisationReturnType>({
    hasError: false,
    errorMessage: undefined,
  });
  const { set, list, create } = useOrganisation();
  const [knownOrganisations, setKnownOrganisations] = useState<
    OrganisationData[]
  >([]);
  const [needsToCreateOrg, setNeedsToCreateOrg] = useState(false);
  const [currentLocation, setCurrentLocation] = useState<
    LocationType | null
  >(null);
  const [hasError, setHasError] = useState(false);
  const [calloutErrorMessage, setCalloutErrorMessage] = useState('');
  const [showOrgNotFoundCallout, setShowOrgNotFoundCallout] = useState(false);

  const { location: { href } } = window;
  const url = new URL(href);
  const isFromParadimeWebsite = ['chrome-extension', 'main-app'].includes(url.searchParams.get('target') || '');
  const isSignup = url.searchParams.get('is_signup') === 'true';

  const [organisationName, setOrganisationName] = useState(url.searchParams.get('org_name') || '');
  const [hasValidationError, setHasValidationError] = useState(organisationName.length === 0);
  const [formIsSubmitted, setFormIsSubmitted] = useState(false);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      setKnownOrganisations(await list());
    })();
  }, []);

  useEffect(() => {
    if (isSignup) setNeedsToCreateOrg(true);
  }, [isSignup]);

  const handleOrgNameChange = (
    e: ChangeEvent<HTMLInputElement>,
    newHasValidationError?: boolean,
  ) => {
    setOrganisationName(e.target.value.toLowerCase());
    setHasValidationError(newHasValidationError || false);
  };

  const organisationSelected = async () => {
    const response = await set(organisationName);

    if (response.errorMessage === 'not_found') {
      setShowOrgNotFoundCallout(true);
      setFormIsSubmitted(false);
    }

    setOrgResponse(response);
    if (response.hasError) {
      console.error(`organisationSelected returned this error: ${response.errorMessage}`);
    }
  };

  const createOrganisation = async () => {
    if (currentLocation) {
      const response = await create(organisationName, currentLocation);
      setOrgResponse(response);
      if (response.hasError) {
        console.error(`organisationSelected returned this error: ${response.errorMessage}`);
      }
      if (response.errorMessage === 'existing') {
        history.push(`/${OnboardingRoutePrefix}/${ONBOARDING_PAGE.ERROR_ORG_ALREADY_EXISTS}`, { orgName: organisationName });
      }
    } else {
      // User needs to choose a location
      setHasError(true);
      setFormIsSubmitted(false);
      setCalloutErrorMessage('Please select a region');
    }
  };

  const handleClick = () => {
    setFormIsSubmitted(true);
    setHasError(false);

    if (needsToCreateOrg) {
      createOrganisation();
    } else {
      organisationSelected();
    }
  };

  const hasKnownOrganisations = knownOrganisations.length > 0;

  const calculateStyles = () => {
    if (style) return style;
    return isFromParadimeWebsite
      ? undefined
      : {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        maxWidth: '450px',
      } as CSSProperties;
  };

  return (
    <AutoLayout
      direction="vertical"
      width="full"
      padding="normal"
      distribution="packed"
      horizontalGap="dense"
      alignment="center"
      style={calculateStyles()}
    >
      <Typography
        tagName="span"
        type="h4"
        style={{ textAlign: 'center' }}
        font="inter"
      >
        {`${needsToCreateOrg ? 'Create' : 'Enter'} Organization`}
      </Typography>
      <Typography
        tagName="span"
        type="body-small"
        colorStep="60"
        style={{ textAlign: 'center' }}
      >
        {getSubtitleText({ hasKnownOrganisations, needsToCreateOrg })}
      </Typography>
      {hasKnownOrganisations && !needsToCreateOrg && (
        <>
          <KnownOrganisations
            showLoader={false}
            knownOrganisations={knownOrganisations}
            onSelectOrganisation={set}
          />
          <Divider text=" Or " textBackgroundColor="var(--white)" />
        </>
      )}
      <S.OrganisationNameInput
        color="default"
        disabled={needsToCreateOrg && !isFromParadimeWebsite}
        full
        label="Organization"
        placeholder="Enter your organization name"
        type="text"
        view="outlined"
        dataName="organisationName"
        value={organisationName}
        expose={handleOrgNameChange}
        onKeyUp={(k) => {
          if (k.code === 'Enter') {
            handleClick();
          }
        }}
        error={orgResponse.hasError}
        errorHelperText={translateErrorMessage(orgResponse.errorMessage)}
        checkValidationAfterEveryChange
        validation={[{
          rule: ValidationRules.ORGANISATION_RULE,
          message: '3 - 64 letters, numbers, hyphens, underscores.',
        }]}
      />
      {needsToCreateOrg && (
        <>
          <Divider
            text="Select organization region"
            textBackgroundColor="var(--white)"
          />
          <LocationSelector
            locations={Locations}
            onLocationClick={setCurrentLocation}
            currentLocation={currentLocation}
          />
        </>
      )}
      {hasError && (
        <Callout
          icon="warning-sign"
          content={(
            <Typography tagName="span" type="body-bold-small" color="danger">
              {calloutErrorMessage}
            </Typography>
          )}
          color="danger"
          view="smooth"
          dense
          style={{ maxWidth: '400px', margin: 'auto' }}
        />
      )}
      {showOrgNotFoundCallout && (
        <Callout
          icon="warning-sign"
          title="Organization not found"
          content={(
            <AutoLayout
              direction="vertical"
              verticalGap="super-ultra-dense"
              distribution="packed"
              padding="none"
            >
              <Typography tagName="span" type="body-bold-small" color="danger">
                Check spelling or click signup below to create a new organization.
              </Typography>
              <DefaultButton
                type="standard"
                view="outlined"
                color="danger"
                text="Signup"
                onClick={() => {
                  setShowOrgNotFoundCallout(false);
                  setNeedsToCreateOrg(true);
                  url.searchParams.set('is_signup', 'true');
                  url.searchParams.set('org_name', organisationName);
                  history.push({ search: url.search });
                }}
                eventContext={Contexts.ONBOARDING}
                eventObject="orgNotFoundNeedsSignup"
                eventAction={Actions.CLICKED}
                style={{
                  margin: '2px',
                  background: 'var(--white)',
                }}
              />
            </AutoLayout>
          )}
          color="danger"
          view="smooth"
          dense
          style={{ maxWidth: '400px', margin: 'auto' }}
        />
      )}
      <AutoLayout
        direction="horizontal"
        width="full"
        padding="normal"
        distribution="packed"
        horizontalGap="dense"
        alignment="center"
        style={{
          paddingLeft: '0',
          paddingRight: '0',
          paddingTop: '0',
        }}
      >
        <DefaultButton
          style={{ maxWidth: '101px' }}
          color="primary"
          type="standard"
          view="filled"
          text="Continue"
          onClick={handleClick}
          loading={formIsSubmitted}
          eventContext={Contexts.APP}
          eventObject="organisation"
          eventAction={Actions.SELECTED}
          disabled={hasValidationError}
        />
      </AutoLayout>
    </AutoLayout>
  );
};

export default OrganisationSelector;
