import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import React, { FC, ReactNode, CSSProperties } from 'react';

export interface ToastFactoryProps {
  text?: string | ReactNode,
  icon: string,
  title?: ReactNode,
  imageStyle?: CSSProperties
}

const ToastFactory: FC<ToastFactoryProps> = ({
  text, icon, title, imageStyle,
}) => (
  <AutoLayout
    direction="vertical"
    padding="none"
    verticalGap="super-ultra-dense"
  >
    {
      title && (
        <AutoLayout
          direction="horizontal"
          padding="none"
          horizontalGap="very-dense"
        >
          <img src={icon} alt="info" style={{ ...imageStyle, alignSelf: 'center' }} />
          {title}
        </AutoLayout>
      )
    }
    {text && (
    <Typography
      type="body-bold"
      tagName="p"
    >
      {text}
    </Typography>
    )}
  </AutoLayout>
);

export default ToastFactory;
