import React, { FC } from 'react';
import {
  VictoryChart,
  VictoryStack,
  VictoryBar,
  VictoryAxis,
  VictoryTheme,
  VictoryContainer,
  VictoryLabel,
  VictoryTooltip,
} from 'victory';
import { DateTime } from 'luxon';
import { formatCurrency } from './utils';
import { key } from '../../../utilis';
import { useGetChartDimensionsReturnType } from '../../Bolt/Scheduler/ScheduleInsights/useGetChartDimensions';

export interface BarChartPlotItem {
  x: string,
  y: number,
}

export interface SavingsBarChartProps {
  data: BarChartPlotItem[][],
  axisDatesList: string[],
  dimensions: useGetChartDimensionsReturnType,
}

const tickLabelStyle = (fontSize: number) => ({
  fontFamily: 'var(--inter)',
  color: 'var(--grey70)',
  fontWeight: 'var(--font_weight_caption)',
  fontSize: fontSize.toFixed(0),
  lineHeight: 'var(--line_height_caption)',
  letterSpacing: 'var(--letter_spacing_caption)',
});

const axisStyle = {
  axis: { stroke: 'var(--grey10)' },
  ticks: { stroke: 'var(--grey10)', size: 2 },
  grid: { stroke: 'none' },
};

const calculateLabelText = (datum: any, index: number) => {
  const currencyAmount = formatCurrency(datum.y);
  const date = DateTime.fromFormat(datum.x, 'yyyy-LL-dd').toFormat('d LLL yy');

  switch (index) {
    case 0:
      return `${currencyAmount} estimated spend after savings on ${date}`;
    case 1:
      return `${currencyAmount} could have been saved on ${date}`;
    case 2:
      return `${currencyAmount} saved on ${date}`;
    default:
      return null;
  }
};

const SavingsBarChart: FC<SavingsBarChartProps> = ({ data, axisDatesList, dimensions }) => {
  const {
    chartHeight,
    axisConfig,
    tooltipConfig,
  } = dimensions;

  // When the list gets long, only display every other label
  const formatDateTickLabels = () => {
    const isLongList = axisDatesList.length > 50;

    return axisDatesList.map((date, index) => {
      const isEvenIndex = index % 2 === 0;
      return isLongList && isEvenIndex
        ? ''
        : DateTime.fromFormat(date, 'yyyy-LL-dd').toFormat('d LLL');
    });
  };

  return (
    <VictoryChart
      theme={VictoryTheme.material}
      scale={{ x: 'time', y: 'linear' }}
      height={chartHeight}
      domainPadding={{ x: 2, y: 2 }}
      padding={{
        left: 20,
        right: 8,
        top: 8,
        bottom: 20,
      }}
      containerComponent={(
        <VictoryContainer
          responsive
        />
      )}
    >
      <VictoryStack
        colorScale={['var(--grey20)', 'var(--violet50)', 'var(--green50)']}
      >
        {data.map((plotItem, index) => (
          <VictoryBar
            key={key(plotItem?.[0]?.x)}
            data={plotItem}
            barRatio={1}
            labels={({ datum }) => calculateLabelText(datum, index)}
            events={[
              {
                target: 'data',
                eventHandlers: {
                  onMouseOver: () => [
                    {
                      target: 'labels',
                      mutation: () => ({ active: true }),
                    },
                  ],
                  onMouseOut: () => [
                    {
                      target: 'labels',
                      mutation: () => ({ active: false }),
                    },
                  ],
                },
              },
            ]}
            labelComponent={(
              <VictoryTooltip
                cornerRadius={4}
                pointerWidth={5}
                pointerLength={5}
                flyoutWidth={tooltipConfig.flyoutWidth}
                flyoutHeight={tooltipConfig.flyoutHeight}
                flyoutStyle={{
                  strokeWidth: 0,
                  fill: 'var(--grey100)',
                }}
                labelComponent={(
                  <VictoryLabel
                    style={{
                      fontFamily: 'var(--inter)',
                      letterSpacing: 'var(--letter_spacing_caption)',
                      fill: 'var(--grey30)',
                      fontWeight: 400,
                      fontSize: tooltipConfig.labelFontSize,
                      textAnchor: 'middle',
                      verticalAnchor: 'middle',
                    }}
                  />
                )}
              />
            )}
          />
        ))}
      </VictoryStack>
      <VictoryAxis
        dependentAxis
        tickFormat={(tick) => formatCurrency(tick, true)}
        tickLabelComponent={(
          <VictoryLabel
            dx={4}
            style={tickLabelStyle(axisConfig.tickLabelFontSize)}
          />
        )}
        style={axisStyle}
      />
      <VictoryAxis
        scale="time"
        tickFormat={formatDateTickLabels()}
        tickCount={axisDatesList.length}
        tickValues={axisDatesList}
        tickLabelComponent={(
          <VictoryLabel
            angle={-45}
            dy={-4}
            dx={3}
            style={tickLabelStyle(axisConfig.tickLabelFontSize)}
          />
        )}
        style={axisStyle}
      />
    </VictoryChart>
  );
};

export default SavingsBarChart;
