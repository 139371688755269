import React, { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import {
  StackedBarChart,
  PieChart,
  TreeTableChart,
  LineChart,
  GroupedBarChart,
} from '@paradime-io/pragma-ui-kit/lib/components/VictoryCharts';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { useCubeLazyQuery } from '../../../app/hooks/useCubeQuery';
import {
  modelMaterializationCountQuery,
  modelMaterializationCountReturnType,
  formatModelMaterializationCountForChart,
  getModelMaterializationCountXAxisLabels,
  modelRunStatusQuery,
  modelRunStatusReturnType,
  formatModelRunStatusForChart,
  modelStatusCountQuery,
  modelStatusCountReturnType,
  formatModelStatusCountForChart,
  getModelStatusCountXAxisLabels,
  modelTimeStatsQuery,
  modelTimeStatsReturnType,
  formatModelTimeStatsForChart,
  getModelTimeStatsXAxisLabels,
  modelDailyStatusQuery,
  modelDailyStatusReturnType,
  formatModelDailyStatusForChart,
  getModelDailyStatusXAxisLabels,
  modelStatsQuery,
  modelStatsReturnType,
  modelStatsColumns,
  formatModelStatsForChart,
} from './OverviewCubeQueries';
import FilterRow from '../FilterRow';
import {
  timeframes,
  HalfPageChartSection,
  FullPageChartSection,
  getFormattedYAxisLabel,
  getStatusColour,
} from '../utils';
import { useGetFiltersFromUrl } from '../hooks/useGetFiltersFromUrl';

const OverviewTab: FC = () => {
  const { getInitialFilterValue } = useGetFiltersFromUrl();

  const [timeframe, setTimeframe] = useState(getInitialFilterValue('timeframe', timeframes[2].value)!);

  const [
    getMaterializedCount, {
      data: materializedCountData,
      loading: isLoadingMaterializedCount,
      error: materializedCountError,
    },
  ] = useCubeLazyQuery<modelMaterializationCountReturnType>({
    query: modelMaterializationCountQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getModelRunStatus,
    { data: modelRunStatusData, loading: isLoadingModelRunStatus, error: modelRunStatusError },
  ] = useCubeLazyQuery<modelRunStatusReturnType>({
    query: modelRunStatusQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getModelStatusCount, {
      data: modelStatusCountData,
      loading: isLoadingModelStatusCount,
      error: modelStatusCountError,
    },
  ] = useCubeLazyQuery<modelStatusCountReturnType>({
    query: modelStatusCountQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getModelTimeStats,
    { data: modelTimeStatsData, loading: isLoadingModelTimeStats, error: modelTimeStatsError },
  ] = useCubeLazyQuery<modelTimeStatsReturnType>({
    query: modelTimeStatsQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getModelDailyStatus, {
      data: modelDailyStatusData,
      loading: isLoadingModelDailyStatus,
      error: modelDailyStatusError,
    },
  ] = useCubeLazyQuery<modelDailyStatusReturnType>({
    query: modelDailyStatusQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getModelStats, { data: modelStatsData, loading: isLoadingModelStats, error: modelStatsError },
  ] = useCubeLazyQuery<modelStatsReturnType>({
    query: modelStatsQuery,
    variables: { timeframe: timeframe.value },
  });

  const refetchAllData = () => {
    getMaterializedCount();
    getModelRunStatus();
    getModelStatusCount();
    getModelTimeStats();
    getModelDailyStatus();
    getModelStats();
  };

  useEffect(() => {
    refetchAllData();
  }, [timeframe]);

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      verticalGap="dense"
      distribution="packed"
    >
      <FilterRow
        dropdowns={[
          {
            value: timeframe,
            options: timeframes,
            onChange: setTimeframe,
            name: 'timeframe',
            title: 'Select date range',
          },
        ]}
      />
      <div style={{
        display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: '16px',
      }}
      >
        <HalfPageChartSection>
          <StackedBarChart
            title="Current models by materialization type"
            subtitle="Identify the type of models in your dbt™ project. Reduce dwh costs by optimizing your materialization strategy, minimize table and seed materializations"
            data={formatModelMaterializationCountForChart(materializedCountData)}
            xAxisLabels={getModelMaterializationCountXAxisLabels(materializedCountData)}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Materialization type"
            yLabel="Models count"
            isLoading={isLoadingMaterializedCount}
            hasNoData={materializedCountError || materializedCountData?.cube?.length === 0}
            xAxisDomain={[
              0.5,
              getModelMaterializationCountXAxisLabels(materializedCountData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
            showDetailsButton
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <PieChart
            title="Current models runs status"
            subtitle="Snapshots of the last run status of your dbt™ models."
            data={formatModelRunStatusForChart(modelRunStatusData).data}
            legendLabels={formatModelRunStatusForChart(modelRunStatusData).statuses}
            customColourScale={formatModelRunStatusForChart(modelRunStatusData).colours}
            isLoading={isLoadingModelRunStatus}
            hasNoData={modelRunStatusError || modelRunStatusData?.cube?.length === 0}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </HalfPageChartSection>
        <FullPageChartSection>
          <LineChart
            title="Daily executed dbt™ models count by status"
            subtitle="Track your dbt™ models execution status daily. Identify trends in recurring models failures trends."
            data={formatModelStatusCountForChart(modelStatusCountData).data}
            xAxisLabels={getModelStatusCountXAxisLabels(modelStatusCountData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Calendar date"
            yLabel="Model count"
            legendLabels={formatModelStatusCountForChart(modelStatusCountData).statuses}
            customColourScale={formatModelStatusCountForChart(modelStatusCountData).colours}
            isLoading={isLoadingModelStatusCount}
            hasNoData={modelStatusCountError || modelStatusCountData?.cube?.length === 0}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
            showDetailsButton
          />
        </FullPageChartSection>
        <FullPageChartSection>
          <GroupedBarChart
            title="dbt™ models runtime performance"
            subtitle="Identify top 10 models with poor run performance. Understand you dbt™ average, min, max runtime"
            data={formatModelTimeStatsForChart(modelTimeStatsData)}
            xAxisLabels={getModelTimeStatsXAxisLabels(modelTimeStatsData)}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Model name"
            yLabel="Execution time (seconds)"
            legendLabels={['model execution time - Mean', 'model execution time - Min', 'model execution time - Max']}
            isLoading={isLoadingModelTimeStats}
            hasNoData={modelTimeStatsError || modelTimeStatsData?.cube?.length === 0}
            maxCharsAxisX={12}
            showDetailsButton
            xAxisDomain={[0.5, getModelTimeStatsXAxisLabels(modelTimeStatsData).length + 1]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </FullPageChartSection>
        <FullPageChartSection>
          <StackedBarChart
            title="Top 10 models with most failures"
            subtitle="Track models in your dbt™ project with most failures in the selected time period and reduce model downtime"
            data={formatModelDailyStatusForChart(modelDailyStatusData)}
            xAxisLabels={getModelDailyStatusXAxisLabels(modelDailyStatusData)}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Model name"
            yLabel="Model build failure count"
            customColourScale={[getStatusColour('fail')]}
            isLoading={isLoadingModelDailyStatus}
            hasNoData={modelDailyStatusError || modelDailyStatusData?.cube?.length === 0}
            maxCharsAxisY={6}
            xAxisDomain={[0.5, getModelDailyStatusXAxisLabels(modelDailyStatusData).length + 1]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
            showDetailsButton
          />
        </FullPageChartSection>
        <FullPageChartSection style={{ height: '100%' }}>
          <TreeTableChart
            title="Models executed by schedules"
            subtitle="Identify which models are triggered by more than one schedule in your project. Reduce model redundancy and improve your schedules efficiency."
            isLoading={isLoadingModelStats}
            hasNoData={modelStatsError || modelStatsData?.cube?.length === 0}
            columns={modelStatsColumns}
            data={formatModelStatsForChart(modelStatsData)}
            rowsPerPage={5}
            togglerIconRowCollapsed="caret-right"
            togglerIconRowExpanded="caret-down"
            togglerTooltipText="Expand to see more"
          />
        </FullPageChartSection>
      </div>
    </AutoLayout>
  );
};

export default OverviewTab;
