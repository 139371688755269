import React, { FC } from 'react';
import { Icon } from '@blueprintjs/core';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import TabsSegmented from '@paradime-io/pragma-ui-kit/lib/components/TabsSegmented';
import { AccountSettingsHelp } from '../../Common/AppHelp/help';

export enum WebhooksTabs {
  CONFIGURE = 'configure',
  EXPLORE_TEMPLATES = 'explore',
}

export interface PageHeaderProps {
  selectedTab: WebhooksTabs,
  onTabChange: (newTab: WebhooksTabs) => void,
}

const PageHeader: FC<PageHeaderProps> = ({
  selectedTab,
  onTabChange,
}) => (
  <AutoLayout
    direction="vertical"
    padding="none"
    verticalGap="normal"
    distribution="packed"
    alignment="top-left"
  >
    <AutoLayout
      direction="horizontal"
      padding="none"
      distribution="packed"
      horizontalGap="very-dense"
    >
      <Typography
        style={{ placeSelf: 'flex-start', padding: '5px 0', margin: 0 }}
        type="h6"
        tagName="span"
      >
        Webhooks
      </Typography>
      <Chips
        color="primary_alt"
        round={false}
        style={{ margin: '4px', verticalAlign: 'bottom' }}
        tag="Learn more"
        type="dense"
        view="smooth"
        onClick={() => (
          window.CommandBar.openHelpHub(
            { articleId: AccountSettingsHelp.WORKSPACE_WEBHOOKS },
          )
        )}
      />
    </AutoLayout>
    <AutoLayout
      direction="horizontal"
      padding="none"
      distribution="space-between"
      style={{ gridAutoColumns: 'unset' }}
    >
      <Typography
        font="inter"
        type="caption"
        color="default"
        colorStep="50"
      >
        Notify other applications and tools through Paradime outbound webhooks,
        allowing for real-time updates across different platforms.
        Choose from our templates or create a custom workflow.
      </Typography>
      <TabsSegmented
        type="dense"
        view="smooth"
        color="primary"
        right={false}
        isIcon={false}
        selectedTabId={selectedTab}
        data={[
          {
            id: WebhooksTabs.CONFIGURE,
            title: (
              <AutoLayout
                direction="horizontal"
                padding="none"
                horizontalGap="very-dense"
                distribution="packed"
                style={{ height: '28px' }}
              >
                <Icon icon="plus" color="var(--violet50)" />
                <Typography font="inter" type="caption" color="primary" colorStep="50">New Webhook</Typography>
              </AutoLayout>
            ),
            visible: true,
          },
          {
            id: WebhooksTabs.EXPLORE_TEMPLATES,
            title: (
              <AutoLayout
                direction="horizontal"
                padding="none"
                horizontalGap="very-dense"
                distribution="packed"
                style={{ height: '28px' }}
              >
                <Icon icon="grid-view" color="var(--violet50)" />
                <Typography font="inter" type="caption" color="primary" colorStep="50">Templates</Typography>
              </AutoLayout>
            ),
            visible: true,
          },
        ]}
        onChange={(newTabId) => onTabChange(newTabId as WebhooksTabs)}
      />
    </AutoLayout>
  </AutoLayout>
);

export default PageHeader;
