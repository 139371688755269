import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { Icon } from '@blueprintjs/core';
import Dropdown from '@paradime-io/pragma-ui-kit/lib/components/Dropdown';
import { MenuTemplate } from '@paradime-io/pragma-ui-kit/lib/components/Menu';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import DefaultInput from '@paradime-io/pragma-ui-kit/lib/components/DefaultInput';
import React, { FC } from 'react';
import cronPresets from './CronPresets';
import * as S from './ScheduleForm.styles';

export interface CronDropdownProps {
  handleCronSchedule: (cronSchedule: string) => void,
  scheduleCron: string,
  humanReadableCron: { isValid: boolean, desc: string} | undefined,
  showCronSuggestion: boolean,
}

export const CronDropdown:FC<CronDropdownProps> = (
  {
    handleCronSchedule,
    scheduleCron,
    humanReadableCron,
    showCronSuggestion,
  },
) => (
  <AutoLayout
    direction="vertical"
    padding="none"
    distribution="packed"
    alignment="top-left"
    verticalGap="ultra-dense"
  >
    <AutoLayout
      direction="horizontal"
      padding="none"
      distribution="space-between"
      style={{ alignItems: 'center' }}
    >
      <Typography
        tagName="span"
        type="caption"
        color="default"
        colorStep="50"
      >
        Cron schedule
      </Typography>
      <AutoLayout
        direction="horizontal"
        distribution="packed"
        horizontalGap="ultra-dense"
        padding="none"
      >
        <Icon size={12} icon="time" color="var(--teal60)" />
        <S.SuperDenseDropdownWrapper>
          <Dropdown
            view="smooth"
            text="Use cron preset"
            content={(
              <MenuTemplate
                dense
                items={cronPresets.map((preset) => ({
                  text: preset.humanReadableFormst,
                  type: 'item',
                  onClick: () => handleCronSchedule(preset.cronFormat),
                }))}
                eventContext={Contexts.BOLT}
              />
              )}
            fill
            color="success_alt"
            dense
            height={300}
            position="top"
            minMenuWidth="400"
          />
        </S.SuperDenseDropdownWrapper>
        <Chips
          icon="power"
          color="default"
          round={false}
          tag="Switch OFF"
          type="dense"
          view="smooth"
          onClick={() => handleCronSchedule('OFF')}
        />
        <Chips
          icon="info-sign"
          color="primary_alt"
          round={false}
          tag="Get cron help"
          type="dense"
          view="smooth"
          onClick={() => window.open('https://crontab.guru', '_blank')}
        />
      </AutoLayout>
    </AutoLayout>
    <DefaultInput
      color="default"
      dense
      full
      sensitive
      placeholder="Cron schedule"
      type="text"
      view="outlined"
      dataName="scheduleCron"
      value={scheduleCron}
      expose={(e) => handleCronSchedule(e.target.value)}
    />
    {humanReadableCron && (
    <Typography
      tagName="span"
      type="caption-small"
      color={humanReadableCron.isValid ? 'default' : 'danger'}
      colorStep="60"
    >
      {humanReadableCron.desc}
    </Typography>
    )}
    {showCronSuggestion && (
    <AutoLayout
      direction="horizontal"
      distribution="packed"
      horizontalGap="very-dense"
      padding="none"
    >
      <Icon size={12} icon="lightbulb" color="var(--orange60)" />
      <Typography
        tagName="span"
        type="caption-small"
        color="warning_alt"
        colorStep="60"
      >
        Sure you want to run the schedule at this frequency?
        <br />
        We recommend not more than once every 10 minutes
        to have enough time for dbt™ models to compile and run between schedules.
      </Typography>
    </AutoLayout>
    )}
  </AutoLayout>
);
