import React, { FC } from 'react';
import Callout from '@paradime-io/pragma-ui-kit/lib/components/Callout';

const PausedScheduleCallout: FC = () => (
  <Callout
    icon="info-sign"
    dense
    view="smooth"
    color="warning_alt"
    title="Bolt schedule paused"
    content="This schedule is currently paused and won't be triggered. To activate it, click the edit button and enable it in the settings."
  />
);

export default PausedScheduleCallout;
