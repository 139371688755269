import React, {
  Dispatch, FC, SetStateAction, useState,
} from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultInput from '@paradime-io/pragma-ui-kit/lib/components/DefaultInput';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import DataTable from '@paradime-io/pragma-ui-kit/lib/components/PrimeReactDataTable';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { useSyncThoughtspotDbtModelsMutation } from '../../../client/generated/service';
import LoadingSyncsZeroState from './ZeroStates/LoadingSyncsZeroState';
import SyncFailedZeroState from './ZeroStates/SyncFailedZeroState';
import SuccessPage from './SuccessPage';
import { useHeaderContext } from './useHeaderContext';
import { subtitleTypes } from './Header';
import { TableRow } from '.';

export interface SummariseModelsProps {
  data: TableRow[],
  setData: Dispatch<SetStateAction<TableRow[]>>,
  onGoBackClick: () => void,
}

const SummariseModels: FC<SummariseModelsProps> = ({
  data,
  // setData,
  onGoBackClick,
}) => {
  const [connectionName, setConnectionName] = useState('');
  const [databaseName, setDatabaseName] = useState('');
  const [syncError, setSyncError] = useState('');
  const [successfulSyncData, setSuccessfulSyncData] = useState<{
    worksheets: string[], thoughtSpotUrl: string,
  }>({ worksheets: [], thoughtSpotUrl: '' });

  useHeaderContext({
    subtitleType: subtitleTypes.ENTER_DETAILS,
    buttonType: '',
    buttonIsVisible: false,
    showHeader: true,
  });

  // Filter to only show the selected items
  const selectedModels = data
    .filter(({ isSelected }) => isSelected)
    .map(({
      dbtFolder, modelPath, modelName, databaseName: dbName, schemaName, isSelected, id,
    }, index) => ({
      dbtFolder,
      modelPath,
      modelName,
      databaseName: dbName,
      schemaName,
      index: index + 1,
      isSelected,
      id,
    }));

  const [syncThoughtSpotModels, { loading }] = useSyncThoughtspotDbtModelsMutation({
    onCompleted: ({ syncThoughtspotDbtModels }) => {
      if (syncThoughtspotDbtModels?.ok) {
        setSuccessfulSyncData({
          worksheets: syncThoughtspotDbtModels.worksheetsImported || [],
          thoughtSpotUrl: syncThoughtspotDbtModels.thoughtspotUrl || '',
        });
      }

      if (syncThoughtspotDbtModels?.errorMessage) {
        setSyncError(syncThoughtspotDbtModels.errorMessage);
      }
    },
  });

  const mapColumnsToBEData = (listOfColumns: TableRow[]) => (
    listOfColumns.map((column) => ({
      modelFolderName: column.dbtFolder,
      modelFolderPath: column.modelPath,
      modelName: column.modelName,
      databaseName: column.databaseName,
      schemaName: column.schemaName,
    }))
  );

  const columns = [
    { field: 'index', header: '' },
    { field: 'dbtFolder', header: 'dbt folder' },
    { field: 'modelPath', header: 'model path' },
    { field: 'modelName', header: 'model name' },
    { field: 'databaseName', header: 'database name' },
    { field: 'schemaName', header: 'schema name' },
  ];

  if (loading) {
    return <LoadingSyncsZeroState />;
  }

  if (syncError) {
    return <SyncFailedZeroState backendError={syncError} />;
  }

  if (successfulSyncData.worksheets.length) {
    return (
      <SuccessPage
        {...successfulSyncData}
      />
    );
  }

  return (
    <AutoLayout
      direction="vertical"
      verticalGap="normal"
      padding="none"
      distribution="packed"
    >
      <AutoLayout
        direction="horizontal"
        horizontalGap="expanded"
        padding="none"
        distribution="space-between"
        style={{ alignItems: 'end' }}
      >
        <DefaultButton
          type="standard"
          view="smooth"
          color="default"
          dense
          text="Back to all models"
          onClick={onGoBackClick}
          eventContext={Contexts.LOGS}
          eventObject="backToViewAllThoughtSpotModels"
          eventAction={Actions.CLICKED}
        />
        <AutoLayout
          direction="horizontal"
          horizontalGap="very-dense"
          padding="none"
          distribution="packed"
          style={{ alignItems: 'end' }}
        >
          <DefaultInput
            type="text"
            view="outlined"
            color="default"
            dense
            label="ThoughtSpot Connection"
            placeholder="Enter connection name"
            value={connectionName}
            expose={(e) => setConnectionName(e.target.value)}
            required
          />
          <DefaultInput
            type="text"
            view="outlined"
            color="default"
            dense
            label="ThoughtSpot Database"
            placeholder="Enter database name"
            value={databaseName}
            expose={(e) => setDatabaseName(e.target.value)}
            required
          />
          <DefaultButton
            type="standard"
            view="filled"
            color="primary"
            dense
            text="Sync to ThoughtSpot"
            disabled={connectionName.length === 0 || databaseName.length === 0}
            onClick={() => syncThoughtSpotModels({
              variables: {
                connectionName, databaseName, models: mapColumnsToBEData(selectedModels),
              },
            })}
            eventContext={Contexts.LOGS}
            eventObject="syncThoughtSpotModels"
            eventAction={Actions.CLICKED}
          />
        </AutoLayout>
      </AutoLayout>
      <DataTable
        columns={columns}
        data={selectedModels}
        size="small"
        usePagination
        rowsPerPage={10}
        rowsPerPageOptions={[10, 25, 50]}
      />
    </AutoLayout>
  );
};

export default SummariseModels;
