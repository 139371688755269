import { useEffect, useState } from 'react';
import {
  useGetScheduleNamesQuery,
  useGetGitBranchInfoQuery,
  GetScheduleNamesQuery,
} from '../../../client/generated/service';

export type GQLScheduleType = NonNullable<NonNullable<NonNullable<GetScheduleNamesQuery['getScheduleNames']>['scheduleNames']>[number]>;

const useGetSchedulesAndBranches = () => {
  const [scheduleNamesList, setScheduleNamesList] = useState<string[]>([]);
  const [areSchedulesDefined, setAreSchedulesDefined] = useState(false);
  const [defaultBranch, setDefaultBranch] = useState('');
  const [gitBranchList, setGitBranchList] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTurboCiDefined, setIsTurboCiDefined] = useState(false);

  const { data: allSchedulesData } = useGetScheduleNamesQuery({
    variables: {
      offset: 0,
      limit: 500,
      showInactive: false,
    },
  });

  const { data: branchData } = useGetGitBranchInfoQuery();

  useEffect(() => {
    if (allSchedulesData?.getScheduleNames?.scheduleNames) {
      const schedulesNamesObj = allSchedulesData.getScheduleNames.scheduleNames.filter(
        Boolean,
      ) as GQLScheduleType[];
      const scheduleNames = schedulesNamesObj.map((s) => s.name);
      const turboCiScheduleObj = schedulesNamesObj.filter((s) => s.turboCi?.enabled);
      setScheduleNamesList(scheduleNames);
      setIsTurboCiDefined(turboCiScheduleObj.length > 0);
      setAreSchedulesDefined(scheduleNames.length > 0);
    }
  }, [allSchedulesData]);

  useEffect(() => {
    if (branchData?.getGitBranchInfo?.branches && branchData.getGitBranchInfo.mainBranch) {
      const defaultBranchName = branchData.getGitBranchInfo.mainBranch.name;
      const branchNames = branchData.getGitBranchInfo.branches
        .filter((b) => (b.name !== defaultBranchName && b.name.startsWith('origin/')))
        .map((b) => b.name.replace('origin/', ''));

      setDefaultBranch(defaultBranchName);
      setGitBranchList([
        `${defaultBranchName} (default)`,
        ...branchNames,
      ]);
    }
  }, [branchData]);

  useEffect(() => {
    if (
      allSchedulesData?.getScheduleNames?.scheduleNames
      && defaultBranch
      && gitBranchList
    ) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [allSchedulesData, defaultBranch, gitBranchList]);

  return {
    isLoading,
    scheduleNamesList,
    areSchedulesDefined,
    defaultBranch,
    gitBranchList,
    isTurboCiDefined,
  };
};

export default useGetSchedulesAndBranches;
