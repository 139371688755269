import React, { FC } from 'react';
import Callout from '@paradime-io/pragma-ui-kit/lib/components/Callout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';

export interface ElementaryCalloutProps {
  steps?: string[],
  helpLink?: string,
}

const ElementaryCallout: FC<ElementaryCalloutProps> = ({ steps, helpLink }) => (
  <Callout
    icon="info-sign"
    color="warning"
    view="smooth"
    dense
    title="Configure environment variables"
    content={(
      <Typography
        font="inter"
        type="body-small"
        color="warning"
      >
        For this Elementary schedule to work, you will additionally need to:
        <ul>
          {steps?.map((step) => <li>{step}</li>)}
        </ul>
        <a
          href={helpLink}
          target="_blank"
          rel="noreferrer"
          style={{
            color: 'var(--yellow100)',
            textDecoration: 'underline',
          }}
        >
          Check our full guide here.
        </a>
      </Typography>
    )}
  />
);

export default ElementaryCallout;
