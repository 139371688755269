import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import ZeroState, { ParadimeImages } from '@paradime-io/pragma-ui-kit/lib/components/ZeroState';
import CreateScheduleButton from './ActionBar/CreateScheduleButton';

const NoSchedulesZeroState: FC<{ isInactive: boolean }> = ({ isInactive }) => (
  <ZeroState
    image={ParadimeImages.EMPTY_LIGHT}
    direction="horizontal"
    text={(
      <AutoLayout
        direction="vertical"
        padding="none"
        distribution="packed"
        verticalGap="expanded"
        alignment="center"
      >
        <AutoLayout
          padding="none"
          direction="vertical"
          verticalGap="ultra-dense"
        >
          <Typography type="body-bold-small" style={{ textAlign: 'center' }}>
            {isInactive
              ? 'All schedules in this workspace are inactive!'
              : 'Create first schedule'}
          </Typography>
          <Typography type="caption" style={{ textAlign: 'center', maxWidth: '450px', margin: 'auto' }}>
            {isInactive
              ? 'No archived schedules found in this workspace. Only archived schedules will be shown here.'
              : 'No schedules are live in this workspace.'}
          </Typography>
        </AutoLayout>
        <div style={{ width: 'fit-content', display: 'flex', justifySelf: 'center' }}>
          <CreateScheduleButton />
        </div>
      </AutoLayout>
    )}
  />
);

export default NoSchedulesZeroState;
