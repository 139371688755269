import { useState } from 'react';
import {
  useSnowflakeApplyWarehouseOptimizationMutation,
  useSnowflakeRemoveWarehouseOptimizationMutation,
} from '../../../client/generated/service';
import { OptimisationHookProps, optimisationListItem } from '.';
import {
  triggerActivateErrorToast,
  triggerActivateSuccessToast,
  triggerPauseErrorToast,
  triggerPauseSuccessToast,
} from './utils';

const optimisationName = 'AI Autoscaler';

export const useGetSnowflakeWarehouseOptimisations = ({
  onApplyPauseSuccess,
}: OptimisationHookProps): optimisationListItem => {
  const [currentWarehouseName, setCurrentWarehouseName] = useState('');

  const [
    applyOptimisation, { loading: isLoadingApplyOptimisation },
  ] = useSnowflakeApplyWarehouseOptimizationMutation({
    onCompleted: (data) => {
      if (data.snowflakeApplyWarehouseOptimization?.ok) {
        triggerActivateSuccessToast({ optimisationName, warehouseName: currentWarehouseName });
        onApplyPauseSuccess();
      } else {
        triggerActivateErrorToast({ optimisationName, warehouseName: currentWarehouseName });
      }
    },
  });

  const handleApply = (warehouseName: string) => {
    setCurrentWarehouseName(warehouseName);
    applyOptimisation({ variables: { warehouseName } });
  };

  const [
    pauseOptimisation, { loading: isLoadingPauseOptimisation },
  ] = useSnowflakeRemoveWarehouseOptimizationMutation({
    onCompleted: (data) => {
      if (data.snowflakeRemoveWarehouseOptimization?.ok) {
        triggerPauseSuccessToast({ optimisationName, warehouseName: currentWarehouseName });
        onApplyPauseSuccess();
      } else {
        triggerPauseErrorToast({ optimisationName, warehouseName: currentWarehouseName });
      }
    },
  });

  const handlePause = (warehouseName: string) => {
    setCurrentWarehouseName(warehouseName);
    pauseOptimisation({ variables: { warehouseName } });
  };

  return {
    applyOptimisation: handleApply,
    isLoadingApplyOptimisation,
    pauseOptimisation: handlePause,
    isLoadingPauseOptimisation,
  };
};
