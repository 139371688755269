import React, { FC } from 'react';
import MenuItem from '@paradime-io/pragma-ui-kit/lib/components/MenuItem';
import MenuDivider from '@paradime-io/pragma-ui-kit/lib/components/MenuDivider';
import {
  Actions,
  Contexts,
} from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { lineageContextMenuProps } from '../types';

export const lineageContextMenu: FC<lineageContextMenuProps> = ({
  activeNode,
}) => (
  <>
    <MenuItem
      color="default"
      text="Create a new thread"
      type="dense"
      view="smooth"
      disabled
      eventContext={Contexts.LINEAGE}
      eventObject="createNewThread"
      eventAction={Actions.CLICKED}
    />
    <MenuDivider />
    <MenuItem
      color="default"
      text="Open in Hightouch"
      type="dense"
      view="raised"
      disabled
      eventContext={Contexts.LINEAGE}
      eventObject="node"
      eventAction={Actions.OPENED}
      eventProperties={{ type: activeNode.data?.nodeType }}
    />
    <MenuItem
      color="default"
      text="View documentation"
      type="dense"
      view="smooth"
      disabled
      eventContext={Contexts.LINEAGE}
      eventObject="viewDocumentation"
      eventAction={Actions.VIEWED}
    />
  </>
);
