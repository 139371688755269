import styled from 'styled-components';

const TimelineWrapper = styled.div`
  width: 100%;
  & > div {
    width: 100%;
  }
`;

const ArtifactsDataTableWrapper = styled.div`
  & tbody[class*="datatable-tbody"] {
    font-size: var(--font_size_caption);
  }

  & tbody[class*="datatable-tbody"] > tr > td {
    padding: 0.5rem 1rem;
  }
`;

const PreviousNextButton = styled.div`
  & #button-static {
    color: var(--color70) !important;
    background-color: var(--color5) !important;
    padding-left: 8px !important;
    padding-right: 2px !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & #button-next {
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-radius: 0;
  }

  & #button-previous {
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const AiSummaryWrapper = styled.div`
  --font-primary: var(--roboto-mono);
  
  background-color: var(--grey100);
  border-radius: 4px;
  color: var(--grey10);
  padding: 64px 16px 8px 16px;
  height: 512px;
  overflow: auto;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  & .wmde-markdown {
    font-size: var(--font_size_body_small);
  }

  & code {
    color: var(--orange60);
  }
`;

export const WrappingHorizontalAutoLayout = styled.div<{minWidth: number}>`
  & div#schedule-details-analytics > div {
    display: flex;
    flex-wrap: wrap;
    row-gap: 24px;
  }
  
  & div#schedule-details-analytics > div > div {
    flex: 1 1 ${(props) => props.minWidth}px;
  }

  & div#schedule-details-card div[id*="details-card-row"] {
    align-items: flex-start;
  }
`;

export {
  TimelineWrapper, ArtifactsDataTableWrapper, PreviousNextButton, AiSummaryWrapper,
};
