import { gql } from '@apollo/client';

export interface dailyExploreCountReturnType {
  cube: {
    lookerCostByExplore: {
      totalQueryCount: number,
      lookerQueryExplore: string,
      calendarDate: {
        day: string,
      },
    }
  }[]
}

export const dailyExploreCountQuery = gql`
  query dailyExploreCountQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {looker_cost_by_explore: {looker_query_explore: {set: true}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      lookerCostByExplore: looker_cost_by_explore(orderBy: {calendar_date: desc}) {
        totalQueryCount: total_query_count
        lookerQueryExplore: looker_query_explore
        calendarDate: calendar_date {
          day
        }
      }
    }
  }
`;

export const formatDailyExploreCountForChart = (data?: dailyExploreCountReturnType) => {
  if (!data) return { data: [], exploreNames: [] };

  const exploreNames = Array.from(new Set(
    data.cube.map(({ lookerCostByExplore }) => (
      lookerCostByExplore.lookerQueryExplore
    )),
  ));

  const dataToReturn: { x: string, y: number }[][] = [];

  exploreNames.forEach((exploreName) => {
    const formattedData = data.cube
      .filter(({ lookerCostByExplore }) => (
        lookerCostByExplore.lookerQueryExplore === exploreName
      ))
      .map(({ lookerCostByExplore }) => ({
        x: lookerCostByExplore.calendarDate.day,
        y: lookerCostByExplore.totalQueryCount,
      }));

    dataToReturn.push(formattedData);
  });

  return { data: dataToReturn, exploreNames };
};

export const getDailyExploreCountXAxisLabels = (data?: dailyExploreCountReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ lookerCostByExplore }) => (
      lookerCostByExplore.calendarDate.day
    ));
  return Array.from(new Set(dates));
};

export interface top10ModelSpendReturnType {
  cube: {
    lookerCostByModel: {
      totalQueryCostUsd: number,
      lookerQueryModel: string,
    }
  }[]
}

export const top10ModelSpendQuery = gql`
  query top10ModelSpendQuery($timeframe: [String]) {
    cube(
      limit: 10
      where: {looker_cost_by_model: {calendar_date: {inDateRange: $timeframe}}}
    ) {
      lookerCostByModel: looker_cost_by_model(orderBy: {total_query_cost_usd: desc}) {
        totalQueryCostUsd: total_query_cost_usd
        lookerQueryModel: looker_query_model
      }
    }
  }
`;

export const formatTop10ModelSpendForChart = (data?: top10ModelSpendReturnType) => {
  if (!data) return [];

  return [data.cube
    .map(({ lookerCostByModel }) => ({
      x: lookerCostByModel.lookerQueryModel,
      y: lookerCostByModel.totalQueryCostUsd,
    }))];
};

export const getTop10ModelSpendXAxisLabels = (data?: top10ModelSpendReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ lookerCostByModel }) => (
      lookerCostByModel.lookerQueryModel
    ));
  return Array.from(new Set(dates));
};

export interface top10ExploreSpendReturnType {
  cube: {
    lookerCostByExplore: {
      totalQueryCostUsd: number,
      lookerQueryExplore: string,
    }
  }[]
}

export const top10ExploreSpendQuery = gql`
  query top10ExploreSpendQuery($timeframe: [String]) {
    cube(
      limit: 10
      where: {looker_cost_by_explore: {calendar_date: {inDateRange: $timeframe}}}
    ) {
      lookerCostByExplore: looker_cost_by_explore(orderBy: {total_query_cost_usd: desc}) {
        totalQueryCostUsd: total_query_cost_usd
        lookerQueryExplore: looker_query_explore
      }
    }
  }
`;

export const formatTop10ExploreSpendForChart = (data?: top10ExploreSpendReturnType) => {
  if (!data) return [];

  return [data.cube
    .map(({ lookerCostByExplore }) => ({
      x: lookerCostByExplore.lookerQueryExplore,
      y: lookerCostByExplore.totalQueryCostUsd,
    }))];
};

export const getTop10ExploreSpendXAxisLabels = (data?: top10ExploreSpendReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ lookerCostByExplore }) => (
      lookerCostByExplore.lookerQueryExplore
    ));
  return Array.from(new Set(dates));
};

export interface dailyModelSpendReturnType {
  cube: {
    lookerCostByModel: {
      totalQueryCostUsd: number,
      lookerQueryModel: string,
      calendarDate: {
        day: string,
      },
    }
  }[]
}

export const dailyModelSpendQuery = gql`
  query dailyModelSpendQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {looker_cost_by_model: {looker_query_model: {set: true}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      lookerCostByModel: looker_cost_by_model(orderBy: {calendar_date: desc}) {
        totalQueryCostUsd: total_query_cost_usd
        lookerQueryModel: looker_query_model
        calendarDate: calendar_date {
          day
        }
      }
    }
  }
`;

export const formatDailyModelSpendForChart = (data?: dailyModelSpendReturnType) => {
  if (!data) return { data: [], modelNames: [] };

  const modelNames = Array.from(new Set(
    data.cube.map(({ lookerCostByModel }) => (
      lookerCostByModel.lookerQueryModel
    )),
  ));

  const dataToReturn: { x: string, y: number }[][] = [];

  modelNames.forEach((modelName) => {
    const formattedData = data.cube
      .filter(({ lookerCostByModel }) => (
        lookerCostByModel.lookerQueryModel === modelName
      ))
      .map(({ lookerCostByModel }) => ({
        x: lookerCostByModel.calendarDate.day,
        y: lookerCostByModel.totalQueryCostUsd,
      }));

    dataToReturn.push(formattedData);
  });

  return { data: dataToReturn, modelNames };
};

export const getDailyModelSpendXAxisLabels = (data?: dailyModelSpendReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ lookerCostByModel }) => (
      lookerCostByModel.calendarDate.day
    ));
  return Array.from(new Set(dates));
};

export interface dailyExploreSpendReturnType {
  cube: {
    lookerCostByExplore: {
      totalQueryCostUsd: number,
      lookerQueryExplore: string,
      calendarDate: {
        day: string,
      },
    }
  }[]
}

export const dailyExploreSpendQuery = gql`
  query dailyExploreSpendQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {looker_cost_by_explore: {looker_query_explore: {set: true}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      lookerCostByExplore: looker_cost_by_explore(orderBy: {calendar_date: asc}) {
        totalQueryCostUsd: total_query_cost_usd
        lookerQueryExplore: looker_query_explore
        calendarDate: calendar_date {
          day
        }
      }
    }
  }
`;

export const formatDailyExploreSpendForChart = (data?: dailyExploreSpendReturnType) => {
  if (!data) return { data: [], exploreNames: [] };

  const exploreNames = Array.from(new Set(
    data.cube.map(({ lookerCostByExplore }) => (
      lookerCostByExplore.lookerQueryExplore
    )),
  ));

  const dataToReturn: { x: string, y: number }[][] = [];

  exploreNames.forEach((exploreName) => {
    const formattedData = data.cube
      .filter(({ lookerCostByExplore }) => (
        lookerCostByExplore.lookerQueryExplore === exploreName
      ))
      .map(({ lookerCostByExplore }) => ({
        x: lookerCostByExplore.calendarDate.day,
        y: lookerCostByExplore.totalQueryCostUsd,
      }));

    dataToReturn.push(formattedData);
  });

  return { data: dataToReturn, exploreNames };
};

export const getDailyExploreSpendXAxisLabels = (data?: dailyExploreSpendReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ lookerCostByExplore }) => (
      lookerCostByExplore.calendarDate.day
    ));
  return Array.from(new Set(dates));
};
