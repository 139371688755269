const cronPresets = [
  {
    humanReadableFormst: 'Every 10 minutes',
    cronFormat: '*/10 * * * *',
  },
  {
    humanReadableFormst: 'Every hour at minute 0',
    cronFormat: '0 * * * *',
  },
  {
    humanReadableFormst: 'Every 2 hours at minute 0',
    cronFormat: '0 */2 * * *',
  },
  {
    humanReadableFormst: 'Every hour at 0 minute between 9 and 17 hours',
    cronFormat: '0 9-17 * * *',
  },
  {
    humanReadableFormst: 'Every day at 00:00 UTC',
    cronFormat: '0 0 * * *',
  },
  {
    humanReadableFormst: 'Every day at 08.00 UTC',
    cronFormat: '0 8 * * *',
  },
  {
    humanReadableFormst: 'Every Wednesday at 00:00 UTC',
    cronFormat: '0 0 * * 3',
  },
  {
    humanReadableFormst: 'Every Friday at 08:00 UTC',
    cronFormat: '0 8 * * 5',
  },
  {
    humanReadableFormst: 'Every day at 08:00 UTC between Mon and Fri',
    cronFormat: '0 8 * * 1-5',
  },
  {
    humanReadableFormst: 'Once a week at 00:00 UTC on Sun',
    cronFormat: '0 0 * * 0',
  },
  {
    humanReadableFormst: 'Once a week at 00:00 UTC on Sun',
    cronFormat: '0 0 * * 0',
  },
  {
    humanReadableFormst: 'Once a month at 00:00 UTC on Day 10',
    cronFormat: '0 0 10 * *',
  },
];

export default cronPresets;
