/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { DateTime } from 'luxon';
import { Icon, IconName } from '@blueprintjs/core';
import styled from 'styled-components';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Divider from '@paradime-io/pragma-ui-kit/lib/components/Divider';
import { findTimeBetween, key } from '../../../../utilis';
import * as S from '../Scheduler.styles';

const FlyOutContainer = styled.div`
  background: var(--grey100);
  opacity: 0.85;
  color: var(--grey100);
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: var(--inter);
  letter-spacing: var(--letter_spacing_caption);
  font-weight: 400,
  font-size: var(--font_size_caption);
  border-radius: 8px;
  min-width: 200px;
`;

interface TooltipFlyoutProps {
  // Props passed by the Victory component
  x?: number,
  y?: number,
  datum?: any,
}

interface TooltTipRowItem {
  label: string,
  icon?: IconName,
  value: any,
}

const TooltipFlyout = ({
  x,
  y,
  datum,
}: TooltipFlyoutProps) => {
  // tooltip width and height
  const tooltipWidth = 250;
  const tooltipHeight = 200;

  // add constraints - get tooltip x and y positions
  const getTooltipX = () => {
    const xVal = x || 0;
    const tooltipX = xVal - tooltipWidth / 2;
    return tooltipX;
  };

  const getTooltipY = () => {
    const yVal = y || 0;
    const tooltipY = yVal - tooltipHeight - 5;
    return tooltipY;
  };

  const runDuration = findTimeBetween({
    startDate: datum.meta.runStartedOn,
    endDate: datum.meta.runEndedOn,
  });

  const TooltipRow = ({
    label,
    icon,
    value,
  }: TooltTipRowItem) => (
    <AutoLayout
      direction="horizontal"
      distribution="space-between"
      padding="none"
      style={{ alignItems: 'center', gridAutoColumns: 'unset' }}
    >
      <Typography
        font="robotoMono"
        tagName="span"
        type="caption-small"
        colorStep="30"
        style={{ wordBreak: 'break-all' }}
      >
        {label}
      </Typography>
      <AutoLayout
        key={key(datum.meta.gitCommitSha)}
        direction="horizontal"
        padding="none"
        horizontalGap="very-dense"
        distribution="packed"
        style={{ gridAutoColumns: 'unset' }}
      >
        {icon && <Icon size={16} icon={icon} color="var(--grey50)" />}
        <Typography
          font="robotoMono"
          tagName="span"
          type="caption-small"
          color="default"
          colorStep="30"
        >
          {value}
        </Typography>
      </AutoLayout>
    </AutoLayout>
  );

  return (
    <g
      style={{ pointerEvents: 'none' }}
      transform={`scale(0.5) translate(${getTooltipX()}, ${getTooltipY()})`}
    >
      <foreignObject
        x={x || 0}
        y={y || 0}
        width={tooltipWidth}
        height={tooltipHeight}
      >
        <FlyOutContainer>
          <AutoLayout
            direction="vertical"
            distribution="packed"
            padding="none"
            verticalGap="very-dense"
            style={{
              padding: '16px 8px',
            }}
          >
            {TooltipRow(
              {
                label: 'Ended On',
                value: DateTime.fromISO(datum.meta.runEndedOn).toLocaleString(
                  DateTime.DATETIME_MED_WITH_SECONDS,
                ),
              },
            )}
            {TooltipRow({ label: 'Duration', value: runDuration })}
            <Divider size={1} colorStep="70" />
            <AutoLayout
              direction="vertical"
              distribution="packed"
              padding="none"
              verticalGap="ultra-dense"
            >
              {TooltipRow({ label: 'RunId', value: datum.meta.runId })}
              {TooltipRow({ label: 'Branch', icon: 'git-branch', value: datum.meta.gitBranch })}
              {TooltipRow({
                label: 'Commit',
                icon: 'git-commit',
                value: datum.meta.gitCommitSha.slice(0, 7),
              })}
            </AutoLayout>
            <Divider size={1} colorStep="70" />
            <AutoLayout
              direction="vertical"
              distribution="packed"
              padding="none"
              verticalGap="ultra-dense"
            >
              <Typography tagName="span" type="caption-small" colorStep="30">
                Last 7 runs
              </Typography>
              {
                datum.meta.last7runs.length > 0 ? (
                  <AutoLayout
                    direction="horizontal"
                    distribution="packed"
                    padding="none"
                    horizontalGap="very-dense"
                  >
                    {
                      datum.meta.last7runs.map((r: any) => (
                        <S.Dot color={r.color} />
                      ))
                    }
                  </AutoLayout>
                ) : (
                  <Typography font="robotoMono" tagName="span" type="caption-small" colorStep="30">
                    No runs in the last 7 days
                  </Typography>
                )
              }
            </AutoLayout>
          </AutoLayout>
        </FlyOutContainer>
      </foreignObject>
    </g>
  );
};

export default TooltipFlyout;
