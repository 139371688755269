import { gql } from '@apollo/client';

export interface prsMergedReturnType {
  cube: {
    githubPullRequestMergedCount: {
      mergedPrCount: number,
    }
  }[]
}

export const prsMergedQuery = gql`
  query prsMergedQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {github_pull_request_merged_count: {pull_request_merged_at: {inDateRange: $timeframe}}}
    ) {
      githubPullRequestMergedCount: github_pull_request_merged_count {
        mergedPrCount: merged_pr_count
      }
    }
  }
`;

export interface weeklyCodeChangeReturnType {
  cube: {
    githubPullRequestNetLinesOfCodeChange: {
      netLinesOfCodeChange: number,
      pullRequestMergedAt: {
        week: string,
      }
    }
  }[]
}

export const weeklyCodeChangeQuery = gql`
  query weeklyCodeChangeQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {github_pull_request_net_lines_of_code_change: {is_pull_request_merged: {set: true}, pull_request_merged_at: {inDateRange: $timeframe}}}
    ) {
      githubPullRequestNetLinesOfCodeChange: github_pull_request_net_lines_of_code_change(
        orderBy: {pull_request_merged_at: desc}
      ) {
        netLinesOfCodeChange: net_lines_of_code_change
        pullRequestMergedAt: pull_request_merged_at {
          week
        }
      }
    }
  }
`;

export const formatWeeklyCodeChangeForChart = (data?: weeklyCodeChangeReturnType) => {
  if (!data) return [];

  return [data.cube
    .map(({ githubPullRequestNetLinesOfCodeChange }) => ({
      x: githubPullRequestNetLinesOfCodeChange.pullRequestMergedAt.week,
      y: githubPullRequestNetLinesOfCodeChange.netLinesOfCodeChange,
    }))];
};

export const getWeeklyCodeChangeXAxisLabels = (data?: weeklyCodeChangeReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ githubPullRequestNetLinesOfCodeChange }) => (
      githubPullRequestNetLinesOfCodeChange.pullRequestMergedAt.week
    ));
  return Array.from(new Set(dates));
};

export interface prCycleTimeReturnType {
  cube: {
    githubPullRequestAverageCycleTime: {
      avgPrCycleTime: number,
      pullRequestMergedAt: {
        week: string,
      }
    }
  }[]
}

export const prCycleTimeQuery = gql`
  query prCycleTimeQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {github_pull_request_average_cycle_time: {is_pull_request_merged: {set: true}, pull_request_merged_at: {inDateRange: $timeframe}}}
    ) {
      githubPullRequestAverageCycleTime: github_pull_request_average_cycle_time(orderBy: {pull_request_merged_at: asc}) {
        avgPrCycleTime: avg_pr_cycle_time
        pullRequestMergedAt: pull_request_merged_at {
          week
        }
      }
    }
  }
`;

export const formatPrCycleTimeForChart = (data?: prCycleTimeReturnType) => {
  if (!data) return [];

  return [data.cube
    .map(({ githubPullRequestAverageCycleTime }) => ({
      x: githubPullRequestAverageCycleTime.pullRequestMergedAt.week,
      y: githubPullRequestAverageCycleTime.avgPrCycleTime,
    }))];
};

export const getPrCycleTimeXAxisLabels = (data?: prCycleTimeReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ githubPullRequestAverageCycleTime }) => (
      githubPullRequestAverageCycleTime.pullRequestMergedAt.week
    ));
  return Array.from(new Set(dates));
};

export interface prSizeReturnType {
  cube: {
    githubPullRequestSizeAverageSize: {
      avgPrSize: number,
      pullRequestMergedAt: {
        week: string,
      }
    }
  }[]
}

export const prSizeQuery = gql`
  query prSizeQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {github_pull_request_size_average_size: {is_pull_request_merged: {equals: "true"}, pull_request_merged_at: {inDateRange: $timeframe}}}
    ) {
      githubPullRequestSizeAverageSize: github_pull_request_size_average_size(orderBy: {pull_request_merged_at: asc}) {
        avgPrSize: avg_pr_size
        pullRequestMergedAt: pull_request_merged_at {
          week
        }
      }
    }
  }
`;

export const formatPrSizeForChart = (data?: prSizeReturnType) => {
  if (!data) return [];

  return [data.cube
    .map(({ githubPullRequestSizeAverageSize }) => ({
      x: githubPullRequestSizeAverageSize.pullRequestMergedAt.week,
      y: githubPullRequestSizeAverageSize.avgPrSize,
    }))];
};

export const getPrSizeXAxisLabels = (data?: prSizeReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ githubPullRequestSizeAverageSize }) => (
      githubPullRequestSizeAverageSize.pullRequestMergedAt.week
    ));
  return Array.from(new Set(dates));
};

export interface prFileChangesReturnType {
  cube: {
    githubPullRequestAverageFilesChanged: {
      avgFilesChanged: number,
      pullRequestMergedAt: {
        week: string,
      }
    }
  }[]
}

export const prFileChangesQuery = gql`
  query prFileChangesQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {github_pull_request_average_files_changed: {is_pull_request_merged: {set: true}, pull_request_merged_at: {inDateRange: $timeframe}}}
    ) {
      githubPullRequestAverageFilesChanged: github_pull_request_average_files_changed(
        orderBy: {pull_request_merged_at: asc}
      ) {
        avgFilesChanged: avg_files_changed
        pullRequestMergedAt: pull_request_merged_at {
          week
        }
      }
    }
  }
`;

export const formatPrFileChangesForChart = (data?: prFileChangesReturnType) => {
  if (!data) return [];

  return [data.cube
    .map(({ githubPullRequestAverageFilesChanged }) => ({
      x: githubPullRequestAverageFilesChanged.pullRequestMergedAt.week,
      y: githubPullRequestAverageFilesChanged.avgFilesChanged,
    }))];
};

export const getPrFileChangesXAxisLabels = (data?: prFileChangesReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ githubPullRequestAverageFilesChanged }) => (
      githubPullRequestAverageFilesChanged.pullRequestMergedAt.week
    ));
  return Array.from(new Set(dates));
};
