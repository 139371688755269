import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { RunStatusCountsType } from '../ScheduleInsights';
import { capitalize } from '../../../../utilis';
import RadarCTA from './RadarCTA';

interface InsightsChartHeaderProps {
  data: RunStatusCountsType,
}

const InsightsChartHeader:FC<InsightsChartHeaderProps> = ({
  data,
}) => (
  <AutoLayout
    direction="horizontal"
    distribution="space-between"
    padding="none"
    style={{ paddingTop: '8px' }}
  >
    <AutoLayout
      direction="horizontal"
      distribution="packed"
      padding="none"
      horizontalGap="expanded"
      style={{ alignItems: 'start' }}
    >
      <AutoLayout
        direction="vertical"
        distribution="packed"
        padding="none"
        verticalGap="very-dense"
        wrapperStyle={{ alignItems: 'start' }}
      >
        <Typography tagName="span" type="caption" colorStep="40">Success %</Typography>
        <Typography tagName="span" type="body-bold" colorStep="100">
          {((data.total > 0 ? (data.success / data.total) : 0) * 100).toFixed(1)}
        </Typography>
      </AutoLayout>
      {
        Object.entries(data).map(([statusName, statusCount]) => (
          <AutoLayout
            direction="vertical"
            distribution="packed"
            padding="none"
            verticalGap="very-dense"
            wrapperStyle={{ alignItems: 'start' }}
          >
            <Typography tagName="span" type="caption" colorStep="40">{capitalize(statusName)}</Typography>
            <Typography tagName="span" type="body-bold" colorStep="100">{statusCount}</Typography>
          </AutoLayout>
        ))
      }
    </AutoLayout>
    <RadarCTA />
  </AutoLayout>
);

export default InsightsChartHeader;
