import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';

export interface TimescaleButtonsProps {
  timescaleOptions: number[],
  onSelectTimescale: (newTimescale: number) => void,
  selectedTimescale: number,
}

const TimescaleButtons: FC<TimescaleButtonsProps> = ({
  timescaleOptions,
  onSelectTimescale,
  selectedTimescale,
}) => (
  <AutoLayout
    direction="vertical"
    padding="none"
    verticalGap="dense"
    distribution="packed"
  >
    <AutoLayout
      direction="horizontal"
      padding="none"
      horizontalGap="dense"
      distribution="packed"
    >
      {timescaleOptions.map((timescale) => (
        <Typography
          font="inter"
          type="body"
          color="default"
          colorStep="100"
          onClick={() => onSelectTimescale(timescale)}
          style={{
            cursor: 'pointer',
            borderBottom: selectedTimescale === timescale
              ? '3px solid var(--violet70)'
              : 'unset',
          }}
        >
          {`${timescale} days`}
        </Typography>
      ))}
    </AutoLayout>
  </AutoLayout>
);

export default TimescaleButtons;
