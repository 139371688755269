import React, { FC, useEffect, useState } from 'react';
import { AppPortal } from 'svix-react';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import { useGetSvixPortalQuery } from '../../../client/generated/service';
import * as S from './Webhooks.styles';

const Configure: FC = () => {
  const { data: svixPortalData } = useGetSvixPortalQuery();

  const [svixUrl, setSvixUrl] = useState('');

  useEffect(
    () => {
      if (svixPortalData?.getSvixPortal?.ok) {
        setSvixUrl(svixPortalData.getSvixPortal.url);
      }
    }, [svixPortalData],
  );

  return (
    <>
      {svixUrl !== ''
        ? (
          <S.AppPortalWrapper>
            <AppPortal fullSize url={svixUrl} loadingIndicator={<Spinner />} />
          </S.AppPortalWrapper>
        ) : <Spinner />}
    </>
  );
};

export default Configure;
