import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Header, { subtitleTypes } from './Header';
import {
  useListThoughtspotSyncModelsLazyQuery,
  useOrganisationIntegrationListLazyQuery,
} from '../../../client/generated/service';
import GetStartedZeroState from './ZeroStates/GetStartedZeroState';
import LoadingZeroState from './ZeroStates/LoadingModelsZeroState';
import NotConnectedZeroState from './ZeroStates/NotConnectedZeroState';
import ModelsTable from './ModelsTable';
import { ThoughtSpotHeaderContext } from './useHeaderContext';
import { GetAllModelsButtonProps } from './GetAllModelsButton';
import FetchModelsFailed from './ZeroStates/FetchModelsFailedZeroState';
import BoltSchedulesUpgrade from '../../Common/Upgrade/BoltSchedulesUpgrade';
import { useWaitForLDToBeReady } from '../../hooks/useWaitForLDToBeReady';

interface backendModelItem {
  modelFolderName: string,
  modelFolderPath: string,
  modelName: string,
  databaseName: string,
  schemaName: string,
}

export interface TableRow {
  id: string,
  isSelected: boolean,
  dbtFolder: string,
  modelPath: string,
  modelName: string,
  databaseName: string,
  schemaName: string,
}

const ThoughtSpotDbtSync: FC = () => {
  const [modelsData, setModelsData] = useState<TableRow[]>([]);
  const [thoughtSpotIsConnected, setThoughtSpotIsConnected] = useState(false);
  const [boltRunId, setBoltRunId] = useState<number | null>();
  const [fetchModelsError, setFetchModelsError] = useState('');
  const [headerProps, setHeaderProps] = useState({
    subtitleType: 'getStarted' as subtitleTypes,
    buttonType: 'get',
    buttonIsVisible: false,
    showHeader: true,
  });

  const { ldIsReady } = useWaitForLDToBeReady();
  const { flagBoltThoughtspotDbtSync } = useFlags();

  const [getIntegrationsList] = useOrganisationIntegrationListLazyQuery({
    onCompleted: ({ organisationIntegrationList }) => {
      if (organisationIntegrationList) {
        setThoughtSpotIsConnected(
          organisationIntegrationList.thoughtspot === 'active',
        );
      }
    },
  });

  useEffect(() => {
    getIntegrationsList();
  }, []);

  const [
    getThoughtSpotModels,
    { loading: loadingModelsList },
  ] = useListThoughtspotSyncModelsLazyQuery({
    onCompleted: ({ listThoughtspotSyncModels }) => {
      if (listThoughtspotSyncModels?.ok) {
        const formattedData = mapBEDataToColumns(listThoughtspotSyncModels?.models);
        setModelsData(formattedData);

        setBoltRunId(listThoughtspotSyncModels?.boltRunId);
      }

      setFetchModelsError(listThoughtspotSyncModels?.errorMessage || '');
    },
  });

  const mapBEDataToColumns = (listOfModels?: backendModelItem[] | null) => (
    (listOfModels || []).map((model, index) => ({
      id: `${model.modelFolderPath}-${model.modelName}-${index}`,
      isSelected: false,
      dbtFolder: model.modelFolderName,
      modelPath: model.modelFolderPath,
      modelName: model.modelName,
      databaseName: model.databaseName,
      schemaName: model.schemaName,
    }))
  );

  if (ldIsReady && !flagBoltThoughtspotDbtSync) {
    return <BoltSchedulesUpgrade />;
  }

  if (!thoughtSpotIsConnected) {
    return (
      <AutoLayout
        direction="vertical"
        padding="expanded"
        horizontalGap="dense"
        distribution="packed"
        alignment="top-left"
        wrapperStyle={{ gridArea: 'b' }}
      >
        <Header
          onGetAllModelsClick={getThoughtSpotModels}
          showButton={false}
          subtitleType={subtitleTypes.GET_STARTED}
        />
        <NotConnectedZeroState />
      </AutoLayout>
    );
  }

  if (loadingModelsList) {
    return (
      <AutoLayout
        direction="vertical"
        padding="expanded"
        horizontalGap="dense"
        distribution="packed"
        alignment="top-left"
        wrapperStyle={{ gridArea: 'b' }}
      >
        <LoadingZeroState />
      </AutoLayout>
    );
  }

  if (fetchModelsError) {
    return (
      <AutoLayout
        direction="vertical"
        padding="expanded"
        horizontalGap="dense"
        distribution="packed"
        alignment="top-left"
        wrapperStyle={{ gridArea: 'b' }}
      >
        <Header
          onGetAllModelsClick={getThoughtSpotModels}
          showButton
          buttonType="get"
          subtitleType={subtitleTypes.GET_STARTED}
        />
        <FetchModelsFailed backendError={fetchModelsError} />
      </AutoLayout>
    );
  }

  return (
    <ThoughtSpotHeaderContext.Provider
      value={{
        setSubtitleType: (subtitleType: subtitleTypes) => (
          setHeaderProps((curr) => ({ ...curr, subtitleType }))
        ),
        setButtonType: (buttonType: string) => (
          setHeaderProps((curr) => ({ ...curr, buttonType }))
        ),
        setButtonIsVisible: (buttonIsVisible: boolean) => (
          setHeaderProps((curr) => ({ ...curr, buttonIsVisible }))
        ),
        setShowHeader: (showHeader: boolean) => (
          setHeaderProps((curr) => ({ ...curr, showHeader }))
        ),
      }}
    >
      <AutoLayout
        direction="vertical"
        padding="expanded"
        horizontalGap="dense"
        distribution="packed"
        alignment="top-left"
        wrapperStyle={{ gridArea: 'b' }}
      >
        {headerProps.showHeader && (
          <Header
            onGetAllModelsClick={getThoughtSpotModels}
            showButton={headerProps.buttonIsVisible}
            buttonType={headerProps.buttonType as GetAllModelsButtonProps['buttonType']}
            subtitleType={headerProps.subtitleType}
          />
        )}
        {modelsData.length === 0
          ? (
            <GetStartedZeroState
              onGetAllModelsClick={getThoughtSpotModels}
            />
          )
          : <ModelsTable data={modelsData} setData={setModelsData} boltRunId={boltRunId} />}
      </AutoLayout>
    </ThoughtSpotHeaderContext.Provider>
  );
};

export default ThoughtSpotDbtSync;
