import { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { graphQLStore } from '../../../stores';

export interface optimisationAggregateItem {
  agentOptimisationPotentialAnnualSavings: string,
  agentOptimisationSavingsTillDate: string,
  allOptimisationsPotentialAnnualSavings: string,
  allOptimisationsSavingsTillDate: string,
  autoscalerOptimisationPotentialAnnualSavings: string,
  autoscalerOptimisationSavingsTillDate: string,
  estimatedCurrentAnnualSpend: string,
  snowflakeWarehouseName: string,
}

interface OptimisationAggregateQueryResponse { // eslint-disable-next-line camelcase
  snowflake_cost_optimization_warehouse_overview_agg: optimisationAggregateItem[],
}

const costOptimisationsAggregatedQuery = gql`
  query snowflake_cost_optimization_warehouse_overview_agg {
    snowflake_cost_optimization_warehouse_overview_agg {
      agentOptimisationPotentialAnnualSavings: agent_optimisation_potential_annual_savings
      agentOptimisationSavingsTillDate: agent_optimisation_savings_till_date
      allOptimisationsPotentialAnnualSavings: all_optimisations_potential_annual_savings
      allOptimisationsSavingsTillDate: all_optimisations_savings_till_date
      autoscalerOptimisationPotentialAnnualSavings: autoscaler_optimisation_potential_annual_savings
      autoscalerOptimisationSavingsTillDate: autoscaler_optimisation_savings_till_date
      estimatedCurrentAnnualSpend: estimated_current_annual_spend
      snowflakeWarehouseName: snowflake_warehouse_name
    }
  }
`;

export const useGetAggregatedOptimisationData = () => {
  const [shouldRefetch, setShouldRefetch] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [
    aggregatedOptimisationData, setAggregatedOptimisationData,
  ] = useState<optimisationAggregateItem[]>([]);

  const { hasuraClient } = graphQLStore.getState();

  useEffect(() => {
    if (hasuraClient && shouldRefetch) {
      setIsLoading(true);
      hasuraClient
        .query({ query: costOptimisationsAggregatedQuery })
        .then(({ data }: { data: OptimisationAggregateQueryResponse }) => {
          setAggregatedOptimisationData(data.snowflake_cost_optimization_warehouse_overview_agg);
          setIsLoading(false);
          setShouldRefetch(false);
        });
    }
  }, [hasuraClient, shouldRefetch]);

  return {
    aggregatedOptimisationData,
    isLoading,
    getAggregationData: () => setShouldRefetch(true),
  };
};
