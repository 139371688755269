import { DateTime } from 'luxon';
import { triggerToast } from '../../../utilis';

// @ts-ignore - tsc says "notation" is invalid, but it's in the specs
const compactFormatter = Intl.NumberFormat('en', { notation: 'compact', style: 'currency', currency: 'USD' });
const formatter = Intl.NumberFormat('en', { style: 'currency', currency: 'USD' });

export const formatCurrency = (amount?: number, compact?: boolean) => {
  if (!amount) return '$-';
  if (amount < 1) return '$0';
  return compact ? compactFormatter.format(amount) : formatter.format(amount);
};

export const listDates = (daysAgo: number) => {
  const now = DateTime.now();
  const emptyArray = Array.from({ length: daysAgo }, (val, index) => daysAgo - index);
  const dates = emptyArray.map((daysBack) => (
    now
      .minus({ days: daysBack })
      .toFormat('yyyy-LL-dd')
  ));
  return dates;
};

export interface triggerSuccessErrorToastProps {
  optimisationName: string,
  warehouseName: string,
}

export const triggerActivateSuccessToast = ({
  optimisationName,
  warehouseName,
}: triggerSuccessErrorToastProps) => (
  triggerToast({
    header: `${optimisationName} active for ${warehouseName}`,
    type: 'positive',
  })
);

export const triggerPauseSuccessToast = ({
  optimisationName,
  warehouseName,
}: triggerSuccessErrorToastProps) => (
  triggerToast({
    header: `${optimisationName} removed for ${warehouseName}`,
    type: 'positive',
  })
);

export const triggerActivateErrorToast = ({
  optimisationName,
  warehouseName,
}: triggerSuccessErrorToastProps) => (
  triggerToast({
    header: `Unable to activate ${optimisationName} for ${warehouseName}`,
    type: 'attention',
  })
);

export const triggerPauseErrorToast = ({
  optimisationName,
  warehouseName,
}: triggerSuccessErrorToastProps) => (
  triggerToast({
    header: `Unable to pause ${optimisationName} for ${warehouseName}`,
    type: 'attention',
  })
);
