import React, { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import {
  StackedBarChart, PieChart, SingleValueChart, TableChart,
} from '@paradime-io/pragma-ui-kit/lib/components/VictoryCharts';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { useCubeLazyQuery } from '../../../app/hooks/useCubeQuery';
import {
  countPassQuery,
  countPassReturnType,
  countWarningsQuery,
  countWarningsReturnType,
  countErrorsQuery,
  countErrorsReturnType,
  countFailedQuery,
  countFailedReturnType,
  dailyTestsQuery,
  dailyTestsReturnType,
  formatDailyTestsForChart,
  getDailyTestsXAxisLabels,
  currentTestsQuery,
  currentTestsReturnType,
  formatCurrentTestsForChart,
  countBadResultsQuery,
  countBadResultsReturnType,
  countBadResultsColumns,
  formatCountBadResultsForChart,
  topTenWarnQuery,
  topTenWarnReturnType,
  formatTopTenWarnForChart,
  getTopTenWarnXAxisLabels,
  topTenErrorQuery,
  topTenErrorReturnType,
  formatTopTenErrorForChart,
  getTopTenErrorXAxisLabels,
} from './OverviewCubeQueries';
import FilterRow from '../FilterRow';
import {
  timeframes,
  HalfPageChartSection,
  FullPageChartSection,
  QuarterPageChartSection,
  getSingleValueChartString,
  getFormattedYAxisLabel,
  singleValueChartHeight,
  getStatusColour,
} from '../utils';
import { useGetFiltersFromUrl } from '../hooks/useGetFiltersFromUrl';

const OverviewTab: FC = () => {
  const { getInitialFilterValue } = useGetFiltersFromUrl();

  const [timeframe, setTimeframe] = useState(getInitialFilterValue('timeframe', timeframes[2].value)!);

  const [
    getCountPass, { data: countPassData, loading: isLoadingCountPass, error: countPassError },
  ] = useCubeLazyQuery<countPassReturnType>({
    query: countPassQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getCountWarnings,
    { data: countWarningsData, loading: isLoadingCountWarnings, error: countWarningsError },
  ] = useCubeLazyQuery<countWarningsReturnType>({
    query: countWarningsQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getCountErrors,
    { data: countErrorsData, loading: isLoadingCountErrors, error: countErrorsError },
  ] = useCubeLazyQuery<countErrorsReturnType>({
    query: countErrorsQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getCountFailed,
    { data: countFailedData, loading: isLoadingCountFailed, error: countFailedError },
  ] = useCubeLazyQuery<countFailedReturnType>({
    query: countFailedQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getDailyTests, { data: dailyTestsData, loading: isLoadingDailyTests, error: dailyTestsError },
  ] = useCubeLazyQuery<dailyTestsReturnType>({
    query: dailyTestsQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getCurrentTests,
    { data: currentTestsData, loading: isLoadingCurrentTests, error: currentTestsError },
  ] = useCubeLazyQuery<currentTestsReturnType>({
    query: currentTestsQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getCountBadResults,
    { data: countBadResultsData, loading: isLoadingCountBadResults, error: countBadResultsError },
  ] = useCubeLazyQuery<countBadResultsReturnType>({
    query: countBadResultsQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getTopTenWarn, { data: topTenWarnData, loading: isLoadingTopTenWarn, error: topTenWarnError },
  ] = useCubeLazyQuery<topTenWarnReturnType>({
    query: topTenWarnQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getTopTenError,
    { data: topTenErrorData, loading: isLoadingTopTenError, error: topTenErrorError },
  ] = useCubeLazyQuery<topTenErrorReturnType>({
    query: topTenErrorQuery,
    variables: { timeframe: timeframe.value },
  });

  const refetchAllData = () => {
    getCountPass();
    getCountWarnings();
    getCountErrors();
    getCountFailed();
    getDailyTests();
    getCurrentTests();
    getCountBadResults();
    getTopTenWarn();
    getTopTenError();
  };

  useEffect(() => {
    refetchAllData();
  }, [timeframe]);

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      verticalGap="dense"
      distribution="packed"
    >
      <FilterRow
        dropdowns={[
          {
            value: timeframe,
            options: timeframes,
            onChange: setTimeframe,
            name: 'timeframe',
            title: 'Select date range',
          },
        ]}
      />
      <div style={{
        display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: '16px',
      }}
      >
        <QuarterPageChartSection height={singleValueChartHeight}>
          <SingleValueChart
            title="✅ Count current passed tests"
            subtitle="Monitor the current number of tests with pass results. Great job, all good here."
            value={(
              getSingleValueChartString(
                countPassData?.cube?.[0]?.testStatusSummary?.distinctTestCount || 0,
              )
            )}
            isLoading={isLoadingCountPass}
            hasNoData={countPassError || countPassData?.cube?.length === 0}
          />
        </QuarterPageChartSection>
        <QuarterPageChartSection height={singleValueChartHeight}>
          <SingleValueChart
            title="⚠️ Count current warn tests"
            subtitle="Monitor the current number of tests with Warn results. These might not be critical but requires attention."
            value={(
              getSingleValueChartString(
                countWarningsData?.cube?.[0]?.testStatusSummary?.distinctTestCount || 0,
              )
            )}
            isLoading={isLoadingCountWarnings}
            hasNoData={countWarningsError || countWarningsData?.cube?.length === 0}
          />
        </QuarterPageChartSection>
        <QuarterPageChartSection height={singleValueChartHeight}>
          <SingleValueChart
            title="🚨 Count current errored tests"
            subtitle="Monitor the current number of tests with error results. These are critical!"
            value={(
              getSingleValueChartString(
                countErrorsData?.cube?.[0]?.testStatusSummary?.distinctTestCount || 0,
              )
            )}
            isLoading={isLoadingCountErrors}
            hasNoData={countErrorsError || countErrorsData?.cube?.length === 0}
          />
        </QuarterPageChartSection>
        <QuarterPageChartSection height={singleValueChartHeight}>
          <SingleValueChart
            title="❌ Count current failed tests"
            subtitle="Monitor the current number of tests that are failing. These are not executed and will means that the related dbt™️ columns tests have not been executed"
            value={(
              getSingleValueChartString(
                countFailedData?.cube?.[0]?.testStatusSummary?.distinctTestCount || 0,
              )
            )}
            isLoading={isLoadingCountFailed}
            hasNoData={countFailedError || countFailedData?.cube?.length === 0}
          />
        </QuarterPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Daily dbt™ tests monitoring"
            subtitle="Monitor your dbt™ tests results and keep track of your project data quality"
            data={formatDailyTestsForChart(dailyTestsData).data}
            xAxisLabels={getDailyTestsXAxisLabels(dailyTestsData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Calendar date"
            yLabel="Count tables"
            legendLabels={formatDailyTestsForChart(dailyTestsData).statuses}
            customColourScale={formatDailyTestsForChart(dailyTestsData).colours}
            isLoading={isLoadingDailyTests}
            hasNoData={dailyTestsError || dailyTestsData?.cube?.length === 0}
            xAxisDomain={[
              0.5,
              getDailyTestsXAxisLabels(dailyTestsData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
            showDetailsButton
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <PieChart
            title="dbt™ current tests results"
            subtitle="Snapshots of the last run status of your dbt™ tests."
            data={formatCurrentTestsForChart(currentTestsData).data}
            legendLabels={formatCurrentTestsForChart(currentTestsData).statuses}
            customColourScale={formatCurrentTestsForChart(currentTestsData).colours}
            isLoading={isLoadingCurrentTests}
            hasNoData={currentTestsError || currentTestsData?.cube?.length === 0}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </HalfPageChartSection>
        <FullPageChartSection style={{ height: '100%' }}>
          <TableChart
            title="Current errored/warn/failed tests results"
            subtitle="Get insights on the latests tests results."
            isLoading={isLoadingCountBadResults}
            hasNoData={countBadResultsError || countBadResultsData?.cube?.length === 0}
            columns={countBadResultsColumns}
            data={formatCountBadResultsForChart(countBadResultsData)}
            rowsPerPage={5}
          />
        </FullPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Top 10 dbt™ models with higher number of warn test"
            subtitle="Identify dbt™ models with higher number of warn tests in your project."
            data={formatTopTenWarnForChart(topTenWarnData)}
            xAxisLabels={getTopTenWarnXAxisLabels(topTenWarnData)}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            customColourScale={[getStatusColour('warn')]}
            xLabel="Model name"
            yLabel="Count warn tests"
            isLoading={isLoadingTopTenWarn}
            hasNoData={topTenWarnError || topTenWarnData?.cube?.length === 0}
            maxCharsAxisX={12}
            xAxisDomain={[
              0.5,
              getTopTenWarnXAxisLabels(topTenWarnData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
            showDetailsButton
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Top 10 dbt™ models with higher number of errored test"
            subtitle="Identify dbt™ models with higher number of errored tests in your project."
            data={formatTopTenErrorForChart(topTenErrorData)}
            xAxisLabels={getTopTenErrorXAxisLabels(topTenErrorData)}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            customColourScale={[getStatusColour('fail')]}
            xLabel="Model name"
            yLabel="Count errored tests"
            isLoading={isLoadingTopTenError}
            hasNoData={topTenErrorError || topTenErrorData?.cube?.length === 0}
            maxCharsAxisX={12}
            xAxisDomain={[
              0.5,
              getTopTenErrorXAxisLabels(topTenErrorData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
            showDetailsButton
          />
        </HalfPageChartSection>
      </div>
    </AutoLayout>
  );
};

export default OverviewTab;
