import React, { FC, useState, useEffect } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Dropdown from '@paradime-io/pragma-ui-kit/lib/components/Dropdown';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import MenuTemplate from '@paradime-io/pragma-ui-kit/lib/components/Menu/MenuTemplate';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { BuiltInEnvVars } from '@paradime/common/lib/common/helpers/builtInEnvVars';
import useGetSchedulesAndBranches from '../hooks/useGetSchedulesAndBranches';
import {
  useProductionEnvironmentVariablesQuery,
  useSetProductionEnvironmentVariableMutation,
} from '../../../client/generated/service';
import { userAuthStore } from '../../../stores';
import { userHasBoltAdminAccess } from '../../../utilis/PermissionsService';

enum NoScheduleSelection {
  MENU_ITEM_TEXT = 'none',
}

const DeferToProdSetting:FC = () => {
  const { currentUser: { accessLevel } } = userAuthStore.getState();
  const [deferScheduleName, setDeferScheduleName] = useState<string>('none');
  const [savingState, setSavingState] = useState<'saving' | 'saved'>();
  const [
    setProductionEnvironmentVariable,
    { data: prodEnvVarData, loading: isSettingEnvVar },
  ] = useSetProductionEnvironmentVariableMutation();

  const isAdmin = userHasBoltAdminAccess(accessLevel);

  const { loading: isProdEnvVarsLoading } = useProductionEnvironmentVariablesQuery({
    onCompleted: (data) => {
      if (data.productionEnvironmentVariables?.variables) {
        const currentProdDeferScheduleName = data
          .productionEnvironmentVariables?.variables.filter((envVar) => (
            envVar.name === BuiltInEnvVars.DEFER_TO_PROD
          ));

        if (currentProdDeferScheduleName?.length > 0 && currentProdDeferScheduleName[0].value !== '') {
          setDeferScheduleName(currentProdDeferScheduleName[0].value);
        }
      }
    },
  });
  const { isLoading, scheduleNamesList } = useGetSchedulesAndBranches();

  const handleSetScheduleName = (name: string) => {
    setDeferScheduleName(name);
    setProductionEnvironmentVariable({
      variables: {
        name: BuiltInEnvVars.DEFER_TO_PROD,
        value: (name === NoScheduleSelection.MENU_ITEM_TEXT) ? '' : name,
      },
    });
  };

  useEffect(() => {
    if (isSettingEnvVar) {
      setSavingState('saving');
    }

    if (!isSettingEnvVar && prodEnvVarData) {
      setSavingState('saved');
      setTimeout(() => setSavingState(undefined), 2000);
    }
  }, [isSettingEnvVar, prodEnvVarData]);

  return (
    <AutoLayout
      direction="horizontal"
      distribution="space-between"
      padding="none"
      style={{ alignItems: 'center', paddingBottom: '32px' }}
    >
      <AutoLayout
        direction="vertical"
        padding="none"
        distribution="packed"
        verticalGap="ultra-dense"
      >
        <Typography tagName="span" type="body-bold-small">
          Defer to production schedule name
        </Typography>
        <Typography tagName="span" type="caption" colorStep="50">
          Choose the schedule to use during development in the IDE
          to defer to
          <br />
          the production schema for upstream tables.
        </Typography>
      </AutoLayout>
      {
        (isLoading || isProdEnvVarsLoading) ? (
          <Spinner width={16} height={16} thin />
        ) : (
          <AutoLayout
            direction="horizontal"
            distribution="packed"
            horizontalGap="very-dense"
            padding="none"
          >
            { savingState && (
              <Chips
                icon={savingState === 'saved' ? 'confirm' : 'time'}
                color={savingState === 'saved' ? 'success' : 'default'}
                round={false}
                tag={savingState}
                type="dense"
                view="smooth"
              />
            )}
            <Dropdown
              view="outlined"
              text={deferScheduleName}
              content={(
                <MenuTemplate
                  dense
                  items={
                    [
                      NoScheduleSelection.MENU_ITEM_TEXT,
                      ...scheduleNamesList].map((scheduleName) => ({
                      text: scheduleName,
                      type: 'item',
                      onClick: () => handleSetScheduleName(scheduleName),
                    }))
                  }
                  eventContext={Contexts.LOGS}
                />
              )}
              fill
              color="default"
              dense
              height={200}
              position="bottom"
              minMenuWidth="600"
              style={{
                minWidth: '300px',
                border: (!isAdmin ? '1px solid var(--grey10)' : ''),
              }}
              disabled={!isAdmin}
            />
          </AutoLayout>
        )
      }
    </AutoLayout>
  );
};

export default DeferToProdSetting;
