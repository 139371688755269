/* eslint-disable react/require-default-props */
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import BigButton from '@paradime-io/pragma-ui-kit/lib/components/BigButton';
import React, {
  useEffect, useMemo, useState,
} from 'react';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import Dots from '@paradime-io/pragma-ui-kit/lib/components/Dots';
import Divider from '@paradime-io/pragma-ui-kit/lib/components/Divider';
import { Icon } from '@blueprintjs/core';
import {
  useLocationState,
  useUpdateLocationState,
} from '../../../utilis';
import { WarehouseEnv, Warehouse as WarehouseType } from '../../Common/Warehouses/utils';
import * as S from './Warehouse.styles';
import WareHouseFactory from './warehouseFactory';
import EditMenu from './EditMenu';
import { useHandleClosableWindow } from '../../hooks/useHandleClosableWindow';
import useGetInitialFormData, { initialFormDataType } from './useGetInitialWarehouseData';
import { GqlParadimeAccountType } from '../../../client/generated/service';
import { userHasAccountSettingsConnectionsAdminAccess } from '../../../utilis/PermissionsService';

export enum WareHouseModalSource {
  'ACCOUNT_SETTINGS' = 'account-settings',
  'ONBOARDING' = 'onboarding',
  'CREATE_WORKSPACE' = 'create-workspace',
}

interface WarehouseProps<T> {
  env: WarehouseEnv,
  initialDatabaseType?: string,
  viewEditFormData?: { [key: string]: string | number | undefined },
  source: WareHouseModalSource,
  close?: Function,
  viewEditPrefilled?: boolean,
  credentialId?: string,
  newConnection?: boolean,
  accessLevel: GqlParadimeAccountType,
  bigButtons: {
    name: T,
    image: string,
    conditions: T[],
    disabled?: boolean
  }[],
  warehouseOptions: {
    name: T,
    warehouse: any,
  }[],
  isDefault?: boolean,
  connectionName?: string
}

const Warehouse = <T extends unknown>({
  env,
  bigButtons,
  warehouseOptions,
  viewEditFormData,
  source,
  close,
  viewEditPrefilled,
  credentialId,
  newConnection,
  accessLevel,
  isDefault,
  connectionName,
}: WarehouseProps<T>) => {
  const [currentWarehouse, setCurrentWarehouse] = useState<WarehouseType>();
  const [showWarehouses, setShowWarehouses] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state] = useLocationState();
  const { prefilled } = state as { prefilled: boolean, isOnboarding?: boolean };
  const updateLocationState = useUpdateLocationState();
  const [
    currentOnboardedStatus,
    setCurrentOnboardedStatus,
  ] = useState<boolean>();

  const { handleWindowClose } = useHandleClosableWindow();

  const [initialFormData, setInitialFormData] = useState<initialFormDataType>();
  const initialDataFromHook = useGetInitialFormData({
    credentialId,
    isNewConnection: newConnection || false,
    env,
    viewEditFormData,
    formFields: warehouseOptions
      ?.filter((option) => option.name === currentWarehouse)[0]
      ?.warehouse
      ?.form,
    initialFormFields: warehouseOptions
      ?.filter((option) => option.name === currentWarehouse)[0]
      ?.warehouse
      ?.initialFormValues,
  });

  useEffect(() => {
    if (initialDataFromHook) {
      setInitialFormData(initialDataFromHook);
      setCurrentWarehouse(
        initialDataFromHook.databaseType === 'unknown'
          ? undefined
          : initialDataFromHook.databaseType as WarehouseType,
      );
      setShowWarehouses(true);
    }
  }, [initialDataFromHook]);

  handleWindowClose(() => setLoading(false), 'snowflakeWarehouse');

  const {
    sshPublicKey,
    servicePath,
  } = state;
  updateLocationState({ sshPublicKey, servicePath });

  useEffect(() => {
    if (currentOnboardedStatus) {
      setLoading(false);
    }
  }, [currentOnboardedStatus]);

  const closeModal = () => close && close();

  /** Checks if necessary data exists to
   * show editModal */
  const allowsEditModal = useMemo(() => {
    if (env === undefined) return false;
    if (credentialId === undefined) return false;
    if (source !== WareHouseModalSource.ACCOUNT_SETTINGS) return false;
    if (!userHasAccountSettingsConnectionsAdminAccess(accessLevel)) return false;

    return true;
  }, [env, credentialId, source, accessLevel]);

  return (
    <S.ConnectWarehouse>
      <AutoLayout
        direction="vertical"
        padding="expanded"
        verticalGap="very-dense"
        distribution="packed"
        alignment="top-center"
        style={{
          paddingTop: '2rem',
          maxHeight: '670px',
          margin: '30px 0',
          overflowY: 'auto',
        }}
      >
        <AutoLayout
          padding="none"
          direction="horizontal"
          distribution="space-between"
          alignment="center"
          style={{
            gridTemplateColumns: allowsEditModal
              ? undefined
              : '100%',
          }}
        >
          <Typography
            tagName="span"
            type="h4"
            color="default"
            colorStep="100"
            style={{ textAlign: 'center', margin: '0 auto 1rem auto' }}
          >
            Warehouse connection
          </Typography>
          {allowsEditModal
            && (
              <EditMenu
                credentialId={credentialId!}
                env={env!}
                disabled={isDefault}
                connectionName={connectionName}
                closeModal={closeModal}
              />
            )}
        </AutoLayout>
        {showWarehouses
          && (
            <AutoLayout
              alignment="center"
              direction="vertical"
              distribution="packed"
              padding="none"
              verticalGap="dense"
            >
              {
                bigButtons
                  .filter(// @ts-ignore
                    (BBs) => (currentWarehouse ? BBs.conditions.includes(currentWarehouse) : true),
                  )
                  .map((BBs) => {
                    const disabledProps = (disabled?: boolean) => {
                      if (disabled) {
                        return {
                          badgeText: 'Coming Soon',
                          addBadge: true,
                          badgeColor: 'success',
                        };
                      } return {};
                    };
                    return (
                      <BigButton // @ts-ignore
                        key={BBs.name}
                        image={BBs.image}
                        selected={currentWarehouse === BBs.name}
                        onClick={() => setCurrentWarehouse(BBs.name as WarehouseType)}
                        fill
                        disabled={BBs.disabled}
                        {...disabledProps(BBs.disabled)}
                      />
                    );
                  })
              }
            </AutoLayout>
          )}
        {currentWarehouse && <Divider />}
        {
          warehouseOptions
            .filter((option) => option.name === currentWarehouse)
            .map((option) => (
              <WareHouseFactory
                key={`${option.name}-${env}-warehouse`}
                expose={(_: boolean, onboardedState: boolean) => {
                  setCurrentOnboardedStatus(onboardedState);
                }}
                warehouse={option.warehouse}
                prefilled={
                  Boolean((initialFormData?.databaseType !== 'unknown'
                    && source !== WareHouseModalSource.ACCOUNT_SETTINGS)
                    || viewEditPrefilled
                    || prefilled)
                }
                viewEditFormData={viewEditFormData}
                env={env}
                source={source}
                loading={loading}
                eventContext={Contexts.MODAL}
                initialFormData={initialFormData}
                isNewConnection={newConnection}
                closeModal={closeModal}
                credentialId={initialFormData?.credentialId}
                warehouseName={currentWarehouse}
              />
            ))
        }

        {source === WareHouseModalSource.ACCOUNT_SETTINGS
          && (
            <>
              <Typography
                onClick={closeModal}
                type="button"
                style={{ alignSelf: 'center', justifySelf: 'center', cursor: 'pointer' }}
                colorStep="50"
              >
                Cancel
              </Typography>
              {
              userHasAccountSettingsConnectionsAdminAccess(accessLevel) && (
                <Icon
                  size={19}
                  icon="chevron-left"
                  color="var(--grey50)"
                  onClick={() => setCurrentWarehouse(undefined)}
                  style={{
                    position: 'absolute',
                    bottom: 15,
                    left: 11,
                    cursor: 'pointer',
                  }}
                />
              )
              }
            </>
          )}
        {source !== WareHouseModalSource.ACCOUNT_SETTINGS && currentWarehouse
          && !initialFormData?.credentialId && (
            <Icon
              size={19}
              icon="chevron-left"
              color="var(--grey50)"
              onClick={() => setCurrentWarehouse(undefined)}
              style={{
                position: 'absolute',
                bottom: 15,
                left: 11,
                cursor: 'pointer',
              }}
            />
        )}
      </AutoLayout>
      <Dots selected={currentWarehouse === undefined ? 1 : 2} total={2} />
    </S.ConnectWarehouse>
  );
};

export default Warehouse;
