/* eslint-disable no-console */
/* eslint-disable no-alert */
import React from 'react';
import { SDKResult, setup, LoomVideo } from '@loomhq/loom-sdk';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { Contexts, Actions } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { graphQLStore } from '../../../../stores';
import SaveDialog from './SaveDialog';

const Loom = () => {
  const [loomInstance, setLoomInstance] = React.useState<SDKResult>();
  const clusterConfig = graphQLStore((s) => s.clusterConfig);
  const [showSaveDialog, setShowSaveDialog] = React.useState(false);
  const [shareUrl, setShareUrl] = React.useState<LoomVideo>();

  const initialiseLoom = async () => {
    if (!loomInstance && clusterConfig) {
      const instance = await setup({
        apiKey: clusterConfig.loomApiKey,
      });

      setLoomInstance(instance);
    }
  };

  // @ts-ignore
  let configuredLoomButton;
  const makeLoomButton = async () => {
    const button = document.getElementById('startLoomButton');

    if (loomInstance) {
      configuredLoomButton = loomInstance.configureButton({
        element: button!,
      });

      configuredLoomButton.on('insert-click', async (video) => {
        setShowSaveDialog(true);
        setShareUrl(video);
      });

      configuredLoomButton.on('cancel', () => {
        window.postMessage({ type: 'GROW_WINDOW', payload: '' }, '*');
      });

      configuredLoomButton.on('complete', () => {
        window.postMessage({ type: 'GROW_WINDOW', payload: '' }, '*');
      });
    }
  };

  let counter = 1;
  window.addEventListener('message', (e) => {
    setTimeout(() => {
      if (e?.origin?.includes('loom.com')
        && e?.data?.args?.[0]?.payload?.[0]?.value?.appStage === 'countdown'
        && counter === 1
      ) {
        window.postMessage({ type: 'SHRINK_WINDOW', payload: '' }, '*');
        counter += 1;
        // @ts-ignore
        if (configuredLoomButton !== undefined) {
          // @ts-ignore
          configuredLoomButton.moveBubble({ x: 100, y: 0 });
        }
      }
    }, 500);
  });

  React.useEffect(() => {
    initialiseLoom();
  }, [loomInstance, clusterConfig, initialiseLoom]);

  React.useEffect(() => {
    makeLoomButton();
  }, [loomInstance, makeLoomButton]);

  const hideSaveDialog = () => setShowSaveDialog(false);

  return (
    <div id="makeALoomPage">
      <SaveDialog
        isOpen={showSaveDialog}
        shareUrl={shareUrl?.sharedUrl || ''}
        onLeftButtonClick={hideSaveDialog}
        onRightButtonClick={(title) => {
          hideSaveDialog();
          window.postMessage({ type: 'SHARE_LINK', payload: { title, shareUrl } }, '*');
        }}
        onClose={hideSaveDialog}
      />
      <AutoLayout
        direction="vertical"
        padding="dense"
        verticalGap="dense"
        width="fit"
      >
        <DefaultButton
          // @ts-ignore
          id="startLoomButton"
          type="standard"
          color="primary"
          dense
          eventContext={Contexts.EXTENSION}
          eventObject="startLoomRecording"
          eventAction={Actions.CLICKED}
        >
          Make a Loom recording
        </DefaultButton>
        <DefaultButton
          // @ts-ignore
          id="closeLoomWindowButton"
          type="standard"
          color="primary"
          dense
          onClick={() => window.postMessage({ type: 'CLOSE_WINDOW', payload: '' }, '*')}
          eventContext={Contexts.EXTENSION}
          eventObject="closeLoomWindow"
          eventAction={Actions.CLICKED}
        >
          Close window
        </DefaultButton>
      </AutoLayout>
    </div>
  );
};

export default Loom;
