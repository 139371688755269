import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import RadarPage from '../RadarPage';
import PullRequestsTab from './PullRequestsTab';
import CommitsTab from './CommitsTab';
import { DashboardTabIds } from '../config';

const Work: FC = () => {
  const { tabId } = useParams<{tabId: DashboardTabIds}>();

  return (
    <RadarPage>
      {tabId === DashboardTabIds.PULL_REQUESTS && <PullRequestsTab />}
      {tabId === DashboardTabIds.COMMITS && <CommitsTab />}
    </RadarPage>
  );
};

export default Work;
