import React, { FC } from 'react';
import { Checklist, FrigadeJS, useFlow } from '@frigade/react';
import { useHistory } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Header from './Header';
import ConfigureCustomSchemaStep from './ConfigureCustomSchemaStep';
import ConfigureTurboCiJobStep from './ConfigureTurboCiJobStep';
import { BoltFlows } from '../../Common/Frigade/flows';
import { AccountSettingsTab } from '../../NavBar';

const enum TurboCiSteps {
  CONFIGURE_CUSTOM_SCHEMA = 'configure-custom-schema',
  INSTALL_GITHUB_APP = 'install-github',
  CONFIGURE_TURBO_CI_JOB = 'configure-turbo-ci-job',
}

const TurboCi:FC = () => {
  const history = useHistory();

  const { flow } = useFlow(BoltFlows.TURBO_CI_FLOW);
  const markStepNotStarted = (stepId: string) => (
    flow?.steps?.get(stepId)?.complete()
  );

  const handleFlowButtonClick = (step: FrigadeJS.FlowStep) => {
    switch (step.id) {
      case TurboCiSteps.INSTALL_GITHUB_APP:
        history.push(`/account-settings/${AccountSettingsTab.INTEGRATIONS}`);
        markStepNotStarted(step.id);
        break;
      default:
        break;
    }
    return Promise.resolve(true);
  };

  return (
    <AutoLayout
      direction="vertical"
      padding="expanded"
      horizontalGap="dense"
      distribution="packed"
      alignment="top-left"
      wrapperStyle={{ gridArea: 'b', overflowY: 'auto' }}
      width="full"
      style={{ maxWidth: '1280px' }}
    >
      <Header showHelp />
      <Typography tagName="span" type="body-small">
        Configure Turbo CI in this workspace in 3 easy steps.
      </Typography>
      <Checklist.Collapsible
        flowId={BoltFlows.TURBO_CI_FLOW}
        type="inline"
        checklistStyle="condensed"
        autoExpandNextStep={false}
        style={{
          boxShadow: 'none',
          border: '1px solid var(--grey5)',
        }}
        customStepTypes={{
          configureCustomSchema: ConfigureCustomSchemaStep,
          turboCiJobStep: ConfigureTurboCiJobStep,
        }}
        onButtonClick={handleFlowButtonClick}
        forceMount
      />
    </AutoLayout>
  );
};

export default TurboCi;
