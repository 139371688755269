import { gql } from '@apollo/client';
import { getStatusColour, scheduleStatuses } from '../utils';

export interface countSchedulesReturnType {
  cube: {
    scheduleTotalRuns: {
      countBoltScheduleRunId: number,
    }
  }[]
}

export const countSchedulesQuery = gql`
  query countSchedulesQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {schedule_total_runs: {calendar_date: {inDateRange: $timeframe}}}
    ) {
      scheduleTotalRuns: schedule_total_runs {
        countBoltScheduleRunId: count_bolt_schedule_run_id
      }
    }
  }
`;

export interface countMinutesReturnType {
  cube: {
    scheduleTotalRuntime: {
      totalRuntimeMinutes: number,
    }
  }[]
}

export const countMinutesQuery = gql`
  query countMinutesQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {schedule_total_runtime: {calendar_date: {inDateRange: $timeframe}}}
    ) {
      scheduleTotalRuntime: schedule_total_runtime {
        totalRuntimeMinutes: total_runtime_minutes
      }
    }
  }
`;

export interface dailyRunStatusReturnType {
  cube: {
    scheduleDailyRunStatus: {
      countBoltScheduleRunId: number,
      boltScheduleRunStatus: 'Success' | 'Error',
      calendarDate: {
        day: string,
      }
    }
  }[]
}

export const dailyRunStatusQuery = gql`
  query dailyRunStatusQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {schedule_daily_run_status: {calendar_date: {inDateRange: $timeframe}}}
    ) {
      scheduleDailyRunStatus: schedule_daily_run_status {
        countBoltScheduleRunId: count_bolt_schedule_run_id
        boltScheduleRunStatus: bolt_schedule_run_status
        calendarDate: calendar_date {
          day
        }
      }
    }
  }
`;

export const formatDailyRunStatusForChart = (data?: dailyRunStatusReturnType) => {
  if (!data) return { data: [], statuses: [], colours: [] };

  const dataToReturn: { x: string, y: number }[][] = [];
  const legendLabels: string[] = [];
  const legendColours: string[] = [];

  scheduleStatuses.forEach((status) => {
    const schedules = data.cube
      .filter(({ scheduleDailyRunStatus }) => (
        scheduleDailyRunStatus.boltScheduleRunStatus === status
      ))
      .map(({ scheduleDailyRunStatus }) => ({
        x: scheduleDailyRunStatus.calendarDate.day,
        y: scheduleDailyRunStatus.countBoltScheduleRunId,
      }));

    if (schedules.length > 0) {
      dataToReturn.push(schedules);
      legendLabels.push(status);
      legendColours.push(getStatusColour(status));
    }
  });

  return { data: dataToReturn, statuses: legendLabels, colours: legendColours };
};

export const getDailyRunStatusXAxisLabels = (data?: dailyRunStatusReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ scheduleDailyRunStatus }) => scheduleDailyRunStatus.calendarDate.day);
  return Array.from(new Set(dates));
};

export interface scheduleRunActorsReturnType {
  cube: {
    scheduleRunsByActor: {
      countBoltScheduleRunId: number,
      boltScheduleRunActor: string,
    }
  }[]
}

export const scheduleRunActorsQuery = gql`
  query scheduleRunActorsQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {schedule_runs_by_actor: {calendar_date: {inDateRange: $timeframe}}}
    ) {
      scheduleRunsByActor: schedule_runs_by_actor {
        countBoltScheduleRunId: count_bolt_schedule_run_id
        boltScheduleRunActor: bolt_schedule_run_actor
      }
    }
  }
`;

export const formatScheduleRunActorsForChart = (data?: scheduleRunActorsReturnType) => {
  if (!data) return [];

  return data.cube.map(({ scheduleRunsByActor }) => ({
    x: scheduleRunsByActor.boltScheduleRunActor,
    y: scheduleRunsByActor.countBoltScheduleRunId,
  }));
};

export interface scheduleOwnersReturnType {
  cube: {
    scheduleByOwner: {
      countBoltScheduleNameId: number,
      boltScheduleNameOwner: string,
    }
  }[]
}

export const scheduleOwnersQuery = gql`
  query scheduleOwnersQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {schedule_by_owner: {is_schedule_current_status: {equals: "true"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      scheduleByOwner: schedule_by_owner {
        countBoltScheduleNameId: count_bolt_schedule_name_id
        boltScheduleNameOwner: bolt_schedule_name_owner
      }
    }
  }
`;

export const formatScheduleOwnersForChart = (data?: scheduleOwnersReturnType) => {
  if (!data) return [];

  return data.cube.map(({ scheduleByOwner }) => ({
    x: scheduleByOwner.boltScheduleNameOwner,
    y: scheduleByOwner.countBoltScheduleNameId,
  }));
};

export interface scheduleRunCronsReturnType {
  cube: {
    scheduleRunsByCron: {
      countBoltScheduleNameId: number,
      boltScheduleNameScheduleCron: string,
    }
  }[]
}

export const scheduleRunCronsQuery = gql`
  query scheduleRunCronsQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {schedule_runs_by_cron: {is_schedule_current_status: {equals: "true"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      scheduleRunsByCron: schedule_runs_by_cron {
        countBoltScheduleNameId: count_bolt_schedule_name_id
        boltScheduleNameScheduleCron: bolt_schedule_name_schedule_cron
      }
    }
  }
`;

export const formatScheduleRunCronsForChart = (data?: scheduleRunCronsReturnType) => {
  if (!data) return [];

  return [data.cube
    .map(({ scheduleRunsByCron }) => ({
      x: scheduleRunsByCron.boltScheduleNameScheduleCron,
      y: scheduleRunsByCron.countBoltScheduleNameId,
    }))];
};

export const getScheduleRunCronsXAxisLabels = (data?: scheduleRunCronsReturnType) => {
  if (!data) return [];

  const cronStrings = data.cube
    .map(({ scheduleRunsByCron }) => scheduleRunsByCron.boltScheduleNameScheduleCron);
  return Array.from(new Set(cronStrings));
};

export interface scheduleRunsByHourReturnType {
  cube: {
    scheduleRunsByHour: {
      countBoltScheduleRunId: number,
      boltScheduleRunHourWindow: number,
    }
  }[]
}

export const scheduleRunsByHourQuery = gql`
  query scheduleRunByHourQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {schedule_runs_by_hour: {is_schedule_current_status: {equals: "true"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      scheduleRunsByHour: schedule_runs_by_hour(orderBy: {bolt_schedule_run_hour_window: asc}) {
        countBoltScheduleRunId: count_bolt_schedule_run_id
        boltScheduleRunHourWindow: bolt_schedule_run_hour_window
      }
    }
  }
`;

export const formatScheduleRunsByHourForChart = (data?: scheduleRunsByHourReturnType) => {
  if (!data) return [];

  return [data.cube
    .map(({ scheduleRunsByHour }) => ({
      x: scheduleRunsByHour.boltScheduleRunHourWindow.toString(),
      y: scheduleRunsByHour.countBoltScheduleRunId,
    }))];
};

export const getScheduleRunsByHourXAxisLabels = (data?: scheduleRunsByHourReturnType) => {
  if (!data) return [];

  const hourWindows = data.cube
    .map(({ scheduleRunsByHour }) => scheduleRunsByHour.boltScheduleRunHourWindow.toString());
  return Array.from(new Set(hourWindows));
};

export interface scheduleRunsByErrorReturnType {
  cube: {
    scheduleErrorCount: {
      errorCount: number,
      boltScheduleName: string,
    }
  }[]
}

export const scheduleRunsByErrorQuery = gql`
  query scheduleRunsByErrorQuery($timeframe: [String]) {
    cube(
      limit: 5
      where: {schedule_total_error_runs: {calendar_date: {inDateRange: $timeframe}}}
    ) {
      scheduleErrorCount: schedule_total_error_runs(orderBy: {count_error_bolt_schedule_run: desc}) {
        errorCount: count_error_bolt_schedule_run
        boltScheduleName: bolt_schedule_name
      }
    }
  }
`;

export const formatScheduleRunsByErrorForChart = (data?: scheduleRunsByErrorReturnType) => {
  if (!data) return [];

  return [data.cube
    .map(({ scheduleErrorCount }) => ({
      x: scheduleErrorCount.boltScheduleName,
      y: scheduleErrorCount.errorCount,
    }))];
};

export const getScheduleRunsByErrorXAxisLabels = (data?: scheduleRunsByErrorReturnType) => {
  if (!data) return [];

  const scheduleNames = data.cube
    .map(({ scheduleErrorCount }) => scheduleErrorCount.boltScheduleName);
  return Array.from(new Set(scheduleNames));
};

export interface scheduleRuntimeStatsReturnType {
  cube: {
    scheduleRuntimeStats: {
      avgRuntimeMinutes: number,
      maxRuntimeMinutes: number,
      minRuntimeMinutes: number,
      boltScheduleName: string,
    }
  }[]
}

export const scheduleRuntimeStatsQuery = gql`
  query scheduleRuntimeStatsQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {schedule_runtime_stats: {calendar_date: {inDateRange: $timeframe}}}
    ) {
      scheduleRuntimeStats: schedule_runtime_stats(orderBy: {avg_runtime_minutes: desc}) {
        avgRuntimeMinutes: avg_runtime_minutes
        maxRuntimeMinutes: max_runtime_minutes
        minRuntimeMinutes: min_runtime_minutes
        boltScheduleName: bolt_schedule_name
      }
    }
  }
`;

export const formatScheduleRuntimeStatsForChart = (data?: scheduleRuntimeStatsReturnType) => {
  if (!data) return [];

  const minRuntime = data.cube
    .map(({ scheduleRuntimeStats }) => ({
      x: scheduleRuntimeStats.boltScheduleName,
      y: scheduleRuntimeStats.minRuntimeMinutes,
    }));

  const avgRuntime = data.cube
    .map(({ scheduleRuntimeStats }) => ({
      x: scheduleRuntimeStats.boltScheduleName,
      y: scheduleRuntimeStats.avgRuntimeMinutes,
    }));

  const maxRuntime = data.cube
    .map(({ scheduleRuntimeStats }) => ({
      x: scheduleRuntimeStats.boltScheduleName,
      y: scheduleRuntimeStats.maxRuntimeMinutes,
    }));

  return [minRuntime, avgRuntime, maxRuntime];
};

export const getScheduleRuntimeStatsXAxisLabels = (data?: scheduleRuntimeStatsReturnType) => {
  if (!data) return [];

  const scheduleNames = data.cube
    .map(({ scheduleRuntimeStats }) => scheduleRuntimeStats.boltScheduleName);
  return Array.from(new Set(scheduleNames));
};
