import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import * as S from './UserInsights.styled';
import ListRow from './ListRow';
import ChartIcon from './images/timeline-area-chart.svg';

export interface ZeroStateNoDataProps {
  showSuggestedActions: boolean,
}

const userActions = [
  { actionName: 'Invite a team mate', url: '/account-settings/team' },
  { actionName: 'Create a model with Dino AI', url: '/editor' },
  { actionName: 'Build a Bolt schedule', url: '/bolt' },
];

const ZeroStateNoData: FC<ZeroStateNoDataProps> = ({ showSuggestedActions }) => (
  <S.MetricCard>
    <AutoLayout
      direction="vertical"
      padding="none"
      verticalGap="dense"
      distribution="packed"
      alignment="center"
      style={{ padding: '40px 32px' }}
    >
      <img src={ChartIcon} alt="" style={{ height: '40px', width: '40px', margin: 'auto' }} />
      <AutoLayout
        direction="vertical"
        padding="none"
        verticalGap="none"
        distribution="packed"
      >
        <Typography
          font="inter"
          type="body-bold-small"
          color="default"
          colorStep="100"
          style={{ textAlign: 'center' }}
        >
          Insights will be available shortly
        </Typography>
        <Typography
          font="inter"
          type="caption"
          color="default"
          colorStep="50"
          style={{ textAlign: 'center' }}
        >
          Insights may take up to a day to calculate after creating an organization.
          <br />
          Please check back here later, and enjoy using Paradime!
        </Typography>
      </AutoLayout>
      {showSuggestedActions && (
        <AutoLayout
          direction="vertical"
          padding="none"
          verticalGap="very-dense"
          distribution="packed"
          wrapperStyle={{ maxWidth: '400px', margin: 'auto' }}
        >
          {userActions.map(({ actionName, url }, index) => (
            <ListRow
              actionName={actionName}
              url={url}
              withDivider={index < userActions.length - 1}
            />
          ))}
        </AutoLayout>
      )}
    </AutoLayout>
  </S.MetricCard>
);

export default ZeroStateNoData;
