import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { RadarTab } from '../../../Radar/config';

const RadarCTA:FC = () => {
  const history = useHistory();

  return (
    <AutoLayout
      direction="vertical"
      distribution="packed"
      padding="none"
      verticalGap="very-dense"
      wrapperStyle={{ alignItems: 'start' }}
    >
      <Typography
        tagName="span"
        type="caption"
        colorStep="50"
      >
        Find issues with this schedule
      </Typography>
      <DefaultButton
        type="standard"
        view="gradient-secondary"
        icon="dashboard"
        dense
        eventContext={Contexts.BOLT}
        eventObject="goToRadar"
        eventAction={Actions.CLICKED}
        text="Go to Radar"
        onClick={() => history.push(`/radar/${RadarTab.SCHEDULES}`)}
        style={{ textDecoration: 'none' }}
      />
    </AutoLayout>
  );
};

export default RadarCTA;
