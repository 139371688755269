import React, { FC } from 'react';
import ZeroState, { ParadimeImages } from '@paradime-io/pragma-ui-kit/lib/components/ZeroState';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';

const ZeroStateDataLoading: FC = () => (
  <div style={{ marginTop: '100px' }}>
    <ZeroState
      image={ParadimeImages.EMPTY_LIGHT}
      text={(
        <AutoLayout
          padding="none"
          direction="vertical"
          alignment="center"
          verticalGap="ultra-dense"
        >
          <Typography type="body-bold-small" style={{ textAlign: 'center' }}>
            Waiting for warehouse data
          </Typography>
          <Typography type="caption" style={{ textAlign: 'center' }}>
            Paradime is calculating savings to optimize your Snowflake warehouses.
            <br />
            It will be ready soon - check back later.
          </Typography>
        </AutoLayout>
      )}
    />
  </div>
);

export default ZeroStateDataLoading;
