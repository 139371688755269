import { gql } from '@apollo/client';
import { ColumnType } from '@paradime-io/pragma-ui-kit/lib/components/PrimeReactDataTable';
import { selectAllDropdownOption, getAllVars, getAllWhere } from '../utils';

export interface warehouseNamesForDropdownReturnType {
  cube: {
    snowflakeDailyQueryCostByWarehouse: {
      snowflakeWarehouseName: string,
    }
  }[]
}

export const warehouseNamesForDropdownQuery = gql`
  query warehouseNamesForDropdownQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {snowflake_daily_query_cost_by_warehouse: {snowflake_warehouse_name: {set: true}, snowflake_start_time: {inDateRange: $timeframe}}}
    ) {
      snowflakeDailyQueryCostByWarehouse: snowflake_daily_query_cost_by_warehouse {
        snowflakeWarehouseName: snowflake_warehouse_name
      }
    }
  }
`;

export const formatWarehouseNamesForDropdown = (data?: warehouseNamesForDropdownReturnType) => {
  if (!data) return [];

  return [
    selectAllDropdownOption,
    ...data.cube
      .map(({ snowflakeDailyQueryCostByWarehouse }) => ({
        label: snowflakeDailyQueryCostByWarehouse.snowflakeWarehouseName,
        value: snowflakeDailyQueryCostByWarehouse.snowflakeWarehouseName,
      })),
  ];
};

export interface snowflakeBalanceReturnType {
  cube: {
    snowflakeDailyRemainingBalanceSummary: {
      totalCapacityBalance: number,
      totalFreeUsageBalance: number,
      totalRolloverBalance: number,
      snowflakeDate: {
        day: string,
      }
    }
  }[]
}

export const snowflakeBalanceQuery = gql`
  query snowflakeBalanceQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {snowflake_daily_remaining_balance_summary: {snowflake_date: {inDateRange: $timeframe}}}
    ) {
      snowflakeDailyRemainingBalanceSummary: snowflake_daily_remaining_balance_summary {
        totalCapacityBalance: total_capacity_balance
        totalFreeUsageBalance: total_free_usage_balance
        totalRolloverBalance: total_rollover_balance
        snowflakeDate: snowflake_date {
          day
        }
      }
    }
  }
`;

export const formatSnowflakeBalanceForChart = (data?: snowflakeBalanceReturnType) => {
  if (!data) return { data: [], statuses: [] };

  const dataToReturn: { x: string, y: number }[][] = [];
  const legendLabels: string[] = [];

  const capacityBalance = data.cube
    .map(({ snowflakeDailyRemainingBalanceSummary }) => ({
      x: snowflakeDailyRemainingBalanceSummary.snowflakeDate.day,
      y: snowflakeDailyRemainingBalanceSummary.totalCapacityBalance,
    }));

  if (capacityBalance.length > 0) {
    dataToReturn.push(capacityBalance);
    legendLabels.push('Snowflake capacity balance');
  }

  const rolloverBalance = data.cube
    .map(({ snowflakeDailyRemainingBalanceSummary }) => ({
      x: snowflakeDailyRemainingBalanceSummary.snowflakeDate.day,
      y: snowflakeDailyRemainingBalanceSummary.totalRolloverBalance,
    }));

  if (rolloverBalance.length > 0) {
    dataToReturn.push(rolloverBalance);
    legendLabels.push('Snowflake rollover balance');
  }

  const freeUsageBalance = data.cube
    .map(({ snowflakeDailyRemainingBalanceSummary }) => ({
      x: snowflakeDailyRemainingBalanceSummary.snowflakeDate.day,
      y: snowflakeDailyRemainingBalanceSummary.totalFreeUsageBalance,
    }));

  if (freeUsageBalance.length > 0) {
    dataToReturn.push(freeUsageBalance);
    legendLabels.push('Snowflake free usage balance');
  }

  return { data: dataToReturn, statuses: legendLabels };
};

export const getSnowflakeBalanceXAxisLabels = (data?: snowflakeBalanceReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ snowflakeDailyRemainingBalanceSummary }) => (
      snowflakeDailyRemainingBalanceSummary.snowflakeDate.day
    ));
  return Array.from(new Set(dates));
};

export interface warehouseSpendReturnType {
  cube: {
    snowflakeDailyQueryCostByWarehouse: {
      totalQueryCost: number,
      snowflakeWarehouseName: string | null,
      snowflakeStartTime: {
        day: string,
      }
    }
  }[]
}

export const warehouseSpendQuery = gql`
  query warehouseSpendQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {snowflake_daily_query_cost_by_warehouse: {snowflake_start_time: {inDateRange: $timeframe}}}
    ) {
      snowflakeDailyQueryCostByWarehouse: snowflake_daily_query_cost_by_warehouse(
        orderBy: {snowflake_start_time: asc, snowflake_warehouse_name: asc}
      ) {
        totalQueryCost: total_query_cost
        snowflakeWarehouseName: snowflake_warehouse_name
        snowflakeStartTime: snowflake_start_time {
          day
        }
      }
    }
  }
`;

export const formatWarehouseSpendForChart = (data?: warehouseSpendReturnType) => {
  if (!data) return { data: [], warehouseNames: [] };

  const warehouseNames = Array.from(new Set(
    data.cube.map(({ snowflakeDailyQueryCostByWarehouse }) => (
      snowflakeDailyQueryCostByWarehouse.snowflakeWarehouseName
    )),
  ))
    .filter((name) => name !== null);

  const dataToReturn: { x: string, y: number }[][] = [];

  warehouseNames.forEach((warehouseName) => {
    const formattedData = data.cube
      .filter(({ snowflakeDailyQueryCostByWarehouse }) => (
        snowflakeDailyQueryCostByWarehouse.snowflakeWarehouseName === warehouseName
      ))
      .map(({ snowflakeDailyQueryCostByWarehouse }) => ({
        x: snowflakeDailyQueryCostByWarehouse.snowflakeStartTime.day,
        y: snowflakeDailyQueryCostByWarehouse.totalQueryCost,
      }));

    dataToReturn.push(formattedData);
  });

  return { data: dataToReturn, warehouseNames };
};

export const getWarehouseSpendXAxisLabels = (data?: warehouseSpendReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ snowflakeDailyQueryCostByWarehouse }) => (
      snowflakeDailyQueryCostByWarehouse.snowflakeStartTime.day
    ));
  return Array.from(new Set(dates));
};

export interface dailyCostReturnType {
  cube: {
    snowflakeDailyAverageCosts: {
      avgCloudServicesCost: number,
      avgComputeCost: number,
      snowflakeStartTime: {
        day: string,
      }
    }
  }[]
}

export const dailyCostQuery = gql`
  query dailyCostQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {snowflake_daily_average_costs: {snowflake_start_time: {inDateRange: $timeframe}}}
    ) {
      snowflakeDailyAverageCosts: snowflake_daily_average_costs(orderBy: {snowflake_start_time: asc}) {
        avgCloudServicesCost: avg_cloud_services_cost
        avgComputeCost: avg_compute_cost
        snowflakeStartTime: snowflake_start_time {
          day
        }
      }
    }
  }
`;

export const formatDailyCostForChart = (data?: dailyCostReturnType) => {
  if (!data) return [];

  const cloudCost = data.cube
    .map(({ snowflakeDailyAverageCosts }) => ({
      x: snowflakeDailyAverageCosts.snowflakeStartTime.day,
      y: snowflakeDailyAverageCosts.avgCloudServicesCost,
    }));

  const computeCost = data.cube
    .map(({ snowflakeDailyAverageCosts }) => ({
      x: snowflakeDailyAverageCosts.snowflakeStartTime.day,
      y: snowflakeDailyAverageCosts.avgComputeCost,
    }));

  return [cloudCost, computeCost];
};

export const getDailyCostXAxisLabels = (data?: dailyCostReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ snowflakeDailyAverageCosts }) => (
      snowflakeDailyAverageCosts.snowflakeStartTime.day
    ));
  return Array.from(new Set(dates));
};

export interface hourlyCostReturnType {
  cube: {
    snowflakeHourlyAverageQueryCost: {
      avgQueryCost: number,
      snowflakeQueryHourOfDay: number,
    }
  }[]
}

export const hourlyCostQuery = gql`
  query hourlyCostQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {snowflake_hourly_average_query_cost: {snowflake_start_time: {inDateRange: $timeframe}}}
    ) {
      snowflakeHourlyAverageQueryCost: snowflake_hourly_average_query_cost(orderBy: {snowflake_query_hour_of_day: asc}) {
        avgQueryCost: avg_query_cost
        snowflakeQueryHourOfDay: snowflake_query_hour_of_day
      }
    }
  }
`;

export const formatHourlyCostForChart = (data?: hourlyCostReturnType) => {
  if (!data) return [];

  return [data.cube
    .map(({ snowflakeHourlyAverageQueryCost }) => ({
      x: snowflakeHourlyAverageQueryCost.snowflakeQueryHourOfDay.toString(),
      y: snowflakeHourlyAverageQueryCost.avgQueryCost,
    }))];
};

export const getHourlyCostXAxisLabels = (data?: hourlyCostReturnType) => {
  if (!data) return [];

  const hourOfDay = data.cube
    .map(({ snowflakeHourlyAverageQueryCost }) => (
      snowflakeHourlyAverageQueryCost.snowflakeQueryHourOfDay.toString()
    ));
  return Array.from(new Set(hourOfDay));
};

export interface userCostReturnType {
  cube: {
    snowflakeQueryCostByUser: {
      totalQueryCost: number,
      snowflakeUserName: string,
    }
  }[]
}

export const userCostQuery = (allWarehouses: boolean) => gql`
  query userCostQuery($timeframe: [String]${getAllVars(allWarehouses, 'warehouseName')}) {
    cube(
      limit: 5000
      where: {snowflake_query_cost_by_user: {snowflake_warehouse_name: ${getAllWhere(allWarehouses, 'warehouseName')}, snowflake_start_time: {inDateRange: $timeframe}}}
    ) {
      snowflakeQueryCostByUser: snowflake_query_cost_by_user(orderBy: {total_query_cost: desc}) {
        totalQueryCost: total_query_cost
        snowflakeUserName: snowflake_user_name
      }
    }
  }
`;

export const formatUserCostForChart = (data?: userCostReturnType) => {
  if (!data) return [];

  return [data.cube
    .map(({ snowflakeQueryCostByUser }) => ({
      x: snowflakeQueryCostByUser.snowflakeUserName,
      y: snowflakeQueryCostByUser.totalQueryCost,
    }))];
};

export const getUserCostXAxisLabels = (data?: userCostReturnType) => {
  if (!data) return [];

  const names = data.cube
    .map(({ snowflakeQueryCostByUser }) => (
      snowflakeQueryCostByUser.snowflakeUserName
    ));
  return Array.from(new Set(names));
};

export interface roleCostReturnType {
  cube: {
    snowflakeQueryCostByRole: {
      totalQueryCost: number,
      snowflakeRoleName: string,
    }
  }[]
}

export const roleCostQuery = (allWarehouses: boolean) => gql`
  query roleCostQuery($timeframe: [String]${getAllVars(allWarehouses, 'warehouseName')}) {
    cube(
      limit: 5000
      where: {snowflake_query_cost_by_role: {snowflake_warehouse_name: ${getAllWhere(allWarehouses, 'warehouseName')}, snowflake_start_time: {inDateRange: $timeframe}}}
    ) {
      snowflakeQueryCostByRole: snowflake_query_cost_by_role(orderBy: {total_query_cost: desc}) {
        totalQueryCost: total_query_cost
        snowflakeRoleName: snowflake_role_name
      }
    }
  }
`;

export const formatRoleCostForChart = (data?: roleCostReturnType) => {
  if (!data) return [];

  return [data.cube
    .map(({ snowflakeQueryCostByRole }) => ({
      x: snowflakeQueryCostByRole.snowflakeRoleName,
      y: snowflakeQueryCostByRole.totalQueryCost,
    }))];
};

export const getRoleCostXAxisLabels = (data?: roleCostReturnType) => {
  if (!data) return [];

  const names = data.cube
    .map(({ snowflakeQueryCostByRole }) => (
      snowflakeQueryCostByRole.snowflakeRoleName
    ));
  return Array.from(new Set(names));
};

export interface costByTypeReturnType {
  cube: {
    snowflakeDbtCostByResourceType: {
      totalQueryCost: number,
      dbtResourceType: string | null,
    }
  }[]
}

export const costByTypeQuery = gql`
  query costByTypeQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {snowflake_dbt_cost_by_resource_type: {snowflake_start_time: {inDateRange: $timeframe}}}
    ) {
      snowflakeDbtCostByResourceType: snowflake_dbt_cost_by_resource_type(orderBy: {total_query_cost: desc}) {
        totalQueryCost: total_query_cost
        dbtResourceType: dbt_resource_type
      }
    }
  }
`;

export const formatCostByTypeForChart = (data?: costByTypeReturnType) => {
  if (!data) return [];

  return [data.cube
    .map(({ snowflakeDbtCostByResourceType }) => ({
      x: snowflakeDbtCostByResourceType.dbtResourceType || 'unknown',
      y: snowflakeDbtCostByResourceType.totalQueryCost,
    }))];
};

export const getCostByTypeXAxisLabels = (data?: costByTypeReturnType) => {
  if (!data) return [];

  const names = data.cube
    .map(({ snowflakeDbtCostByResourceType }) => (
      snowflakeDbtCostByResourceType.dbtResourceType || 'unknown'
    ));
  return Array.from(new Set(names));
};

export interface prodRunsReturnType {
  cube: {
    snowflakeDbtScheduledRunCost: {
      totalQueryCost: number,
      dbtScheduleName: string | null,
      snowflakeStartTime: {
        day: string,
      }
    }
  }[]
}

export const prodRunsQuery = gql`
  query prodRunsQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {snowflake_dbt_scheduled_run_cost: {snowflake_start_time: {inDateRange: $timeframe}}}
    ) {
      snowflakeDbtScheduledRunCost: snowflake_dbt_scheduled_run_cost(orderBy: {snowflake_start_time: asc}) {
        totalQueryCost: total_query_cost
        dbtScheduleName: dbt_schedule_name
        snowflakeStartTime: snowflake_start_time {
          day
        }
      }
    }
  }
`;

export const formatProdRunsForChart = (data?: prodRunsReturnType) => {
  if (!data) return { data: [], scheduleNames: [] };

  const scheduleNames = Array.from(new Set(
    data.cube.map(({ snowflakeDbtScheduledRunCost }) => (
      snowflakeDbtScheduledRunCost.dbtScheduleName
    )),
  ))
    .filter((name) => name !== null);

  const dataToReturn: { x: string, y: number }[][] = [];

  scheduleNames.forEach((scheduleName) => {
    const formattedData = data.cube
      .filter(({ snowflakeDbtScheduledRunCost }) => (
        snowflakeDbtScheduledRunCost.dbtScheduleName === scheduleName
      ))
      .map(({ snowflakeDbtScheduledRunCost }) => ({
        x: snowflakeDbtScheduledRunCost.snowflakeStartTime.day,
        y: snowflakeDbtScheduledRunCost.totalQueryCost,
      }));

    dataToReturn.push(formattedData);
  });

  return { data: dataToReturn, scheduleNames };
};

export const getProdRunsXAxisLabels = (data?: prodRunsReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ snowflakeDbtScheduledRunCost }) => (
      snowflakeDbtScheduledRunCost.snowflakeStartTime.day
    ));
  return Array.from(new Set(dates));
};

export interface queriesCostReturnType {
  cube: {
    snowflakeDbtPerformanceSummary: {
      avgElapsedTimeInSeconds: number,
      totalElapsedTimeInSeconds: number,
      countExecutedQueries: number,
      avgQueryCost: number,
      totalQueryCost: number,
      dbtPackageName: string | null,
      dbtNodeName: string,
      dbtResourceType: string | null,
    }
  }[]
}

export const queriesCostQuery = gql`
  query queriesCostQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {snowflake_dbt_performance_summary: {snowflake_start_time: {inDateRange: $timeframe}}}
    ) {
      snowflakeDbtPerformanceSummary: snowflake_dbt_performance_summary(orderBy: {total_query_cost: desc}) {
        avgElapsedTimeInSeconds: avg_elapsed_time_in_seconds
        totalElapsedTimeInSeconds: total_elapsed_time_in_seconds
        countExecutedQueries: count_executed_queries
        avgQueryCost: avg_query_cost
        totalQueryCost: total_query_cost
        dbtPackageName: dbt_package_name
        dbtNodeName: dbt_node_name
        dbtResourceType: dbt_resource_type
      }
    }
  }
`;

export const queriesCostColumns = [
  { field: 'dbtPackageName', header: 'dbt™ node package name' },
  { field: 'dbtNodeName', header: 'dbt™ node name' },
  { field: 'dbtResourceType', header: 'dbt™ node resource type' },
  { field: 'totalQueryCost', header: 'Snowflake query cost - sum' },
  { field: 'totalElapsedTimeInSeconds', header: 'Snowflake total elapsed time seconds' },
  { field: 'avgElapsedTimeInSeconds', header: 'Snowflake average elapsed time seconds' },
  { field: 'countExecutedQueries', header: 'Count executed queries' },
  { field: 'avgQueryCost', header: 'Snowflake query cost - average' },
].map((col) => ({ ...col, sortable: true }));

export const formatQueriesCostForChart = (data?: queriesCostReturnType) => {
  if (!data) return [];

  return data.cube.map(({ snowflakeDbtPerformanceSummary }) => ({
    ...snowflakeDbtPerformanceSummary,
    dbtPackageName: snowflakeDbtPerformanceSummary.dbtPackageName || 'No value',
    dbtResourceType: snowflakeDbtPerformanceSummary.dbtResourceType || 'No value',
  }));
};

export interface timeoutQueriesReturnType {
  cube: {
    snowflakeQueryOverview: {
      totalQueryCost: number,
      snowflakeQueryType: string,
      snowflakeQueryParameterizedHash: string,
      snowflakeWarehouseName: string,
      snowflakeRoleName: string,
      snowflakeUserName: string,
      snowflakeStartTime: {
        value: string,
      }
      snowflakeEndTime: {
        value: string,
      }
      snowflakeErrorMessage: string,
      snowflakeQueryId: string,
    }
  }[]
}

export const timeoutQueriesQuery = (allWarehouses: boolean) => gql`
  query timeoutQueriesQuery($timeframe: [String]${getAllVars(allWarehouses, 'warehouseName')}) {
    cube(
      limit: 5000
      where: {snowflake_query_overview: {snowflake_error_message: {contains: "Statement reached its statement or warehouse timeout"}, snowflake_warehouse_name: ${getAllWhere(allWarehouses, 'warehouseName')}, snowflake_start_time: {inDateRange: $timeframe}}}
    ) {
      snowflakeQueryOverview: snowflake_query_overview(orderBy: {total_query_cost: desc}) {
        totalQueryCost: total_query_cost
        snowflakeQueryType: snowflake_query_type
        snowflakeQueryParameterizedHash: snowflake_query_parameterized_hash
        snowflakeWarehouseName: snowflake_warehouse_name
        snowflakeRoleName: snowflake_role_name
        snowflakeUserName: snowflake_user_name
        snowflakeStartTime: snowflake_start_time {
          value
        }
        snowflakeEndTime: snowflake_end_time {
          value
        }
        snowflakeErrorMessage: snowflake_error_message
        snowflakeQueryId: snowflake_query_id
      }
    }
  }
`;

export const timeoutQueriesColumns = [
  { field: 'snowflakeStartTime', header: 'Snowflake start time', type: ColumnType.DATE },
  { field: 'snowflakeQueryType', header: 'Snowflake query type' },
  { field: 'snowflakeWarehouseName', header: 'Snowflake warehouse name' },
  { field: 'snowflakeRoleName', header: 'Snowflake role name' },
  { field: 'snowflakeUserName', header: 'Snowflake user name' },
  { field: 'snowflakeQueryId', header: 'Snowflake query id' },
  { field: 'snowflakeQueryParameterizedHash', header: 'Snowflake query parameterized hash' },
  { field: 'totalQueryCost', header: 'Snowflake total query cost' },
  { field: 'snowflakeEndTime', header: 'Snowflake end time', type: ColumnType.DATE },
].map((col) => ({ ...col, sortable: true }));

export const formatTimeoutQueriesForChart = (data?: timeoutQueriesReturnType) => {
  if (!data) return [];

  return data.cube.map(({ snowflakeQueryOverview }) => ({
    ...snowflakeQueryOverview,
    snowflakeStartTime: snowflakeQueryOverview.snowflakeStartTime.value,
    snowflakeEndTime: snowflakeQueryOverview.snowflakeEndTime.value,
  }));
};
