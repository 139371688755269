import { useEffect, useState } from 'react';
import { DocumentNode } from 'graphql';
import { ApolloQueryResult } from '@apollo/client';
import { graphQLStore } from '../../stores';

export interface useCubeLazyQueryProps<ReturnDataType> {
  query: DocumentNode,
  variables?: any,
  onCompleted?: (response: ApolloQueryResult<ReturnDataType>) => void,
}

interface useCubeQueryReturnType<ReturnDataType> {
  data?: ReturnDataType,
  loading: boolean,
  error: boolean,
}

type useCubeLazyQueryReturnType<ReturnDataType> = [
  () => void,
  useCubeQueryReturnType<ReturnDataType>,
]

export const useCubeLazyQuery = <ReturnDataType, >({
  query,
  variables,
  onCompleted,
}: useCubeLazyQueryProps<ReturnDataType>): useCubeLazyQueryReturnType<ReturnDataType> => {
  const [triggerTheQuery, setTriggerTheQuery] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [responseData, setResponseData] = useState<ReturnDataType>();

  const cubeClient = graphQLStore((s) => s.cubeClient);

  useEffect(() => {
    if (cubeClient && triggerTheQuery) {
      setTriggerTheQuery(false);
      setLoading(true);
      setError(false);

      cubeClient
        .query({ query, variables })
        .then((response: ApolloQueryResult<ReturnDataType>) => {
          setResponseData(response.data);

          if (onCompleted) onCompleted(response);

          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setError(true);
        });
    }
  }, [cubeClient, triggerTheQuery]);

  return [
    () => setTriggerTheQuery(true),
    { data: responseData, loading, error },
  ];
};

export const useCubeQuery = <ReturnDataType, >({
  query,
  variables,
  onCompleted,
}: useCubeLazyQueryProps<ReturnDataType>): useCubeQueryReturnType<ReturnDataType> => {
  const [triggerQuery, reponseDetails] = useCubeLazyQuery<ReturnDataType>({
    query,
    variables,
    onCompleted,
  });

  useEffect(() => {
    triggerQuery();
  }, []);

  return reponseDetails;
};
