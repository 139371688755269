import React, { Dispatch, FC, SetStateAction } from 'react';
import MultiLineInput from '@paradime-io/pragma-ui-kit/lib/components/MultiLineInput';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';

export interface CommandsBlockProps {
  commands: string,
  setCommands: Dispatch<SetStateAction<string>>,
}

const CommandsBlock: FC<CommandsBlockProps> = ({
  commands,
  setCommands,
}) => (
  <AutoLayout
    direction="vertical"
    padding="none"
    distribution="packed"
    verticalGap="ultra-dense"
  >
    <MultiLineInput
      view="outlined"
      color="default"
      label="Commands"
      placeholder="Add commands in separate lines"
      dense
      maxLines={5}
      minLines={3}
      value={commands}
      exposeOnChange={(value: string) => setCommands(value)}
      style={{ background: 'var(--white)' }}
    />
    <Typography tagName="span" type="caption" color="default" colorStep="50">
      Examples:
      {' '}
      <i>dbt seed, dbt run, dbt test</i>
    </Typography>
  </AutoLayout>
);

export default CommandsBlock;
