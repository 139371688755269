import React, { FC } from 'react';
import ZeroState, { ParadimeImages } from '@paradime-io/pragma-ui-kit/lib/components/ZeroState';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import GetAllModelsButton, { GetAllModelsButtonProps } from '../GetAllModelsButton';
import { useHeaderContext } from '../useHeaderContext';
import { subtitleTypes } from '../Header';

const GetSartedZeroState: FC<GetAllModelsButtonProps> = ({
  onGetAllModelsClick,
}) => {
  useHeaderContext({
    subtitleType: subtitleTypes.GET_STARTED,
    buttonType: 'get',
    buttonIsVisible: false,
    showHeader: true,
  });

  return (
    <ZeroState
      image={ParadimeImages.EMPTY_LIGHT}
      text={(
        <AutoLayout
          padding="none"
          direction="vertical"
          verticalGap="normal"
          alignment="center"
          wrapperStyle={{ justifyContent: 'center' }}
          style={{ width: '300px' }}
        >
          <Typography
            type="body-bold-small"
            style={{ textAlign: 'center' }}
            color="default"
            colorStep="100"
          >
            Select &apos;Get all models&apos; to get started
          </Typography>
          <GetAllModelsButton
            onGetAllModelsClick={onGetAllModelsClick}
            style={{ width: '140px', margin: 'auto' }}
          />
        </AutoLayout>
      )}
    />
  );
};

export default GetSartedZeroState;
