/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useContext, useEffect } from 'react';
import { subtitleTypes } from './Header';

interface headerContextProps {
  subtitleType: subtitleTypes,
  buttonType: string,
  buttonIsVisible: boolean,
  showHeader: boolean,
}

export const ThoughtSpotHeaderContext = createContext({
  setSubtitleType: (subtitle: subtitleTypes) => {},
  setButtonType: (buttonType: string) => {},
  setButtonIsVisible: (isVisible: boolean) => {},
  setShowHeader: (isVisible: boolean) => {},
});

export const useHeaderContext = ({
  subtitleType,
  buttonType,
  buttonIsVisible,
  showHeader,
}: headerContextProps) => {
  const {
    setSubtitleType,
    setButtonType,
    setButtonIsVisible,
    setShowHeader,
  } = useContext(ThoughtSpotHeaderContext);

  useEffect(() => {
    setSubtitleType(subtitleType);
    setButtonIsVisible(buttonIsVisible);
    setButtonType(buttonType);
    setShowHeader(showHeader);
  }, []);
};
