import { useState, useEffect } from 'react';
import {
  useGetAlertConfigLazyQuery,
  useSetAlertConfigMutation,
  AlertConfig,
} from '../../../client/generated/service';

const useManageAlerts = () => {
  const [isUpdatedAlert, setIsUpdatedAlert] = useState(false);
  const [alertConfigs, setAlertConfigs] = useState<AlertConfig[]>();
  const [
    fetchAlertConfigs,
    { loading: isLoadingAlertConfigs },
  ] = useGetAlertConfigLazyQuery({
    onCompleted: (data) => {
      if (data.getAlertConfig?.configs) {
        setAlertConfigs(data.getAlertConfig.configs);
      }
    },
  });

  const [updateAlert, { loading: isUpdatingAlert }] = useSetAlertConfigMutation({
    onCompleted: (data) => {
      if (data.setAlertConfig?.ok) {
        setIsUpdatedAlert(true);
        setTimeout(() => setIsUpdatedAlert(false), 3000);
      }
    },
  });

  useEffect(() => {
    fetchAlertConfigs();
  }, []);

  return {
    alertConfigs,
    fetchAlertConfigs,
    isLoadingAlertConfigs,
    updateAlert,
    isUpdatingAlert,
    isUpdatedAlert,
  };
};

export { useManageAlerts };
