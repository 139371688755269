import React, { FC, ReactNode } from 'react';
import { Icon, IconName } from '@blueprintjs/core';
import { CSSProperties } from 'styled-components';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { fontType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import Card from '@paradime-io/pragma-ui-kit/lib/components/Card';
import paradimeLogo from '../images/paradimeLogo.svg';
import pinExtension from '../images/pinExtension.svg';

export interface PageTemplateProps {
  mainImage?: string,
  icon?: { name: IconName, size?: number, color?: string },
  title?: string,
  titleTextType?: fontType,
  subtitle?: string,
  subtitleTextType?: fontType,
  subtitleStyles?: CSSProperties,
  withLogo?: boolean,
  withBox?: boolean,
  boxOrientation?: 'horizontal' | 'vertical',
  boxContents?: ReactNode,
  withPinExtensionLogo?: boolean,
  extraSpaceBeforeBox?: string,
  withBackgroundBand?: boolean,
  backgroundBandColour?: string,
  customComponent?: JSX.Element,
  boxWidth?: string,
  useThickerBorder?: boolean,
  pageBackgroundColour?: string,
}

const PageTemplate: FC<PageTemplateProps> = ({
  mainImage,
  icon,
  title,
  titleTextType,
  subtitle,
  subtitleTextType,
  subtitleStyles,
  withLogo = true,
  withBox = true,
  boxOrientation,
  boxContents,
  withPinExtensionLogo = false,
  extraSpaceBeforeBox,
  withBackgroundBand,
  backgroundBandColour,
  customComponent,
  boxWidth,
  useThickerBorder = true,
  pageBackgroundColour,
}) => {
  const logoRowHeight = withPinExtensionLogo ? '164px' : '40px';

  const getBoxWidthAndHeight = () => {
    switch (boxOrientation) {
      case 'horizontal':
        return { width: '1200px', height: '330px' };
      default:
        return { width: boxWidth || '397px', height: '726px' };
    }
  };

  const getBoxStyles = () => {
    let styles = {};

    if (boxOrientation === 'horizontal') {
      styles = { ...styles, margin: 'auto' };
    }

    if (withBackgroundBand) {
      styles = { ...styles, padding: 0 };
    }

    return Object.keys(styles).length > 0 ? styles : undefined;
  };

  return (
    <AutoLayout
      direction="vertical"
      padding="very-dense"
      verticalGap="none"
      distribution="packed"
      style={{
        width: '100vw',
        height: '100vh',
        gridTemplateRows: `${logoRowHeight} calc(100% - ${logoRowHeight})`,
        backgroundColor: pageBackgroundColour,
      }}
    >
      <AutoLayout
        direction="horizontal"
        padding="none"
        distribution="space-between"
      >
        {withLogo && (
          <img
            src={paradimeLogo}
            alt="paradime"
            width="169px"
            style={{ alignSelf: 'start' }}
          />
        )}
        {withPinExtensionLogo && (
          <img
            src={pinExtension}
            alt="paradime"
            width="265px"
            style={{ marginRight: '100px' }}
          />
        )}
      </AutoLayout>
      <AutoLayout
        direction={boxOrientation === 'vertical' ? 'horizontal' : 'vertical'}
        padding="none"
        horizontalGap="dense"
        verticalGap="expanded"
        distribution="packed"
        style={boxOrientation === 'vertical' && withBox
          ? {
            gridTemplateColumns: `calc(100% - ${getBoxWidthAndHeight().width} - 16px) ${getBoxWidthAndHeight().width}`,
            marginRight: '32px',
          }
          : undefined}
      >
        <AutoLayout
          direction="vertical"
          padding="none"
          verticalGap="normal"
          distribution="packed"
        >
          {icon && (
            <Icon
              icon={icon.name}
              size={icon.size || 72}
              color={icon.color || 'var(--green100)'}
              style={{ margin: 'auto' }}
            />
          )}
          {title && (
            <Typography
              font="poppins"
              type={titleTextType || 'h2'}
              color="default"
              colorStep="100"
              style={{ textAlign: 'center' }}
            >
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography
              font="poppins"
              type={subtitleTextType || 'body-large'}
              color="default"
              colorStep="100"
              style={{ textAlign: 'center', ...subtitleStyles }}
            >
              {subtitle}
            </Typography>
          )}
          {mainImage && (
            <img
              src={mainImage}
              alt=""
              width="100%"
              height="100%"
              style={{ maxWidth: '670px', margin: 'auto' }}
            />
          )}
        </AutoLayout>
        {extraSpaceBeforeBox && <div style={{ height: extraSpaceBeforeBox }} />}
        {withBox && (
          <Card
            tabIndex={-1}
            {...getBoxWidthAndHeight()}
            useThickerBorder={useThickerBorder}
            style={{ ...getBoxStyles(), border: '1px solid var(--grey10)' }}
          >
            {withBackgroundBand
              ? (
                <div
                  style={{
                    // horizontal box height - 2*padding
                    height: 'calc(330px - 45px - 45px)',
                    width: '100%',
                    margin: '45px 0',
                    backgroundColor: backgroundBandColour,
                    border: '4px solid var(--black)',
                    boxShadow: '5px 5px 0px var(--black), 4px 4px 0px var(--black), 3px 3px 0px var(--black), 2px 2px 0px var(--black), 1px 1px 0px var(--black)',
                  }}
                >
                  {boxContents}
                </div>
              )
              : boxContents}
          </Card>
        )}
        {customComponent && customComponent}
      </AutoLayout>
    </AutoLayout>
  );
};

export default PageTemplate;
