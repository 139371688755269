import React, { ReactNode } from 'react';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { FrigadeJS } from '@frigade/react';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import CodeSnippetCopy from '@paradime/common/lib/common/helpers/CodeSnippetCopy';
import { useMarkStepCompleted } from '@paradime/common/lib/common/helpers/frigadeHelperFunctions';
import * as S from '../Bolt.styles';
import { BoltFlows } from '../../Common/Frigade/flows';

const CreateGithubActionStep = (stepData: FrigadeJS.FlowStep): ReactNode => {
  const { primaryButtonTitle, id: stepId } = stepData;
  const { markStepCompleted } = useMarkStepCompleted(BoltFlows.TURBO_CD_FLOW);

  const code = `
name: CD run

on:
  pull_request:
    types:
      - closed

jobs:
  pardime_global_cd_run:
    if: github.event.pull_request.merged == true
    runs-on: ubuntu-latest
    timeout-minutes: 60

    env:
      SCHEDULE_NAME: CD_run
      API_URL: \${{ secrets.PARADIME_ENDPOINT }}
      API_SECRET: \${{ secrets.PARADIME_API_SECRET }}
      API_KEY: \${{ secrets.PARADIME_API_KEY }}
      WORKSPACE_TOKEN:  \${{ secrets.PARADIME_WORKSPACE_TOKEN }}
      BASE_PARADIME_BOLT_URL: https://app.paradime.io/bolt/run_id

    steps:

    - name: Trigger Bolt Run
      shell: bash
      run: |
        response=$(curl -s -X POST \\
        -H "Content-Type: application/json" \\
        -H "X-API-KEY: \${{ env.API_KEY }}" \\
        -H "X-API-SECRET: \${{ env.API_SECRET }}" \\
        -d "{
          \\"query\\": \\"mutation trigger { triggerBoltRun(scheduleName: \\\\\\"\${{ env.SCHEDULE_NAME }}\\\\\\") { runId } }\\"
        }" "\${{ env.API_URL }}")
        echo "$response"

        runId=$(echo "$response" | jq -r '.data.triggerBoltRun.runId')
        if [ "$runId" == "null" ]; then
          exit 1
        fi

        echo "##vso[task.setvariable variable=runId]$runId"
        echo "\${{ env.BASE_PARADIME_BOLT_URL }}\${runId}?workspaceUid=\${{ env.WORKSPACE_TOKEN }}"
`;

  return (
    <AutoLayout
      direction="vertical"
      distribution="packed"
      verticalGap="dense"
      padding="none"
      alignment="top-left"
      style={{ paddingBottom: '16px' }}
    >
      <Typography tagName="span" type="h6">Create a CD Job schedule</Typography>
      <S.CodeTypography tagName="span" type="body-small">
        Create a folder called
        {' '}
        <code>.github</code>
        {' '}
        and a subfolder called
        {' '}
        <code>workflows</code>
        {' '}
        in your dbt™️ repository.
        {' '}
        Create a new
        {' '}
        <code>paradime_cd.yml</code>
        {' '}
        file in the
        {' '}
        <code>.github/workflows</code>
        {' '}
        folder and the code block below.
      </S.CodeTypography>
      <CodeSnippetCopy codeSnippet={code} language="yaml" />
      <DefaultButton
        type="standard"
        view="filled"
        color="primary"
        dense
        text={primaryButtonTitle}
        eventContext={Contexts.LOGS}
        eventObject="markStepComplete"
        eventAction={Actions.CLICKED}
        onClick={() => markStepCompleted(stepId)}
        style={{ width: 'fit-content' }}
      />
    </AutoLayout>
  );
};

export default CreateGithubActionStep;
