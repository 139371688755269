import React, { FC, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import Callout from '@paradime-io/pragma-ui-kit/lib/components/Callout';
import { ApolloError } from '@apollo/client';
import PageTitle, { PageTitles } from '../../PageTitle';
import ConfigureApiTriggerDrawer from './ConfigureApiTriggerDrawer';
import LogsTable from './LogsTable';
import { PlatformSettingsHelp } from '../../Common/AppHelp/help';
import AuditLogsUpgrade from '../../Common/Upgrade/AuditLogsUpgrade';
import { useWaitForLDToBeReady } from '../../hooks/useWaitForLDToBeReady';

const AuditLogs: FC = () => {
  const { ldIsReady } = useWaitForLDToBeReady();
  const { flagAppAuditLogs } = useFlags();
  const [error, setError] = useState<ApolloError>();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  if (ldIsReady && !flagAppAuditLogs) {
    return <AuditLogsUpgrade />;
  }

  return (
    <>
      <PageTitle title={PageTitles.AUDIT_LOGS} />

      {error
        && (
        <Callout
          color="default"
          icon="info-sign"
          view="smooth"
          title="Unable to update audit logs"
          content="We are not able to show your organization’s most recent audit logs at this time. Records below are limited to showing up to the last successful update."
        />
        )}

      <AutoLayout
        direction="vertical"
        padding="none"
        distribution="packed"
        alignment="top-left"
        verticalGap="ultra-dense"
      >
        <AutoLayout
          direction="horizontal"
          distribution="space-between"
          padding="none"
        >
          <AutoLayout
            direction="horizontal"
            alignment="left"
            padding="none"
            distribution="packed"
            horizontalGap="dense"
            style={{
              alignItems: 'center',
            }}
          >
            <Typography
              style={{ placeSelf: 'flex-start', margin: 0 }}
              type="h6"
              tagName="span"
              color="default"
            >
              Audit Logs
            </Typography>

            <Chips
              color="primary_alt"
              round={false}
              style={{ margin: '4px', justifySelf: 'end' }}
              tag="Learn more"
              type="dense"
              view="smooth"
              onClick={() => (
                window.CommandBar.openHelpHub(
                  { articleId: PlatformSettingsHelp.AUDIT_LOGS },
                )
              )}
            />
          </AutoLayout>
          <DefaultButton
            eventContext={Contexts.ACCOUNT_SETTINGS}
            eventObject="configureApiTriggerAuditLogs"
            eventAction={Actions.CLICKED}
            dense
            disabled={!flagAppAuditLogs}
            view="smooth"
            text="Configure an API trigger"
            color="primary"
            onClick={() => setIsDrawerVisible(true)}
          />
        </AutoLayout>
        <Typography
          type="caption"
          tagName="span"
          color="default"
          colorStep="50"
        >
          Access audit logs from the last 30 days.
          {' '}
          Use the API to fetch data older than the last 30 days.
        </Typography>
      </AutoLayout>

      <LogsTable setError={setError} />

      {isDrawerVisible
        && (
        <ConfigureApiTriggerDrawer
          isVisible={isDrawerVisible}
          onHide={() => setIsDrawerVisible(false)}
        />
        )}
    </>
  );
};

export default AuditLogs;
