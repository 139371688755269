export const getFrigadeApperanceConfig = () => ({
  colors: {
    primary: {
      surface: 'var(--violet60)',
      foreground: 'var(--theia-foreground)',
    },
    neutral: {
      foreground: 'var(--theia-foreground)',
    },
  },
  fontSizes: {
    xs: '0.875rem',
    sm: '0.875rem',
    md: '0.875rem',
    lg: '0.875rem',
    xl: '0.875rem',
    '2xl': '0.875rem',
    '3xl': '0.875rem',
    '4xl': '0.875rem',
    '5xl': '0.875rem',
  },
  fontWeights: {
    thin: '400',
    extralight: '400',
    light: '400',
    regular: '400',
    medium: '400',
    demibold: '400',
    bold: '400',
    extrabold: '400',
    black: '400',
  },
  radii: {
    md: '4px',
    lg: '4px',
    round: '4px',
  },
});

/* The following is no longer supported in frigade v2.
  {
    styleOverrides: {
      checklistInlineContainer: {
        backgroundColor: configType.darkMode ? 'var(--dark-slategrey)' : 'var(--white)',
      },
      checklistStepContainer: {
        backgroundColor: configType.darkMode ? 'var(--dark-slategrey)' : 'var(--white)',
      },
      checklistTitle: {
        display: configType.hideTitle ? 'none' : 'flex',
      },
      checklistSubtitle: {
        color: 'var(--theia-foreground)',
      },
      progressBarStepText: {
        color: 'var(--theia-foreground)',
      },
      stepImage: {
        maxHeight: '640px',
      },
      button: {
        borderRadius: '4px',
        fontSize: '0.875rem',
        height: '28px',
        padding: '0 12px 0 12px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      buttonSecondary: {
        borderRadius: '4px',
        fontSize: '0.875rem',
        height: '28px',
        padding: '0 12px 0 12px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      stepTitle: {
        fontSize: '0.875rem',
        marginBottom: '0px',
      },
      checkIconContainer: {
        width: '16px',
        height: '16px',
        borderRadius: '4px',
      },
      progressBarContainer: {
        display: configType.hideProgress ? 'none' : 'flex',
      },
    },
  }
*/
