import React, { FC } from 'react';
import DefaultButton, { DefaultButtonProps } from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';

const COMMANDBAR_INAPP_FEEDBACK_NUDGE_ID = 9538;

interface ShareFeedbackButtonProps extends DefaultButtonProps {
  feedbackContext: string,
}

const ShareFeedbackButton: FC<ShareFeedbackButtonProps> = ({
  feedbackContext,
  ...buttonProps
}) => {
  const onClick = () => {
    window.CommandBar.resetNudge(COMMANDBAR_INAPP_FEEDBACK_NUDGE_ID);
    window.CommandBar.trackEvent(
      'shareFeedback',
      {
        context: feedbackContext,
      },
    );
  };

  return (
    <DefaultButton
      {...buttonProps}
      onClick={onClick}
    />
  );
};

export default ShareFeedbackButton;
