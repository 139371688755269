import React, { FC, useState } from 'react';
import { gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import MetricCard from '../../Common/UserInsights/MetricCard';
import { transformSecondsToHoursMinutes } from '../../Common/UserInsights/utils';
import ZeroStateNoData from '../../Common/UserInsights/ZeroStateNoData';
import HorizontalTextCardContent from '../../Common/UserInsights/HorizontalTextCardContent';
import InsightsContainer from '../../Common/UserInsights/InsightsContainer';
import { formatCurrency } from './utils';
import { useHasuraQuery } from '../../../app/hooks/useHasuraQuery';

interface CostSavingsPerScheduleResponse { // eslint-disable-next-line camelcase
  cost_savings_calculator_per_bolt_schedule: {
    workspaceId: string,
    companyToken: string,
    totalCostSaved: string,
    totalTimeSaved: string,
  }[],
}

const CostSavingsQuery = gql`
  query CostSavingsPerScheduleQuery ($scheduleId: String!) {
    cost_savings_calculator_per_bolt_schedule(args: { schedule_name_uuid: $scheduleId }) {
      workspaceId: workspace_uid
      companyToken: company_token
      totalCostSaved: total_cost_saving_in_usd
      totalTimeSaved: total_time_saved_seconds
    }
  }
`;

const UserInsights: FC = () => {
  const [totalTimeSaved, setTotalTimeSaved] = useState<number>(0);
  const [totalMoneySaved, setTotalMoneySaved] = useState<number>(0);
  const [errorHasNoData, setErrorHasNoData] = useState(false);

  const { scheduleId } = useParams<{ scheduleId: string }>();

  const { loading: isLoadingData } = useHasuraQuery<CostSavingsPerScheduleResponse>({
    query: CostSavingsQuery,
    variables: { scheduleId },
    onCompleted: ({ data }) => {
      if (data?.cost_savings_calculator_per_bolt_schedule?.length > 0) {
        setErrorHasNoData(false);

        const totalTime = data.cost_savings_calculator_per_bolt_schedule
          .reduce((currentTotal, { totalTimeSaved: timeSaved }) => (
            currentTotal + Number(timeSaved)
          ), 0);
        setTotalTimeSaved(totalTime);

        const totalMoney = data.cost_savings_calculator_per_bolt_schedule
          .reduce((currentTotal, { totalCostSaved }) => (
            currentTotal + Number(totalCostSaved)
          ), 0);
        setTotalMoneySaved(totalMoney);
      } else {
        setErrorHasNoData(true);
      }
    },
  });

  return (
    <InsightsContainer
      title="Cost and time savings"
      isLoading={isLoadingData}
    >
      {errorHasNoData
        ? <ZeroStateNoData showSuggestedActions={false} />
        : (
          <AutoLayout
            direction="horizontal"
            padding="none"
            horizontalGap="dense"
            distribution="packed"
            style={{ gridTemplateColumns: '1fr 1fr' }}
          >
            <MetricCard
              dense
              hasError={false}
              content={(
                <HorizontalTextCardContent
                  icon="bank-account"
                  heading="Total Paradime Resource Costs Savings"
                  savingAmount={`${formatCurrency(totalMoneySaved)}`}
                />
              )}
            />
            <MetricCard
              dense
              hasError={false}
              content={(
                <HorizontalTextCardContent
                  icon="time"
                  heading="Total DevOps Time Saved"
                  savingAmount={transformSecondsToHoursMinutes(totalTimeSaved)}
                />
              )}
            />
          </AutoLayout>
        )}
    </InsightsContainer>
  );
};

export default UserInsights;
