import { gql } from '@apollo/client';
import { getAllVars, getAllWhere } from '../utils';

export interface totalRuntimeReturnType {
  cube: {
    bigqueryDailyAvgRuntime: {
      avgRuntimeMs: number,
      bigqueryStartTime: {
        day: string,
      }
    }
  }[]
}

export const totalRuntimeQuery = (allQueryHashes: boolean) => gql`
  query totalRuntimeQuery($timeframe: [String]${getAllVars(allQueryHashes, 'queryHash')}) {
    cube(
      limit: 5000
      where: {bigquery_daily_avg_runtime: {bigquery_query_hash: ${getAllWhere(allQueryHashes, 'queryHash')}, bigquery_start_time: {inDateRange: $timeframe}}}
    ) {
      bigqueryDailyAvgRuntime: bigquery_daily_avg_runtime(orderBy: {bigquery_start_time: asc}) {
        avgRuntimeMs: avg_runtime_ms
        bigqueryStartTime: bigquery_start_time {
          day
        }
      }
    }
  }
`;

export const formatTotalRuntimeForChart = (data?: totalRuntimeReturnType) => {
  if (!data) return [];

  return [data.cube
    .map(({ bigqueryDailyAvgRuntime }) => ({
      x: bigqueryDailyAvgRuntime.bigqueryStartTime.day,
      y: bigqueryDailyAvgRuntime.avgRuntimeMs,
    }))];
};

export const getTotalRuntimeXAxisLabels = (data?: totalRuntimeReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ bigqueryDailyAvgRuntime }) => (
      bigqueryDailyAvgRuntime.bigqueryStartTime.day
    ));
  return Array.from(new Set(dates));
};

export interface dailySlotUseReturnType {
  cube: {
    bigqueryDailyAvgUsedSlotCount: {
      totalAvgUsedSlotCount: number,
      bigqueryStartTime: {
        day: string,
      }
    }
  }[]
}

export const dailySlotUseQuery = (allQueryHashes: boolean) => gql`
  query dailySlotUseQuery($timeframe: [String]${getAllVars(allQueryHashes, 'queryHash')}) {
    cube(
      limit: 5000
      where: {bigquery_daily_avg_used_slot_count: {bigquery_query_hash: ${getAllWhere(allQueryHashes, 'queryHash')}, bigquery_start_time: {inDateRange: $timeframe}}}
    ) {
      bigqueryDailyAvgUsedSlotCount: bigquery_daily_avg_used_slot_count(orderBy: {bigquery_start_time: asc}) {
        totalAvgUsedSlotCount: total_avg_used_slot_count
        bigqueryStartTime: bigquery_start_time {
          day
        }
      }
    }
  }
`;

export const formatDailySlotUseForChart = (data?: dailySlotUseReturnType) => {
  if (!data) return [];

  return [data.cube
    .map(({ bigqueryDailyAvgUsedSlotCount }) => ({
      x: bigqueryDailyAvgUsedSlotCount.bigqueryStartTime.day,
      y: bigqueryDailyAvgUsedSlotCount.totalAvgUsedSlotCount,
    }))];
};

export const getDailySlotUseXAxisLabels = (data?: dailySlotUseReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ bigqueryDailyAvgUsedSlotCount }) => (
      bigqueryDailyAvgUsedSlotCount.bigqueryStartTime.day
    ));
  return Array.from(new Set(dates));
};

export interface spiltBytesReturnType {
  cube: {
    bigqueryDailyShuffleAndProcessedBytes: {
      totalBytesProcessed: number,
      totalShuffleOutputBytes: number,
      bigqueryStartTime: {
        day: string,
      }
    }
  }[]
}

export const spiltBytesQuery = (allQueryHashes: boolean) => gql`
  query spiltBytesQuery($timeframe: [String]${getAllVars(allQueryHashes, 'queryHash')}) {
    cube(
      limit: 5000
      where: {bigquery_daily_shuffle_and_processed_bytes: {bigquery_query_hash: ${getAllWhere(allQueryHashes, 'queryHash')}, bigquery_start_time: {inDateRange: $timeframe}}}
    ) {
      bigqueryDailyShuffleAndProcessedBytes: bigquery_daily_shuffle_and_processed_bytes(orderBy: {bigquery_start_time: asc}) {
        totalBytesProcessed: total_bytes_processed
        totalShuffleOutputBytes: total_shuffle_output_bytes
        bigqueryStartTime: bigquery_start_time {
          day
        }
      }
    }
  }
`;

export const formatSpiltBytesForChart = (data?: spiltBytesReturnType) => {
  if (!data) return [];

  const processedBytes = data.cube
    .map(({ bigqueryDailyShuffleAndProcessedBytes }) => ({
      x: bigqueryDailyShuffleAndProcessedBytes.bigqueryStartTime.day,
      y: bigqueryDailyShuffleAndProcessedBytes.totalBytesProcessed,
    }));

  const shuffledBytes = data.cube
    .map(({ bigqueryDailyShuffleAndProcessedBytes }) => ({
      x: bigqueryDailyShuffleAndProcessedBytes.bigqueryStartTime.day,
      y: bigqueryDailyShuffleAndProcessedBytes.totalShuffleOutputBytes,
    }));

  return [processedBytes, shuffledBytes];
};

export const getSpiltBytesXAxisLabels = (data?: spiltBytesReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ bigqueryDailyShuffleAndProcessedBytes }) => (
      bigqueryDailyShuffleAndProcessedBytes.bigqueryStartTime.day
    ));
  return Array.from(new Set(dates));
};

export interface spendPerUserReturnType {
  cube: {
    bigqueryCostByUser: {
      totalEstimatedCostUsd: number,
      bigqueryUserEmail: string,
    }
  }[]
}

export const spendPerUserQuery = (allQueryHashes: boolean) => gql`
  query spendPerUserQuery($timeframe: [String]${getAllVars(allQueryHashes, 'queryHash')}) {
    cube(
      limit: 5000
      where: {bigquery_cost_by_user: {bigquery_query_hash: ${getAllWhere(allQueryHashes, 'queryHash')}, bigquery_start_time: {inDateRange: $timeframe}}}
    ) {
      bigqueryCostByUser: bigquery_cost_by_user(orderBy: {total_estimated_cost_usd: desc}) {
        totalEstimatedCostUsd: total_estimated_cost_usd
        bigqueryUserEmail: bigquery_user_email
      }
    }
  }
`;

export const formatSpendPerUserForChart = (data?: spendPerUserReturnType) => {
  if (!data) return [];

  return data.cube
    .map(({ bigqueryCostByUser }) => ({
      x: bigqueryCostByUser.bigqueryUserEmail,
      y: bigqueryCostByUser.totalEstimatedCostUsd,
    }));
};

export interface dailySpendReturnType {
  cube: {
    bigqueryDailyEstimatedCost: {
      totalEstimatedCostUsd: number,
      bigqueryStartTime: {
        day: string,
      }
    }
  }[]
}

export const dailySpendQuery = (allQueryHashes: boolean) => gql`
  query dailySpendQuery($timeframe: [String]${getAllVars(allQueryHashes, 'queryHash')}) {
    cube(
      limit: 5000
      where: {bigquery_daily_estimated_cost: {bigquery_query_hash: ${getAllWhere(allQueryHashes, 'queryHash')}, bigquery_start_time: {inDateRange: $timeframe}}}
    ) {
      bigqueryDailyEstimatedCost: bigquery_daily_estimated_cost(orderBy: {bigquery_start_time: asc}) {
        totalEstimatedCostUsd: total_estimated_cost_usd
        bigqueryStartTime: bigquery_start_time {
          day
        }
      }
    }
  }
`;

export const formatDailySpendForChart = (data?: dailySpendReturnType) => {
  if (!data) return [];

  return [data.cube
    .map(({ bigqueryDailyEstimatedCost }) => ({
      x: bigqueryDailyEstimatedCost.bigqueryStartTime.day,
      y: bigqueryDailyEstimatedCost.totalEstimatedCostUsd,
    }))];
};

export const getDailySpendXAxisLabels = (data?: dailySpendReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ bigqueryDailyEstimatedCost }) => (
      bigqueryDailyEstimatedCost.bigqueryStartTime.day
    ));
  return Array.from(new Set(dates));
};
