import { useEffect, useState } from 'react';

export interface ChartDimensionsConfig {
  BASE_CHART_WIDTH: number,
  BASE_CHART_HEIGHT: number,
  BASE_AXIS_LABEL_FONTSIZE: number,
  MIN_AXIS_LABEL_FONTSIZE: number,
  BASE_TICK_LABEL_FONTSIZE: number,
  MIN_TICK_LABEL_FONTSIZE: number,
  BASE_TOOLTIP_LABEL_FONTSIZE: number,
  MIN_TOOLTIP_LABEL_FONTSIZE: number,
  BASE_TOOLTIP_FLYOUT_WIDTH: number,
  MIN_TOOLTIP_FLYOUT_WIDTH: number,
  BASE_TOOLTIP_FLYOUT_HEIGHT: number,
  MIN_TOOLTIP_FLYOUT_HEIGHT: number,
}

export interface ChartDimensionsType {
  width: number,
  height: number,
}

export interface TooltipConfigType {
  labelFontSize: number,
  flyoutWidth: number,
  flyoutHeight: number,
}

export interface AxisConfigType {
  labelFontSize: number,
  tickLabelFontSize: number,
}

export interface getInterpolatedValueProps {
  baseValue: number,
  minValue: number,
  factor: number,
}

export interface useGetChartDimensionsProps {
  elementRef: any,
  config: ChartDimensionsConfig,
}

export interface useGetChartDimensionsReturnType {
  chartSize?: ChartDimensionsType,
  chartHeight: number,
  chartWidth: number,
  axisConfig: AxisConfigType,
  tooltipConfig: TooltipConfigType,
}

export const useGetChartDimensions = ({
  elementRef, config,
}: useGetChartDimensionsProps): useGetChartDimensionsReturnType => {
  const [chartSize, setChartSize] = useState<ChartDimensionsType>();
  const [chartHeight, setChartHeight] = useState(config.BASE_CHART_HEIGHT);
  const [chartWidth, setChartWidth] = useState(config.BASE_CHART_WIDTH);
  const [axisConfig, setAxisConfig] = useState<AxisConfigType>({
    labelFontSize: config.BASE_AXIS_LABEL_FONTSIZE,
    tickLabelFontSize: config.BASE_TICK_LABEL_FONTSIZE,
  });
  const [tooltipConfig, setTooltipConfig] = useState<TooltipConfigType>({
    labelFontSize: config.BASE_TOOLTIP_LABEL_FONTSIZE,
    flyoutHeight: config.BASE_TOOLTIP_FLYOUT_HEIGHT,
    flyoutWidth: config.BASE_TOOLTIP_FLYOUT_WIDTH,
  });

  const getSize = (el: any): ChartDimensionsType => {
    const width = el.current?.clientWidth;
    const height = el.current?.clientHeight;

    return { width, height };
  };

  useEffect(() => {
    const onResize = () => {
      if (elementRef) {
        setChartSize(getSize(elementRef));
      }
    };
    window.addEventListener('resize', onResize);

    if (elementRef.current) {
      setChartSize(getSize(elementRef));
    }

    return () => window.removeEventListener('resize', onResize);
  }, []);

  const getInterpolatedValue = ({
    baseValue, minValue, factor,
  }: getInterpolatedValueProps) => (
    Math.max(baseValue * factor, minValue)
  );

  useEffect(() => {
    if (chartSize && chartSize.width > config.BASE_CHART_WIDTH) {
      const interpolatingFactor = config.BASE_CHART_WIDTH / chartSize.width;
      setChartHeight(config.BASE_CHART_HEIGHT * interpolatingFactor);
      setChartWidth(config.BASE_CHART_WIDTH * interpolatingFactor);

      const axislabel = getInterpolatedValue({
        baseValue: config.BASE_AXIS_LABEL_FONTSIZE,
        minValue: config.MIN_AXIS_LABEL_FONTSIZE,
        factor: interpolatingFactor,
      });
      const axisTickLabel = getInterpolatedValue({
        baseValue: config.BASE_TICK_LABEL_FONTSIZE,
        minValue: config.MIN_TICK_LABEL_FONTSIZE,
        factor: interpolatingFactor,
      });

      setAxisConfig({ labelFontSize: axislabel, tickLabelFontSize: axisTickLabel });

      const tooltipLabel = getInterpolatedValue({
        baseValue: config.BASE_TOOLTIP_LABEL_FONTSIZE,
        minValue: config.MIN_TOOLTIP_LABEL_FONTSIZE,
        factor: interpolatingFactor,
      });

      const flyoutWidth = getInterpolatedValue({
        baseValue: config.BASE_TOOLTIP_FLYOUT_WIDTH,
        minValue: config.MIN_TOOLTIP_FLYOUT_WIDTH,
        factor: interpolatingFactor,
      });

      const flyoutHeight = getInterpolatedValue({
        baseValue: config.BASE_TOOLTIP_FLYOUT_HEIGHT,
        minValue: config.MIN_TOOLTIP_FLYOUT_HEIGHT,
        factor: interpolatingFactor,
      });

      setTooltipConfig({ labelFontSize: tooltipLabel, flyoutWidth, flyoutHeight });
    }
  }, [chartSize]);

  return {
    chartSize,
    chartHeight,
    chartWidth,
    axisConfig,
    tooltipConfig,
  };
};
