import styled from 'styled-components';
import DefaultInput from '@paradime-io/pragma-ui-kit/lib/components/DefaultInput';

const OrganisationNameInput = styled(DefaultInput)`
  & > input {
    text-transform: lowercase;
  }

  padding: 0 4px;

  & > input::placeholder {
    text-transform: none;
  }
`;

export { OrganisationNameInput };
