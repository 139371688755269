import React, { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import {
  StackedBarChart,
  SingleValueChart,
  LineChart,
  AreaChart,
} from '@paradime-io/pragma-ui-kit/lib/components/VictoryCharts';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { useCubeLazyQuery } from '../../../app/hooks/useCubeQuery';
import {
  prsMergedQuery,
  prsMergedReturnType,
  weeklyCodeChangeQuery,
  weeklyCodeChangeReturnType,
  formatWeeklyCodeChangeForChart,
  getWeeklyCodeChangeXAxisLabels,
  prCycleTimeQuery,
  prCycleTimeReturnType,
  formatPrCycleTimeForChart,
  getPrCycleTimeXAxisLabels,
  prSizeQuery,
  prSizeReturnType,
  formatPrSizeForChart,
  getPrSizeXAxisLabels,
  prFileChangesQuery,
  prFileChangesReturnType,
  formatPrFileChangesForChart,
  getPrFileChangesXAxisLabels,
} from './PullRequestsCubeQueries';
import FilterRow from '../FilterRow';
import {
  longerTimeframes,
  HalfPageChartSection,
  getSingleValueChartString,
  getFormattedYAxisLabel,
  FullPageChartSection,
} from '../utils';
import { useGetFiltersFromUrl } from '../hooks/useGetFiltersFromUrl';

const PullRequestsTab: FC = () => {
  const { getInitialFilterValue } = useGetFiltersFromUrl();

  const [timeframe, setTimeframe] = useState(getInitialFilterValue('timeframe', longerTimeframes[0].value)!);

  const [
    getPrsMerged, { data: prsMergedData, loading: isLoadingPrsMerged, error: prsMergedError },
  ] = useCubeLazyQuery<prsMergedReturnType>({
    query: prsMergedQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getWeeklyCodeChange, {
      data: weeklyCodeChangeData,
      loading: isLoadingWeeklyCodeChange,
      error: weeklyCodeChangeError,
    },
  ] = useCubeLazyQuery<weeklyCodeChangeReturnType>({
    query: weeklyCodeChangeQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getPrCycleTime,
    { data: prCycleTimeData, loading: isLoadingPrCycleTime, error: prCycleTimeError },
  ] = useCubeLazyQuery<prCycleTimeReturnType>({
    query: prCycleTimeQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getPrSize, { data: prSizeData, loading: isLoadingPrSize, error: prSizeError },
  ] = useCubeLazyQuery<prSizeReturnType>({
    query: prSizeQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getPrFileChanges,
    { data: prFileChangesData, loading: isLoadingPrFileChanges, error: prFileChangesError },
  ] = useCubeLazyQuery<prFileChangesReturnType>({
    query: prFileChangesQuery,
    variables: { timeframe: timeframe.value },
  });

  const refetchAllData = () => {
    getPrsMerged();
    getPrCycleTime();
    getPrSize();
    getPrFileChanges();
  };

  useEffect(() => {
    refetchAllData();
    getWeeklyCodeChange();
  }, [timeframe]);

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      verticalGap="dense"
      distribution="packed"
    >
      <FilterRow
        dropdowns={[
          {
            value: timeframe,
            options: longerTimeframes,
            onChange: setTimeframe,
            name: 'timeframe',
            title: 'Select date range',
          },
        ]}
      />
      <div style={{
        display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: '16px',
      }}
      >
        <HalfPageChartSection>
          <SingleValueChart
            title="Total Pull Requests Merged"
            value={(
              getSingleValueChartString(
                prsMergedData?.cube?.[0]?.githubPullRequestMergedCount?.mergedPrCount || 0,
              )
            )}
            isLoading={isLoadingPrsMerged}
            hasNoData={prsMergedError || prsMergedData?.cube?.length === 0}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <AreaChart
            title="Weekly Average Pull Request Size"
            data={formatPrSizeForChart(prSizeData)}
            xAxisLabels={getPrSizeXAxisLabels(prSizeData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Calendar date"
            yLabel="Average PR size"
            isLoading={isLoadingPrSize}
            hasNoData={prSizeError || prSizeData?.cube?.length === 0}
            xAxisDomain={[
              0.5,
              getPrSizeXAxisLabels(prSizeData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <LineChart
            title="Weekly Average Pull Request Cycle Time"
            data={formatPrCycleTimeForChart(prCycleTimeData)}
            xAxisLabels={getPrCycleTimeXAxisLabels(prCycleTimeData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Calendar date"
            yLabel="Average PR cycle time (minutes)"
            isLoading={isLoadingPrCycleTime}
            hasNoData={prCycleTimeError || prCycleTimeData?.cube?.length === 0}
            xAxisDomain={[
              0.5,
              getPrCycleTimeXAxisLabels(prCycleTimeData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <AreaChart
            title="Weekly Average number of file changes per Pull Request"
            data={formatPrFileChangesForChart(prFileChangesData)}
            xAxisLabels={getPrFileChangesXAxisLabels(prFileChangesData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Calendar date"
            yLabel="Average changed files per PR"
            isLoading={isLoadingPrFileChanges}
            hasNoData={prFileChangesError || prFileChangesData?.cube?.length === 0}
            xAxisDomain={[
              0.5,
              getPrFileChangesXAxisLabels(prFileChangesData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </HalfPageChartSection>
        <FullPageChartSection>
          <StackedBarChart
            title="Weekly Net Code Changes in Production"
            data={formatWeeklyCodeChangeForChart(weeklyCodeChangeData)}
            xAxisLabels={getWeeklyCodeChangeXAxisLabels(weeklyCodeChangeData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Calendar date"
            yLabel="Count code changes"
            isLoading={isLoadingWeeklyCodeChange}
            hasNoData={weeklyCodeChangeError || weeklyCodeChangeData?.cube?.length === 0}
            xAxisDomain={[
              0.5,
              getWeeklyCodeChangeXAxisLabels(weeklyCodeChangeData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </FullPageChartSection>
      </div>
    </AutoLayout>
  );
};

export default PullRequestsTab;
