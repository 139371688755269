import React, { Dispatch, FC, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DataTable from '@paradime-io/pragma-ui-kit/lib/components/PrimeReactDataTable';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { DefaultButton } from '@paradime-io/pragma-ui-kit';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { useHeaderContext } from './useHeaderContext';
import { subtitleTypes } from './Header';
import { TableRow } from '.';

export interface ViewModelsProps {
  data: TableRow[],
  setData: Dispatch<SetStateAction<TableRow[]>>,
  atLeastOneModelIsSelected?: boolean,
  onShowSummaryClick: () => void,
  boltRunId?: number | null,
}

const ViewModels: FC<ViewModelsProps> = ({
  data,
  setData,
  atLeastOneModelIsSelected,
  onShowSummaryClick,
  boltRunId,
}) => {
  const history = useHistory();

  useHeaderContext({
    subtitleType: subtitleTypes.SELECT_MODELS,
    buttonType: 'refresh',
    buttonIsVisible: true,
    showHeader: true,
  });

  const columns = [
    { field: 'dbtFolder', header: 'dbt folder' },
    { field: 'modelPath', header: 'model path' },
    { field: 'modelName', header: 'model name' },
    { field: 'databaseName', header: 'database name' },
    { field: 'schemaName', header: 'schema name' },
  ];

  return (
    <AutoLayout
      direction="vertical"
      verticalGap="normal"
      padding="none"
      distribution="packed"
    >
      <AutoLayout
        direction="horizontal"
        horizontalGap="expanded"
        padding="none"
        distribution="space-between"
      >
        <Typography
          font="inter"
          type="caption"
          onClick={() => {
            history.push(`/bolt/run_id/${boltRunId}`);
          }}
          style={{
            color: 'var(--deeplink-blue)',
            cursor: 'pointer',
          }}
        >
          View this schedule in Bolt
        </Typography>
        <DefaultButton
          type="standard"
          view="filled"
          color="primary"
          dense
          text="Go to summary"
          disabled={!atLeastOneModelIsSelected}
          onClick={onShowSummaryClick}
          eventContext={Contexts.BOLT}
          eventObject="viewThoughtSpotModelsSummary"
          eventAction={Actions.CLICKED}
        />
      </AutoLayout>
      <DataTable
        columns={columns}
        data={data}
        size="small"
        showRowCheckboxes
        selectedRows={data.filter(({ isSelected }) => isSelected)}
        onSelectionChange={(selectedRows) => {
          setData((currentData) => (
            currentData.map((curr) => {
              const isSelected = selectedRows
                .findIndex((selectedRow) => selectedRow.id === curr.id)
                > -1;

              return { ...curr, isSelected };
            })
          ));
        }}
        usePagination
        rowsPerPage={10}
        rowsPerPageOptions={[10, 25, 50]}
      />
    </AutoLayout>
  );
};

export default ViewModels;
