import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import RadarPage from '../RadarPage';
import OverviewTab from './OverviewTab';
import DetailsTab from './DetailsTab';
import { DashboardTabIds } from '../config';

const SourceFreshness: FC = () => {
  const { tabId } = useParams<{tabId: DashboardTabIds}>();

  return (
    <RadarPage>
      {tabId === DashboardTabIds.OVERVIEW && <OverviewTab />}
      {tabId === DashboardTabIds.DETAILED && <DetailsTab />}
    </RadarPage>
  );
};

export default SourceFreshness;
