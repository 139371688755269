import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Radio, { RadioProps } from '@paradime-io/pragma-ui-kit/lib/components/Radio';

export type TabId = string | number

export interface TabData {
  id: TabId,
  title: string,
  subtitle?: string,
  disabled?: boolean,
  view?: RadioProps['view'],
  visible?: boolean,
}

export interface TabsRadioProps {
  data: TabData[],
  currentTabId?: TabId,
  exposeOnChange?: (newTabId: TabId) => void,
}

const TabsRadio: FC<TabsRadioProps> = ({
  data,
  currentTabId,
  exposeOnChange,
}) => (
  <AutoLayout
    padding="none"
    horizontalGap="very-dense"
    direction="horizontal"
  >
    {
      data
        .filter((tab) => {
          if (tab?.visible === undefined) return true;
          return tab.visible;
        })
        .map((datum) => (
          <Radio
            key={datum.id}
            label={datum.title}
            view={datum.view || 'smooth'}
            color="default"
            type="dense"
            value={String(datum.id)}
            checked={currentTabId === datum.id}
            onChange={() => exposeOnChange && exposeOnChange(datum.id)}
            disabled={datum.disabled}
            helperText={datum.subtitle}
            style={{ fontWeight: 500 }}
          />
        ))
    }
  </AutoLayout>
);

export default TabsRadio;
