import React, { ReactNode } from 'react';
import { FrigadeJS } from '@frigade/react';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { useMarkStepCompleted } from '@paradime/common/lib/common/helpers/frigadeHelperFunctions';
import { BoltFlows } from '../../Common/Frigade/flows';
import Arcade from '../../Common/Components/Arcade';

const GenerateApiKeysStep = (stepData: FrigadeJS.FlowStep): ReactNode => {
  const { primaryButtonTitle, id: stepId } = stepData;
  const { markStepCompleted } = useMarkStepCompleted(BoltFlows.TURBO_CD_FLOW);

  return (
    <AutoLayout
      direction="vertical"
      distribution="packed"
      verticalGap="dense"
      padding="none"
      alignment="top-left"
      style={{ paddingBottom: '16px' }}
    >
      <Typography tagName="span" type="h6">Generate API Keys</Typography>
      <Typography tagName="span" type="body-small">
        Generate API Key, API Secret and API Endpoint for your workspace.
      </Typography>
      <Arcade
        url="https://demo.arcade.software/S84qStElOr59tR3Sa1zs?embed"
        title="Paradime | Settings generate API Key"
      />
      <DefaultButton
        type="standard"
        view="filled"
        color="primary"
        dense
        text={primaryButtonTitle}
        eventContext={Contexts.LOGS}
        eventObject="markStepComplete"
        eventAction={Actions.CLICKED}
        onClick={() => markStepCompleted(stepId)}
        style={{ width: 'fit-content' }}
      />
    </AutoLayout>
  );
};

export default GenerateApiKeysStep;
