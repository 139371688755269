import React, { FC } from 'react';
import ErrorCallout from '@paradime-io/pragma-ui-kit/lib/components/ErrorCallout';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { GlobalErrorType, RunErrorType } from './ScheduleList';

export const formattedHtmlString = (htmlString: string) => htmlString.split('\\n').map((line) => (
  <>
    {line}
    <br />
  </>
)) as unknown as string;

export enum ErrorLevel {
  SCHEDULE_NAME = 'Schedule Name',
  SCHEDULE_RUN = 'Schedule Run',
}

interface ErrorsProps {
  error?: RunErrorType | null,
  globalError?: GlobalErrorType | null,
  errorLevel?: ErrorLevel
}

const Errors: FC<ErrorsProps> = ({ error, globalError, errorLevel }) => (
  <>
    {
      globalError && (
        <ErrorCallout
          color={globalError.errorMessageSeverity.colorType as colorType}
          title={globalError.errorMessageTitle || 'Global Error'}
          errorDetails={{
            humanErrorMessage: formattedHtmlString(globalError.humanErrorMessage),
            rawErrorMessage: formattedHtmlString(globalError.rawErrorMessage),
          }}
          eventContext={Contexts.BOLT}
        />
      )
    }
    {
      error && (
        <ErrorCallout
          color={error.errorMessageSeverity.colorType as colorType}
          title={error.errorMessageTitle || `${errorLevel} Error`}
          errorDetails={{
            humanErrorMessage: formattedHtmlString(error.humanErrorMessage),
            rawErrorMessage: formattedHtmlString(error.rawErrorMessage),
          }}
          eventContext={Contexts.BOLT}
        />
      )
    }
  </>
);

export default Errors;
