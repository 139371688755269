/* eslint-disable no-nested-ternary */
import React, {
  Dispatch, FC, SetStateAction, useEffect, useState,
} from 'react';
import cronstrue from 'cronstrue';
import cronParser from 'cron-parser';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import TabsRadio, { TabData } from '../Scheduler/TabsRadio';
import SectionHeader from './SectionHeader';
import { GqlScheduleNameWithWorkspace } from '../../../client/generated/service';
import { CronDropdown } from './CronDropdown';
import { RunCompletionDropdown } from './RunCompletionDropdown';
import GithubAppStatusChip from './GithubAppStatusChip';
import { ConnectionStatus } from './SlackNotify';
import { ScheduleKindType } from '..';

export interface ScheduleBlockProps {
  scheduleCron: string,
  setScheduleCron: Dispatch<SetStateAction<string>>,
  exposeIsValid?: (isValid: boolean) => void,
  scheduleWorkspaceName: string,
  setScheduleWorkspaceName: Dispatch<SetStateAction<string>>,
  boltScheduleName: string,
  setBoltScheduleName: Dispatch<SetStateAction<string>>,
  scheduleNamesByWorkspace: GqlScheduleNameWithWorkspace[],
  scheduleOnTriggerCompleteStatuses: string[],
  setScheduleOnTriggerCompleteStatuses: Dispatch<SetStateAction<string[]>>,
  triggerType: TRIGGER_TYPE_TAB_ID,
  setTriggerType: Dispatch<SetStateAction<TRIGGER_TYPE_TAB_ID>>,
  githubAppConnectionStatus?: ConnectionStatus,
  scheduleType: ScheduleKindType,
}

const nonStandardCronSchedules = {
  '@annually': 'At 00:00 on 1st January every year',
  '@yearly': 'At 00:00 on 1st January every year',
  '@monthly': 'At 00:00 on 1st day every month',
  '@weekly': 'At 00:00 hour every Sunday',
  '@daily': 'At 00:00 hour every day',
  '@hourly': 'At minute 0 every hour',
  '@reboot': 'After rebooting',
} as {[k: string]: string};

const MAX_CRON_EXECUTIONS_PER_HOUR = 6;

export enum TRIGGER_TYPE_TAB_ID {
  SCHEDULED_RUN = 'scheduled_run',
  ON_RUN_COMPLETION = 'on_run_completion',
  ON_MERGE = 'on_merge',
}

const ScheduleBlock:FC<ScheduleBlockProps> = ({
  scheduleCron,
  setScheduleCron,
  exposeIsValid,
  scheduleWorkspaceName,
  setScheduleWorkspaceName,
  boltScheduleName,
  setBoltScheduleName,
  scheduleNamesByWorkspace,
  scheduleOnTriggerCompleteStatuses,
  setScheduleOnTriggerCompleteStatuses,
  triggerType,
  setTriggerType,
  githubAppConnectionStatus,
  scheduleType,
}) => {
  const [humanReadableCron, setHumanReadableCron] = useState<{ isValid: boolean, desc: string}>();
  const [showCronSuggestion, setShowCronSuggestion] = useState(false);
  const [boltSchedulesForCurrentWorkspace, setBoltSchedulesForCurrentWorkspace] = useState<
    GqlScheduleNameWithWorkspace[]>([]);

  const TRIGGER_TYPE_TABS: TabData[] = [
    {
      id: TRIGGER_TYPE_TAB_ID.SCHEDULED_RUN,
      title: 'Scheduled Run',
      subtitle: 'Triggers based on a cron schedule',
      view: 'flat',
    },
    {
      id: TRIGGER_TYPE_TAB_ID.ON_RUN_COMPLETION,
      title: 'On Run completion',
      subtitle: 'Triggers when another Bolt schedule finishes',
      view: 'flat',
    },
    {
      id: TRIGGER_TYPE_TAB_ID.ON_MERGE,
      title: 'On merge',
      subtitle: 'Triggers when a pull request is merged to the branch',
      view: 'flat',
      visible: scheduleType !== 'turbo_ci',
    },
  ];

  const handleCronSchedule = (cronSchedule: string) => {
    try {
      const interval = cronParser.parseExpression(cronSchedule);
      const cronFields = JSON.parse(JSON.stringify(interval.fields));
      setHumanReadableCron({
        isValid: true,
        desc: cronstrue.toString(cronSchedule),
      });
      setScheduleCron(cronSchedule);
      setShowCronSuggestion(cronFields.minute.length > MAX_CRON_EXECUTIONS_PER_HOUR);
    } catch (err) {
      setScheduleCron(cronSchedule);
      setShowCronSuggestion(false);

      const cronNonStandardIsValid = Object.keys(nonStandardCronSchedules).includes(cronSchedule);
      const cronOffIsValid = cronSchedule === 'OFF';

      setHumanReadableCron({
        isValid: cronOffIsValid || cronNonStandardIsValid,
        desc: cronOffIsValid
          ? 'Schedule is off'
          : (
            cronNonStandardIsValid
              ? nonStandardCronSchedules[cronSchedule]
              : 'Invalid cron schedule format'
          ),
      });
    }
  };

  useEffect(() => {
    handleCronSchedule(scheduleCron);
    if (humanReadableCron) {
      if (exposeIsValid) exposeIsValid(humanReadableCron.isValid);
    }
  }, [scheduleCron]);

  useEffect(() => {
    if (humanReadableCron) {
      if (exposeIsValid) exposeIsValid(humanReadableCron.isValid);
    }
  }, [humanReadableCron]);

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      distribution="packed"
      alignment="top-left"
      verticalGap="very-dense"
    >
      <SectionHeader
        icon="calendar"
        header="Execution time"
        description="Specify the time when the schedule will run."
        help={{
          text: 'Learn more',
          onClick: () => window.open('https://docs.paradime.io/app-help/bolt-scheduler/create-your-first-dbt-tm-schedule', '_blank'),
        }}
      />

      <Typography
        color="default"
        colorStep="50"
        type="caption"
      >
        Trigger type
      </Typography>
      <TabsRadio
        data={TRIGGER_TYPE_TABS}
        currentTabId={triggerType}
        exposeOnChange={(newCurrentTabId) => {
          setTriggerType(newCurrentTabId as TRIGGER_TYPE_TAB_ID);
        }}
      />

      {triggerType === TRIGGER_TYPE_TAB_ID.SCHEDULED_RUN && (
        <CronDropdown
          handleCronSchedule={handleCronSchedule}
          scheduleCron={scheduleCron}
          humanReadableCron={humanReadableCron}
          showCronSuggestion={showCronSuggestion}
        />
      )}

      {triggerType === TRIGGER_TYPE_TAB_ID.ON_RUN_COMPLETION && (
        <RunCompletionDropdown
          scheduleWorkspaceName={scheduleWorkspaceName}
          setScheduleWorkspaceName={setScheduleWorkspaceName}
          boltScheduleName={boltScheduleName}
          boltSchedulesForCurrentWorkspace={boltSchedulesForCurrentWorkspace}
          setBoltScheduleName={setBoltScheduleName}
          scheduleOnTriggerCompleteStatuses={scheduleOnTriggerCompleteStatuses}
          setScheduleOnTriggerCompleteStatuses={setScheduleOnTriggerCompleteStatuses}
          scheduleNamesByWorkspace={scheduleNamesByWorkspace}
          setBoltSchedulesForCurrentWorkspace={setBoltSchedulesForCurrentWorkspace}
        />
      )}

      {triggerType === TRIGGER_TYPE_TAB_ID.ON_MERGE && (
        <GithubAppStatusChip githubAppConnectionStatus={githubAppConnectionStatus} />
      )}
    </AutoLayout>
  );
};

export default ScheduleBlock;
