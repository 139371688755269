import React, { FC, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import CodeBox from '@paradime-io/pragma-ui-kit/lib/components/CodeBox';
import * as S from '../Bolt.styles';

export interface CommandCodeBoxProps {
  lines: string[],
}

const CommandCodeBox:FC<CommandCodeBoxProps> = ({
  lines,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [hover, setHover] = useState(false);

  return (
    <AutoLayout
      direction="vertical"
      distribution="packed"
      padding="none"
      verticalGap="ultra-dense"
      alignment="top-left"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <AutoLayout
        direction="horizontal"
        distribution="space-between"
        padding="none"
        style={{ alignItems: 'center' }}
      >
        <Typography type="overline-small" color="default" colorStep="40">Commands</Typography>
        <S.SmallIconChip
          color="primary_alt"
          round={false}
          tag="Expand"
          type="dense"
          icon="maximize"
          view="smooth"
          onClick={() => setExpanded(true)}
        />
      </AutoLayout>
      <S.CommandCodeBoxWrapper>
        <CodeBox
          backgroundColor="var(--grey0)"
          colorStep="60"
          lines={lines}
          maxHeight={64}
          style={{
            overflow: hover ? 'auto' : 'hidden',
            whiteSpace: 'nowrap',
          }}
        />
      </S.CommandCodeBoxWrapper>
      <Dialog
        header="Schedule Commands"
        visible={expanded}
        closeOnEscape
        dismissableMask
        resizable
        style={{ width: '80vw', height: '500px' }}
        onHide={() => setExpanded(false)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            height: '100%',
            width: '100%',
          }}
        >
          <S.DialogCodeBoxWrapper>
            <CodeBox
              backgroundColor="var(--grey0)"
              colorStep="60"
              lines={lines}
              maxHeight={500}
              style={{ overflowY: 'auto', height: '100%' }}
            />
          </S.DialogCodeBoxWrapper>
        </div>
      </Dialog>
    </AutoLayout>
  );
};

export default CommandCodeBox;
