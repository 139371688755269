import { catalogueItemTabsVisibilityProps } from '../types';

export const catalogueItemTabsVisibility = ({
  resultItem,
}: catalogueItemTabsVisibilityProps) => {
  switch (resultItem?.__typename) {
    case 'GQLCatalogueLookerDashboardItem':
      return ({
        overview: true,
        fields: false,
        metrics: false,
        columns: false,
        dependencies: true,
        recordings: true,
        schema: false,
        model: false,
      });
    case 'GQLCatalogueLookerExploreItem':
    case 'GQLCatalogueLookerLookItem':
      return ({
        overview: true,
        fields: true,
        metrics: false,
        columns: false,
        dependencies: true,
        recordings: true,
        schema: false,
        model: false,
      });
    default:
      return ({
        overview: false,
        fields: false,
        metrics: false,
        columns: false,
        dependencies: false,
        recordings: false,
        schema: false,
        model: false,
      });
  }
};
