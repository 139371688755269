import React, { FC, useRef } from 'react';
import { DateTime } from 'luxon';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import Card from '@paradime-io/pragma-ui-kit/lib/components/Card';
import { RunType } from '../Schedule';
import ExecutionsChart, { ScheduleRunDataType } from './ExecutionsChart';
import { useGetChartDimensions } from './useGetChartDimensions';
import { findTimeBetweenSeconds } from '../../../../utilis';
import InsightsChartHeader from './InsightsChartHeader';

export type RunStatusCountsType = {
  success: number,
  error: number,
  skipped: number,
  total: number,
}

interface ScheduleInsightsProps {
  scheduleId: string,
  runs: RunType[],
}

const ScheduleInsights:FC<ScheduleInsightsProps> = ({
  scheduleId,
  runs,
}) => {
  const chartRef = useRef(null);
  const chartDimensions = useGetChartDimensions({
    elementRef: chartRef,
    config: {
      BASE_CHART_WIDTH: 640,
      BASE_CHART_HEIGHT: 200,
      BASE_AXIS_LABEL_FONTSIZE: 6,
      MIN_AXIS_LABEL_FONTSIZE: 1,
      BASE_TICK_LABEL_FONTSIZE: 5,
      MIN_TICK_LABEL_FONTSIZE: 1,
      BASE_TOOLTIP_LABEL_FONTSIZE: 6,
      MIN_TOOLTIP_LABEL_FONTSIZE: 1,
      BASE_TOOLTIP_FLYOUT_WIDTH: 125,
      MIN_TOOLTIP_FLYOUT_WIDTH: 45,
      BASE_TOOLTIP_FLYOUT_HEIGHT: 40,
      MIN_TOOLTIP_FLYOUT_HEIGHT: 15,
    },
  });

  const getColorString = (c: colorType) => {
    switch (c) {
      case 'success':
        return 'var(--green60)';
      case 'warning':
        return 'var(--yellow60)';
      case 'danger':
        return 'var(--red60)';
      default:
        return 'var(--grey60)';
    }
  };

  const getAxisDomain = (data: ScheduleRunDataType[]) => {
    if (data.length !== 1) return undefined;

    const xD: [Date, Date] = [
      DateTime.fromJSDate(data[0].x).plus({ minute: -1 }).toJSDate(),
      DateTime.fromJSDate(data[0].x).plus({ minute: 1 }).toJSDate(),
    ];
    const yD: [number, number] = (data[0].y !== '-' && data[0].y) ? [data[0].y - 1, data[0].y + 1] : [-1, 1];

    return { x: xD, y: yD };
  };

  const statusCounts: RunStatusCountsType = {
    success: 0,
    error: 0,
    skipped: 0,
    total: 0,
  };
  const todayMinus30Days = DateTime.now().minus({ days: 30 });
  const chartData: ScheduleRunDataType[] = [];
  runs.forEach((r, index) => {
    const endDttm = DateTime.fromISO(r.endDttm || '');

    if (endDttm.toMillis() > todayMinus30Days.toMillis() && r.endDttm) {
      switch (r.state.text) {
        case 'Success':
          statusCounts.success += 1;
          break;
        case 'Error':
          statusCounts.error += 1;
          break;
        default:
          statusCounts.skipped += 1;
      }
      statusCounts.total += 1;

      // last 7 runs status color, oldest is first
      const last7runs = runs.slice(index - 7, index).map((previousRun) => ({
        color: getColorString(previousRun.state.colorType as colorType),
      })).reverse();

      chartData.push({
        x: new Date(r.startDttm),
        y: findTimeBetweenSeconds({
          startDate: r.startDttm,
          endDate: r.endDttm,
        }),
        fill: getColorString(r.state.colorType as colorType),
        meta: {
          runId: r.meta.id,
          runStartedOn: r.startDttm,
          runEndedOn: r.endDttm,
          gitBranch: r.branch,
          gitCommitSha: r.commit.hash,
          last7runs,
        },
        path: `/bolt/${scheduleId}/${r.meta.uuid}`,
      });
    }
  });

  return (
    <AutoLayout
      direction="vertical"
      distribution="packed"
      verticalGap="normal"
      alignment="top-left"
      padding="none"
    >
      <div ref={chartRef}>
        <Card
          tabIndex={-1}
          padding="dense"
          style={{
            height: '500px',
            width: '100%',
            gridTemplateRows: 'min-content min-content auto',
          }}
        >
          <Typography tagName="span" type="body-bold">
            Execution Time History (Last 30 days)
          </Typography>
          <InsightsChartHeader data={statusCounts} />
          <ExecutionsChart
            data={chartData}
            dimensions={chartDimensions}
            domain={getAxisDomain(chartData)}
          />
        </Card>
      </div>

    </AutoLayout>
  );
};

export default ScheduleInsights;
