import { gql } from '@apollo/client';
import { ColumnType } from '@paradime-io/pragma-ui-kit/lib/components/PrimeReactDataTable';
import { getStatusColour, getTableChips, scheduleStatuses } from '../utils';

export interface scheduleNamesForDropdownReturnType {
  cube: {
    scheduleRecentRuns: {
      boltScheduleName: string,
    }
  }[]
}

export const scheduleNamesForDropdownQuery = gql`
  query scheduleNamesForDropdownQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {schedule_recent_runs: {calendar_date: {inDateRange: $timeframe}}}
    ) {
      scheduleRecentRuns: schedule_recent_runs {
        boltScheduleName: bolt_schedule_name
      }
    }
  }
`;

export const formatScheduleNamesForDropdown = (data?: scheduleNamesForDropdownReturnType) => {
  if (!data) return [];

  return data.cube
    .map(({ scheduleRecentRuns }) => ({
      label: scheduleRecentRuns.boltScheduleName,
      value: scheduleRecentRuns.boltScheduleName,
    }));
};

export interface successRateReturnType {
  cube: {
    scheduleRunSuccessRate: {
      boltScheduleRunSuccessRate: number,
    }
  }[]
}

export const successRateQuery = gql`
  query successRateQuery($timeframe: [String], $scheduleName: String) {
    cube(
      limit: 5000
      where: {schedule_run_success_rate: {bolt_schedule_name: {equals: $scheduleName}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      scheduleRunSuccessRate: schedule_run_success_rate {
        boltScheduleRunSuccessRate: bolt_schedule_run_success_rate
      }
    }
  }
`;

export interface completedRunsCountReturnType {
  cube: {
    scheduleTotalRuns: {
      countBoltScheduleRunId: number,
    }
  }[]
}

export const completedRunsCountQuery = gql`
  query completedRunsCount($timeframe: [String], $scheduleName: String) {
    cube(
      limit: 5000
      where: {schedule_total_runs: {bolt_schedule_name: {equals: $scheduleName}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      scheduleTotalRuns: schedule_total_runs {
        countBoltScheduleRunId: count_bolt_schedule_run_id
      }
    }
  }
`;

export interface successfulRunsCountReturnType {
  cube: {
    scheduleTotalSuccessfulRuns: {
      countSuccessBoltScheduleRun: number,
    }
  }[]
}

export const successfulRunsCountQuery = gql`
  query successfulRunsCountQuery($timeframe: [String], $scheduleName: String) {
    cube(
      limit: 5000
      where: {schedule_total_successful_runs: {bolt_schedule_name: {equals: $scheduleName}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      scheduleTotalSuccessfulRuns: schedule_total_successful_runs {
        countSuccessBoltScheduleRun: count_success_bolt_schedule_run
      }
    }
  }
`;

export interface erroredRunsCountReturnType {
  cube: {
    scheduleTotalErrorRuns: {
      countErrorBoltScheduleRun: number,
    }
  }[]
}

export const erroredRunsCountQuery = gql`
  query erroredRunsCountQuery($timeframe: [String], $scheduleName: String) {
    cube(
      limit: 5000
      where: {schedule_total_error_runs: {bolt_schedule_name: {equals: $scheduleName}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      scheduleTotalErrorRuns: schedule_total_error_runs {
        countErrorBoltScheduleRun: count_error_bolt_schedule_run
      }
    }
  }
`;

export interface runDurationsReturnType {
  cube: {
    scheduleRunTimeline: {
      boltScheduleRuntimeMinutes: number,
      boltScheduleRunStatus: 'Success' | 'Error',
      boltScheduleRunEndDttm: {
        minute: string,
      }
    }
  }[]
}

export const runDurationsCountQuery = gql`
  query runDurationsCountQuery($timeframe: [String], $scheduleName: String) {
    cube(
      limit: 5000
      where: {schedule_run_timeline: {bolt_schedule_name: {equals: $scheduleName}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      scheduleRunTimeline: schedule_run_timeline(orderBy: {bolt_schedule_run_end_dttm: asc}) {
        boltScheduleRuntimeMinutes: bolt_schedule_runtime_minutes
        boltScheduleRunStatus: bolt_schedule_run_status
        boltScheduleRunEndDttm: bolt_schedule_run_end_dttm {
          minute
        }
      }
    }
  }
`;

export const formatRunDurationsCountForChart = (data?: runDurationsReturnType) => {
  if (!data) return { data: [], statuses: [], colours: [] };

  const dataToReturn: { x: string, y: number }[][] = [];
  const legendLabels: string[] = [];
  const legendColours: string[] = [];

  scheduleStatuses.forEach((status) => {
    const tests = data.cube
      .filter(({ scheduleRunTimeline }) => scheduleRunTimeline.boltScheduleRunStatus === status)
      .map(({ scheduleRunTimeline }) => ({
        x: scheduleRunTimeline.boltScheduleRunEndDttm.minute,
        y: scheduleRunTimeline.boltScheduleRuntimeMinutes,
      }));

    if (tests.length > 0) {
      dataToReturn.push(tests);
      legendLabels.push(status);
      legendColours.push(getStatusColour(status));
    }
  });

  return { data: dataToReturn, statuses: legendLabels, colours: legendColours };
};

export interface topTenDurationReturnType {
  cube: {
    scheduleAverageModelExecutionTime: {
      avgExecutionTime: number,
      modelName: string,
    }
  }[]
}

export const topTenDurationQuery = gql`
  query topTenDurationQuery($timeframe: [String], $scheduleName: String) {
    cube(
      limit: 10
      where: {schedule_average_model_execution_time: {bolt_schedule_name: {equals: $scheduleName}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      scheduleAverageModelExecutionTime: schedule_average_model_execution_time(
        orderBy: {avg_execution_time: desc}
      ) {
        avgExecutionTime: avg_execution_time
        modelName: model_name
      }
    }
  }
`;

export const formatTopTenDurationsForChart = (data?: topTenDurationReturnType) => {
  if (!data) return [];

  return [data.cube.map(({ scheduleAverageModelExecutionTime }) => ({
    x: scheduleAverageModelExecutionTime.modelName,
    y: scheduleAverageModelExecutionTime.avgExecutionTime,
  }))];
};

export const getTopTenDurationsXAxisLabels = (data?: topTenDurationReturnType) => {
  if (!data) return [];

  const modelNames = data.cube
    .map(({ scheduleAverageModelExecutionTime }) => scheduleAverageModelExecutionTime.modelName);
  return Array.from(new Set(modelNames));
};

export interface currentScheduleStatusReturnType {
  cube: {
    scheduleRecentRuns: {
      boltScheduleRuntimeMinutes: number,
      boltScheduleName: string,
      boltScheduleNameScheduleCron: string,
      boltScheduleNameCommandsJson: string,
      boltScheduleNameOwner: string,
      boltScheduleRunStartDttm: {
        value: string,
      }
      boltScheduleRunEndDttm: {
        value: string,
      }
      boltScheduleRunActor: string,
      boltScheduleRunStatus: string,
    }
  }[]
}

export const currentScheduleStatusQuery = gql`
  query currentScheduleStatusQuery($timeframe: [String], $scheduleName: String) {
    cube(
      limit: 5000
      where: {schedule_recent_runs: {is_schedule_last_run: {equals: "true"}, bolt_schedule_name: {equals: $scheduleName}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      scheduleRecentRuns: schedule_recent_runs {
        boltScheduleRuntimeMinutes: bolt_schedule_runtime_minutes
        boltScheduleName: bolt_schedule_name
        boltScheduleNameScheduleCron: bolt_schedule_name_schedule_cron
        boltScheduleNameCommandsJson: bolt_schedule_name_commands_json
        boltScheduleNameOwner: bolt_schedule_name_owner
        boltScheduleRunStartDttm: bolt_schedule_run_start_dttm {
          value
        }
        boltScheduleRunEndDttm: bolt_schedule_run_end_dttm {
          value
        }
        boltScheduleRunActor: bolt_schedule_run_actor
        boltScheduleRunStatus: bolt_schedule_run_status
      }
    }
  }
`;

export const currentScheduleStatusColumns = [
  { field: 'boltScheduleName', header: 'Schedule name' },
  { field: 'boltScheduleNameOwner', header: 'Schedule owner' },
  { field: 'boltScheduleRunStartDttm', header: 'Schedule run executed', type: ColumnType.DATE },
  { field: 'boltScheduleRunEndDttm', header: 'Schedule run completed', type: ColumnType.DATE },
  { field: 'boltScheduleRunActor', header: 'Schedule run executed by' },
  { field: 'boltScheduleRunStatus', header: 'Schedule run status', type: ColumnType.CHIP },
  { field: 'boltScheduleRuntimeMinutes', header: 'Schedule runtime minutes' },
  { field: 'boltScheduleNameScheduleCron', header: 'Schedule cron' },
].map((col) => ({ ...col, sortable: true }));

export const formatCurrentScheduleStatusForChart = (data?: currentScheduleStatusReturnType) => {
  if (!data) return [];

  return data.cube.map(({ scheduleRecentRuns }) => ({
    ...scheduleRecentRuns,
    boltScheduleRunStartDttm: scheduleRecentRuns.boltScheduleRunStartDttm.value,
    boltScheduleRunEndDttm: scheduleRecentRuns.boltScheduleRunEndDttm.value,
    boltScheduleRunStatus: getTableChips(scheduleRecentRuns.boltScheduleRunStatus),
  }));
};

export interface durationByCommandReturnType {
  cube: {
    scheduleExecutionSummary: {
      executionTime: number,
      runResultArgsInvocationCommand: string,
    }
  }[]
}

export const durationByCommandQuery = gql`
  query durationByCommandQuery($timeframe: [String], $scheduleName: String) {
    cube(
      limit: 5000
      where: {schedule_execution_summary: {is_schedule_last_run: {equals: "true"}, bolt_schedule_name: {equals: $scheduleName}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      scheduleExecutionSummary: schedule_execution_summary {
        executionTime: execution_time
        runResultArgsInvocationCommand: run_result_args_invocation_command
      }
    }
  }
`;

export const formatDurationByCommandForChart = (data?: durationByCommandReturnType) => {
  if (!data) return { data: [], commandNames: [] };

  const commandNames = Array.from(new Set(
    data.cube.map(({ scheduleExecutionSummary }) => (
      scheduleExecutionSummary.runResultArgsInvocationCommand
    )),
  ));

  const dataToReturn: { x: string, y: number }[][] = [];

  data.cube.forEach(({ scheduleExecutionSummary }) => {
    dataToReturn.push([{
      x: ' ',
      y: scheduleExecutionSummary.executionTime,
    }]);
  });

  return { data: dataToReturn, commandNames };
};

export interface durationByModelReturnType {
  cube: {
    scheduleModelDetails: {
      executionTime: number,
      modelName: string,
    }
  }[]
}

export const durationByModelQuery = gql`
  query durationByModelQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {schedule_model_details: {is_schedule_last_run: {set: true}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      scheduleModelDetails: schedule_model_details(orderBy: {execution_time: desc}) {
        executionTime: execution_time
        modelName: model_name
      }
    }
  }
`;

export const formatDurationByModelForChart = (data?: durationByModelReturnType) => {
  if (!data) return [];

  return data.cube.map(({ scheduleModelDetails }) => ({
    x: scheduleModelDetails.modelName,
    y: scheduleModelDetails.executionTime,
  }));
};

export interface modelsInScheduleReturnType {
  cube: {
    scheduleModelDetails: {
      executionTime: number,
      modelNameDatabase: string,
      modelNameSchema: string,
      modelName: string,
      runResultStatus: string,
    }
  }[]
}

export const modelsInScheduleQuery = gql`
  query modelsInScheduleQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {schedule_model_details: {is_schedule_last_run: {set: true}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      scheduleModelDetails: schedule_model_details(orderBy: {execution_time: desc}) {
        executionTime: execution_time
        modelNameDatabase: model_name_database
        modelNameSchema: model_name_schema
        modelName: model_name
        runResultStatus: run_result_status
      }
    }
  }
`;

export const modelsInScheduleColumns = [
  { field: 'modelNameDatabase', header: 'Model database' },
  { field: 'modelNameSchema', header: 'Model schema' },
  { field: 'modelName', header: 'Model name' },
  { field: 'runResultStatus', header: 'Model execution status', type: ColumnType.CHIP },
  { field: 'executionTime', header: 'Model execution time' },
].map((col) => ({ ...col, sortable: true }));

export const formatModelsInScheduleForChart = (data?: modelsInScheduleReturnType) => {
  if (!data) return [];

  return data.cube.map(({ scheduleModelDetails }) => ({
    ...scheduleModelDetails,
    runResultStatus: getTableChips(scheduleModelDetails.runResultStatus),
  }));
};
