import { useEffect, useState } from 'react';
import {
  useGetSlackUsersLazyQuery,
  GetSlackUsersQuery,
  useGetSlackChannelsLazyQuery,
  GetSlackChannelsQuery,
} from '../../../client/generated/service-dataEndpoint';

type SlackUsersType = NonNullable<NonNullable<GetSlackUsersQuery['getSlackUsers']>['users']>[number];
type SlackChannelsType = NonNullable<NonNullable<GetSlackChannelsQuery['getSlackChannels']>['channels']>[number];

export const useGetSlackUsersChannels = () => {
  const [slackUsers, setSlackUsers] = useState<string[]>();
  const [slackChannels, setSlackChannels] = useState<string[]>();
  const [fetchSlackUsers] = useGetSlackUsersLazyQuery({
    onCompleted: (data) => {
      if (data.getSlackUsers.users) {
        setSlackUsers(data.getSlackUsers.users.map((u: SlackUsersType) => `@${u.name}`));
      }
    },
  });
  const [fetchSlackChannels] = useGetSlackChannelsLazyQuery({
    onCompleted: (data) => {
      if (data.getSlackChannels.channels) {
        setSlackChannels(data.getSlackChannels.channels.map((c: SlackChannelsType) => `#${c.name}`));
      }
    },
  });
  const [loading, setLoading] = useState(true);
  const [slackUsersAndChannels, setSlackUsersAndChannels] = useState<string[]>();

  useEffect(() => {
    if (slackUsers && slackChannels) {
      setSlackUsersAndChannels([...slackUsers, ...slackChannels]);
      setLoading(false);
    }
  }, [slackUsers, slackChannels]);

  const fetchSlackUsersChannels = ({ refresh }: {refresh:boolean}) => {
    setLoading(true);
    fetchSlackUsers({ variables: { refresh } });
    fetchSlackChannels({ variables: { refresh } });
  };

  const getFilteredSlackUsersChannels = (searchQuery?: string) => {
    if (slackUsersAndChannels) {
      if (searchQuery) {
        return slackUsersAndChannels.filter(
          (s) => s.includes(searchQuery),
        );
      }
      return slackUsersAndChannels;
    }
    return [];
  };

  return {
    loading,
    fetchSlackUsersChannels,
    getFilteredSlackUsersChannels,
  };
};
