import React, { FC } from 'react';
import ZeroState from '@paradime-io/pragma-ui-kit/lib/components/ZeroState';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { useHeaderContext } from '../useHeaderContext';
import { subtitleTypes } from '../Header';

const LoadingSyncsZeroState: FC = () => {
  useHeaderContext({
    subtitleType: subtitleTypes.BLANK,
    buttonType: '',
    buttonIsVisible: false,
    showHeader: false,
  });

  return (
    <ZeroState
      showProgressAnimation
      text={(
        <AutoLayout
          padding="none"
          direction="vertical"
          verticalGap="super-ultra-dense"
          alignment="center"
          wrapperStyle={{ justifyContent: 'center' }}
          style={{ width: '300px' }}
        >
          <Typography
            type="body-bold-small"
            style={{ textAlign: 'center' }}
            color="default"
            colorStep="100"
          >
            Sync in progress
          </Typography>
          <Typography
            type="caption"
            style={{ textAlign: 'center' }}
            color="default"
            colorStep="70"
          >
            Please keep this tab open while your models are synced to ThoughtSpot.
            Syncing will continue if you close Paradime at this time.
          </Typography>
        </AutoLayout>
      )}
    />
  );
};

export default LoadingSyncsZeroState;
