import React, { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import {
  StackedBarChart,
  PieChart,
  SingleValueChart,
  TableChart,
  GroupedBarChart,
  AreaChart,
} from '@paradime-io/pragma-ui-kit/lib/components/VictoryCharts';
import { useCubeLazyQuery } from '../../../app/hooks/useCubeQuery';
import {
  sourceNamesForDropdownQuery,
  sourceNamesForDropdownReturnType,
  formatSourceNamesForDropdown,
  freshnessPassRateQuery,
  freshnessPassRateReturnType,
  formatFreshnessPassRateForChart,
  getFreshnessPassRateXAxisLabels,
  freshnessByStatusQuery,
  freshnessByStatusReturnType,
  formatFreshnessByStatusForChart,
  getFreshnessByStatusXAxisLabels,
  countStaleQuery,
  countStaleReturnType,
  formatCountStaleForChart,
  getCountStaleXAxisLabels,
  countWarnQuery,
  countWarnReturnType,
  formatCountWarnForChart,
  getCountWarnXAxisLabels,
  currentFreshnessStatusQuery,
  currentFreshnessStatusReturnType,
  formatCurrentFreshnessStatusChart,
  currentFreshnessHealthQuery,
  currentFreshnessHealthReturnType,
  freshnessStatusQuery,
  freshnessStatusReturnType,
  freshnessStatusColumns,
  formatFreshnessStatusForChart,
} from './DetailsCubeQueries';
import {
  timeframes,
  HalfPageChartSection,
  FullPageChartSection,
  getSingleValueChartString,
  getFormattedYAxisLabel,
  getStatusColour,
} from '../utils';
import FilterRow from '../FilterRow';
import { useGetFiltersFromUrl } from '../hooks/useGetFiltersFromUrl';

const DetailsTab: FC = () => {
  const { getInitialFilterValue } = useGetFiltersFromUrl();

  const [timeframe, setTimeframe] = useState(getInitialFilterValue('timeframe', timeframes[2].value)!);
  const [sourceName, setSourceName] = useState(getInitialFilterValue('sourceName', ' ')!);

  const [
    getSourceNamesForDropdown,
    { data: sourceNamesForDropdown, loading: isLoadingSourceNamesForDropdown },
  ] = useCubeLazyQuery<sourceNamesForDropdownReturnType>({
    query: sourceNamesForDropdownQuery,
    variables: { timeframe: timeframe.value },
    onCompleted: ({ data }) => {
      const firstOption = data.cube?.[0]?.sourceDetails?.sourceName;
      const urlVal = getInitialFilterValue('sourceName', firstOption);
      if (urlVal) setSourceName(urlVal);
    },
  });

  const [
    getFreshnessPassRate, {
      data: freshnessPassRateData,
      loading: isLoadingFreshnessPassRate,
      error: freshnessPassRateError,
    },
  ] = useCubeLazyQuery<freshnessPassRateReturnType>({
    query: freshnessPassRateQuery,
    variables: { timeframe: timeframe.value, sourceName: sourceName.value },
  });
  const [
    getFreshnessByStatus, {
      data: freshnessByStatusData,
      loading: isLoadingFreshnessByStatus,
      error: freshnessByStatusError,
    },
  ] = useCubeLazyQuery<freshnessByStatusReturnType>({
    query: freshnessByStatusQuery,
    variables: { timeframe: timeframe.value, sourceName: sourceName.value },
  });
  const [
    getCountStale, { data: countStaleData, loading: isLoadingCountStale, error: countStaleError },
  ] = useCubeLazyQuery<countStaleReturnType>({
    query: countStaleQuery,
    variables: { timeframe: timeframe.value, sourceName: sourceName.value },
  });
  const [
    getCountWarn, { data: countWarnData, loading: isLoadingCountWarn, error: countWarnError },
  ] = useCubeLazyQuery<countWarnReturnType>({
    query: countWarnQuery,
    variables: { timeframe: timeframe.value, sourceName: sourceName.value },
  });
  const [
    getCurrentState,
    { data: currentStateData, loading: isLoadingCurrentState, error: currentStateError },
  ] = useCubeLazyQuery<currentFreshnessStatusReturnType>({
    query: currentFreshnessStatusQuery,
    variables: { timeframe: timeframe.value, sourceName: sourceName.value },
  });
  const [
    getCurrentHealth,
    { data: currentHealthData, loading: isLoadingCurrentHealth, error: currentHealthError },
  ] = useCubeLazyQuery<currentFreshnessHealthReturnType>({
    query: currentFreshnessHealthQuery,
    variables: { timeframe: timeframe.value, sourceName: sourceName.value },
  });
  const [
    getFreshnessStatus,
    { data: freshnessStatusData, loading: isLoadingFreshnessStatus, error: freshnessStatusError },
  ] = useCubeLazyQuery<freshnessStatusReturnType>({
    query: freshnessStatusQuery,
    variables: { timeframe: timeframe.value, sourceName: sourceName.value },
  });

  const refetchAllData = () => {
    getFreshnessPassRate();
    getFreshnessByStatus();
    getCountStale();
    getCountWarn();
    getCurrentState();
    getCurrentHealth();
    getFreshnessStatus();
  };

  useEffect(() => {
    if (sourceName.value !== ' ') {
      refetchAllData();
    }
  }, [timeframe, sourceName]);

  useEffect(() => {
    getSourceNamesForDropdown();
  }, [timeframe]);

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      verticalGap="dense"
      distribution="packed"
    >
      <FilterRow
        dropdowns={[
          {
            value: timeframe,
            options: timeframes,
            onChange: setTimeframe,
            name: 'timeframe',
            title: 'Select date range',
          },
          {
            value: sourceName,
            options: formatSourceNamesForDropdown(sourceNamesForDropdown),
            onChange: setSourceName,
            isLoading: isLoadingSourceNamesForDropdown,
            name: 'sourceName',
            title: 'Select a source',
          },
        ]}
      />
      <div style={{
        display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: '16px',
      }}
      >
        <HalfPageChartSection>
          <PieChart
            title="Current tables freshness status"
            data={formatCurrentFreshnessStatusChart(currentStateData).data}
            legendLabels={formatCurrentFreshnessStatusChart(currentStateData).statuses}
            customColourScale={formatCurrentFreshnessStatusChart(currentStateData).colours}
            isLoading={isLoadingCurrentState}
            hasNoData={currentStateError || currentStateData?.cube?.length === 0}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <SingleValueChart
            title="Current Source Freshness Health"
            value={(
              getSingleValueChartString(
                currentHealthData?.cube?.[0]?.sourceStatusDaily?.successRate || 0,
                'percent',
              )
            )}
            isLoading={isLoadingCurrentHealth}
            hasNoData={currentHealthError || currentHealthData?.cube?.length === 0}
          />
        </HalfPageChartSection>
        <FullPageChartSection style={{ height: '100%' }}>
          <TableChart
            title="Data source freshness status"
            isLoading={isLoadingFreshnessStatus}
            hasNoData={freshnessStatusError || freshnessStatusData?.cube?.length === 0}
            columns={freshnessStatusColumns}
            data={formatFreshnessStatusForChart(freshnessStatusData)}
            rowsPerPage={5}
          />
        </FullPageChartSection>
        <HalfPageChartSection>
          <AreaChart
            title="Source tables freshness pass rate overtime"
            data={formatFreshnessPassRateForChart(freshnessPassRateData)}
            xAxisLabels={getFreshnessPassRateXAxisLabels(freshnessPassRateData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick, 'compact', 'percent')}
            xLabel="Calendar date"
            yLabel="Pass rate (%)"
            isLoading={isLoadingFreshnessPassRate}
            hasNoData={freshnessPassRateError || freshnessPassRateData?.cube?.length === 0}
            yAxisDomain={[0, 1.0]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick, 'standard', 'percent'),
            }}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <GroupedBarChart
            title="Source tables freshness results daily by status"
            data={formatFreshnessByStatusForChart(freshnessByStatusData).data}
            xAxisLabels={getFreshnessByStatusXAxisLabels(freshnessByStatusData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Calendar date"
            yLabel="Count tables"
            legendLabels={formatFreshnessByStatusForChart(freshnessByStatusData).statuses}
            customColourScale={formatFreshnessByStatusForChart(freshnessByStatusData).colours}
            isLoading={isLoadingFreshnessByStatus}
            hasNoData={freshnessByStatusError || freshnessByStatusData?.cube?.length === 0}
            xAxisDomain={[
              0.5,
              getFreshnessByStatusXAxisLabels(freshnessByStatusData).length + 1,
            ]}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Source tables freshness results count | Warn"
            horizontal
            data={formatCountWarnForChart(countWarnData)}
            xAxisLabels={getCountWarnXAxisLabels(countWarnData)}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            customColourScale={[getStatusColour('warn')]}
            xLabel="Source table name"
            yLabel="Count tables"
            isLoading={isLoadingCountWarn}
            hasNoData={countWarnError || countWarnData?.cube?.length === 0}
            maxCharsAxisX={6}
            xAxisDomain={[
              0.5,
              getCountWarnXAxisLabels(countWarnData).length + 1,
            ]}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Source tables freshness results count | Stale"
            horizontal
            data={formatCountStaleForChart(countStaleData)}
            xAxisLabels={getCountStaleXAxisLabels(countStaleData)}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            customColourScale={[getStatusColour('fail')]}
            xLabel="Source table name"
            yLabel="Count tables"
            isLoading={isLoadingCountStale}
            hasNoData={countStaleError || countStaleData?.cube?.length === 0}
            maxCharsAxisX={6}
            xAxisDomain={[
              0.5,
              getCountStaleXAxisLabels(countStaleData).length + 1,
            ]}
          />
        </HalfPageChartSection>
      </div>
    </AutoLayout>
  );
};

export default DetailsTab;
