import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Checklist, FrigadeJS } from '@frigade/react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import { useGetSetupState } from './hooks/useGetSetupState';
import { RadarFlows } from '../Common/Frigade/flows';
import { AccountSettingsTab } from '../NavBar';
import { BoltTab } from '../Bolt';

export enum RadarSetupSteps {
  SETUP_PROD_WAREHOUSE = 'setup-prod-warehouse-connection',
  CREATE_BOLT_SCHEDULE = 'create-bolt-schedule',
  CONNECT_GITHUB = 'connect-to-github',
  SETUP_COST_CONNECTION = 'setup-snowflake-cost-connection',
}

const Setup:FC = () => {
  const history = useHistory();
  const { isLoadingSetupState } = useGetSetupState();

  const handleFlowPrimaryButtonClick = (step: FrigadeJS.FlowStep) => {
    switch (step.id) {
      case RadarSetupSteps.SETUP_PROD_WAREHOUSE:
        history.push(`/account-settings/${AccountSettingsTab.CONNECTIONS}`);
        break;
      case RadarSetupSteps.CREATE_BOLT_SCHEDULE:
        history.push(`/bolt/${BoltTab.BOLT}/new-schedule`);
        break;
      case RadarSetupSteps.CONNECT_GITHUB:
        history.push(
          `/account-settings/${AccountSettingsTab.INTEGRATIONS}`,
          { highlightId: 'github-integration' },
        );
        break;
      case RadarSetupSteps.SETUP_COST_CONNECTION:
        history.push(`/account-settings/${AccountSettingsTab.CONNECTIONS}`);
        break;
      default:
        break;
    }

    return Promise.resolve(true);
  };

  const handleFlowSecondaryButtonClick = (step: FrigadeJS.FlowStep) => {
    switch (step.id) {
      case RadarSetupSteps.CONNECT_GITHUB:
        window.open('https://docs.paradime.io/app-help/integrations/github', '_blank');
        break;
      case RadarSetupSteps.SETUP_COST_CONNECTION:
        window.open('https://docs.paradime.io/app-help/connections/connect-to-your-data-warehouse/cost-connection/snowflake-connection', '_blank');
        break;
      default:
        break;
    }

    return Promise.resolve(true);
  };

  if (isLoadingSetupState) {
    return (
      <div style={{ marginTop: '84px' }}>
        <Spinner width={32} height={32} position="center" thin />
      </div>
    );
  }

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      distribution="packed"
      alignment="top-left"
      verticalGap="expanded"
    >
      <Checklist.Collapsible
        flowId={RadarFlows.SETUP_FLOW}
        type="inline"
        checklistStyle="condensed"
        autoExpandNextStep={false}
        style={{
          boxShadow: 'none',
          border: '1px solid var(--grey5)',
        }}
        onPrimary={handleFlowPrimaryButtonClick}
        onSecondary={handleFlowSecondaryButtonClick}
        forceMount
      />
    </AutoLayout>
  );
};

export default Setup;
