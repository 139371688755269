import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import WrenchIcon from './images/wrench.svg';

const ZeroStateErrorFetchingData: FC = () => (
  <AutoLayout
    direction="vertical"
    padding="none"
    verticalGap="dense"
    distribution="packed"
    alignment="center"
    style={{ padding: '40px 32px' }}
  >
    <img src={WrenchIcon} alt="" style={{ height: '40px', width: '40px', margin: 'auto' }} />
    <Typography
      font="inter"
      type="body-bold-small"
      color="default"
      colorStep="100"
      style={{ textAlign: 'center' }}
    >
      Unable to load insight
    </Typography>
  </AutoLayout>
);

export default ZeroStateErrorFetchingData;
