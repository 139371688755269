import React, { FC, useEffect, useState } from 'react';
import yaml from 'js-yaml';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultInput from '@paradime-io/pragma-ui-kit/lib/components/DefaultInput';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import Dropdown from '@paradime-io/pragma-ui-kit/lib/components/Dropdown';
import Callout from '@paradime-io/pragma-ui-kit/lib/components/Callout';
import Switch from '@paradime-io/pragma-ui-kit/lib/components/Switch';
import ErrorCallout from '@paradime-io/pragma-ui-kit/lib/components/ErrorCallout';
import { MenuTemplate } from '@paradime-io/pragma-ui-kit/lib/components/Menu';
import { MenuTemplateItem } from '@paradime-io/pragma-ui-kit/lib/components/Menu/MenuTemplate';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { ValidationRules } from '../../../utilis/useCheckValidation';
import { convertKeysToSnakeCase, triggerAlert } from '../../../utilis';
import { alertStore, userAuthStore } from '../../../stores';
import {
  NotifyCondition, ScheduleKindType, ScheduleSource, ScheduleType,
} from '../../Bolt';
import SectionHeader from './SectionHeader';
import ScheduleBlock, { TRIGGER_TYPE_TAB_ID } from './ScheduleBlock';
import ScheduleSeverity from './ScheduleSeverity';
import SlackNotify, { ConnectionStatus } from './SlackNotify';
import EmailNotify from './EmailNotify';
import {
  GqlScheduleNameWithWorkspace,
  useGetAllScheduleNamesWithWorkspaceLazyQuery,
  useOrganisationIntegrationListQuery,
  useRemoveScheduleNameMutation,
  useSetScheduleNameMutation,
} from '../../../client/generated/service';
import { getScheduleType } from '../utils';
import CommandsBlock from './CommandsBlock';
import ScheduleCode from '../Scheduler/ScheduleCode';
import ElementaryCallout from './ElementaryCallout';

export interface ScheduleFormProps {
  scheduleNameEntryId?: number | null,
  initialData?: ScheduleType;
  defaultBranch: string,
  branches: string[],
  scheduleNamesList: string[],
  isTurboCiDefined: boolean,
  onSubmit: () => void,
  onCancel: () => void,
  onDelete?: () => void,
  elementaryCalloutSteps?: string[],
  elementaryCalloutHelpLink?: string,
}

type ScheduleSubmitStatus = {
  humanReadableMessage: string,
  rawMessage?: string,
  status: 'success' | 'failure',
};

const ScheduleForm: FC<ScheduleFormProps> = ({
  scheduleNameEntryId,
  initialData,
  defaultBranch,
  branches,
  scheduleNamesList,
  isTurboCiDefined,
  onSubmit,
  onCancel,
  onDelete,
  elementaryCalloutSteps,
  elementaryCalloutHelpLink,
}) => {
  const { flagBoltEnableSeverity } = useFlags();

  const currentUser = userAuthStore((s) => s.currentUser);
  const [showScheduleCode, setShowScheduleCode] = useState(false);
  const [scheduleYaml, setScheduleYaml] = useState<string>();
  const [scheduleType, setScheduleType] = useState<ScheduleKindType>(getScheduleType(initialData));
  const [scheduleNameString, setScheduleNameString] = useState('');
  const [deferredScheduleName, setDeferredScheduleName] = useState('');
  const [successfulRunDropdownText, setSuccessfulRunDropdownText] = useState('Last run');
  const [successfulRunOnly, setSuccessfulRunOnly] = useState(false);
  const [scheduleCommands, setScheduleCommands] = useState('');
  const [scheduleBranch, setScheduleBranch] = useState(defaultBranch);
  const [scheduleOwner, setScheduleOwner] = useState(currentUser.email);
  const [scheduleCron, setScheduleCron] = useState('');
  const [slackNotifyOn, setSlackNotifyOn] = useState<NotifyCondition[]>([]);
  const [slackNotifyUserChannel, setSlackNotifyUserChannel] = useState<string[]>([]);
  const [emailNotifyOn, setEmailNotifyOn] = useState<NotifyCondition[]>([]);
  const [emailNotifyEmails, setEmailNotifyEmails] = useState('');
  const [scheduleWorkspaceName, setScheduleWorkspaceName] = useState('');
  const [boltScheduleName, setBoltScheduleName] = useState('');
  const [triggerType, setTriggerType] = useState(TRIGGER_TYPE_TAB_ID.SCHEDULED_RUN);
  const [scheduleIsSuspended, setScheduleIsSuspended] = useState(false);

  const [
    setScheduleName, { data: setData },
  ] = useSetScheduleNameMutation();

  const [
    removeScheduleName, { data: removeData },
  ] = useRemoveScheduleNameMutation();

  const [getAllScheduleNamesWithWorkspace,
    { data: allScheduleNamesWithWorkspace }] = useGetAllScheduleNamesWithWorkspaceLazyQuery({
    onCompleted: () => {
      setScheduleNamesByWorkspace(
        // eslint-disable-next-line max-len
        allScheduleNamesWithWorkspace?.getAllScheduleNamesWithWorkspace?.scheduleNames as GqlScheduleNameWithWorkspace[],
      );
    },
  });

  const { data: integrationsData } = useOrganisationIntegrationListQuery();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const closeAlert = alertStore((state) => state.closeAlert);
  const [scheduleSubmitResult, setScheduleSubmitResult] = useState<ScheduleSubmitStatus>();

  const [isScheduleNameValid, setIsScheduleNameValid] = useState(false);
  const [isScheduleOwnerValid, setIsScheduleOwnerValid] = useState(false);
  const [isDeferredValid, setIsDeferredValid] = useState(true);
  const [isScheduleCronValid, setIsScheduleCronValid] = useState(false);
  const [isSlackNotifyValid, setIsSlackNotifyValid] = useState(true);
  const [isEmailNotifyValid, setIsEmailNotifyValid] = useState(true);
  const [isScheduleValid, setIsScheduleValid] = useState(false);
  const [scheduleNamesByWorkspace, setScheduleNamesByWorkspace] = useState<
    GqlScheduleNameWithWorkspace[]>([]);
  const [scheduleOnTriggerCompleteStatuses,
    setScheduleOnTriggerCompleteStatuses] = useState<string[]>([]);

  const handleScheduleName = (name: string) => {
    const scheduleNameExists = scheduleNamesList.findIndex((s) => s === name) > -1;
    setScheduleNameString(name);
    setIsScheduleNameValid(!scheduleNameExists || (scheduleNameEntryId !== undefined));
  };

  const handleScheduleOwner = (owner: string, hasError?: boolean) => {
    setIsScheduleOwnerValid(!hasError);
    setScheduleOwner(owner);
  };

  const checkDeferredScheduleValid = () => {
    if (scheduleType === 'deferred' || scheduleType === 'turbo_ci') {
      setIsDeferredValid(deferredScheduleName.length > 0);
    } else {
      setIsDeferredValid(true);
    }
  };

  const checkValidityFromTriggerType = () => {
    let validityFromTriggerTypeField;
    const githubAppConnectionStatus = integrationsData
      ?.organisationIntegrationList
      .githubApp as ConnectionStatus;

    switch (triggerType) {
      case TRIGGER_TYPE_TAB_ID.SCHEDULED_RUN:
        validityFromTriggerTypeField = isScheduleCronValid;
        break;
      case TRIGGER_TYPE_TAB_ID.ON_RUN_COMPLETION:
        validityFromTriggerTypeField = boltScheduleName.length > 0
          && scheduleWorkspaceName.length > 0
          && scheduleOnTriggerCompleteStatuses.length > 0;
        break;
      case TRIGGER_TYPE_TAB_ID.ON_MERGE:
        validityFromTriggerTypeField = githubAppConnectionStatus === ConnectionStatus.ACTIVE;
        break;
      default:
        validityFromTriggerTypeField = false;
    }
    return validityFromTriggerTypeField;
  };

  const getCommandsList = (commands: string) => commands.split('\n').map((c) => c.trim()).filter((c) => c !== '');

  const checkScheduleValid = () => {
    const validityFromTriggerTypeField = checkValidityFromTriggerType();

    return isScheduleNameValid
      && scheduleNameString.length > 0
      && isDeferredValid
      && getCommandsList(scheduleCommands).length > 0
      && scheduleOwner.length > 0
      && validityFromTriggerTypeField
      && isScheduleOwnerValid
      && isSlackNotifyValid
      && isEmailNotifyValid;
  };

  useEffect(() => {
    getAllScheduleNamesWithWorkspace();
  }, []);

  useEffect(() => {
    handleScheduleName(scheduleNameString);
    handleScheduleOwner(scheduleOwner);
    const isValid = checkScheduleValid();
    setIsScheduleValid(isValid);
  }, []);

  useEffect(() => {
    if (initialData) {
      setScheduleNameString(initialData.name);
      setDeferredScheduleName(initialData.deferredSchedule?.deferredScheduleName
        || initialData.turboCi?.deferredManifestSchedule
        || '');
      setSuccessfulRunDropdownText((
        initialData.deferredSchedule?.successfulRunOnly
        || initialData.turboCi?.successfulRunOnly
      ) ? 'Last successful run' : 'Last run');
      setSuccessfulRunOnly(initialData.deferredSchedule?.successfulRunOnly || false);
      setScheduleCommands(initialData.commands.join('\n') || '');
      setScheduleBranch(initialData.gitBranch as string || defaultBranch);
      setScheduleOwner(initialData.owner || currentUser.email);
      setScheduleCron(initialData.schedule || '');
      setSlackNotifyOn(initialData.slackOn || []);
      setSlackNotifyUserChannel(initialData.slackNotify || []);
      setEmailNotifyOn(initialData.emailOn || []);
      setEmailNotifyEmails(initialData.emailNotify?.join(', ') || '');
      if (initialData.scheduleTrigger) {
        setTriggerType(TRIGGER_TYPE_TAB_ID.ON_RUN_COMPLETION);
      }
      setBoltScheduleName(initialData.scheduleTrigger?.scheduleName || '');
      setScheduleWorkspaceName(initialData.scheduleTrigger?.workspaceName || '');
      setScheduleOnTriggerCompleteStatuses(
        initialData.scheduleTrigger?.triggerOn as string[] || [],
      );
      setScheduleType(getScheduleType(initialData));
      setScheduleIsSuspended(initialData?.suspended || false);
    }
  }, [initialData]);

  useEffect(() => {
    checkDeferredScheduleValid();
  }, [scheduleType, deferredScheduleName]);

  useEffect(() => {
    const isValid = checkScheduleValid();
    setIsScheduleValid(isValid);
  }, [
    scheduleNameString, scheduleOwner, scheduleCommands,
    isScheduleNameValid, isScheduleCronValid, isDeferredValid,
    isSlackNotifyValid, isEmailNotifyValid, boltScheduleName,
    scheduleOnTriggerCompleteStatuses, scheduleWorkspaceName,
    triggerType,
  ]);

  const createScheduleObjectData = () => ({
    name: scheduleNameString,
    ...(scheduleType === 'turbo_ci') && {
      turboCi: {
        enabled: true,
        deferredScheduleName,
        successfulRunOnly,
      },
    },
    ...(scheduleType === 'deferred') && {
      deferredSchedule: {
        enabled: true,
        deferredScheduleName,
        successfulRunOnly,
      },
    },
    environment: 'production',
    source: 'ui' as ScheduleSource,
    schedule: triggerType === TRIGGER_TYPE_TAB_ID.ON_RUN_COMPLETION ? 'OFF' : (scheduleCron || 'OFF'),
    owner: scheduleOwner,
    commands: getCommandsList(scheduleCommands),
    gitBranch: scheduleBranch,
    ...(slackNotifyOn.length > 0) && {
      slackOn: slackNotifyOn as unknown as NotifyCondition[],
      slackNotify: slackNotifyUserChannel,
    },
    ...(emailNotifyOn.length > 0) && {
      emailOn: emailNotifyOn as unknown as NotifyCondition[],
      emailNotify: emailNotifyEmails.split(',').map((e) => e.trim()),
    },
    ...(triggerType === TRIGGER_TYPE_TAB_ID.ON_RUN_COMPLETION) && {
      scheduleTrigger: {
        enabled: true,
        scheduleName: boltScheduleName,
        workspaceName: scheduleWorkspaceName,
        triggerOn: scheduleOnTriggerCompleteStatuses,
      },
    },
    suspended: scheduleIsSuspended,
    triggerOnMerge: triggerType === TRIGGER_TYPE_TAB_ID.ON_MERGE,
  });

  const handleSubmit = () => {
    setIsSubmitting(true);

    setScheduleName({
      variables: {
        scheduleNameInputs: createScheduleObjectData(),
        scheduleNameEntryId: scheduleNameEntryId || 0,
      },
    });
  };

  const handleShowScheduleCode = () => {
    const scheduleDataSnakeCase = convertKeysToSnakeCase(createScheduleObjectData());
    // eslint-disable-next-line camelcase
    const { owner: owner_email, ...scheduleDataSnakeCaseRest } = scheduleDataSnakeCase;
    const scheduleDataForYaml = {
      owner_email,
      ...scheduleDataSnakeCaseRest,
    };

    setScheduleYaml(yaml.dump(scheduleDataForYaml));
    setShowScheduleCode(true);
  };

  const handleDelete = () => {
    triggerAlert({
      color: 'danger',
      message: 'Are you sure you want to remove this schedule from Bolt?\n\n'
            + "If you go ahead, this schedule will stop running but you will still be able to see all the previous data from the inactive tab. If you don't want to run this schedule,\n"
            + 'you may consider setting cron to "OFF" as an alternative.',
      title: 'Removing schedule',
      rightButtonText: 'Confirm',
      leftButtonText: 'Cancel',
      showLeftButton: true,
      onRightButtonClick: () => {
        setIsDeleting(true);
        removeScheduleName({
          variables: {
            scheduleNameEntryId: scheduleNameEntryId!,
          },
        });
        closeAlert();
      },
      leftButtonEventData: {
        eventAction: Actions.CANCELLED,
        eventContext: Contexts.LOGS,
        eventObject: 'deleteSchedule',
      },
      rightButtonEventData: {
        eventAction: Actions.UPDATED,
        eventContext: Contexts.LOGS,
        eventObject: 'deleteSchedule',
      },
    });
  };

  useEffect(() => {
    if (setData?.setScheduleName?.ok && !setData.setScheduleName.errorLog) {
      setScheduleSubmitResult({
        humanReadableMessage: 'Your schedule was submitted successfully. It is now deploying and should be live in a minute or so. You will now be redirected to the list of schedules.',
        status: 'success',
      });

      setTimeout(() => {
        setIsSubmitting(false);
        if (onSubmit) onSubmit();
      }, 3000);
    }

    if (setData?.setScheduleName?.errorLog) {
      setScheduleSubmitResult({
        humanReadableMessage: 'We encountered an error while deploying your schedule. Please try again. If the problem persists, contact us at support@paradime.io or through in-app chat for help.',
        rawMessage: setData?.setScheduleName?.errorLog,
        status: 'failure',
      });
      setIsSubmitting(false);
    }
  }, [setData]);

  useEffect(() => {
    if (removeData?.removeScheduleNameEntry?.ok && !removeData?.removeScheduleNameEntry.errorLog) {
      setScheduleSubmitResult({
        humanReadableMessage: 'Your schedule was deleted successfully. You will now be redirected to the list of schedules.',
        status: 'success',
      });

      setTimeout(() => {
        setIsDeleting(false);
        if (onDelete) onDelete();
      }, 3000);
    }

    if (removeData?.removeScheduleNameEntry?.errorLog) {
      setScheduleSubmitResult({
        humanReadableMessage: 'We encountered an error while deleting your schedule. Please try again. If the problem persists, contact us at support@paradime.io or through in-app chat for help.',
        rawMessage: removeData?.removeScheduleNameEntry?.errorLog,
        status: 'failure',
      });
      setIsDeleting(false);
    }
  }, [removeData]);

  return (
    <>
      <ScheduleCode
        scheduleCode={scheduleYaml}
        scheduleName={scheduleNameString}
        isVisible={showScheduleCode}
        onHide={() => setShowScheduleCode(false)}
      />
      <AutoLayout
        direction="vertical"
        padding="very-dense"
        distribution="packed"
        alignment="top-left"
        verticalGap="normal"
      >
        {(elementaryCalloutSteps || [])?.length > 0 && (
          <ElementaryCallout steps={elementaryCalloutSteps} helpLink={elementaryCalloutHelpLink} />
        )}
        <AutoLayout
          direction="vertical"
          padding="none"
          distribution="packed"
          alignment="top-left"
          verticalGap="very-dense"
        >
          <SectionHeader
            icon="globe-network"
            header="Schedule details"
            description="Add details about the schedule"
            customRightComponent={(
              <Switch
                type="dense"
                view="smooth"
                color="primary"
                label={scheduleIsSuspended ? 'paused' : 'active'}
                checked={!scheduleIsSuspended}
                onChange={() => setScheduleIsSuspended((isSuspended) => !isSuspended)}
                eventContext={Contexts.BOLT}
                eventObject={`turnSchedule${scheduleIsSuspended ? 'On' : 'Off'}`}
                eventAction={Actions.CLICKED}
              />
            )}
          />
          <Dropdown
            view="outlined"
            text={scheduleType}
            label="Schedule Type (standard / deferred / turbo-ci)"
            content={(
              <MenuTemplate
                dense
                items={[
                  {
                    text: 'standard',
                    type: 'item',
                    onClick: () => setScheduleType('standard'),
                  },
                  {
                    text: 'deferred',
                    type: 'item',
                    onClick: () => setScheduleType('deferred'),
                    disabled: scheduleNamesList.length === 0,
                  },
                  {
                    text: 'turbo-ci',
                    type: 'item',
                    onClick: () => setScheduleType('turbo_ci'),
                    disabled: scheduleNamesList.length === 0 || isTurboCiDefined,
                  },
                ]}
                eventContext={Contexts.MODAL}
              />
            )}
            fill
            color="default"
            dense
          />
          <DefaultInput
            autoFocus
            color="default"
            dense
            full
            sensitive
            label="Name"
            placeholder="Schedule name"
            type="text"
            view="outlined"
            dataName="scheduleName"
            value={scheduleNameString}
            error={!isScheduleNameValid}
            errorHelperText="Schedule name already exists"
            expose={(e) => handleScheduleName(e.target.value.trim())}
          />
          {
            (scheduleType !== 'standard') && (
              <>
                <Dropdown
                  view="outlined"
                  text={deferredScheduleName}
                  fill
                  color="default"
                  dense
                  label={(!isDeferredValid) ? 'Deferred schedule (Error: Choose at least one schedule)' : 'Deferred schedule'}
                  content={(
                    <MenuTemplate
                      dense
                      items={
                        [
                          {
                            text: 'self (ie. current schedule itself)',
                            type: 'item',
                            onClick: () => setDeferredScheduleName(scheduleNameString),
                            disabled: scheduleNameString.length === 0,
                            icon: 'array',
                          },
                          ...scheduleNamesList.map((name) => ({
                            text: name,
                            type: 'item',
                            onClick: () => setDeferredScheduleName(name),
                          })) as MenuTemplateItem[],
                        ]
                      }
                      eventContext={Contexts.EDITOR}
                    />
                  )}
                  hasError={!isDeferredValid}
                />
                <Dropdown
                  view="outlined"
                  text={successfulRunDropdownText}
                  label="Last run type (for comparison)"
                  content={(
                    <MenuTemplate
                      dense
                      items={[
                        {
                          text: 'Last successful run',
                          type: 'item',
                          onClick: () => {
                            setSuccessfulRunDropdownText('Last successful run');
                            setSuccessfulRunOnly(true);
                          },
                        },
                        {
                          text: 'Last run',
                          type: 'item',
                          onClick: () => {
                            setSuccessfulRunDropdownText('Last run');
                            setSuccessfulRunOnly(false);
                          },
                        },
                      ]}
                      eventContext={Contexts.EDITOR}
                    />
                  )}
                  fill
                  color="default"
                  dense
                />
              </>
            )
          }
          <CommandsBlock setCommands={setScheduleCommands} commands={scheduleCommands} />
          <Dropdown
            view="outlined"
            text={scheduleBranch || defaultBranch}
            fill
            color="default"
            dense
            label="Git branch"
            content={(
              <MenuTemplate
                dense
                items={
                  branches.map((branch) => ({
                    text: branch,
                    type: 'item',
                    onClick: () => setScheduleBranch(
                      branch.replace(' (default)', ''),
                    ),
                  }))
                }
                eventContext={Contexts.LOGS}
              />
            )}
            height={300}
          />
          {
            flagBoltEnableSeverity ? (
              <ScheduleSeverity severity={scheduleOwner} setSeverity={setScheduleOwner} />
            ) : (
              <DefaultInput
                color="default"
                dense
                full
                sensitive
                label="Owner email"
                placeholder="Email of scheduler owner"
                type="text"
                view="outlined"
                dataName="scheduleOwnerEmail"
                value={scheduleOwner}
                expose={(e, hasError) => handleScheduleOwner(e.target.value.trim(), hasError)}
                validation={[
                  {
                    rule: ValidationRules.VALID_EMAIL,
                    message: 'Enter a valid email',
                  },
                ]}
                checkValidationAfterEveryChange
                checkValidationOnBlur
              />
            )
          }
        </AutoLayout>
        <ScheduleBlock
          scheduleCron={scheduleCron}
          setScheduleCron={setScheduleCron}
          exposeIsValid={(isValid) => setIsScheduleCronValid(isValid)}
          scheduleWorkspaceName={scheduleWorkspaceName}
          setScheduleWorkspaceName={setScheduleWorkspaceName}
          boltScheduleName={boltScheduleName}
          setBoltScheduleName={setBoltScheduleName}
          scheduleNamesByWorkspace={scheduleNamesByWorkspace}
          scheduleOnTriggerCompleteStatuses={scheduleOnTriggerCompleteStatuses}
          setScheduleOnTriggerCompleteStatuses={setScheduleOnTriggerCompleteStatuses}
          triggerType={triggerType}
          setTriggerType={setTriggerType}
          githubAppConnectionStatus={
            integrationsData?.organisationIntegrationList.githubApp as ConnectionStatus
          }
          scheduleType={scheduleType}
        />
        <AutoLayout
          direction="vertical"
          padding="none"
          distribution="packed"
          alignment="top-left"
          verticalGap="very-dense"
        >
          <SectionHeader
            icon="inbox-update"
            header="Notification settings"
            description="Specify who will be notified on Slack and Email when the schedule fails, passes or both."
          />
          <SlackNotify
            slackNotifyOn={slackNotifyOn}
            setSlackNotifyOn={setSlackNotifyOn}
            slackNotifyUserChannel={slackNotifyUserChannel}
            setSlackNotifyUserChannel={setSlackNotifyUserChannel}
            exposeIsValid={(isValid) => setIsSlackNotifyValid(isValid)}
            slackConnectionStatus={
              integrationsData?.organisationIntegrationList.slack as ConnectionStatus
            }
          />
          <EmailNotify
            emailNotifyOn={emailNotifyOn}
            setEmailNotifyOn={setEmailNotifyOn}
            emailNotifyEmails={emailNotifyEmails}
            setEmailNotifyEmails={setEmailNotifyEmails}
            exposeIsValid={(isValid) => setIsEmailNotifyValid(isValid)}
          />
        </AutoLayout>
        {(scheduleSubmitResult?.status === 'failure') && (
          <ErrorCallout
            color="danger"
            title="Schedule submit error"
            errorDetails={{
              humanErrorMessage: scheduleSubmitResult.humanReadableMessage,
              rawErrorMessage: scheduleSubmitResult.rawMessage || '',
            }}
            eventContext={Contexts.LOGS}
          />
        )}
        {(scheduleSubmitResult?.status === 'success') && (
          <Callout
            icon="tick-circle"
            color="success"
            view="smooth"
            dense
            title="Success"
            content={scheduleSubmitResult?.humanReadableMessage}
          />
        )}
        <AutoLayout
          direction="horizontal"
          padding="none"
          distribution="space-between"
          style={{ alignItems: 'center' }}
        >
          <AutoLayout
            direction="horizontal"
            padding="none"
            distribution="packed"
            horizontalGap="very-dense"
          >
            <DefaultButton
              type="standard"
              view="outlined"
              color="default"
              dense
              disabled={isSubmitting}
              text="Cancel"
              eventContext={Contexts.LOGS}
              eventObject="cancelAddNewSchedule"
              eventAction={Actions.CLICKED}
              onClick={() => onCancel()}
            />
            {
              scheduleNameEntryId && (scheduleNameEntryId !== null) && (
                <DefaultButton
                  type="standard"
                  view="smooth"
                  color="danger"
                  icon="trash"
                  dense
                  disabled={isDeleting}
                  text="Delete"
                  eventContext={Contexts.LOGS}
                  eventObject="deleteSchedule"
                  eventAction={Actions.CLICKED}
                  onClick={() => handleDelete()}
                />
              )
            }
          </AutoLayout>
          <AutoLayout
            direction="horizontal"
            padding="none"
            distribution="packed"
            horizontalGap="very-dense"
          >
            <DefaultButton
              type="standard"
              view="outlined"
              color="default"
              icon="code"
              dense
              text="View code"
              disabled={!isScheduleValid || isDeleting}
              eventContext={Contexts.LOGS}
              eventObject="viewScheduleCode"
              eventAction={Actions.CLICKED}
              onClick={handleShowScheduleCode}
            />
            <DefaultButton
              type="standard"
              view="filled"
              color="primary"
              dense
              text="Publish"
              disabled={!isScheduleValid || isDeleting}
              eventContext={Contexts.LOGS}
              eventObject="submitNewSchedule"
              eventAction={Actions.CLICKED}
              onClick={handleSubmit}
              loading={isSubmitting}
            />
          </AutoLayout>
        </AutoLayout>
      </AutoLayout>
    </>
  );
};

export default ScheduleForm;
