import React, { FC } from 'react';

export interface ArcadeProps {
  url: string,
  title: string,
}

const Arcade:FC<ArcadeProps> = ({
  url,
  title,
}) => (
  <div
    style={{
      position: 'relative',
      paddingBottom: 'calc(66.66666666666666% + 41px)',
      height: '0',
      width: '100%',
      flex: '1 1 auto',
      display: 'flex',
    }}
  >
    <iframe
      src={url}
      title={title}
      frameBorder="0"
      loading="lazy"
      allowFullScreen
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        colorScheme: 'light',
      }}
    />
  </div>
);

export default Arcade;
