import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import CodeSnippetCopy from '../../../Common/Components/CodeSnippetCopy';
import { CodeTypography } from '../../Bolt.styles';

interface YAMLProps {
  scheduleCode?: string,
}

const YAML:FC<YAMLProps> = ({
  scheduleCode,
}) => {
  const history = useHistory();

  return (
    <>
      <CodeTypography type="body-small" color="default" colorStep="50" tagName="span">
        <br />
        Example code to add to the
        {' '}
        <code>paradime_schedules.yml</code>
        {' '}
        file and trigger this schedule as
        {' '}
        <strong>git-tracked</strong>
        .
      </CodeTypography>
      <CodeSnippetCopy codeSnippet={scheduleCode || ''} language="yaml" />
      <DefaultButton
        dense
        view="smooth"
        icon="console"
        text="Go to editor"
        eventContext={Contexts.APP}
        eventObject="goToEditor"
        eventAction={Actions.CLICKED}
        onClick={() => {
          history.push('/editor');
        }}
        style={{ width: 'fit-content', textTransform: 'none' }}
      />
    </>
  );
};

export default YAML;
