import React, {
  Dispatch, FC, SetStateAction, useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import Autolayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import InputMultiSelect, { InputMultiSelectItem } from '@paradime-io/pragma-ui-kit/lib/components/InputMultiSelect';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import * as S from '../Bolt.styles';
import { NotifyCondition } from '../../Bolt';
import { useGetSlackUsersChannels } from '../hooks/useGetSlackUsersChannels';

export enum ConnectionStatus {
  'AVAILABLE' = 'available',
  'ACTIVE' = 'active',
}

interface SlackNotifyProps {
  slackNotifyOn: NotifyCondition[],
  setSlackNotifyOn: Dispatch<SetStateAction<NotifyCondition[]>>,
  slackNotifyUserChannel: string[],
  setSlackNotifyUserChannel: Dispatch<SetStateAction<string[]>>,
  exposeIsValid?: (isValid: boolean) => void,
  slackConnectionStatus?: ConnectionStatus,
}

const SlackNotify: FC<SlackNotifyProps> = ({
  slackNotifyOn,
  setSlackNotifyOn,
  slackNotifyUserChannel,
  setSlackNotifyUserChannel,
  exposeIsValid,
  slackConnectionStatus,
}) => {
  const history = useHistory();
  const [isValidNotify, setIsValidNotify] = useState(true);
  const [errorString, setErrorString] = useState('');
  const [slackStatus, setSlackStatus] = useState<ConnectionStatus>();
  const [filteredSlackList, setFilteredSlackList] = useState<string[]>();

  const {
    loading,
    getFilteredSlackUsersChannels,
    fetchSlackUsersChannels,
  } = useGetSlackUsersChannels();

  useEffect(() => {
    fetchSlackUsersChannels({ refresh: true });
  }, []);

  useEffect(() => {
    if (slackConnectionStatus) {
      setSlackStatus(slackConnectionStatus);
    }
  }, [slackConnectionStatus]);

  useEffect(() => {
    if (slackStatus === ConnectionStatus.ACTIVE && !loading) {
      const filtered = getFilteredSlackUsersChannels();
      setFilteredSlackList(filtered);
    }
  }, [slackStatus, loading]);

  const updateSlackNotifyOn = (items: InputMultiSelectItem[]) => {
    const selectedItems = items.filter((item) => item.selected);
    setSlackNotifyOn(selectedItems.map((i) => i.value as NotifyCondition));
  };

  const updateSlackNotifyUserChannel = (items: InputMultiSelectItem[]) => {
    const selectedItems = items.filter((item) => item.selected);
    setSlackNotifyUserChannel(selectedItems.map((i) => i.value));
  };

  const openIntegrations = () => {
    history.push('/account-settings/integrations');
  };

  useEffect(() => {
    if (slackNotifyOn.length > 0 && slackNotifyUserChannel.length === 0) {
      setIsValidNotify(false);
      setErrorString('Please enter valid Slack user(s) or channel(s) when Slack Notify On is set');
    }

    if (slackNotifyOn.length > 0 && slackNotifyUserChannel.length > 0) {
      setIsValidNotify(true);
    }

    if (slackNotifyOn.length === 0 && slackNotifyUserChannel.length >= 0) {
      setIsValidNotify(true);
    }
  }, [slackNotifyOn, slackNotifyUserChannel]);

  useEffect(() => {
    if (isValidNotify) {
      setErrorString('');
    }

    if (exposeIsValid) exposeIsValid(isValidNotify);
  }, [isValidNotify]);

  if (slackStatus === ConnectionStatus.AVAILABLE) {
    return (
      <DefaultButton
        type="standard"
        view="outlined"
        fill
        color="default"
        integrationIcon="slack" // Pragma change needed to add slack icon
        text="Connect Slack"
        onClick={openIntegrations}
        eventContext={Contexts.BOLT}
        eventObject="connectSlack"
        eventAction={Actions.CLICKED}
        style={{ borderRadius: '4px' }}
      />
    );
  }

  if (loading) {
    return (
      <Spinner position="start" height={20} width={20} thin />
    );
  }

  return (
    <>
      <InputMultiSelect
        view="outlined"
        fill
        type="dense"
        color="default"
        label="Slack notify on"
        initialValues={slackNotifyOn}
        dataItems={['failed', 'passed']}
        onSelectItem={(_, itemList) => updateSlackNotifyOn(itemList)}
        eventContext={Contexts.BOLT}
        eventObject="slackNotifyOn"
        eventAction={Actions.SELECTED}
      />
      <Autolayout
        direction="vertical"
        padding="none"
        distribution="packed"
        alignment="top-left"
        verticalGap="ultra-dense"
      >
        <Autolayout
          direction="vertical"
          padding="none"
          distribution="packed"
          alignment="top-left"
          verticalGap="none"
        >
          <Autolayout
            direction="horizontal"
            distribution="packed"
            horizontalGap="very-dense"
            padding="none"
            style={{ alignItems: 'center' }}
          >
            <Typography tagName="span" type="overline-small" colorStep="50">
              Slack channels and users
            </Typography>
            <S.SmallIconChip
              icon="refresh"
              color="default"
              round={false}
              tag="Refresh"
              type="dense"
              view="smooth"
              onClick={() => fetchSlackUsersChannels({ refresh: true })}
            />
          </Autolayout>
          <InputMultiSelect
            view="outlined"
            fill
            type="dense"
            color="default"
            initialValues={slackNotifyUserChannel}
            dataItems={filteredSlackList || []}
            onSelectItem={(_, itemList) => updateSlackNotifyUserChannel(itemList)}
            eventContext={Contexts.BOLT}
            eventObject="slackNotify"
            eventAction={Actions.SELECTED}
          />
        </Autolayout>
        {!isValidNotify && (
          <Typography
            tagName="span"
            type="caption-small"
            color="danger"
            colorStep="60"
          >
            {errorString}
          </Typography>
        )}
      </Autolayout>
    </>
  );
};

export default SlackNotify;
