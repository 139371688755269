import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import useGetSchedulesAndBranches from '../hooks/useGetSchedulesAndBranches';
import useGetScheduleName from '../hooks/useGetScheduleName';
import ScheduleForm from '../ScheduleForm';
import BoltSchedulesUpgrade from '../../Common/Upgrade/BoltSchedulesUpgrade';
import { useWaitForLDToBeReady } from '../../hooks/useWaitForLDToBeReady';
import { useGetTemplateData } from './TemplateSchedules/useGetTemplateData';

const NewSchedule: FC = () => {
  const history = useHistory();
  const { scheduleId } = useParams<{ scheduleId?: string }>();

  const {
    templateSchedule, elementaryCalloutSetupSteps, elementaryCalloutHelpLink,
  } = useGetTemplateData();

  const { ldIsReady } = useWaitForLDToBeReady();
  const { flagAppBolt } = useFlags();

  const {
    isLoading,
    scheduleNamesList,
    gitBranchList,
    defaultBranch,
    isTurboCiDefined,
  } = useGetSchedulesAndBranches();
  const { isLoading: isScheduleLoading, scheduleFormData } = useGetScheduleName(scheduleId);

  const handleAddNewSchedule = () => {
    history.push('/bolt');
  };

  const getInitialFormData = () => {
    if (scheduleFormData) {
      return {
        ...scheduleFormData,
        name: `${scheduleFormData.name} copy`,
      };
    }

    if (templateSchedule) return templateSchedule;

    return undefined;
  };

  if (ldIsReady && !flagAppBolt) {
    return <BoltSchedulesUpgrade />;
  }

  if (isLoading && isScheduleLoading) {
    return <Spinner position="center" />;
  }

  return (
    <ScheduleForm
      initialData={getInitialFormData()}
      scheduleNamesList={scheduleNamesList}
      isTurboCiDefined={isTurboCiDefined}
      branches={gitBranchList}
      defaultBranch={defaultBranch}
      onSubmit={handleAddNewSchedule}
      onCancel={() => history.push('/bolt')}
      elementaryCalloutSteps={elementaryCalloutSetupSteps}
      elementaryCalloutHelpLink={elementaryCalloutHelpLink}
    />
  );
};

export default NewSchedule;
