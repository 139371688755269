import { gql } from '@apollo/client';
import { ColumnType } from '@paradime-io/pragma-ui-kit/lib/components/PrimeReactDataTable';

export interface activeDashboardUsageReturnType {
  cube: {
    lookerDashboardPerformanceAndCost: {
      totalQueryCostUsd: number | null,
      avgRuntimeSeconds: number | null,
      lookerDashboardTitle: string,
      lookerDashboardRunOnLoad: boolean | null,
      daysSinceLastDashboardAccess: number,
    }
  }[]
}

export const activeDashboardUsageQuery = gql`
  query activeDashboardUsageQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {looker_dashboard_performance_and_cost: {looker_dashboard_title: {set: true}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      lookerDashboardPerformanceAndCost: looker_dashboard_performance_and_cost(orderBy: {total_query_cost_usd: desc}) {
        totalQueryCostUsd: total_query_cost_usd
        avgRuntimeSeconds: avg_runtime_seconds
        lookerDashboardTitle: looker_dashboard_title
        lookerDashboardRunOnLoad: looker_dashboard_run_on_load
        daysSinceLastDashboardAccess: days_since_last_dashboard_access
      }
    }
  }
`;

const transformBooleanToString = (val: boolean | null) => {
  if (val === null) return 'No value';
  return val ? 'Yes' : 'No';
};

export const activeDashboardUsageColumns = [
  { field: 'lookerDashboardTitle', header: 'Looker dashboard title' },
  { field: 'lookerDashboardRunOnLoad', header: 'Looker dashboard run on load' },
  { field: 'daysSinceLastDashboardAccess', header: 'Last accessed (days)' },
  { field: 'avgRuntimeSeconds', header: 'Runtime in seconds' },
  { field: 'totalQueryCostUsd', header: 'Total query cost (USD)', type: ColumnType.CURRENCY },
].map((col) => ({ ...col, sortable: true }));

export const formatActiveDashboardUsageForChart = (data?: activeDashboardUsageReturnType) => {
  if (!data) return [];

  return data.cube
    .map(({ lookerDashboardPerformanceAndCost }) => ({
      ...lookerDashboardPerformanceAndCost,
      lookerDashboardRunOnLoad: (
        transformBooleanToString(lookerDashboardPerformanceAndCost.lookerDashboardRunOnLoad)
      ),
      avgRuntimeSeconds: lookerDashboardPerformanceAndCost.avgRuntimeSeconds === null
        ? '-'
        : lookerDashboardPerformanceAndCost.avgRuntimeSeconds,
      totalQueryCostUsd: lookerDashboardPerformanceAndCost.totalQueryCostUsd === null
        ? 0
        : lookerDashboardPerformanceAndCost.totalQueryCostUsd,
    }));
};

export interface top10SpendReturnType {
  cube: {
    lookerDashboardPerformanceAndCost: {
      totalQueryCostUsd: number | null,
      lookerDashboardTitle: string,
    }
  }[]
}

export const top10SpendQuery = gql`
  query top10SpendQuery($timeframe: [String]) {
    cube(
      limit: 10
      where: {looker_dashboard_performance_and_cost: {calendar_date: {inDateRange: $timeframe}}}
    ) {
      lookerDashboardPerformanceAndCost: looker_dashboard_performance_and_cost(orderBy: {total_query_cost_usd: desc}) {
        totalQueryCostUsd: total_query_cost_usd
        lookerDashboardTitle: looker_dashboard_title
      }
    }
  }
`;

export const formatTop10SpendForChart = (data?: top10SpendReturnType) => {
  if (!data) return [];

  return [data.cube
    .map(({ lookerDashboardPerformanceAndCost }) => ({
      x: lookerDashboardPerformanceAndCost.lookerDashboardTitle || '',
      y: lookerDashboardPerformanceAndCost.totalQueryCostUsd || 0,
    }))];
};

export const getTop10SpendXAxisLabels = (data?: top10SpendReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ lookerDashboardPerformanceAndCost }) => (
      lookerDashboardPerformanceAndCost.lookerDashboardTitle || ''
    ));
  return Array.from(new Set(dates));
};

export interface dailySpendReturnType {
  cube: {
    lookerDashboardPerformanceAndCost: {
      totalQueryCostUsd: number | null,
      calendarDate: {
        day: string,
      },
    }
  }[]
}

export const dailySpendQuery = gql`
  query dailySpendQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {looker_dashboard_performance_and_cost: {calendar_date: {inDateRange: $timeframe}}}
    ) {
      lookerDashboardPerformanceAndCost: looker_dashboard_performance_and_cost(orderBy: {calendar_date: desc}) {
        totalQueryCostUsd: total_query_cost_usd
        calendarDate: calendar_date {
          day
        }
      }
    }
  }
`;

export const formatDailySpendForChart = (data?: dailySpendReturnType) => {
  if (!data) return [];

  return [data.cube
    .map(({ lookerDashboardPerformanceAndCost }) => ({
      x: lookerDashboardPerformanceAndCost.calendarDate.day,
      y: lookerDashboardPerformanceAndCost.totalQueryCostUsd || 0,
    }))];
};

export const getDailySpendXAxisLabels = (data?: dailySpendReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ lookerDashboardPerformanceAndCost }) => (
      lookerDashboardPerformanceAndCost.calendarDate.day
    ));
  return Array.from(new Set(dates));
};
