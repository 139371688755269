import { ConnectionParameter } from '@paradime-io/pragma-ui-kit/lib/components/WarehouseConnectionRowItem';
import { GetOrganisationDbConfigsQuery, GqlParadimeAccountType, GqlBigqueryConfig } from '../../client/generated/service';
import { WarehouseEnv } from '../Common/Warehouses/utils';
import bigqueryLogo from '../Common/Warehouses/images/bigquery.svg';
import redshiftLogo from '../Common/Warehouses/images/redshift.svg';
import snowflakeLogo from '../Common/Warehouses/images/snowflake.svg';
import fireboltLogo from '../Common/Warehouses/images/firebolt.svg';
import databricksLogo from '../Common/Warehouses/images/databricks.svg';
import motherDuckLogo from '../Common/Warehouses/images/motherDuck.svg';
// import duckDbLogo from '../Common/Warehouses/images/duckdb.svg';
import { userHasAccountSettingsConnectionsAdminAccess } from '../../utilis/PermissionsService';

type completeDatabaseConfig = Omit<NonNullable<warehouseCompleteConfigItem['configuration']>, '__typename'>
type incompleteDatabaseConfig = Omit<NonNullable<warehouseIncompleteConfigItem['configuration']>, '__typename'>

export type warehouseCompleteConfigItem = Omit<GetOrganisationDbConfigsQuery['getOrganisationDbConfigs']['configurations'][number], '__typename'>
export type warehouseIncompleteConfigItem = Omit<GetOrganisationDbConfigsQuery['getOrganisationDbConfigs']['incomplete'][number], '__typename'>

type warehouseCompleteConfigPlusEnv = completeDatabaseConfig & {
  accessLevel?: GqlParadimeAccountType,
  env?: WarehouseEnv,
  bigqueryOrgConfig?: { executionProject: string },
}

type warehouseIncompleteConfigPlusEnv = incompleteDatabaseConfig & {
  accessLevel?: GqlParadimeAccountType,
  env?: WarehouseEnv,
}

// This is a duplicate of the enum in `../Common/Warehouses/Utils`
// Because the browser seems to have suddenly forgotten that enum exists
// Probably an issue with the ordering - trying to use it before it has been defined
// TODO: Investigate, and remove this duplicate
enum Warehouse {
  'BIGQUERY' = 'bigquery',
  'SNOWFLAKE' = 'snowflake',
  'REDSHIFT' = 'redshift',
  'FIREBOLT' = 'firebolt',
  'DATABRICKS' = 'databricks',
  'DUCK_DB' = 'duckdb',
  'MOTHERDUCK' = 'motherduck',
  'UNKNOWN' = 'unknown'
}

export const WarehouseConnectionLogos = {
  [Warehouse.BIGQUERY]: bigqueryLogo,
  [Warehouse.SNOWFLAKE]: snowflakeLogo,
  [Warehouse.REDSHIFT]: redshiftLogo,
  [Warehouse.FIREBOLT]: fireboltLogo,
  [Warehouse.DATABRICKS]: databricksLogo,
  [Warehouse.MOTHERDUCK]: motherDuckLogo,
  [Warehouse.DUCK_DB]: motherDuckLogo,
  [Warehouse.UNKNOWN]: '',
};

export const getConnectionLogo = (
  databaseType: Warehouse,
  newJoinerDatabaseType?: Warehouse,
) => {
  if (databaseType === Warehouse.UNKNOWN) {
    return WarehouseConnectionLogos[newJoinerDatabaseType || Warehouse.UNKNOWN];
  }
  return WarehouseConnectionLogos[databaseType];
};

export const getConnectionName = (configData?: GetOrganisationDbConfigsQuery) => {
  const defaultData = configData?.getOrganisationDbConfigs.configurations
    .find((config) => config.isDefault);
  switch (defaultData?.configuration?.databaseType) {
    case 'bigquery':
      return defaultData?.configuration.bigqueryConfig?.connectionName;
    case 'redshift':
      return defaultData?.configuration.redshiftConfig?.connectionName;
    case 'snowflake':
      return defaultData?.configuration.snowflakeConfig?.connectionName;
    case 'firebolt':
      return defaultData?.configuration.fireboltConfig?.connectionName;
    case 'databricks':
      return defaultData?.configuration.databricksConfig?.connectionName;
    case 'duckdb':
      return defaultData?.configuration.duckdbConfig?.connectionName;
    default:
      return '...loading';
  }
};

export const getCompleteParameters = ({
  databaseType,
  snowflakeConfig,
  bigqueryConfig,
  redshiftConfig,
  fireboltConfig,
  databricksConfig,
  duckdbConfig,
  accessLevel,
  env,
}: warehouseCompleteConfigPlusEnv) => {
  const newParams: ConnectionParameter[] = [];

  switch (databaseType) {
    case Warehouse.SNOWFLAKE:
      switch (env) {
        case WarehouseEnv.PROD:
        case WarehouseEnv.DEV:
          newParams.push({
            name: 'profile',
            value: snowflakeConfig!.connectionName,
          });
          newParams.push({
            name: 'target name',
            value: snowflakeConfig!.targetName,
          });
          break;
        case WarehouseEnv.METADATA:
          newParams.push({
            name: 'account',
            value: snowflakeConfig!.account,
          });
          newParams.push({
            name: 'database',
            value: snowflakeConfig!.database,
          });
          break;
        case WarehouseEnv.COSTS:
          newParams.push({
            name: 'account',
            value: snowflakeConfig!.account,
          });
          newParams.push({
            name: 'database',
            value: snowflakeConfig!.database,
          });
          break;

        default:
          if (userHasAccountSettingsConnectionsAdminAccess(accessLevel!)) {
            newParams.push({
              name: 'target name',
              value: snowflakeConfig!.targetName,
            });
          }
          break;
      }
      break;
    case Warehouse.FIREBOLT:
      switch (env) {
        case WarehouseEnv.PROD:
        case WarehouseEnv.DEV:
          newParams.push({
            name: 'profile',
            value: fireboltConfig!.connectionName,
          });
          newParams.push({
            name: 'target name',
            value: fireboltConfig!.targetName,
          });
          break;
        case WarehouseEnv.METADATA:
          newParams.push({
            name: 'account',
            value: fireboltConfig!.accountName,
          });
          newParams.push({
            name: 'database',
            value: fireboltConfig!.database,
          });
          break;
        case WarehouseEnv.COSTS:
          if (userHasAccountSettingsConnectionsAdminAccess(accessLevel!)) {
            newParams.push({
              name: 'account',
              value: fireboltConfig!.accountName,
            });
          } else {
            newParams.push({
              name: 'account',
              value: fireboltConfig!.engineName,
            });
          }
          newParams.push({
            name: 'database',
            value: fireboltConfig!.database,
          });
          break;

        default:
          if (userHasAccountSettingsConnectionsAdminAccess(accessLevel!)) {
            newParams.push({
              name: 'target name',
              value: fireboltConfig!.targetName,
            });
          }
          break;
      }
      break;

    case Warehouse.REDSHIFT:
      switch (env) {
        case WarehouseEnv.PROD:
        case WarehouseEnv.DEV:
          newParams.push({
            name: 'profile',
            value: redshiftConfig!.connectionName,
          });
          newParams.push({
            name: 'target name',
            value: redshiftConfig!.targetName,
          });
          break;
        case WarehouseEnv.METADATA:
          newParams.push({
            name: 'hostname',
            value: redshiftConfig!.hostName,
          });
          newParams.push({
            name: 'database',
            value: redshiftConfig!.database,
          });
          break;
        default:
          break;
      }
      break;

    case Warehouse.BIGQUERY:
      switch (env) {
        case WarehouseEnv.PROD:
        case WarehouseEnv.DEV:
          newParams.push({
            name: 'profile',
            value: bigqueryConfig!.connectionName,
          });
          newParams.push({
            name: 'target name',
            value: bigqueryConfig!.targetName,
          });
          break;
        case WarehouseEnv.METADATA:
          // metadata currently returns dummy data
          break;
        case WarehouseEnv.COSTS:
          newParams.push({
            name: 'project name',
            value: (bigqueryConfig as GqlBigqueryConfig)!.costProjectId || '',
          });

          newParams.push({
            name: 'GCS bucket name',
            value: (bigqueryConfig as GqlBigqueryConfig)!.costGcsBucketName || '',
          });
          break;

        default:
          if (userHasAccountSettingsConnectionsAdminAccess(accessLevel!)) {
            newParams.push({
              name: 'project name',
              value: bigqueryConfig!.connectionName,
            });
          }
          break;
      }
      break;

    case Warehouse.DATABRICKS:
      switch (env) {
        case WarehouseEnv.PROD:
        case WarehouseEnv.DEV:
          newParams.push({
            name: 'profile',
            value: databricksConfig!.connectionName,
          });
          newParams.push({
            name: 'target name',
            value: databricksConfig!.targetName,
          });
          break;
        case WarehouseEnv.METADATA:
          // metadata currently returns dummy data
          break;
        default:
          if (userHasAccountSettingsConnectionsAdminAccess(accessLevel!)) {
            newParams.push({
              name: 'project name',
              value: databricksConfig!.connectionName,
            });
          }
          break;
      }
      break;

    case Warehouse.DUCK_DB:
    case Warehouse.MOTHERDUCK:
      switch (env) {
        case WarehouseEnv.PROD:
        case WarehouseEnv.DEV:
          newParams.push({
            name: 'profile',
            value: duckdbConfig!.connectionName,
          });
          newParams.push({
            name: 'target name',
            value: duckdbConfig!.targetName,
          });
          break;
        case WarehouseEnv.METADATA:
          // metadata currently returns dummy data
          break;
        default:
          if (userHasAccountSettingsConnectionsAdminAccess(accessLevel!)) {
            newParams.push({
              name: 'project name',
              value: duckdbConfig!.connectionName,
            });
          }
          break;
      }
      break;

    default:
      break;
  }

  return newParams;
};

export const getViewEditIncompleteFormData = ({
  bigqueryOrgConfig,
  redshiftOrgConfig,
  snowflakeOrgConfig,
  fireboltOrgConfig,
  databricksOrgConfig,
  duckdbOrgConfig,
  databaseType,
}: warehouseIncompleteConfigPlusEnv) => {
  let warehouseConfig: { [key: string]: string | number | undefined } = {};

  switch (databaseType) {
    case Warehouse.SNOWFLAKE:
      warehouseConfig = { ...snowflakeOrgConfig, location: 'europe-west2' };
      break;

    case Warehouse.BIGQUERY:
      warehouseConfig = { ...bigqueryOrgConfig };
      break;

    case Warehouse.REDSHIFT:
      warehouseConfig = { ...redshiftOrgConfig, location: 'europe-west2' };
      break;

    case Warehouse.FIREBOLT:
      warehouseConfig = { ...fireboltOrgConfig, location: 'europe-west2' };
      break;

    case Warehouse.DATABRICKS:
      warehouseConfig = { ...databricksOrgConfig, location: 'europe-west2' };
      break;

    case Warehouse.DUCK_DB:
    case Warehouse.MOTHERDUCK:
      warehouseConfig = { ...duckdbOrgConfig };
      break;

    default:
      break;
  }
  return warehouseConfig;
};

export const getViewEditCompleteFormData = ({
  bigqueryConfig,
  bigqueryOrgConfig,
  redshiftConfig,
  snowflakeConfig,
  fireboltConfig,
  databricksConfig,
  duckdbConfig,
  databaseType,
}: warehouseCompleteConfigPlusEnv) => {
  let warehouseConfig: { [key: string]: string | number | undefined | null | boolean } = {};

  switch (databaseType) {
    case Warehouse.SNOWFLAKE:
      warehouseConfig = { ...snowflakeConfig, datasetName: snowflakeConfig?.schema, location: 'europe-west2' };
      break;

    case Warehouse.BIGQUERY:
      warehouseConfig = {
        ...bigqueryConfig, ...bigqueryOrgConfig, schema: bigqueryConfig?.datasetName,
      };
      break;

    case Warehouse.REDSHIFT:
      warehouseConfig = { ...redshiftConfig, datasetName: redshiftConfig?.schema, location: 'europe-west2' };
      break;

    case Warehouse.FIREBOLT:
      warehouseConfig = { ...fireboltConfig, datasetName: fireboltConfig?.schema, location: 'europe-west2' };
      break;

    case Warehouse.DATABRICKS:
      warehouseConfig = { ...databricksConfig, datasetName: databricksConfig?.schema, location: 'europe-west2' };
      break;

    case Warehouse.DUCK_DB:
    case Warehouse.MOTHERDUCK:
      warehouseConfig = { ...duckdbConfig };
      break;

    default:
      break;
  }
  return warehouseConfig;
};

export const getIncompleteParameters = ({
  databaseType,
  bigqueryOrgConfig,
  snowflakeOrgConfig,
  redshiftOrgConfig,
  fireboltOrgConfig,
  databricksOrgConfig,
  duckdbOrgConfig,
  accessLevel,
  env,
}: warehouseIncompleteConfigPlusEnv) => {
  const newParams: ConnectionParameter[] = [];

  switch (databaseType) {
    case Warehouse.SNOWFLAKE:
      switch (env) {
        case WarehouseEnv.PROD:
        case WarehouseEnv.DEV:
          newParams.push({
            name: 'profile',
            value: snowflakeOrgConfig!.connectionName,
          });
          newParams.push({
            name: 'target name',
            value: snowflakeOrgConfig!.targetName,
          });
          break;

        default:
          if (userHasAccountSettingsConnectionsAdminAccess(accessLevel!)) {
            newParams.push({
              name: 'target name',
              value: snowflakeOrgConfig!.targetName,
            });
          }
          break;
      }
      break;

    case Warehouse.FIREBOLT:
      switch (env) {
        case WarehouseEnv.PROD:
        case WarehouseEnv.DEV:
          newParams.push({
            name: 'profile',
            value: fireboltOrgConfig!.connectionName,
          });
          newParams.push({
            name: 'target name',
            value: fireboltOrgConfig!.targetName,
          });
          break;

        default:
          if (userHasAccountSettingsConnectionsAdminAccess(accessLevel!)) {
            newParams.push({
              name: 'target name',
              value: fireboltOrgConfig!.targetName,
            });
          }
          break;
      }
      break;

    case Warehouse.REDSHIFT:
      switch (env) {
        case WarehouseEnv.PROD:
        case WarehouseEnv.DEV:
          newParams.push({
            name: 'profile',
            value: redshiftOrgConfig!.connectionName,
          });
          newParams.push({
            name: 'target name',
            value: redshiftOrgConfig!.targetName,
          });
          break;
        default:
          break;
      }
      break;

    case Warehouse.BIGQUERY:
      switch (env) {
        case WarehouseEnv.PROD:
        case WarehouseEnv.DEV:
          newParams.push({
            name: 'profile',
            value: bigqueryOrgConfig!.connectionName,
          });
          newParams.push({
            name: 'target name',
            value: bigqueryOrgConfig!.targetName,
          });
          break;
        case WarehouseEnv.METADATA:
          // metadata currently returns dummy data
          break;
        case WarehouseEnv.COSTS:
          newParams.push({
            name: 'project name',
            value: (bigqueryOrgConfig as GqlBigqueryConfig)!.costProjectId || '',
          });

          newParams.push({
            name: 'GCS bucket name',
            value: (bigqueryOrgConfig as GqlBigqueryConfig)!.costGcsBucketName || '',
          });
          break;

        default:
          if (userHasAccountSettingsConnectionsAdminAccess(accessLevel!)) {
            newParams.push({
              name: 'project name',
              value: bigqueryOrgConfig!.connectionName,
            });
          }
          break;
      }
      break;

    case Warehouse.DATABRICKS:
      switch (env) {
        case WarehouseEnv.PROD:
        case WarehouseEnv.DEV:
          newParams.push({
            name: 'profile',
            value: databricksOrgConfig!.connectionName,
          });
          newParams.push({
            name: 'target name',
            value: databricksOrgConfig!.targetName,
          });
          break;
        case WarehouseEnv.METADATA:
          // metadata currently returns dummy data
          break;

        default:
          if (userHasAccountSettingsConnectionsAdminAccess(accessLevel!)) {
            newParams.push({
              name: 'project name',
              value: databricksOrgConfig!.connectionName,
            });
          }
          break;
      }
      break;

    case Warehouse.DUCK_DB:
    case Warehouse.MOTHERDUCK:
      switch (env) {
        case WarehouseEnv.PROD:
        case WarehouseEnv.DEV:
          newParams.push({
            name: 'profile',
            value: duckdbOrgConfig!.connectionName,
          });
          newParams.push({
            name: 'target name',
            value: duckdbOrgConfig!.targetName,
          });
          break;
        case WarehouseEnv.METADATA:
          // metadata currently returns dummy data
          break;

        default:
          if (userHasAccountSettingsConnectionsAdminAccess(accessLevel!)) {
            newParams.push({
              name: 'project name',
              value: duckdbOrgConfig!.connectionName,
            });
          }
          break;
      }
      break;

    default:
      break;
  }

  return newParams;
};
