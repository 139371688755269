import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import CodeSnippetCopy from '../../../Common/Components/CodeSnippetCopy';
import { CodeTypography } from '../../Bolt.styles';
import astro from './images/astro.png';
import pypi from './images/pypi.svg';

interface DAGProps {
  scheduleName: string,
}

const DAG:FC<DAGProps> = ({
  scheduleName,
}) => {
  const scheduleCode = `
from airflow.decorators import dag

from paradime_dbt_provider.operators.paradime import ParadimeBoltDbtScheduleRunArtifactOperator, ParadimeBoltDbtScheduleRunOperator
from paradime_dbt_provider.sensors.paradime import ParadimeBoltDbtScheduleRunSensor

PARADIME_CONN_ID = "<paradime-connection-id>"  # Update this to your connection id
BOLT_SCHEDULE_NAME = "${scheduleName}"  # Update this to your schedule name


@dag(
    default_args={"conn_id": PARADIME_CONN_ID},
)
def run_schedule_and_download_manifest():
    # Run the schedule and return the run id as the xcom return value
    task_run_schedule = ParadimeBoltDbtScheduleRunOperator(task_id="run_schedule", schedule_name=BOLT_SCHEDULE_NAME)

    # Get the run id from the xcom return value
    run_id = "{{ task_instance.xcom_pull(task_ids='run_schedule') }}"

    # Wait for the schedule to complete before continuing
    task_wait_for_schedule = ParadimeBoltDbtScheduleRunSensor(task_id="wait_for_schedule", run_id=run_id)

    # Download the manifest.json file from the schedule run and return the path as the xcom return value
    task_download_manifest = ParadimeBoltDbtScheduleRunArtifactOperator(task_id="download_manifest", run_id=run_id, artifact_path="target/manifest.json")

    # Get the path to the manifest.json file from the xcom return value
    output_path = "{{ task_instance.xcom_pull(task_ids='download_manifest') }}"

    task_run_schedule >> task_wait_for_schedule >> task_download_manifest


run_schedule_and_download_manifest()
  
`;

  return (
    <>
      <CodeTypography type="body-small" color="default" colorStep="50" tagName="span">
        <br />
        Example DAG to trigger this schedule in Airflow, wait for the task to finish and
        then finally download the manifest.json.
      </CodeTypography>
      <CodeSnippetCopy codeSnippet={scheduleCode} language="python" />
      <AutoLayout
        direction="horizontal"
        padding="none"
        distribution="packed"
        horizontalGap="very-dense"
      >
        <DefaultButton
          dense
          view="outlined"
          icon={<img src={astro} height={20} alt="astronomer registry" />}
          text="Astronomer Registry"
          eventContext={Contexts.APP}
          eventObject="goToAstronomer"
          eventAction={Actions.CLICKED}
          onClick={() => window.open(
            'https://registry.astronomer.io/providers/airflow-provider-paradime-dbt/versions/latest',
            '_blank',
          )}
          style={{ width: 'fit-content', textTransform: 'none' }}
        />
        <DefaultButton
          dense
          view="outlined"
          icon={<img src={pypi} height={20} alt="pypi registry" />}
          text="PyPi Registry"
          eventContext={Contexts.APP}
          eventObject="goToPyPi"
          eventAction={Actions.CLICKED}
          onClick={() => window.open(
            'https://pypi.org/project/airflow-provider-paradime-dbt/',
            '_blank',
          )}
          style={{ width: 'fit-content', textTransform: 'none' }}
        />
      </AutoLayout>
    </>
  );
};

export default DAG;
