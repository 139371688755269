import React, { FC } from 'react';
import ZeroState, { ParadimeImages } from '@paradime-io/pragma-ui-kit/lib/components/ZeroState';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { useHeaderContext } from '../useHeaderContext';
import { subtitleTypes } from '../Header';
import { BoltHelp } from '../../../Common/AppHelp/help';

export interface FetchModelsFailedProps {
  backendError: string,
}

const FetchModelsFailed: FC<FetchModelsFailedProps> = ({ backendError }) => {
  useHeaderContext({
    subtitleType: subtitleTypes.GET_STARTED,
    buttonType: 'get',
    buttonIsVisible: true,
    showHeader: true,
  });

  return (
    <ZeroState
      image={ParadimeImages.INCOMPLETE_LIGHT}
      text={(
        <AutoLayout
          padding="none"
          direction="vertical"
          verticalGap="super-ultra-dense"
          alignment="center"
          wrapperStyle={{ justifyContent: 'center' }}
          style={{ width: '300px' }}
        >
          <Typography
            type="body-bold-small"
            style={{ textAlign: 'center' }}
            color="default"
            colorStep="100"
          >
            Unable to fetch models from your dbt project
          </Typography>
          <Typography
            type="caption"
            style={{ textAlign: 'center' }}
            color="default"
            colorStep="70"
          >
            We&apos;ve encountered an issue fetching models from your dbt project.
          </Typography>
        </AutoLayout>
      )}
      infoCard={backendError}
      buttons={[
        {
          onClick: () => (
            window.CommandBar.openHelpHub(
              { articleId: BoltHelp.THOUGHTSPOT },
            )
          ),
          text: 'Help me fix this',
          dense: true,
          eventContext: Contexts.LOGS,
          eventObject: 'showThoughtspotHelp',
          eventAction: Actions.CLICKED,
        },
      ]}
    />
  );
};

export default FetchModelsFailed;
