import { useEffect } from 'react';
import { useMarkStepCompleted } from '@paradime/common/lib/common/helpers/frigadeHelperFunctions';
import useGetConnectionsConfig from '../../AccountSettings/Connections/hooks/useGetConnectionsConfig';
import useGetSchedulesAndBranches from '../../Bolt/hooks/useGetSchedulesAndBranches';
import { useOrganisationIntegrationListQuery } from '../../../client/generated/service';
import { RadarFlows } from '../../Common/Frigade/flows';
import { RadarSetupSteps } from '../Setup';

const useGetSetupState = () => {
  const { markStepCompleted } = useMarkStepCompleted(RadarFlows.SETUP_FLOW);
  const {
    isProdWarehouseConnected, productionLoading,
    isCostWarehouseConnected, costsLoading,
  } = useGetConnectionsConfig();

  const { isLoading: isLoadingSchedules, areSchedulesDefined } = useGetSchedulesAndBranches();

  useEffect(() => {
    if (isProdWarehouseConnected) {
      markStepCompleted(RadarSetupSteps.SETUP_PROD_WAREHOUSE);
    }
  }, [isProdWarehouseConnected]);

  useEffect(() => {
    if (areSchedulesDefined) {
      markStepCompleted(RadarSetupSteps.CREATE_BOLT_SCHEDULE);
    }
  }, [areSchedulesDefined]);

  const { loading: isLoadingIntegrations } = useOrganisationIntegrationListQuery({
    onCompleted: (data) => {
      if (data.organisationIntegrationList.githubApp === 'active') {
        markStepCompleted(RadarSetupSteps.CONNECT_GITHUB);
      }
    },
  });

  useEffect(() => {
    if (isCostWarehouseConnected) {
      markStepCompleted(RadarSetupSteps.SETUP_COST_CONNECTION);
    }
  }, [isCostWarehouseConnected]);

  return {
    isLoadingSetupState: productionLoading
    || costsLoading
    || isLoadingSchedules
    || isLoadingIntegrations,
  };
};

export { useGetSetupState };
