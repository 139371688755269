/**
 * there's 2 ts-ignores in this file
 * because typescript 4 still doesn't have the
 * proper type for `Intl.RelativeTimeFormatUnit`
 *
 *
 * this should be updated once it has it
 *
 * ---
 *
 * the type is supported on all major browsers
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat
 */

import { DateTime } from 'luxon';

// @ts-ignore
export const units: Intl.RelativeTimeFormatUnit[] = [
  'year',
  'month',
  'week',
  'day',
  'hour',
  'minute',
  'second',
];

export const getRelativeTime = (dateTime: DateTime) => {
  const diff = dateTime.diffNow().shiftTo(...units);
  const unit = units.find((u) => diff.get(u) !== 0) || 'second';

  // @ts-ignore
  const relativeFormatter = new Intl.RelativeTimeFormat('en', {
    numeric: 'auto',
  });
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
};
