import { useContext } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TabData } from '@paradime-io/pragma-ui-kit/lib/components/TabsDefault';
import { RadarHelp } from '../Common/AppHelp/help';
import upgradeSchedules from './images/schedules.svg';
import upgradeModels from './images/models.svg';
import upgradeTests from './images/tests.svg';
import upgradeSources from './images/sources.svg';
import upgradeWork from './images/teamwork.svg';
import upgradeCost from './images/costs.svg';
import { RadarWorkspaceContext } from '.';

export enum RadarTab {
  SETUP = 'setup',
  SCHEDULES = 'schedules',
  MODELS = 'models',
  SOURCES = 'sources',
  TESTS = 'tests',
  WORK = 'work',
  COST = 'cost',
  BI_LOOKER = 'bi-looker',
  ALERTS = 'alerts',
}

export const getRadarPagesConfig = () => ({
  [RadarTab.SETUP]: {
    title: 'Quick setup',
    showHeaderButton: false,
  },
  [RadarTab.SCHEDULES]: {
    title: 'Schedules',
    helpArticleId: RadarHelp.SCHEDULES,
    showHeaderButton: false,
  },
  [RadarTab.MODELS]: {
    title: 'Models',
    helpArticleId: RadarHelp.MODELS,
    showHeaderButton: false,
  },
  [RadarTab.TESTS]: {
    title: 'Tests',
    helpArticleId: RadarHelp.TESTS,
    showHeaderButton: false,
  },
  [RadarTab.SOURCES]: {
    title: 'Sources',
    helpArticleId: RadarHelp.SOURCES,
    showHeaderButton: false,
  },
  [RadarTab.WORK]: {
    title: 'Team work',
    helpArticleId: RadarHelp.WORK,
    showHeaderButton: false,
  },
  [RadarTab.COST]: {
    title: 'Costs',
    helpArticleId: RadarHelp.COST,
    showHeaderButton: false,
  },
  [RadarTab.BI_LOOKER]: {
    title: 'Looker',
    helpArticleId: RadarHelp.COST,
    showHeaderButton: false,
  },
  [RadarTab.ALERTS]: {
    title: 'Data Alerts',
    helpArticleId: RadarHelp.COST,
    showHeaderButton: false,
  },
});

export const supportedCostWarehouses = [
  'snowflake',
  'bigquery',
];

export const upgradeImagePaths = {
  [RadarTab.SETUP]: {},
  [RadarTab.SCHEDULES]: {
    path: upgradeSchedules,
  },
  [RadarTab.MODELS]: {
    path: upgradeModels,
  },
  [RadarTab.TESTS]: {
    path: upgradeTests,
  },
  [RadarTab.SOURCES]: {
    path: upgradeSources,
  },
  [RadarTab.WORK]: {
    path: upgradeWork,
  },
  [RadarTab.COST]: {
    path: upgradeCost,
  },
  [RadarTab.BI_LOOKER]: {
    path: upgradeCost,
  },
};

export enum DashboardTabIds {
  OVERVIEW = 'overview',
  DETAILED = 'detailed',
  EXPLORES = 'explores',
  DASHBOARDS = 'dashboards',
  COST_OPTIMISATIONS = 'cost-optimisations',
  PULL_REQUESTS = 'pull-requests',
  COMMITS = 'commits',
  SNOWFLAKE = 'snowflake',
  BIGQUERY = 'bigquery',
}

export type RadarDashboardTabsType = Exclude<RadarTab, RadarTab.SETUP>;

type RadarTabsType = {[key in RadarTab]: TabData[]}

const overviewTab = { id: DashboardTabIds.OVERVIEW, title: 'Overview', visible: true };
const detailedTab = { id: DashboardTabIds.DETAILED, title: 'Detailed', visible: true };

export const useGetRadarTabsData = (): RadarTabsType => {
  const { flagRadarCostOptimisations } = useFlags();
  const { costWarehouseType } = useContext(RadarWorkspaceContext);

  const isSnowflakeWarehouse = costWarehouseType === 'snowflake';
  const isBigqueryWarehouse = costWarehouseType === 'bigquery';

  return ({
    [RadarTab.SCHEDULES]: [overviewTab, detailedTab],
    [RadarTab.MODELS]: [overviewTab, detailedTab],
    [RadarTab.SOURCES]: [overviewTab, detailedTab],
    [RadarTab.TESTS]: [overviewTab, detailedTab],
    [RadarTab.WORK]: [
      { id: DashboardTabIds.PULL_REQUESTS, title: 'Pull Requests', visible: true },
      { id: DashboardTabIds.COMMITS, title: 'Commits', visible: true },
    ],
    [RadarTab.COST]: [
      { id: DashboardTabIds.SNOWFLAKE, title: 'Monitoring', visible: isSnowflakeWarehouse },
      { id: DashboardTabIds.BIGQUERY, title: 'Overview', visible: isBigqueryWarehouse },
      { id: DashboardTabIds.COST_OPTIMISATIONS, title: 'Optimizations', visible: flagRadarCostOptimisations && isSnowflakeWarehouse },
      { id: DashboardTabIds.DETAILED, title: 'Details', visible: flagRadarCostOptimisations && isBigqueryWarehouse },
    ],
    [RadarTab.BI_LOOKER]: [
      {
        id: DashboardTabIds.EXPLORES, title: 'Explores', visible: true,
      },
      {
        id: DashboardTabIds.DASHBOARDS, title: 'Dashboards', visible: true,
      },
    ],
    [RadarTab.ALERTS]: [overviewTab],
    [RadarTab.SETUP]: [],
  });
};

export const AlertsConfigData = [{
  id: 'schedule-time-guard',
  name: 'Schedule Time Guard',
  category: 'Bolt',
  description: 'Alert is triggered when schedule duration exceeds last 7 day average by threshold',
}];
