import React, {
  Dispatch, FC, SetStateAction, useState, useEffect,
} from 'react';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { useHistory, useLocation } from 'react-router-dom';
import Panel from '@paradime-io/pragma-ui-kit/lib/components/Panel';
import { ButtonProps, ChildComponents } from '@paradime-io/pragma-ui-kit/lib/components/Panel/useGetPanelComponents';
import { RadarTab } from './config';

export interface SidebarProps {
  isLeftPanelOpen: boolean,
  setIsLeftPanelOpen: Dispatch<SetStateAction<boolean>>,
}

const Sidebar: FC<SidebarProps> = ({
  isLeftPanelOpen,
  setIsLeftPanelOpen,
}) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const [selectedPanelButton, setSelectedPanelButton] = useState(RadarTab.SCHEDULES);

  useEffect(() => {
    if (pathname.includes(RadarTab.SETUP)) setSelectedPanelButton(RadarTab.SETUP);
    if (pathname.includes(RadarTab.SCHEDULES)) setSelectedPanelButton(RadarTab.SCHEDULES);
    if (pathname.includes(RadarTab.MODELS)) setSelectedPanelButton(RadarTab.MODELS);
    if (pathname.includes(RadarTab.SOURCES)) setSelectedPanelButton(RadarTab.SOURCES);
    if (pathname.includes(RadarTab.TESTS)) setSelectedPanelButton(RadarTab.TESTS);
    if (pathname.includes(RadarTab.WORK)) setSelectedPanelButton(RadarTab.WORK);
    if (pathname.includes(RadarTab.COST)) setSelectedPanelButton(RadarTab.COST);
    if (pathname.includes(RadarTab.BI_LOOKER)) setSelectedPanelButton(RadarTab.BI_LOOKER);
    if (pathname.includes(RadarTab.ALERTS)) setSelectedPanelButton(RadarTab.ALERTS);
  }, [pathname]);

  const handleButtonClick = (buttonName: RadarTab, route: string) => {
    setSelectedPanelButton(buttonName);
    history.push(route);
  };

  const panelButtons = [
    {
      type: ChildComponents.BUTTON,
      icon: 'cog',
      color: 'primary',
      text: 'Setup',
      buttonType: 'standard',
      onClick: () => handleButtonClick(RadarTab.SETUP, `/radar/${RadarTab.SETUP}`),
      isSelected: selectedPanelButton === RadarTab.SETUP,
    },
    {
      type: ChildComponents.BUTTON,
      icon: 'stopwatch',
      color: 'primary',
      text: 'Schedules',
      buttonType: 'standard',
      onClick: () => handleButtonClick(RadarTab.SCHEDULES, `/radar/${RadarTab.SCHEDULES}`),
      isSelected: selectedPanelButton === RadarTab.SCHEDULES,
    },
    {
      type: ChildComponents.BUTTON,
      icon: 'one-to-many',
      color: 'primary',
      text: 'Models',
      buttonType: 'standard',
      onClick: () => handleButtonClick(RadarTab.MODELS, `/radar/${RadarTab.MODELS}`),
      isSelected: selectedPanelButton === RadarTab.MODELS,
    },
    {
      type: ChildComponents.BUTTON,
      icon: 'layout-grid',
      color: 'primary',
      text: 'Sources',
      buttonType: 'standard',
      onClick: () => handleButtonClick(RadarTab.SOURCES, `/radar/${RadarTab.SOURCES}`),
      isSelected: selectedPanelButton === RadarTab.SOURCES,
    },
    {
      type: ChildComponents.BUTTON,
      icon: 'doughnut-chart',
      color: 'primary',
      text: 'Tests',
      buttonType: 'standard',
      onClick: () => handleButtonClick(RadarTab.TESTS, `/radar/${RadarTab.TESTS}`),
      isSelected: selectedPanelButton === RadarTab.TESTS,
    },
    {
      type: ChildComponents.BUTTON,
      icon: 'form',
      color: 'primary',
      text: 'Work',
      buttonType: 'standard',
      onClick: () => handleButtonClick(RadarTab.WORK, `/radar/${RadarTab.WORK}`),
      isSelected: selectedPanelButton === RadarTab.WORK,
    },
    {
      type: ChildComponents.BUTTON,
      icon: 'dollar',
      color: 'primary',
      text: 'Costs',
      buttonType: 'standard',
      onClick: () => handleButtonClick(RadarTab.COST, `/radar/${RadarTab.COST}`),
      isSelected: selectedPanelButton === RadarTab.COST,
    },
    {
      type: ChildComponents.BUTTON,
      icon: 'timeline-bar-chart',
      color: 'primary',
      text: 'Looker',
      buttonType: 'standard',
      onClick: () => handleButtonClick(RadarTab.BI_LOOKER, `/radar/${RadarTab.BI_LOOKER}`),
      isSelected: selectedPanelButton === RadarTab.BI_LOOKER,
    },
    {
      type: ChildComponents.BUTTON,
      icon: 'pulse',
      color: 'primary',
      text: 'Data Alerts',
      buttonType: 'standard',
      onClick: () => handleButtonClick(RadarTab.ALERTS, `/radar/${RadarTab.ALERTS}`),
      isSelected: selectedPanelButton === RadarTab.ALERTS,
    },
  ] as ButtonProps[];

  return (
    <>
      <Panel
        type="drawer"
        isExpanded={isLeftPanelOpen}
        expose={(panelIsOpen: boolean) => setIsLeftPanelOpen(panelIsOpen)}
        eventContext={Contexts.RADAR}
        topComponents={panelButtons}
        panelID="bolt"
        rememberLastOpenState
      />
    </>
  );
};

export default Sidebar;
