import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import * as S from './CostOptimisations.styles';

export interface BarChartLegendProps {
  entries: { color: string, text: string }[],
}

const BarChartLegend: FC<BarChartLegendProps> = ({ entries }) => (
  <AutoLayout
    direction="horizontal"
    padding="none"
    horizontalGap="dense"
    distribution="packed"
  >
    {entries.map(({ color, text }) => (
      <AutoLayout
        direction="horizontal"
        padding="none"
        horizontalGap="very-dense"
        distribution="packed"
      >
        <S.Dot color={color} />
        <Typography
          font="inter"
          type="body-small"
          color="default"
          colorStep="100"
        >
          {text}
        </Typography>
      </AutoLayout>
    ))}
  </AutoLayout>
);

export default BarChartLegend;
