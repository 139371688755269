/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react';
import { underline, lightGray } from 'ansicolor';
import BoltDetailsCard from '@paradime-io/pragma-ui-kit/lib/components/BoltDetailsCard';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import CodeBox from '@paradime-io/pragma-ui-kit/lib/components/CodeBox';
import { DateTime } from 'luxon';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { GetScheduleRunQuery, useAiExplainDbtCommandOutputLazyQuery } from '../../../../client/generated/service';
import TabsRadio, { TabData } from '../TabsRadio';
import CommandLogsHeader from './CommandLogsHeader';
import DebugLogs from './DebugLogs';
import useViewArtifact from '../../hooks/useViewArtifact';
import { boltStore } from '../../../../stores';
import { parseStatsDbtLogs, RuntimeStatsProblemLineIndices } from './LogsParser';
import CommandAiSummary from './CommandAiSummary';

const padNumber = (number: number) => number.toString().padStart(2, '0');

export interface CommandProps extends NonNullable<NonNullable<NonNullable<GetScheduleRunQuery['getScheduleRun']>['commands']>[number]> {
  initialIsOpen?: boolean,
  commandId: number,
}

enum logsTabIds {
  LOGS_SUMMARY = 'logs_summary',
  CONSOLE_LOGS = 'console_logs',
  DEBUG_LOGS = 'debug_logs'
}

const Command: FC<CommandProps> = ({
  commandId,
  command,
  endDttm,
  output,
  startDttm,
  state,
  initialIsOpen = false,
  logScheduleCommandResourceId,
}) => {
  const { viewArtifactSqlFromPath } = useViewArtifact();
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const [problemLineIndices, setProblemLineIndices] = useState<RuntimeStatsProblemLineIndices>();
  const [
    currentVisibleConsoleLogsLineIndex, setCurrentVisibleConsoleLogsLineIndex,
  ] = useState<number>(0);
  const [
    previousVisibleConsoleLogsLineIndex, setPreviousVisibleConsoleLogsLineIndex,
  ] = useState<number>(0);
  const [highlightClass, setHighlightClass] = useState('highlightError');
  const [currentTabId, setCurrentTabId] = useState(
    endDttm ? logsTabIds.LOGS_SUMMARY : logsTabIds.CONSOLE_LOGS,
  );
  const [commandStats, setCommandStats] = useState<string>();
  const [commandAiSummary, setCommandAiSummary] = useState<string>();
  const expandCommandId = boltStore((s) => s.expandCommandId);
  const setExpandCommandId = boltStore((s) => s.setExpandCommandId);
  const [
    getCommandAiSummary,
    { loading: isLoadingAiCommandSummary },
  ] = useAiExplainDbtCommandOutputLazyQuery({
    variables: {
      scheduleCommandId: commandId,
    },
    onCompleted: (data) => {
      if (data.aiExplainDbtCommandOutput?.message) {
        setCommandAiSummary(data.aiExplainDbtCommandOutput.message);
      }
    },
  });

  const rightIcon = () => {
    if (output) return isOpen ? 'double-chevron-down' : 'double-chevron-right';

    return undefined;
  };

  const logsTabs: TabData[] = [
    {
      id: logsTabIds.LOGS_SUMMARY,
      title: '✨ Summary',
    },
    {
      id: logsTabIds.CONSOLE_LOGS,
      title: 'Console Logs',
    },
    {
      id: logsTabIds.DEBUG_LOGS,
      title: 'Debug Logs',
      disabled: !logScheduleCommandResourceId, // deal with 0 value...
    },
  ];

  const runtimeStatsOutput = () => {
    const parsedData = parseStatsDbtLogs(output || '');
    if (parsedData.problemLines) {
      setProblemLineIndices(parsedData.problemLines);
    }

    if (parsedData.runtimeStats && parsedData.runtimeStats.total > 0) {
      return ([
        `${parsedData.runtimeStats.running} running`,
        `${parsedData.runtimeStats.pass} pass`,
        `${parsedData.runtimeStats.warn} warn`,
        `${parsedData.runtimeStats.error} error`,
        `${parsedData.runtimeStats.skip} skip`,
        `${parsedData.runtimeStats.total} total`,
      ].join(', '));
    }

    return undefined;
  };

  useEffect(() => {
    if (initialIsOpen) setIsOpen(initialIsOpen);
  }, [initialIsOpen]);

  useEffect(() => {
    // open the card when there is a commandId to be expanded
    if (expandCommandId && expandCommandId === commandId) {
      setIsOpen(true);
    }
  }, [expandCommandId]);

  useEffect(() => {
    // set expand command id to false when the card is closed
    if (!isOpen) {
      setExpandCommandId(undefined);
    }

    if (isOpen && currentTabId === logsTabIds.LOGS_SUMMARY && endDttm !== undefined) {
      getCommandAiSummary();
    }
  }, [isOpen, currentTabId, endDttm]);

  useEffect(() => {
    if (output) {
      const tempStats = runtimeStatsOutput();
      setCommandStats(tempStats);
    }
  }, [output]);

  useEffect(() => {
    if (endDttm && startDttm) {
      const startDateTime = DateTime.fromISO(startDttm);
      const endDateTime = DateTime.fromISO(endDttm);
      const duration = endDateTime.diff(startDateTime).shiftTo('minutes', 'seconds', 'milliseconds');
      const commandDuration = [
        padNumber(duration.minutes),
        padNumber(duration.seconds),
        padNumber(duration.milliseconds),
      ].join(':');

      setCommandStats((stats) => (
        stats ? [commandDuration, stats].join(' | ') : commandDuration
      ));
    }
  }, [endDttm, startDttm]);

  return (
    <div id={`command-${commandId}`}>
      <BoltDetailsCard
        components={[
          {
            type: 'row',
            items: [
              {
                type: 'column',
                items: [
                  {
                    reactNode: (
                      <AutoLayout direction="horizontal" horizontalGap="very-dense" padding="none" style={{ gridTemplateColumns: 'auto auto' }}>
                        {state.text !== 'running' && <Chips color={state.colorType as colorType} tag={state.text} type="dense" />}
                        <AutoLayout direction="vertical" padding="none" verticalGap="none">
                          <Typography type="body-bold-small" style={{ overflowWrap: 'break-word' }}>{command}</Typography>
                          {state.text !== 'failed' && (
                            <Typography colorStep="60" type="caption-small">{commandStats}</Typography>
                          )}
                        </AutoLayout>
                      </AutoLayout>
                    ),
                    type: 'reactNode',
                  },
                ],
              },
            ],
          },
        ]}
        onClick={() => output && setIsOpen((s) => !s)}
        raiseOnHover
        rightIcon={rightIcon()}
        rightIconSize={24}
        slim
        isOpen={isOpen}
        loading={state.text === 'running'}
      >
        <div style={{ position: 'relative' }}>
          <div
            style={{
              position: 'absolute', left: 16, top: 16, zIndex: 10,
            }}
          >
            <TabsRadio
              data={logsTabs}
              currentTabId={currentTabId}
              exposeOnChange={
                (newCurrentTabId) => setCurrentTabId(newCurrentTabId as logsTabIds)
              }
            />
          </div>
          {
            currentTabId === logsTabIds.LOGS_SUMMARY && (
              <CommandAiSummary
                isLoading={isLoadingAiCommandSummary || endDttm === undefined}
                summary={commandAiSummary}
              />
            )
          }
          {
            currentTabId === logsTabIds.CONSOLE_LOGS && (
              <CodeBox
                backgroundColor="var(--grey100)"
                colorStep="0"
                lines={output ? output.split('\n') : []}
                maxHeight={512}
                scrollToBottom
                style={{ paddingTop: 50 }}
                currentVisibleLineIndex={currentVisibleConsoleLogsLineIndex}
                onChangeCurrentVisibleLineIndex={setCurrentVisibleConsoleLogsLineIndex}
                highlightCurrentLine={{
                  className: highlightClass,
                  previousLine: previousVisibleConsoleLogsLineIndex,
                  setPreviousLine: setPreviousVisibleConsoleLogsLineIndex,
                }}
                boxHeader={(
                  <CommandLogsHeader
                    command={command}
                    logLines={output ? output.split('\n') : []}
                    problemLines={problemLineIndices}
                    scrollToLineIndex={currentVisibleConsoleLogsLineIndex}
                    setScrollToLineIndex={setCurrentVisibleConsoleLogsLineIndex}
                    setPreviousScrollToLineIndex={setPreviousVisibleConsoleLogsLineIndex}
                    setHighlightClass={setHighlightClass}
                  />
                )}
                spanClickHandlers={[
                  {
                    regex: /.*\s*compiled code at\s*(.*)/i,
                    matchStyle: {
                      cursor: 'pointer',
                    },
                    onMatch: (line: string, matchedText: string) => {
                      const formattedMatchedText = lightGray(underline(matchedText));
                      return line.replace(matchedText, formattedMatchedText);
                    },
                    onMatchClick: (compiledSqlPath: string) => viewArtifactSqlFromPath(
                      commandId, compiledSqlPath,
                    ),
                  },
                  {
                    regex: /(.*No comparison.*)/i,
                    matchStyle: {
                      color: 'var(--red70)',
                      backgroundColor: 'var(--red5)',
                      borderRadius: '4px',
                      padding: '2px 8px',
                      animation: 'highlightFadeIn 300ms',
                    },
                    onMatch: (line: string, matchedText: string) => {
                      const formattedMatchedText = matchedText;
                      return line.replace(matchedText, formattedMatchedText);
                    },
                  },
                ]}
              />
            )
          }
          {
            currentTabId === logsTabIds.DEBUG_LOGS && logScheduleCommandResourceId
            && <DebugLogs commandResourceId={logScheduleCommandResourceId} />
          }
        </div>
      </BoltDetailsCard>
    </div>
  );
};

export default Command;
