import React, { FC } from 'react';
import styled from 'styled-components';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DataEditor from '@paradime-io/pragma-ui-kit/lib/components/DataEditor';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import Callout from '@paradime-io/pragma-ui-kit/lib/components/Callout';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { useHeaderContext } from './useHeaderContext';
import { subtitleTypes } from './Header';

export interface SuccessPageProps {
  worksheets: string[],
  thoughtSpotUrl: string,
}

const CalloutWithAdjustedMargin = styled(Callout)`
  p {
    margin: 4px !important;
  }
`;

const SuccessPage: FC<SuccessPageProps> = ({
  worksheets,
  thoughtSpotUrl,
}) => {
  useHeaderContext({
    subtitleType: subtitleTypes.GET_STARTED,
    buttonType: 'get',
    buttonIsVisible: true,
    showHeader: true,
  });

  const columns = [
    { id: '1', title: 'worksheet' },
  ];

  const mapBEDataToColumns = (listOfWorksheets?: string[] | null) => (
    (listOfWorksheets || []).map((worksheet) => ({ worksheet }))
  );

  return (
    <AutoLayout
      direction="vertical"
      verticalGap="normal"
      padding="none"
      distribution="packed"
    >
      <CalloutWithAdjustedMargin
        icon="info-sign"
        color="success"
        title=""
        view="smooth"
        content={(
          <AutoLayout
            direction="horizontal"
            padding="none"
            distribution="space-between"
          >
            <Typography
              font="inter"
              type="h6"
              color="success"
              colorStep="100"
            >
              Worksheets successfully created
            </Typography>
            <DefaultButton
              type="standard"
              view="outlined"
              color="success"
              dense
              text="View in ThoughtSpot"
              integrationIcon="thoughtSpot"
              onClick={() => window.open(thoughtSpotUrl)}
              eventContext={Contexts.LOGS}
              eventObject="viewModelsInThoughtSpot"
              eventAction={Actions.CLICKED}
              style={{ background: 'var(--white)' }}
            />
          </AutoLayout>
        )}
      />
      <DataEditor
        cols={columns}
        data={mapBEDataToColumns(worksheets)}
        theme={{
          accentColor: '#635AE0', // violet70
        }}
        showRowNumbers={false}
      />
    </AutoLayout>
  );
};

export default SuccessPage;
