import React, { FC, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { Provider } from '@frigade/react';
import { getFrigadeApperanceConfig } from '@paradime/common/lib/common/helpers/frigadeAppearanceConfig';
import { isDevEnvironment } from '../utilis/common';
import { useGetUserIdentifyingProperties } from '../utilis/useGetUserIdentifyingProperties';

export interface FrigadeWrapperProps {
  children?: ReactNode,
}

const FrigadeWrapper: FC<FrigadeWrapperProps> = ({ children }) => {
  const history = useHistory();

  const userIdentifyingProperties = useGetUserIdentifyingProperties();
  const workspaceToken = userIdentifyingProperties?.workspaceToken;
  const userId = userIdentifyingProperties?.backendUserId;

  if (workspaceToken && userId) {
    return (
      <Provider
        apiKey="api_public_BK4ApAbfsQgqHTcyHVXGC8zUv8LwmpujNDreGKG6R5Ss7IljyGU6h0KOyUeta2uL"
        theme={getFrigadeApperanceConfig()}
        userId={isDevEnvironment ? 'demotrooper@paradime.io' : `${workspaceToken}-${userId}`}
        userProperties={{ organizationId: workspaceToken }}
        navigate={(url, target): void => {
          if (target === '_blank') {
            window.open(url, target);
          } else {
            history.push(url);
          }
        }}
      >
        {children}
      </Provider>
    );
  }

  return <>{children}</>;
};

export default FrigadeWrapper;
