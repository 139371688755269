import React, {
  Dispatch, FC, SetStateAction, useState, useEffect,
} from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { useHistory, useLocation } from 'react-router-dom';
import Panel from '@paradime-io/pragma-ui-kit/lib/components/Panel';
import { ButtonProps, ChildComponents } from '@paradime-io/pragma-ui-kit/lib/components/Panel/useGetPanelComponents';
import { userAuthStore } from '../../stores';
import { BoltTab } from './index';
import {
  userHasBoltThoughtspotDbtSyncAccess,
  userHasAccountSettingsWebhooksAccess,
} from '../../utilis/PermissionsService';

export interface SidebarProps {
  isLeftPanelOpen: boolean,
  setIsLeftPanelOpen: Dispatch<SetStateAction<boolean>>,
}

const Sidebar: FC<SidebarProps> = ({
  isLeftPanelOpen,
  setIsLeftPanelOpen,
}) => {
  const { flagAppBolt } = useFlags();
  const history = useHistory();
  const { pathname } = useLocation();
  const { currentUser: { accessLevel } } = userAuthStore();
  const [selectedPanelButton, setSelectedPanelButton] = useState(BoltTab.BOLT);

  useEffect(() => {
    if (pathname.includes(BoltTab.BOLT)) setSelectedPanelButton(BoltTab.BOLT);
    if (pathname.includes(BoltTab.THOUGHTSPOT)) setSelectedPanelButton(BoltTab.THOUGHTSPOT);
    if (pathname.includes(BoltTab.TURBOCI)) setSelectedPanelButton(BoltTab.TURBOCI);
    if (pathname.includes(BoltTab.TURBOCD)) setSelectedPanelButton(BoltTab.TURBOCD);
    if (pathname.includes(BoltTab.WEBHOOK)) setSelectedPanelButton(BoltTab.WEBHOOK);
  }, [pathname]);

  const handleButtonClick = (buttonName: BoltTab, route: string) => {
    setSelectedPanelButton(buttonName);
    history.push(route);
  };

  const getPanelButtons = () => {
    const panelButtons: ButtonProps[] = [];

    if (flagAppBolt) {
      panelButtons.push(
        {
          type: ChildComponents.BUTTON,
          icon: 'lightning',
          color: 'primary',
          text: 'Bolt',
          buttonType: 'standard',
          onClick: () => handleButtonClick(BoltTab.BOLT, `/${BoltTab.BOLT}`),
          isSelected: selectedPanelButton === BoltTab.BOLT,
        },
        {
          type: ChildComponents.BUTTON,
          icon: 'one-to-many',
          color: 'primary',
          text: 'Turbo CI',
          buttonType: 'standard',
          onClick: () => handleButtonClick(BoltTab.TURBOCI, `/${BoltTab.BOLT}/${BoltTab.TURBOCI}`),
          isSelected: selectedPanelButton === BoltTab.TURBOCI,
        },
        {
          type: ChildComponents.BUTTON,
          icon: 'many-to-one',
          color: 'primary',
          text: 'Turbo CD',
          buttonType: 'standard',
          onClick: () => handleButtonClick(BoltTab.TURBOCD, `/${BoltTab.BOLT}/${BoltTab.TURBOCD}`),
          isSelected: selectedPanelButton === BoltTab.TURBOCD,
        },
        userHasAccountSettingsWebhooksAccess(accessLevel) ? {
          type: ChildComponents.BUTTON,
          icon: 'antenna',
          color: 'primary',
          text: 'Webhooks',
          buttonType: 'standard',
          onClick: () => handleButtonClick(BoltTab.WEBHOOK, `/${BoltTab.BOLT}/${BoltTab.WEBHOOK}`),
          isSelected: selectedPanelButton === BoltTab.WEBHOOK,
        } : {} as ButtonProps,
      );
    }

    if (userHasBoltThoughtspotDbtSyncAccess(accessLevel)) {
      panelButtons.push({
        type: ChildComponents.BUTTON,
        integrationIcon: 'thoughtSpot',
        color: 'primary',
        text: 'ThoughtSpot dbt™ Sync',
        buttonType: 'standard',
        onClick: () => handleButtonClick(BoltTab.THOUGHTSPOT, `/${BoltTab.BOLT}/${BoltTab.THOUGHTSPOT}`),
        isSelected: selectedPanelButton === BoltTab.THOUGHTSPOT,
      });
    }

    return panelButtons;
  };

  return (
    <>
      <Panel
        type="drawer"
        isExpanded={isLeftPanelOpen}
        expose={(panelIsOpen: boolean) => setIsLeftPanelOpen(panelIsOpen)}
        eventContext={Contexts.BOLT}
        topComponents={getPanelButtons()}
        panelID="bolt"
        rememberLastOpenState
      />
    </>
  );
};

export default Sidebar;
