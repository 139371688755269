import React, { FC, useState } from 'react';
import BoltDetailsCard from '@paradime-io/pragma-ui-kit/lib/components/BoltDetailsCard';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import Table, { CellItemType } from '@paradime-io/pragma-ui-kit/lib/components/Table/Table';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { GetScheduleRunQuery } from '../../../client/generated/service';

export type SourceFreshnessCardProps = NonNullable<NonNullable<NonNullable<GetScheduleRunQuery['getScheduleRun']>['freshnessSources']>[number]>;

const header = [
  { id: 'status', label: 'Status' },
  { id: 'tableName', label: 'Name' },
  { id: 'maxLoadedAtDttm', label: 'Loaded' },
  { id: 'criteria', label: 'Criteria' },
];

const SourceFreshnessCard: FC<SourceFreshnessCardProps> = ({
  sourceName,
  tables,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const nonNullTables: NonNullable<NonNullable<SourceFreshnessCardProps>['tables']> = (
    tables && tables?.length > 0
      ? tables.filter((tbl) => tbl)
      : []
  );

  const counts: { [text: string]: { count: number, color: string } } = {};
  tables?.forEach((tbl) => {
    const text = tbl?.status.text;
    if (text) {
      const existingEntry = counts[text];
      if (existingEntry) {
        counts[text] = {
          count: counts[text].count + 1,
          color: tbl.status.colorType,
        };
      } else {
        counts[text] = {
          count: 1,
          color: tbl.status.colorType,
        };
      }
    }
  });

  return (
    <BoltDetailsCard
      components={[
        {
          type: 'row',
          items: [
            {
              type: 'column',
              items: [
                {
                  type: 'reactNode',
                  reactNode: (
                    <AutoLayout
                      direction="horizontal"
                      padding="none"
                      horizontalGap="very-dense"
                    >
                      {Object.entries(counts).map(([name, countAndColor]) => (
                        <Chips
                          color={countAndColor.color as colorType}
                          tag={`${countAndColor.count} ${name}`}
                          interactive={false}
                          type="dense"
                          rightIcon={countAndColor.color === 'danger' ? 'warning-sign' : undefined}
                        />
                      ))}
                      <Typography
                        type="body-small"
                      >
                        {sourceName}
                      </Typography>
                    </AutoLayout>
                  ),
                },
              ],
            },
          ],
        },
      ]}
      rightIcon={isOpen ? 'double-chevron-up' : 'double-chevron-down'}
      rightIconSize={24}
      onClick={() => setIsOpen((o) => !o)}
      isOpen={isOpen}
      raiseOnHover
    >
      <Table
        type="dense"
        view="flat"
        color="default"
        names={header}
        data={nonNullTables.map((table) => {
          if (table) {
            const { status: { text, colorType: subtype } } = table;
            return ({
              ...table,
              status: [{
                value: text,
                type: CellItemType.CHIP,
                subtype,
              }],
              maxLoadedAtDttm: table.maxLoadedAtDttm || '-',
              criteria: table?.criteria || table?.errorString || '-',
            });
          }
          return [];
        }) || []}
        pages={false}
        checkboxes={false}
        position="left"
        labelRowsPerPage="Rows per page"
        selectedItemsLabelIcon="selection"
        eventContext={Contexts.ACCOUNT_SETTINGS}
        style={{
          margin: '4px 0 0 0',
          borderRadius: '9px',
        }}
        paperStyle={{
          width: '100%',
        }}
      />
    </BoltDetailsCard>
  );
};

export default SourceFreshnessCard;
