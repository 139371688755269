import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import Divider from '@paradime-io/pragma-ui-kit/lib/components/Divider';
import Card from '@paradime-io/pragma-ui-kit/lib/components/Card';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { IntegrationItemCard, IntegrationState } from '@paradime-io/pragma-ui-kit/lib/components/IntegrationItemCard';
import { BoltHelp } from '../../../Common/AppHelp/help';
import { useGetTemplateData } from './useGetTemplateData';

const TemplateSchedules: FC = () => {
  const history = useHistory();

  const { templateData } = useGetTemplateData();

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      distribution="packed"
      alignment="top-left"
      verticalGap="normal"
      wrapperWidth="full"
    >
      <AutoLayout
        direction="vertical"
        padding="none"
        distribution="packed"
        verticalGap="none"
      >
        <AutoLayout
          direction="horizontal"
          padding="none"
          distribution="packed"
          horizontalGap="very-dense"
        >
          <Typography type="h6">Create new Bolt schedule</Typography>
          <Chips
            color="primary_alt"
            round={false}
            style={{ margin: '4px', verticalAlign: 'bottom', justifySelf: 'end' }}
            tag="Learn more"
            type="dense"
            view="smooth"
            onClick={() => (
              window.CommandBar.openHelpHub(
                { articleId: BoltHelp.BOLT_HELP_UI },
              )
            )}
          />
        </AutoLayout>
        <Divider />
      </AutoLayout>
      <Card>
        <AutoLayout
          direction="vertical"
          padding="none"
          distribution="packed"
          verticalGap="very-dense"
        >
          <AutoLayout
            direction="horizontal"
            padding="none"
            distribution="space-between"
          >
            <Typography font="inter" type="body-bold-small">
              Create custom Bolt schedule
            </Typography>
            <DefaultButton
              type="standard"
              view="filled"
              color="primary"
              dense
              text="Create new schedule"
              icon="plus"
              onClick={() => history.push('/bolt/new-schedule')}
              eventContext={Contexts.BOLT}
              eventObject="dsfdsf"
              eventAction={Actions.CLICKED}
            />
          </AutoLayout>
          <Typography font="inter" type="caption" color="default" colorStep="60">
            Create a new Bolt schedule from scratch and configure it based on your workflows.
          </Typography>
        </AutoLayout>
      </Card>
      <AutoLayout
        direction="vertical"
        padding="none"
        distribution="packed"
        verticalGap="none"
      >
        <Typography type="h6">Use one of our most popular templates</Typography>
        <Divider />
      </AutoLayout>
      <Typography font="inter" type="caption" color="default" colorStep="50">
        Select one of our most popular templates to get started.
      </Typography>
      <AutoLayout
        direction="horizontal"
        padding="none"
        distribution="packed"
        horizontalGap="expanded"
        alignment="top-left"
        style={{
          gridTemplateColumns: 'repeat(2, 1fr)',
          gridAutoFlow: 'dense',
          rowGap: '32px',
        }}
      >
        {templateData.map(({
          id, icon, name, category, description, disabled, tooltip,
        }) => (
          <IntegrationItemCard
            key={id}
            icon={icon}
            name={name}
            category={category}
            description={description}
            buttonState={IntegrationState.connect}
            onClick={() => history.push(`/bolt/new-schedule?template=${id}`)}
            customButtonProps={{
              text: 'Use',
              rightIcon: 'arrow-right',
              disabled,
              tooltipConfig: {
                content: tooltip || '',
              },
            }}
          />
        ))}
      </AutoLayout>
    </AutoLayout>
  );
};

export default TemplateSchedules;
