import { gql } from '@apollo/client';
import { ColumnType } from '@paradime-io/pragma-ui-kit/lib/components/PrimeReactDataTable';
import { getTableChips, freshnessStatuses, getStatusColour } from '../utils';

export interface sourceNamesForDropdownReturnType {
  cube: {
    sourceDetails: {
      sourceName: string,
    }
  }[]
}

export const sourceNamesForDropdownQuery = gql`
  query sourceNamesForDropdownQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {source_details: {calendar_date: {inDateRange: $timeframe}}}
    ) {
      sourceDetails: source_details {
        sourceName: source_name
      }
    }
  }
`;

export const formatSourceNamesForDropdown = (data?: sourceNamesForDropdownReturnType) => {
  if (!data) return [];

  return data.cube
    .map(({ sourceDetails }) => ({
      label: sourceDetails.sourceName,
      value: sourceDetails.sourceName,
    }));
};

export interface freshnessPassRateReturnType {
  cube: {
    sourceStatusDaily: {
      successRate: number,
      calendarDate: {
        day: string,
      }
    }
  }[]
}

export const freshnessPassRateQuery = gql`
  query freshnessPassRateQuery($timeframe: [String], $sourceName: String) {
    cube(
      limit: 5000
      where: {source_status_daily: {source_name: {equals: $sourceName}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      sourceStatusDaily: source_status_daily(orderBy: {calendar_date: asc}) {
        successRate: success_rate
        calendarDate: calendar_date {
          day
        }
      }
    }
  }
`;

export const formatFreshnessPassRateForChart = (data?: freshnessPassRateReturnType) => {
  if (!data) return [];

  return [data.cube.map(({ sourceStatusDaily }) => ({
    x: sourceStatusDaily.calendarDate.day,
    y: sourceStatusDaily.successRate,
  }))];
};

export const getFreshnessPassRateXAxisLabels = (data?: freshnessPassRateReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ sourceStatusDaily }) => sourceStatusDaily.calendarDate.day);
  return Array.from(new Set(dates));
};

export interface freshnessByStatusReturnType {
  cube: {
    sourceStatusDaily: {
      sourcesDistinctCount: number,
      sourcesTableStatus: 'pass' | 'error',
      calendarDate: {
        day: string,
      }
    }
  }[]
}

export const freshnessByStatusQuery = gql`
  query freshnessByStatusQuery($timeframe: [String], $sourceName: String) {
    cube(
      limit: 5000
      where: {source_status_daily: {source_name: {equals: $sourceName}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      sourceStatusDaily: source_status_daily(orderBy: {calendar_date: asc}) {
        sourcesDistinctCount: sources_distinct_count
        sourcesTableStatus: sources_table_status
        calendarDate: calendar_date {
          day
        }
      }
    }
  }
`;

export const formatFreshnessByStatusForChart = (data?: freshnessByStatusReturnType) => {
  if (!data) return { data: [], statuses: [], colours: [] };

  const dataToReturn: { x: string, y: number }[][] = [];
  const legendLabels: string[] = [];
  const legendColours: string[] = [];

  freshnessStatuses.forEach((status) => {
    const tests = data.cube
      .filter(({ sourceStatusDaily }) => sourceStatusDaily.sourcesTableStatus === status)
      .map(({ sourceStatusDaily }) => ({
        x: sourceStatusDaily.calendarDate.day,
        y: sourceStatusDaily.sourcesDistinctCount,
      }));

    if (tests.length > 0) {
      dataToReturn.push(tests);
      legendLabels.push(`${status}ed sources`);
      legendColours.push(getStatusColour(status));
    }
  });

  return { data: dataToReturn, statuses: legendLabels, colours: legendColours };
};

export const getFreshnessByStatusXAxisLabels = (data?: freshnessByStatusReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ sourceStatusDaily }) => sourceStatusDaily.calendarDate.day);
  return Array.from(new Set(dates));
};

export interface countStaleReturnType {
  cube: {
    sourceStatusDaily: {
      sourcesDistinctCount: number,
      sourceTableName: string,
    }
  }[]
}

export const countStaleQuery = gql`
  query countStaleQuery($timeframe: [String], $sourceName: String) {
    cube(
      limit: 5000
      where: {source_status_daily: {source_name: {equals: $sourceName}, sources_table_status: {equals: "error"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      sourceStatusDaily: source_status_daily {
        sourcesDistinctCount: sources_distinct_count
        sourceTableName: source_table_name
      }
    }
  }
`;

export const formatCountStaleForChart = (data?: countStaleReturnType) => {
  if (!data) return [];

  return [data.cube
    .map(({ sourceStatusDaily }) => ({
      x: sourceStatusDaily.sourceTableName,
      y: sourceStatusDaily.sourcesDistinctCount,
    }))];
};

export const getCountStaleXAxisLabels = (data?: countStaleReturnType) => {
  if (!data) return [];

  const tableNames = data.cube
    .map(({ sourceStatusDaily }) => sourceStatusDaily.sourceTableName);
  return Array.from(new Set(tableNames));
};

export interface countWarnReturnType {
  cube: {
    sourceStatusDaily: {
      sourcesDistinctCount: number,
      sourceTableName: string,
    }
  }[]
}

export const countWarnQuery = gql`
  query countWarnQuery($timeframe: [String], $sourceName: String) {
    cube(
      limit: 5000
      where: {source_status_daily: {source_name: {equals: $sourceName}, sources_table_status: {equals: "warn"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      sourceStatusDaily: source_status_daily {
        sourcesDistinctCount: sources_distinct_count
        sourceTableName: source_table_name
      }
    }
  }
`;

export const formatCountWarnForChart = (data?: countWarnReturnType) => {
  if (!data) return [];

  return [data.cube
    .map(({ sourceStatusDaily }) => ({
      x: sourceStatusDaily.sourceTableName,
      y: sourceStatusDaily.sourcesDistinctCount,
    }))];
};

export const getCountWarnXAxisLabels = (data?: countWarnReturnType) => {
  if (!data) return [];

  const tableNames = data.cube
    .map(({ sourceStatusDaily }) => sourceStatusDaily.sourceTableName);
  return Array.from(new Set(tableNames));
};

export interface currentFreshnessStatusReturnType {
  cube: {
    sourceStatusDaily: {
      sourcesDistinctCount: number,
      sourcesTableStatus: 'pass' | 'error',
    }
  }[]
}

export const currentFreshnessStatusQuery = gql`
  query currentFreshnessStatusQuery($timeframe: [String], $sourceName: String) {
    cube(
      limit: 5000
      where: {source_status_daily: {source_name: {equals: $sourceName}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      sourceStatusDaily: source_status_daily {
        sourcesDistinctCount: sources_distinct_count
        sourcesTableStatus: sources_table_status
      }
    }
  }
`;

export const formatCurrentFreshnessStatusChart = (data?: currentFreshnessStatusReturnType) => {
  if (!data) return { data: [], statuses: [], colours: [] };

  const dataToReturn: { x: string, y: number }[] = [];
  const legendLabels: string[] = [];
  const legendColours: string[] = [];

  freshnessStatuses.forEach((status) => {
    const tests = data.cube
      .filter(({ sourceStatusDaily }) => sourceStatusDaily.sourcesTableStatus === status)
      .map(({ sourceStatusDaily }) => ({
        x: sourceStatusDaily.sourcesTableStatus,
        y: sourceStatusDaily.sourcesDistinctCount,
      }));

    if (tests.length > 0) {
      dataToReturn.push(...tests);
      legendLabels.push(status);
      legendColours.push(getStatusColour(status));
    }
  });

  return { data: dataToReturn, statuses: legendLabels, colours: legendColours };
};

export interface currentFreshnessHealthReturnType {
  cube: {
    sourceStatusDaily: {
      successRate: number,
    }
  }[]
}

export const currentFreshnessHealthQuery = gql`
  query currentFreshnessHealthQuery($timeframe: [String], $sourceName: String) {
    cube(
      limit: 5000
      where: {source_status_daily: {source_name: {equals: $sourceName}, is_source_current_status: {equals: "true"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      sourceStatusDaily: source_status_daily {
        successRate: success_rate
      }
    }
  }
`;

export interface freshnessStatusReturnType {
  cube: {
    sourceDetails: {
      sourceLoaderName: string,
      sourceDatabaseName: string,
      sourceTableName: string,
      sourcesTableStatus: string,
      warnAfterCriteria: string,
      errorAfterCriteria: string,
      sourcesLastLoadedAtTimeAgoInMinutes: string,
      sourcesResultMaxLoadedAtDttm: {
        minute: string,
      }
    }
  }[]
}

export const freshnessStatusQuery = gql`
  query freshnessStatusQuery($timeframe: [String], $sourceName: String) {
    cube(
      limit: 5000
      where: {source_details: {is_source_current_status: {equals: "true"}, source_name: {equals: $sourceName}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      sourceDetails: source_details(orderBy: {source_database_name: asc}) {
        sourceLoaderName: source_loader_name
        sourceDatabaseName: source_database_name
        sourceTableName: source_table_name
        sourcesTableStatus: sources_table_status
        warnAfterCriteria: warn_after_criteria
        errorAfterCriteria: error_after_criteria
        sourcesLastLoadedAtTimeAgoInMinutes: sources_last_loaded_at_time_ago_in_minutes
        sourcesResultMaxLoadedAtDttm: sources_result_max_loaded_at_dttm {
          minute
        }
      }
    }
  }
`;

export const freshnessStatusColumns = [
  { field: 'sourceDatabaseName', header: 'Source database' },
  { field: 'sourceLoaderName', header: 'Source loader name' },
  { field: 'sourceTableName', header: 'Source table name' },
  { field: 'sourcesTableStatus', header: 'Source table status', type: ColumnType.CHIP },
  { field: 'warnAfterCriteria', header: 'Warn after criteria' },
  { field: 'errorAfterCriteria', header: 'Error after criteria' },
  { field: 'sourcesLastLoadedAtTimeAgoInMinutes', header: 'Last loaded at time ago' },
  { field: 'sourcesResultMaxLoadedAtDttm', header: 'Source last loaded at' },
].map((col) => ({ ...col, sortable: true }));

export const formatFreshnessStatusForChart = (data?: freshnessStatusReturnType) => {
  if (!data) return [];

  return data.cube.map(({ sourceDetails }) => ({
    ...sourceDetails,
    sourcesResultMaxLoadedAtDttm: sourceDetails.sourcesResultMaxLoadedAtDttm.minute,
    sourcesTableStatus: getTableChips(sourceDetails.sourcesTableStatus),
  }));
};
