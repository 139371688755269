import { useLocation } from 'react-router-dom';

export const useGetFiltersFromUrl = () => {
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const getInitialFilterValue = (filterName: string, firstResult?: string) => {
    const urlVal = searchParams.get(filterName);

    if (urlVal) {
      return { label: urlVal, value: urlVal };
    }

    if (firstResult) {
      return { label: firstResult, value: firstResult };
    }

    return null;
  };

  return { getInitialFilterValue };
};
