import { DateTime } from 'luxon';

const formatter = Intl.NumberFormat('en', { style: 'currency', currency: 'USD' });

export const formatCurrency = (amount: number) => (
  formatter.format(amount)
);

export const getFirstDayOfWeek = (year: number, weekNumber: number) => (
  DateTime.fromISO(`${year}-W${weekNumber.toString().padStart(2, '0')}-1`)
);

export const listDates = (daysAgo: number, groupByWeek?: boolean) => {
  const now = DateTime.now();
  const emptyArray = Array.from({ length: daysAgo }, (val, index) => daysAgo - index);
  const dates = emptyArray.map((daysBack) => (
    now
      .minus({ days: daysBack })
      .toFormat('yyyy-LL-dd')
  ));

  if (groupByWeek) {
    // Get a list of all the week numbers (also the year - will need that later)
    const weeks = dates.map((date) => {
      const { weekNumber, year } = DateTime.fromFormat(date, 'yyyy-LL-dd');
      return { weekNumber, year };
    });

    // Deduplicate the list so that each week number only appears once
    const deduped = weeks.filter((w1, index, array) => {
      const indexOfFirstItem = array.findIndex((w2) => (w2.weekNumber === w1.weekNumber));
      return indexOfFirstItem === index;
    });

    // Fetch the date of the Monday from each week in the list
    const weekStartDates = deduped.map(({ weekNumber, year }) => (
      getFirstDayOfWeek(year, weekNumber).toFormat('yyyy-LL-dd')
    ));

    return weekStartDates;
  }

  return dates;
};
