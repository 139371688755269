import React, {
  Dispatch, FC, SetStateAction, useEffect, useState,
} from 'react';
import DefaultInput from '@paradime-io/pragma-ui-kit/lib/components/DefaultInput';
import InputMultiSelect, { InputMultiSelectItem } from '@paradime-io/pragma-ui-kit/lib/components/InputMultiSelect';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { ValidationRules } from '../../../utilis/useCheckValidation';
import { NotifyCondition } from '../../Bolt';

interface EmailNotifyProps {
  emailNotifyOn: NotifyCondition[],
  setEmailNotifyOn: Dispatch<SetStateAction<NotifyCondition[]>>,
  emailNotifyEmails: string,
  setEmailNotifyEmails: Dispatch<SetStateAction<string>>,
  exposeIsValid?: (isValid: boolean) => void,
}

const EmailNotify:FC<EmailNotifyProps> = ({
  emailNotifyOn,
  setEmailNotifyOn,
  emailNotifyEmails,
  setEmailNotifyEmails,
  exposeIsValid,
}) => {
  const emailRegExp = new RegExp(ValidationRules.VALID_EMAIL);
  const [isValidNotify, setIsValidNotify] = useState(true);
  const [errorString, setErrorString] = useState('');
  const updateEmailNotifyOn = (items: InputMultiSelectItem[]) => {
    const selectedItems = items.filter((item) => item.selected);
    setEmailNotifyOn(selectedItems.map((i) => i.value as NotifyCondition));
  };

  const getIsAllValidEmails = (emails: string[]) => {
    const isInvalid = emails.filter((email) => !emailRegExp.test(email.trim()));
    return !(isInvalid.length > 0);
  };

  useEffect(() => {
    if (emailNotifyOn.length > 0 && emailNotifyEmails.length === 0) {
      setIsValidNotify(false);
      setErrorString('Please enter one or more valid emails when Email Notify On is set');
    }

    if (emailNotifyOn.length > 0 && emailNotifyEmails.length > 0) {
      const allValidEmails = getIsAllValidEmails(emailNotifyEmails.split(','));
      setIsValidNotify(allValidEmails);
      if (!allValidEmails) {
        setErrorString('Plese enter a comma-separated list of valid emails.');
      }
    }

    if (emailNotifyOn.length === 0 && emailNotifyEmails.length === 0) {
      setIsValidNotify(true);
    }
  }, [emailNotifyOn, emailNotifyEmails]);

  useEffect(() => {
    if (isValidNotify) {
      setErrorString('');
    }

    if (exposeIsValid) exposeIsValid(isValidNotify);
  }, [isValidNotify]);

  return (
    <>
      <InputMultiSelect
        view="outlined"
        fill
        type="dense"
        color="default"
        label="Email notify on"
        initialValues={emailNotifyOn}
        dataItems={['failed', 'passed']}
        onSelectItem={(_, itemList) => updateEmailNotifyOn(itemList)}
        eventContext={Contexts.EDITOR}
        eventObject="emailNotifyOn"
        eventAction={Actions.SELECTED}
      />
      <DefaultInput
        color="default"
        dense
        full
        sensitive
        label="Email notify"
        placeholder="Add comma separated emails"
        type="text"
        view="outlined"
        dataName="scheduleEmailNotify"
        value={emailNotifyEmails}
        expose={(e) => setEmailNotifyEmails(e.target.value)}
        error={!isValidNotify}
        errorHelperText={errorString}
      />
    </>
  );
};

export default EmailNotify;
