import React, {
  Dispatch, FC, SetStateAction, useState, useEffect,
} from 'react';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { useHistory, useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Panel from '@paradime-io/pragma-ui-kit/lib/components/Panel';
import { ButtonProps, ChildComponents } from '@paradime-io/pragma-ui-kit/lib/components/Panel/useGetPanelComponents';
import { PlatformTab } from './index';
import { userAuthStore } from '../../stores';
import {
  userHasPlatformAuditLogsAccess,
  userHasPlatformBillingUpdateAccess,
  userHasPlatformInsightsAccess,
  userHasPlatformPlansUpdateAccess,
} from '../../utilis/PermissionsService';

export interface SidebarProps {
  isLeftPanelOpen: boolean,
  setIsLeftPanelOpen: Dispatch<SetStateAction<boolean>>,
}

const Sidebar: FC<SidebarProps> = ({
  isLeftPanelOpen,
  setIsLeftPanelOpen,
}) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { currentUser: { accessLevel } } = userAuthStore.getState();

  const [selectedPanelButton, setSelectedPanelButton] = useState(PlatformTab.WORKSPACES);
  const tabs = Object.values(PlatformTab);

  const { flagRoiUserInsights } = useFlags();

  useEffect(() => {
    const requiredPath = tabs.find((path) => pathname.includes(path));

    if (requiredPath) {
      setSelectedPanelButton(requiredPath);
    }
  }, [pathname]);

  const handleButtonClick = (buttonName: PlatformTab, route: string) => {
    setSelectedPanelButton(buttonName);
    history.push(route);
  };

  const panelButtons = [
    {
      type: ChildComponents.BUTTON,
      icon: 'cube',
      color: 'primary',
      text: 'Workspaces',
      buttonType: 'standard',
      onClick: () => handleButtonClick(PlatformTab.WORKSPACES, `/platform/${PlatformTab.WORKSPACES}`),
      isSelected: selectedPanelButton === PlatformTab.WORKSPACES,
    },
    userHasPlatformPlansUpdateAccess(accessLevel) && {
      type: ChildComponents.BUTTON,
      icon: 'map',
      color: 'primary',
      text: 'Plans',
      buttonType: 'standard',
      onClick: () => handleButtonClick(PlatformTab.PLANS, `/platform/${PlatformTab.PLANS}`),
      isSelected: selectedPanelButton === PlatformTab.PLANS,
    },
    userHasPlatformBillingUpdateAccess(accessLevel) && {
      type: ChildComponents.BUTTON,
      icon: 'credit-card',
      color: 'primary',
      text: 'Billing',
      buttonType: 'standard',
      onClick: () => handleButtonClick(PlatformTab.BILLING, `/platform/${PlatformTab.BILLING}`),
      isSelected: selectedPanelButton === PlatformTab.BILLING,
    },
    userHasPlatformAuditLogsAccess(accessLevel) && {
      type: ChildComponents.BUTTON,
      icon: 'manual',
      color: 'primary',
      text: 'Audit logs',
      buttonType: 'standard',
      onClick: () => handleButtonClick(PlatformTab.AUDIT_LOGS, `/platform/${PlatformTab.AUDIT_LOGS}`),
      isSelected: selectedPanelButton === PlatformTab.AUDIT_LOGS,
    },
  ] as ButtonProps[];

  if (userHasPlatformInsightsAccess(accessLevel) && flagRoiUserInsights) {
    panelButtons.push({
      type: ChildComponents.BUTTON,
      icon: 'timeline-area-chart',
      color: 'primary',
      text: 'Insights',
      buttonType: 'standard',
      onClick: () => handleButtonClick(PlatformTab.INSIGHTS, `/platform/${PlatformTab.INSIGHTS}`),
      isSelected: selectedPanelButton === PlatformTab.INSIGHTS,
    });
  }

  return (
    <>
      <Panel
        type="drawer"
        isExpanded={isLeftPanelOpen}
        expose={(panelIsOpen: boolean) => setIsLeftPanelOpen(panelIsOpen)}
        eventContext={Contexts.CATALOG}
        topComponents={panelButtons}
        panelID="bolt"
        rememberLastOpenState
      />
    </>
  );
};

export default Sidebar;
