import React, { FC } from 'react';
import Card from '@paradime-io/pragma-ui-kit/lib/components/Card';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { RadarTab } from './config';

export interface RadarUnsupportedProps {
  pageId: RadarTab,
  imageSrc: string,
  bodyText: string,
  upgradePlanName: string,
}

const RadarUnsupported:FC<RadarUnsupportedProps> = ({
  imageSrc,
  upgradePlanName,
  bodyText,
}) => (
  <Card
    tabIndex={-1}
    height="403px"
    padding="normal"
  >
    <AutoLayout
      direction="horizontal"
      padding="none"
      distribution="space-between"
      style={{ gridAutoColumns: 'unset' }}
    >
      <AutoLayout
        direction="vertical"
        verticalGap="expanded"
        padding="none"
        distribution="packed"
      >
        <Chips
          type="dense"
          icon="lock"
          tag={upgradePlanName}
          color="warning_alt"
          round={false}
        />

        <AutoLayout
          direction="vertical"
          verticalGap="dense"
          padding="none"
          distribution="packed"
        >
          <Typography tagName="span" type="h5" color="default">
            Measure what matters
          </Typography>

          <Typography tagName="span" type="body-small" color="default">
            {bodyText}
          </Typography>
        </AutoLayout>

        <AutoLayout
          direction="vertical"
          verticalGap="dense"
          padding="none"
          distribution="packed"
        >
          <AutoLayout
            direction="horizontal"
            padding="none"
            horizontalGap="dense"
            distribution="packed"
          >
            <DefaultButton
              dense
              view="outlined"
              icon="manual"
              text="Learn more"
              eventContext={Contexts.LOGS}
              eventObject="readDocs"
              eventAction={Actions.CLICKED}
              onClick={() => {
                window.open('https://docs.paradime.io/app-help/app-settings/radar', '_blank');
              }}
            />
          </AutoLayout>
        </AutoLayout>
      </AutoLayout>
      <img src={imageSrc} alt="unsupported integration" />
    </AutoLayout>
  </Card>
);

export default RadarUnsupported;
