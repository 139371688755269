import React, { FC } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Button from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Contexts, Actions } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { GqlParadimeAccountType } from '../../../client/generated/service';
import { ScheduleSource } from '..';
import { userHasBoltEditAndCopyScheduleAccess } from '../../../utilis/PermissionsService';

interface ScheduleActionProps {
  source: ScheduleSource
  accessLevel: GqlParadimeAccountType,
  actions: {
    onEdit: () => void,
    onCopy: () => void,
    onCopyCode: () => void,
  }
}

const ScheduleActions: FC<ScheduleActionProps> = ({
  source,
  accessLevel,
  actions,
}) => {
  const hasAccess = userHasBoltEditAndCopyScheduleAccess(accessLevel);

  const { flagBoltAllowScheduleCreateFromUi } = useFlags();

  const requestAccessTooltipConfig = !hasAccess ? {
    content: 'Contact your admin to request access to this action',
  } : undefined;

  return (
    <>
      {
        flagBoltAllowScheduleCreateFromUi && (
          <>
            <Button
              type="standard"
              view="outlined"
              color="default"
              icon="code"
              text="Code"
              dense
              disabled={!hasAccess}
              eventContext={Contexts.BOLT}
              eventObject="copyScheduleYaml"
              eventAction={Actions.CLICKED}
              onClick={() => actions.onCopyCode()}
              onCmdClick={() => actions.onCopyCode()}
              tooltipConfig={requestAccessTooltipConfig}
            />
            <Button
              type="standard"
              view="outlined"
              color="default"
              icon="duplicate"
              text="Copy"
              dense
              disabled={!hasAccess || (source !== 'ui')}
              eventContext={Contexts.BOLT}
              eventObject="copySchedule"
              eventAction={Actions.CLICKED}
              onClick={() => actions.onCopy()}
              onCmdClick={() => actions.onCopy()}
              tooltipConfig={requestAccessTooltipConfig}
            />
            <Button
              type="standard"
              view="outlined"
              color="default"
              icon="edit"
              text="Edit"
              dense
              disabled={!hasAccess || (source !== 'ui')}
              eventContext={Contexts.BOLT}
              eventObject="editSchedule"
              eventAction={Actions.CLICKED}
              onClick={() => actions.onEdit()}
              onCmdClick={() => actions.onEdit()}
              tooltipConfig={requestAccessTooltipConfig}
            />
          </>
        )
      }
    </>
  );
};

export default ScheduleActions;
