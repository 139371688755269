import React, { ReactNode } from 'react';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { FrigadeJS } from '@frigade/react';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { useMarkStepCompleted } from '@paradime/common/lib/common/helpers/frigadeHelperFunctions';
import * as S from '../Bolt.styles';
import { BoltFlows } from '../../Common/Frigade/flows';

const GetWrokspaceIdStep = (stepData: FrigadeJS.FlowStep): ReactNode => {
  const { primaryButtonTitle, id: stepId } = stepData;
  const { markStepCompleted } = useMarkStepCompleted(BoltFlows.TURBO_CD_FLOW);

  return (
    <AutoLayout
      direction="vertical"
      distribution="packed"
      verticalGap="dense"
      padding="none"
      alignment="top-left"
      style={{ paddingBottom: '16px' }}
    >
      <Typography tagName="span" type="h6">Create a CD Job schedule</Typography>
      <S.CodeTypography tagName="span" type="body-small">
        Get your
        {' '}
        <code>workspace_token</code>
        {' '}
        from the
        {' '}
        <strong>
          Account Settings
          {'>'}
          {' '}
          Workspaces
        </strong>
        {' '}
        page
      </S.CodeTypography>
      <DefaultButton
        type="standard"
        view="filled"
        color="primary"
        dense
        text={primaryButtonTitle}
        eventContext={Contexts.LOGS}
        eventObject="markStepComplete"
        eventAction={Actions.CLICKED}
        onClick={() => markStepCompleted(stepId)}
        style={{ width: 'fit-content' }}
      />
    </AutoLayout>
  );
};

export default GetWrokspaceIdStep;
