import React, { FC, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import DefaultInput from '@paradime-io/pragma-ui-kit/lib/components/DefaultInput';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { key } from '../../utilis';
import { DashboardTabIds, RadarTab } from './config';

export interface DropdownOption {
  label: string,
  value: string,
}

export interface DropdownItem {
  value: DropdownOption,
  options?: DropdownOption[],
  onChange: (newVal: DropdownOption) => void,
  isLoading?: boolean,
  name: string,
  useInputField?: boolean,
  title?: string,
}

export interface FilterRowProps {
  dropdowns: DropdownItem[],
}

const FilterRow: FC<FilterRowProps> = ({ dropdowns }) => {
  const history = useHistory();
  const { pageId, tabId } = useParams<{pageId: RadarTab, tabId: DashboardTabIds}>();
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  useEffect(() => {
    searchParams.forEach((dropdownValue, dropdownName) => {
      const chosenDropdown = dropdowns.find(({ name }) => name === dropdownName);
      const chosenOption = chosenDropdown?.options?.find(({ value: val }) => val === dropdownValue);
      if (chosenOption) chosenDropdown?.onChange(chosenOption);
    });
  }, []);

  const getFilterElement = ({
    value, options, onChange, isLoading, name, useInputField,
  }: DropdownItem) => {
    if (isLoading) {
      return (
        <DefaultButton
          loading
          dense
          eventContext={Contexts.RADAR}
          eventObject=""
          eventAction={Actions.CLICKED}
          style={{ width: '150px' }}
        />
      );
    }

    if (useInputField) {
      return (
        <AutoLayout
          direction="vertical"
          padding="none"
          verticalGap="ultra-dense"
          distribution="packed"
        >
          <DefaultInput
            type="text"
            view="outlined"
            color="default"
            dense
            value={value.value}
            onKeyPress={(e) => {
              if (e.code === 'Enter') {
                const newValue = e.target.value?.trim();
                const newOption = { label: newValue, value: newValue };
                onChange(newOption);
              }
            }}
          />
          <Typography font="inter" type="caption-small" colorStep="50">
            Press enter to submit
          </Typography>
        </AutoLayout>
      );
    }

    return (
      <Dropdown
        key={key(options?.[0]?.value)}
        value={value.value}
        options={options}
        onChange={(e) => {
          const selectedOption = options?.find(({ value: val }) => val === e.value);
          if (selectedOption) {
            searchParams.set(name, selectedOption.value);
            history.push(`/radar/${pageId}/${tabId}?${searchParams.toString()}`);
            onChange(selectedOption);
          }
        }}
        optionLabel="label"
        style={{
          minWidth: '150px',
          maxWidth: '250px',
        }}
      />
    );
  };

  return (
    <AutoLayout
      direction="horizontal"
      padding="none"
      horizontalGap="dense"
      distribution="packed"
      alignment="top-left"
    >
      {dropdowns.map(({ title, ...rest }) => (
        <AutoLayout
          direction="vertical"
          distribution="packed"
          padding="none"
          verticalGap="very-dense"
          alignment="top-left"
        >
          {title && (
            <Typography tagName="span" type="caption" colorStep="100">
              {title}
            </Typography>
          )}
          {getFilterElement({ ...rest })}
        </AutoLayout>
      ))}
    </AutoLayout>
  );
};

export default FilterRow;
