// TODO - Once merged with the feature branch, use Rimil's version in the integrations folder
import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { key } from '../../../utilis';

interface FilterButtonType {
  label: string;
  onClick: () => void;
}

interface FilterButtonsListProps {
  list: FilterButtonType[],
  selectedFilter: string,
}

export const FilterButtonsList: FC<FilterButtonsListProps> = ({ list, selectedFilter }) => (
  <AutoLayout
    direction="vertical"
    padding="none"
    verticalGap="very-dense"
    distribution="packed"
    alignment="top"
  >
    {list.map((item) => (
      <DefaultButton
        key={key(item.label)}
        text={item.label}
        onClick={() => item.onClick()}
        eventAction={Actions.FILTERED}
        eventContext={Contexts.ACCOUNT_SETTINGS}
        eventObject={`${item.label} category filtered`}
        view="flat"
        color="default"
        dense
        active={selectedFilter === item.label}
        style={{
          justifyContent: 'left',
        }}
      />
    ))}
  </AutoLayout>
);
