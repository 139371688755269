import React, { FC, ReactNode } from 'react';
import { Icon } from '@blueprintjs/core';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import * as S from './UserInsights.styled';
import MetricCard from './MetricCard';

export interface InsightsContainerProps {
  title: string,
  isLoading?: boolean,
  children: ReactNode,
}

const InsightsContainer: FC<InsightsContainerProps> = ({ title, isLoading, children }) => (
  <S.CardContainer>
    <AutoLayout
      direction="vertical"
      padding="none"
      verticalGap="dense"
      distribution="packed"
    >
      <AutoLayout
        direction="horizontal"
        padding="none"
        horizontalGap="dense"
        distribution="packed"
      >
        <Icon icon="timeline-area-chart" size={16} color="var(--violet0)" />
        <Typography
          font="inter"
          type="h6"
          color="primary"
          colorStep="0"
        >
          {title}
        </Typography>
      </AutoLayout>
      {isLoading
        ? (
          <MetricCard
            content={<div style={{ marginTop: '16px' }}><Spinner thin /></div>}
            hasError={false}
          />
        )
        : children}
    </AutoLayout>
  </S.CardContainer>
);

export default InsightsContainer;
