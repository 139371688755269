import React, { FC } from 'react';
import { Dialog, Typography } from '@paradime-io/pragma-ui-kit';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';

export interface CancelDialogRunInfo {
  ID?: number,
  scheduleName?: string,
}

export interface CancelRunDialogProps {
  isOpen: boolean,
  onClose: () => void,
  handleCancelRun: (runID: number) => void,
  runInfo?: CancelDialogRunInfo | null,
  cancelRunLoading?: boolean,
}

const CancelRunDialog: FC<CancelRunDialogProps> = ({
  isOpen,
  onClose,
  handleCancelRun,
  runInfo,
  cancelRunLoading,
}) => (
  <Dialog
    isOpen={isOpen}
    type="dense"
    color="danger"
    view="smooth"
    icon="info-sign"
    backdropOpacity="20"
    title="Cancel running schedule"
    showLeftButton
    leftButtonText="Let me check"
    showRightButton
    rightButtonText="Sure, continue!"
    onLeftButtonClick={onClose}
    onRightButtonClick={() => runInfo?.ID && handleCancelRun(runInfo.ID)}
    rightButtonLoading={cancelRunLoading}
    closable
    onClose={onClose}
    leftButtonEventData={{
      eventContext: Contexts.LOGS,
      eventObject: 'closedCancelScheduleRunDialog',
      eventAction: Actions.CLICKED,
    }}
    rightButtonEventData={{
      eventContext: Contexts.LOGS,
      eventObject: 'cancelScheduleRun',
      eventAction: Actions.CANCELLED,
    }}
  >
    <Typography
      font="inter"
      type="body-small"
      color="danger"
    >
      You are about to stop the
      <Typography
        tagName="span"
        type="body-bold-small"
        color="danger"
      >
        {' '}
        run with ID
        {' '}
        {runInfo?.ID}
        {' '}

      </Typography>
      for the schedule
      <Typography
        tagName="span"
        type="body-bold-small"
        color="danger"
      >
        {' '}
        {runInfo?.scheduleName}
        {' '}

      </Typography>
      .
    </Typography>
  </Dialog>
);

export default CancelRunDialog;
