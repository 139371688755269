import React, { FC } from 'react';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';

interface TestResultMessageProps {
  message: string,
}

const TestResultMessage: FC<TestResultMessageProps> = ({
  message,
}) => (
  <Typography
    tagName="div"
    type="caption"
    colorStep="100"
    singleline
    className="test-result-message"
    style={{ width: '300px' }}
    data-pr-tooltip={message}
    data-pr-position="top"
    data-pr-at="top"
  >
    {message}
  </Typography>
);

export default TestResultMessage;
