import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import { BoltHelp } from '../../Common/AppHelp/help';

export enum subtitleTypes {
  GET_STARTED = 'getStarted',
  SELECT_MODELS = 'selectModels',
  ENTER_DETAILS = 'enterDetails',
  BLANK = '',
}

interface HeaderProps {
  showHelp: boolean,
}

const Header: FC<HeaderProps> = ({ showHelp }) => (
  <AutoLayout
    direction="horizontal"
    padding="none"
    distribution="space-between"
    horizontalGap="expanded"
    width="full"
  >
    <AutoLayout
      direction="vertical"
      padding="none"
      distribution="packed"
      verticalGap="none"
    >
      <Typography
        font="inter"
        type="h6"
        color="default"
        colorStep="100"
      >
        Turbo CD
      </Typography>
      <AutoLayout
        direction="horizontal"
        padding="none"
        distribution="packed"
        horizontalGap="ultra-dense"
      >
        <Typography
          font="inter"
          type="caption"
          color="default"
          colorStep="50"
        >
          Get help on setting up Turbo CD.
        </Typography>
        {showHelp ? (
          <Chips
            color="primary_alt"
            round={false}
            style={{ margin: '4px', verticalAlign: 'bottom' }}
            tag="Learn more"
            type="dense"
            view="smooth"
            onClick={() => (
              window.CommandBar.openHelpHub(
                { articleId: BoltHelp.BOLT_WRITING_SCHEDULES },
              )
            )}
          />
        )
          : null}
      </AutoLayout>
    </AutoLayout>
  </AutoLayout>
);

export default Header;
