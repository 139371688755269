import React, { FC, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import TabsDefault from '@paradime-io/pragma-ui-kit/lib/components/TabsDefault';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import YAMLCode from './YAML';
import DAG from './DAG';
import Python from './Python';
import { CodeTypography } from '../../Bolt.styles';
import airflowLogo from './images/airflow.svg';
import pythonLogo from './images/python.png';
import yamlLogo from './images/yaml.svg';

export interface ScheduleCodeProps {
  scheduleName?: string,
  scheduleCode?: string,
  isVisible: boolean,
  isLoading?: boolean,
  onHide: () => void,
}

enum ScheduleCodeTabIds {
  YAML = 'yaml-tab',
  AIRFLOW = 'airflow-tab',
  PYTHON = 'python-tab',
}

const tabsData = [
  {
    id: ScheduleCodeTabIds.YAML, title: 'YAML', visible: true, image: yamlLogo,
  },
  {
    id: ScheduleCodeTabIds.AIRFLOW, title: 'Airflow', visible: true, image: airflowLogo,
  },
  {
    id: ScheduleCodeTabIds.PYTHON, title: 'Python', visible: true, image: pythonLogo,
  },
];

const ScheduleCode:FC<ScheduleCodeProps> = ({
  scheduleName,
  scheduleCode,
  isVisible,
  isLoading,
  onHide,
}) => {
  const [selectedTabId, setSelectedTabId] = useState<string>(ScheduleCodeTabIds.YAML);

  const getTabView = () => {
    if (isLoading) {
      return (<Spinner width={32} height={32} thin />);
    }

    switch (selectedTabId) {
      case ScheduleCodeTabIds.YAML:
        return (<YAMLCode scheduleCode={scheduleCode} />);
      case ScheduleCodeTabIds.AIRFLOW:
        return (<DAG scheduleName={scheduleName || ''} />);
      case ScheduleCodeTabIds.PYTHON:
        return (<Python scheduleName={scheduleName || ''} />);
      default:
        return (<YAMLCode scheduleCode={scheduleCode} />);
    }
  };

  return (
    <Sidebar
      visible={isVisible}
      position="right"
      onHide={onHide}
      style={{ width: '600px' }}
      header={() => (
        <Typography type="h6" color="default" tagName="span">
          View code
        </Typography>
      )}
    >
      <AutoLayout
        direction="vertical"
        distribution="packed"
        padding="none"
        verticalGap="dense"
        wrapperStyle={{ alignItems: 'flex-start' }}
      >
        <CodeTypography type="body-small" color="default" colorStep="50" tagName="span">
          Paradime Bolt schedules can be triggered from anywhere - from the UI, YAML, Airflow,
          and using the Python SDK.
          <br />
          <br />
          Here, we give you code examples to trigger this schedule based on your use-case.
          <br />
          <br />
        </CodeTypography>
        <AutoLayout
          direction="vertical"
          distribution="packed"
          padding="none"
          verticalGap="dense"
          style={{ marginBottom: '64px' }}
        >
          <TabsDefault
            type="dense"
            view="flat"
            color="default"
            fill={false}
            data={tabsData}
            eventContext={Contexts.BOLT}
            currentTabId={selectedTabId}
            exposeOnChange={(newTabId) => setSelectedTabId(newTabId?.toString())}
          />
          {getTabView()}
        </AutoLayout>
      </AutoLayout>
    </Sidebar>
  );
};

export default ScheduleCode;
