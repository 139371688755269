import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import RadarPage from '../RadarPage';
import DashboardsTab from './DashboardsTab';
import ExploresTab from './ExploresTab';
import { DashboardTabIds } from '../config';

const Looker: FC = () => {
  const { tabId } = useParams<{tabId: DashboardTabIds}>();

  return (
    <RadarPage>
      {tabId === DashboardTabIds.DASHBOARDS && <DashboardsTab />}
      {tabId === DashboardTabIds.EXPLORES && <ExploresTab />}
    </RadarPage>
  );
};

export default Looker;
