import React, { FC, useContext } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import { useGetCostAgentOptimisations } from './useGetCostAgentOptimisations';
import { useGetSnowflakeWarehouseOptimisations } from './useGetSnowflakeWarehouseOptimisations';
import ZeroStateDataLoading from './ZeroStateDataLoading';
import OptimisationsTable from './OptimisationsTable';
import BarChartCard from './BarChartCard';
import SavingsSummary from './SavingsSummary';
import { useGetAggregatedOptimisationData } from './useGetAggregatedOptimisationData';
import { RadarWorkspaceContext } from '..';

export interface optimisationTableItem {
  [warehouseName: string]: {
    isApplied: boolean,
    savingAmount: number,
    currentAnnualSpend: number,
  }
}

export interface dailyDataItem {
  [timestamp: string]: {
    amountSaved: number,
    amountSpent: number,
  }
}

export interface OptimisationItem {
  warehouseName: string,
  isApplied: boolean,
  savingAmount: number,
}

export interface OptimisationHookProps {
  onApplyPauseSuccess: () => void,
}

export interface optimisationListItem {
  applyOptimisation: (warehouseName: string) => void,
  isLoadingApplyOptimisation: boolean,
  pauseOptimisation: (warehouseName: string) => void,
  isLoadingPauseOptimisation: boolean,
}

const CostOptimisations: FC = () => {
  const { costWarehouseType } = useContext(RadarWorkspaceContext);
  const costConnectionNotSetup = costWarehouseType === 'unknown';

  const {
    aggregatedOptimisationData,
    isLoading,
    getAggregationData,
  } = useGetAggregatedOptimisationData();

  const costAgentOptimisations = useGetCostAgentOptimisations({
    onApplyPauseSuccess: getAggregationData,
  });
  const snowflakeWarehouseOptimisations = useGetSnowflakeWarehouseOptimisations({
    onApplyPauseSuccess: getAggregationData,
  });

  if (costConnectionNotSetup) return null;

  if (isLoading) {
    return (
      <div style={{ marginTop: '84px' }}>
        <Spinner width={32} height={32} position="center" thin />
      </div>
    );
  }

  if (aggregatedOptimisationData.length === 0) {
    return <ZeroStateDataLoading />;
  }

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      verticalGap="dense"
      distribution="packed"
      style={{ maxWidth: '1500px' }}
    >
      <AutoLayout
        direction="vertical"
        padding="none"
        verticalGap="very-dense"
        distribution="packed"
      >
        <SavingsSummary
          aggregatedOptimisationData={aggregatedOptimisationData}
        />
        <BarChartCard />
      </AutoLayout>
      <OptimisationsTable
        costAgentOptimisations={costAgentOptimisations}
        snowflakeWarehouseOptimisations={snowflakeWarehouseOptimisations}
        aggregatedOptimisationData={aggregatedOptimisationData}
      />
    </AutoLayout>
  );
};

export default CostOptimisations;
