import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import {
  VictoryScatter, VictoryChart, VictoryTheme, VictoryContainer,
  VictoryAxis, VictoryLabel, VictoryTooltip, DomainPropType,
} from 'victory';
import { DateTime } from 'luxon';
import NoDataZeroState from './NoDataZeroState';
import TooltipFlyout from './TooltipFlyOut';
import { useGetChartDimensionsReturnType } from './useGetChartDimensions';

const axisLabelStyle = (fontSize: string) => ({
  fontFamily: 'var(--inter)',
  letterSpacing: 'var(--letter_spacing_caption)',
  color: 'var(--grey100)',
  fontWeight: 800,
  fontSize,
});

const tickLabelStyle = (fontSize: string) => ({
  fontFamily: 'var(--inter)',
  letterSpacing: 'var(--letter_spacing_caption)',
  color: 'var(--grey100)',
  fontWeight: 800,
  fontSize,
});

const axisStyle = {
  axis: { stroke: 'var(--grey10)', strokeWidth: 1 },
  ticks: { stroke: 'var(--white)', size: 0 },
  grid: { stroke: 'var(--grey5)', strokeDasharray: 2 },
};

export type ScheduleRunDataType = {
  x: Date,
  y: number | '-' | undefined,
  fill: string,
  // label: string,
  path: string,
  meta: {[k: string]: any},
}

interface ExecutionsChartProps {
  data: ScheduleRunDataType[],
  dimensions: useGetChartDimensionsReturnType,
  domain?: DomainPropType,
}

const ExecutionsChart:FC<ExecutionsChartProps> = ({
  data,
  dimensions,
  domain,
}) => {
  const history = useHistory();
  const {
    chartHeight,
    axisConfig,
    tooltipConfig,
  } = dimensions;

  if (data.length === 0) {
    return (<NoDataZeroState />);
  }

  return (
    <VictoryChart
      theme={VictoryTheme.material}
      scale={{ x: 'time', y: 'linear' }}
      height={chartHeight}
      domainPadding={{ x: 10, y: 2 }}
      padding={{
        left: 36,
        right: 8,
        top: 0,
        bottom: 10,
      }}
      domain={domain}
      containerComponent={(
        <VictoryContainer
          responsive
        />
    )}
    >
      <VictoryAxis
        dependentAxis
        label="time (seconds)"
        style={{
          ...axisStyle,
          axis: { strokeWidth: 0 },
        }}
        tickLabelComponent={(
          <VictoryLabel
            dx={4}
            style={tickLabelStyle(axisConfig.tickLabelFontSize.toFixed(0))}
          />
    )}
        axisLabelComponent={(
          <VictoryLabel
            dx={0}
            dy={-20}
            style={axisLabelStyle(axisConfig.labelFontSize.toFixed(0))}
          />
    )}
      />
      <VictoryAxis
        label="date"
        scale="time"
        style={axisStyle}
        tickFormat={(tick: any) => DateTime.fromJSDate(tick).toFormat('LLL d')}
        tickLabelComponent={(
          <VictoryLabel
            angle={-45}
            dx={-4}
            style={tickLabelStyle(axisConfig.tickLabelFontSize.toFixed(0))}
          />
        )}
        axisLabelComponent={(
          <VictoryLabel
            dy={16}
            style={axisLabelStyle(axisConfig.labelFontSize.toFixed(0))}
          />
        )}
      />
      <VictoryScatter
        style={{
          data: {
            fill: ({ datum }:{datum?: any}) => datum.fill,
          },
        }}
        size={2}
        animate={{
          duration: 2000,
          onLoad: { duration: 1000 },
        }}
        data={data}
        labels={() => ''}
        events={[
          {
            target: 'data',
            eventHandlers: {
              onClick: (ev: any, d: any) => {
                history.push(d.datum.path);
              },
              onMouseOver: (ev: any, d: any) => [
                {
                  target: 'data',
                  mutation: () => ({
                    style: {
                      cursor: 'pointer',
                      fill: d.datum.fill,
                      stroke: d.datum.fill,
                      strokeWidth: 2,
                    },
                  }),
                },
                {
                  target: 'labels',
                  mutation: () => ({ active: true }),
                },
              ],
              onMouseOut: (ev: any, d: any) => [
                {
                  target: 'data',
                  mutation: () => ({
                    style: {
                      cursor: 'default',
                      fill: d.datum.fill,
                      stroke: undefined,
                      strokeWidth: 0,
                    },
                  }),
                },
                {
                  target: 'labels',
                  mutation: () => ({ active: false }),
                },
              ],
            },
          },
        ]}
        labelComponent={(
          <VictoryTooltip
            cornerRadius={4}
            flyoutComponent={(
              <TooltipFlyout />
            )}
            labelComponent={(
              <VictoryLabel
                style={{
                  fontFamily: 'var(--inter)',
                  letterSpacing: 'var(--letter_spacing_caption)',
                  fill: 'var(--grey30)',
                  fontWeight: 400,
                  fontSize: tooltipConfig.labelFontSize,
                  textAnchor: 'middle',
                  verticalAnchor: 'middle',
                }}
              />
            )}
          />
    )}
      />
    </VictoryChart>
  );
};

export default ExecutionsChart;
