import { useEffect, useState } from 'react';
import { RepoProviderType, companyStore } from '../../../stores';
import {
  useGetDwhCostConfigLazyQuery,
  useProjectSettingsLazyQuery,
} from '../../../client/generated/service';

const useGetRadarContext = () => {
  const [isRadarContextLoading, setIsRadarContextLoading] = useState(false);
  const {
    repoProvider, setRepoProvider, costWarehouseType, setCostWarehouseType,
  } = companyStore.getState();

  const [getProjectSettings, { loading: isLoadingProjectSettings }] = useProjectSettingsLazyQuery({
    onCompleted: (data) => {
      if (data.projectSettings?.gitProvider) {
        setRepoProvider(data.projectSettings.gitProvider as RepoProviderType);
      }
    },
  });
  useEffect(() => {
    if (!repoProvider) { getProjectSettings(); }
  }, [repoProvider]);

  const [getDwhCostConfig, { loading: isLoadingCostConfig }] = useGetDwhCostConfigLazyQuery({
    onCompleted: (data) => {
      if (data.getDwhCostConfig.databaseType) {
        setCostWarehouseType(data.getDwhCostConfig.databaseType);
      }
    },
  });
  useEffect(() => {
    if (!costWarehouseType) { getDwhCostConfig(); }
  }, [costWarehouseType]);

  useEffect(() => {
    if (isLoadingCostConfig || isLoadingProjectSettings) {
      setIsRadarContextLoading(true);
    } else {
      setIsRadarContextLoading(false);
    }
  }, [isLoadingProjectSettings, isLoadingCostConfig]);

  return {
    isRadarContextLoading,
    repoProvider,
    costWarehouseType,
  };
};

export { useGetRadarContext };
