import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import GetAllModelsButton, { GetAllModelsButtonProps } from './GetAllModelsButton';
import { BoltHelp } from '../../Common/AppHelp/help';

export enum subtitleTypes {
  GET_STARTED = 'getStarted',
  SELECT_MODELS = 'selectModels',
  ENTER_DETAILS = 'enterDetails',
  BLANK = '',
}

export interface HeaderProps extends GetAllModelsButtonProps {
  showButton: boolean,
  subtitleType: subtitleTypes,
}

const subtitles = {
  [subtitleTypes.GET_STARTED]: {
    text: 'Generate ThoughtSpot worksheets from your synced dbt™ models.',
    showHelp: false,
    helpArticleId: 0,
  },
  [subtitleTypes.SELECT_MODELS]: {
    text: 'Select models to sync to ThoughtSpot.',
    showHelp: false,
    helpArticleId: 0,
  },
  [subtitleTypes.ENTER_DETAILS]: {
    text: 'Enter your ThoughtSpot connection and database name to create worksheets using the selected models.',
    showHelp: true,
    helpArticleId: BoltHelp.THOUGHTSPOT,
  },
  [subtitleTypes.BLANK]: {
    text: '',
    showHelp: false,
    helpArticleId: 0,
  },
};

const Header: FC<HeaderProps> = ({
  onGetAllModelsClick,
  showButton,
  buttonType,
  subtitleType,
}) => (
  <AutoLayout
    direction="horizontal"
    padding="none"
    distribution="space-between"
    horizontalGap="expanded"
    width="full"
  >
    <AutoLayout
      direction="vertical"
      padding="none"
      distribution="packed"
      verticalGap="none"
    >
      <Typography
        font="inter"
        type="h6"
        color="default"
        colorStep="100"
      >
        ThoughtSpot dbt™ Sync
      </Typography>
      <AutoLayout
        direction="horizontal"
        padding="none"
        distribution="packed"
        horizontalGap="ultra-dense"
      >
        <Typography
          font="inter"
          type="caption"
          color="default"
          colorStep="50"
        >
          {subtitles[subtitleType].text}
        </Typography>
        {subtitles[subtitleType].showHelp ? (
          <Chips
            color="primary_alt"
            round={false}
            style={{ margin: '4px', verticalAlign: 'bottom' }}
            tag="Learn more"
            type="dense"
            view="smooth"
            onClick={() => (
              window.CommandBar.openHelpHub(
                { articleId: subtitles[subtitleType].helpArticleId },
              )
            )}
          />
        )
          : null}
      </AutoLayout>
    </AutoLayout>
    {showButton && (
      <GetAllModelsButton
        onGetAllModelsClick={onGetAllModelsClick}
        buttonType={buttonType}
      />
    )}
  </AutoLayout>
);

export default Header;
