import React, { FC, useState } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import PageTitle, { PageTitles } from '../../PageTitle';
import PageHeader, { WebhooksTabs } from './PageHeader';
import Configure from './Configure';
import ExploreTemplates from './ExploreTemplates';

const Webhooks: FC = () => {
  const [selectedTab, setSelectedTab] = useState(WebhooksTabs.CONFIGURE);

  return (
    <AutoLayout
      direction="vertical"
      padding="expanded"
      horizontalGap="dense"
      distribution="packed"
      alignment="top-left"
      wrapperStyle={{ gridArea: 'b', overflowY: 'auto' }}
      width="full"
      style={{ maxWidth: '1280px' }}
    >
      <PageTitle title={PageTitles.WEBHOOKS} />
      <AutoLayout
        direction="vertical"
        padding="none"
        verticalGap="normal"
        distribution="packed"
        alignment="top-left"
      >
        <PageHeader
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
        />
        {selectedTab === WebhooksTabs.CONFIGURE && <Configure />}
        {selectedTab === WebhooksTabs.EXPLORE_TEMPLATES && <ExploreTemplates />}
      </AutoLayout>
    </AutoLayout>
  );
};

export default Webhooks;
