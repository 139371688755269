import React, { FC } from 'react';
import Helmet from 'react-helmet';

export enum PageTitles {
  'HOME' = 'Home',
  'EDITOR' = 'Editor',
  'LINEAGE' = 'Lineage',
  'CATALOG' = 'Catalog',
  'BOLT' = 'Bolt',
  'RADAR' = 'Radar',
  'SIGNALS' = 'Signals',
  // Account settings
  'CONNECTIONS' = 'Connection Settings',
  'WORKSPACE' = 'Workspace Settings',
  'WEBHOOKS' = 'Webhooks',
  'PLANS' = 'Pricing Plans',
  'BILLING' = 'Billing',
  'INTEGRATIONS' = 'Integrations',
  'TEAM' = 'Team Settings',
  'APP_HELP' = 'Help',
  'INSIGHTS' = 'Insights',
  // Profile Settings
  'PROFILE' = 'Profile Settings',
  'PRIVACY' = 'Privacy Settings',
  'ENV_VARS' = 'Environment Variables',
  'ONBOARDING' = 'Onboarding',
  'AUDIT_LOGS' = 'Audit Logs',
}

const PageTitle: FC<{ title?: string}> = ({ title }) => {
  const pageTitle = title ? `${title} | Paradime` : 'Paradime';

  return (
    <Helmet
      title={pageTitle}
    />
  );
};

export default PageTitle;
