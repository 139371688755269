import React, { Dispatch, FC, SetStateAction } from 'react';
import InAppVideoButton from '@paradime/common/lib/common/helpers/InAppVideoButton';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { GqlParadimeAccountType } from '../../client/generated/service';
import ShareFeedbackButton from '../Common/Components/ShareFeedbackButton';

export interface DashboardHeaderProps {
  isEditMode: boolean,
  setIsEditMode: Dispatch<SetStateAction<boolean>>,
  title: string,
  subtitle?: string,
  helpArticleId?: number,
  accessLevel: GqlParadimeAccountType,
  customDashboardsIsEnabled: boolean,
  hasPaywall: boolean,
  showHeaderButton?: boolean,
}

const DashboardHeader:FC<DashboardHeaderProps> = ({
  title,
  subtitle,
  helpArticleId,
}) => (
  <AutoLayout
    direction="vertical"
    padding="none"
    verticalGap="ultra-dense"
  >
    <AutoLayout
      direction="horizontal"
      padding="none"
      distribution="space-between"
      style={{ alignItems: 'center' }}
    >
      <AutoLayout
        direction="horizontal"
        padding="none"
        distribution="packed"
        horizontalGap="very-dense"
      >
        <Typography type="h6">{title}</Typography>
        {helpArticleId && (
        <Chips
          color="primary_alt"
          round={false}
          style={{ margin: '4px', verticalAlign: 'bottom', justifySelf: 'end' }}
          tag="Learn more"
          type="dense"
          view="smooth"
          onClick={() => (
            window.CommandBar.openHelpHub({ articleId: helpArticleId })
          )}
        />
        )}
      </AutoLayout>
      <AutoLayout
        direction="horizontal"
        padding="none"
        distribution="packed"
        horizontalGap="very-dense"
      >
        <InAppVideoButton
          tutorial={{
            title: 'Radar tutorial',
            description: 'The best tutorial that there is on Radar',
            videoUrl: 'https://youtu.be/qL8XiDd488w',
          }}
        />
        <ShareFeedbackButton
          type="standard"
          view="outlined"
          color="primary_alt"
          icon="chat"
          text="Share feedback"
          dense
          eventContext={Contexts.RADAR}
          eventObject="shareFeedback"
          eventAction={Actions.CLICKED}
          feedbackContext="radar-dashboards"
        />
      </AutoLayout>
    </AutoLayout>
    {subtitle && (
      <Typography type="caption" colorStep="50">
        {subtitle}
      </Typography>
    )}
  </AutoLayout>
);

export default DashboardHeader;
