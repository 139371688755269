import React, {
  FC, FormEvent, useEffect, useState,
} from 'react';
import { ColumnGroup } from 'primereact/columngroup';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { SortOrder } from 'primereact/api';
import DataTable, { ColumnType } from '@paradime-io/pragma-ui-kit/lib/components/PrimeReactDataTable';
import TextWithTooltip from '@paradime-io/pragma-ui-kit/lib/components/TextWithTooltip';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import { formatCurrency } from './utils';
import { optimisationListItem } from '.';
import { optimisationAggregateItem } from './useGetAggregatedOptimisationData';
import { useListActiveSnowflakeWarehouseOptimizationsLazyQuery } from '../../../client/generated/service';
import { RadarHelp } from '../../Common/AppHelp/help';
import { userHasRadarCostOptimisationsEditAccess } from '../../../utilis/PermissionsService';
import { userAuthStore } from '../../../stores';

export interface OptimisationsTableProps {
  costAgentOptimisations: optimisationListItem,
  snowflakeWarehouseOptimisations: optimisationListItem,
  aggregatedOptimisationData: optimisationAggregateItem[],
}

const columns = [
  { field: 'warehouseName', header: 'Warehouse name', style: { maxWidth: '250px' } },
  {
    field: 'agentSavings',
    header: 'Savings',
    type: ColumnType.CHIP_WITH_TEXT,
  },
  {
    field: 'enableAgentSavings',
    header: 'Enable',
    type: ColumnType.SWITCH,
    style: { maxWidth: '75px' },
  },
  {
    field: 'autoScalerSavings',
    header: 'Savings',
    type: ColumnType.CHIP_WITH_TEXT,
  },
  {
    field: 'enableAutoScalerSavings',
    header: 'Enable',
    type: ColumnType.SWITCH,
    style: { maxWidth: '75px' },
  },
  { field: 'potentialAnnualSavings', header: 'Potential Total Annual Savings', type: ColumnType.CURRENCY },
  { field: 'currentAnnualCost', header: 'Estimated Annual Spend', type: ColumnType.CURRENCY },
];

const headerColumnGroup = (
  <ColumnGroup>
    <Row>
      <Column header="Warehouse Name" rowSpan={2} sortable field="warehouseName" />
      <Column
        header={(
          <TextWithTooltip
            value="Warehouse AI Agent Optimizer"
            type="body-bold-small"
            info="Lower your credit consumption on auto-pilot optimizing warehouse utilization."
            dark
          />
        )}
        colSpan={2}
      />
      <Column
        header={(
          <TextWithTooltip
            value="Warehouse AI Autoscaler"
            type="body-bold-small"
            info="Improve warehouse size utilization with bespoke downsizing of your warehouse."
            dark
          />
        )}
        colSpan={2}
      />
      <Column header="Potential Total Annual Savings" rowSpan={2} sortable field="potentialAnnualSavings" />
      <Column header="Estimated Annual Spend" rowSpan={2} sortable field="currentAnnualCost" />
    </Row>
    <Row>
      <Column header="Savings" field="agentSavings" />
      <Column header="Enable" field="enableAgentSavings" />
      <Column header="Savings" field="autoScalerSavings" />
      <Column header="Enable" field="enableAutoScalerSavings" />
    </Row>
  </ColumnGroup>
);

const OptimisationsTable: FC<OptimisationsTableProps> = ({
  costAgentOptimisations,
  snowflakeWarehouseOptimisations,
  aggregatedOptimisationData,
}) => {
  const [activeAgentWarehouses, setActiveAgentWarehouses] = useState<string[]>([]);
  const [activeAutoScalerWarehouses, setActiveAutoScalerWarehouses] = useState<string[]>([]);

  const { accessLevel } = userAuthStore((s) => s.currentUser);
  const hasEditAccess = userHasRadarCostOptimisationsEditAccess(accessLevel);

  const [getActiveOptimisations] = useListActiveSnowflakeWarehouseOptimizationsLazyQuery({
    onCompleted: (data) => {
      const {
        optimizerAgentWarehouses,
        autoscalerAgentWarehouses,
      } = data.listActiveSnowflakeWarehouseOptimizations;

      setActiveAgentWarehouses(optimizerAgentWarehouses);
      setActiveAutoScalerWarehouses(autoscalerAgentWarehouses);
    },
  });

  useEffect(() => {
    getActiveOptimisations();
  }, []);

  const getChipDetails = (savingAmount: number, isApplied: boolean) => {
    if (isApplied && savingAmount > 0) {
      return { color: 'success', tag: 'Actual' };
    }
    if (isApplied) {
      return { color: 'primary', tag: 'Calculating' };
    }
    return { color: 'default', tag: 'Estimated' };
  };

  const getTableData = () => (aggregatedOptimisationData.map((data) => {
    const isAgentSelected = activeAgentWarehouses.includes(data.snowflakeWarehouseName);
    const agentSavingAmount = isAgentSelected
      ? Number(data.agentOptimisationSavingsTillDate)
      : Number(data.agentOptimisationPotentialAnnualSavings);
    const isAgentLoading = costAgentOptimisations.isLoadingApplyOptimisation
      || costAgentOptimisations.isLoadingPauseOptimisation;
    const isAgentDisabled = !hasEditAccess
      || isAgentLoading
      || (!isAgentSelected && agentSavingAmount === 0);

    const isAutoScalerSelected = activeAutoScalerWarehouses.includes(data.snowflakeWarehouseName);
    const autoScalerSavingAmount = isAutoScalerSelected
      ? Number(data.autoscalerOptimisationSavingsTillDate)
      : Number(data.autoscalerOptimisationPotentialAnnualSavings);
    const isAutoScalerLoading = snowflakeWarehouseOptimisations.isLoadingApplyOptimisation
      || snowflakeWarehouseOptimisations.isLoadingPauseOptimisation;
    const isAutoScalerDisabled = !hasEditAccess
      || isAutoScalerLoading
      || (!isAutoScalerSelected && autoScalerSavingAmount === 0);

    return ({
      warehouseName: data.snowflakeWarehouseName,
      currentAnnualCost: data.estimatedCurrentAnnualSpend || 0,
      potentialAnnualSavings: data.allOptimisationsPotentialAnnualSavings || 0,
      agentSavings: {
        chip: getChipDetails(agentSavingAmount, isAgentSelected),
        text: formatCurrency(agentSavingAmount),
      },
      enableAgentSavings: {
        checked: isAgentSelected,
        onChange: (e: FormEvent<HTMLInputElement>, isChecked: boolean) => (
          isChecked
            ? costAgentOptimisations.applyOptimisation(data.snowflakeWarehouseName)
            : costAgentOptimisations.pauseOptimisation(data.snowflakeWarehouseName)
        ),
        disabled: isAgentDisabled,
        color: isAgentDisabled ? 'default' : 'success',
      },
      autoScalerSavings: {
        chip: getChipDetails(autoScalerSavingAmount, isAutoScalerSelected),
        text: formatCurrency(autoScalerSavingAmount),
      },
      enableAutoScalerSavings: {
        checked: isAutoScalerSelected,
        onChange: (e: FormEvent<HTMLInputElement>, isChecked: boolean) => (
          isChecked
            ? snowflakeWarehouseOptimisations.applyOptimisation(data.snowflakeWarehouseName)
            : snowflakeWarehouseOptimisations.pauseOptimisation(data.snowflakeWarehouseName)
        ),
        disabled: isAutoScalerDisabled,
        color: isAutoScalerDisabled ? 'default' : 'success',
      },
    });
  }));

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      verticalGap="dense"
      distribution="packed"
    >
      <AutoLayout
        direction="horizontal"
        padding="none"
        distribution="packed"
        horizontalGap="very-dense"
      >
        <Typography
          font="inter"
          type="body-bold"
          color="default"
          colorStep="100"
        >
          Warehouse Optimizations
        </Typography>
        <Chips
          color="primary_alt"
          round={false}
          style={{ margin: '4px', verticalAlign: 'bottom', justifySelf: 'end' }}
          tag="Find Out About Each Savings Status"
          type="dense"
          view="smooth"
          onClick={() => (
            window.CommandBar.openHelpHub({ articleId: RadarHelp.OPTIMISATIONS })
          )}
        />
      </AutoLayout>
      <DataTable
        columns={columns}
        headerColumnGroup={headerColumnGroup}
        data={getTableData()}
        defaultSortField="potentialAnnualSavings"
        defaultSortOrder={SortOrder.DESC}
        showSearchBar={false}
        usePagination
        rowsPerPage={25}
        size="small"
        dragAndDropColumns={false}
      />
    </AutoLayout>
  );
};

export default OptimisationsTable;
