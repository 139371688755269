import React, { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import {
  StackedBarChart,
  PieChart,
  SingleValueChart,
  TableChart,
  AreaChart,
} from '@paradime-io/pragma-ui-kit/lib/components/VictoryCharts';
import { useCubeLazyQuery } from '../../../app/hooks/useCubeQuery';
import {
  modelNamesForDropdownQuery,
  modelNamesForDropdownReturnType,
  formatModelNamesForDropdown,
  dailyPassRateQuery,
  dailyPassRateReturnType,
  formatDailyPassRateForChart,
  getDailyPassRateXAxisLabels,
  statusOverTimeQuery,
  statusOverTimeReturnType,
  formatStatusOverTimeForChart,
  getStatusOverTimeXAxisLabels,
  resultsByColumnQuery,
  resultsByColumnReturnType,
  formatResultsByColumnForChart,
  getResultsByColumnXAxisLabels,
  dailyImpactedRowsQuery,
  dailyImpactedRowsReturnType,
  formatDailyImpactedRowsForChart,
  getDailyImpactedRowsXAxisLabels,
  passRateQuery,
  passRateReturnType,
  resultsMakeUpQuery,
  resultsMakeUpReturnType,
  formatResultsMakeUpForChart,
  currentOverviewQuery,
  currentOverviewReturnType,
  currentOverviewColumns,
  formatCurrentOverviewForChart,
} from './DetailsCubeQueries';
import {
  timeframes,
  HalfPageChartSection,
  FullPageChartSection,
  getSingleValueChartString,
  getFormattedYAxisLabel,
} from '../utils';
import FilterRow from '../FilterRow';
import CodeSnippetCopy from '../../Common/Components/CodeSnippetCopy';
import { useGetFiltersFromUrl } from '../hooks/useGetFiltersFromUrl';
import { TableTooltip } from '../Radar.styles';

const DetailsTab: FC = () => {
  const { getInitialFilterValue } = useGetFiltersFromUrl();

  const [timeframe, setTimeframe] = useState(getInitialFilterValue('timeframe', timeframes[2].value)!);
  const [modelName, setModelName] = useState(getInitialFilterValue('modelName', ' ')!);

  const [
    getModelNamesForDropdown,
    { data: modelNamesForDropdown, loading: isLoadingModelNamesForDropdown },
  ] = useCubeLazyQuery<modelNamesForDropdownReturnType>({
    query: modelNamesForDropdownQuery,
    variables: { timeframe: timeframe.value },
    onCompleted: ({ data }) => {
      const firstOption = data.cube?.[0]?.modelRunStatistics?.runResultUniqueId;
      const urlVal = getInitialFilterValue('modelName', firstOption);
      if (urlVal) setModelName(urlVal);
    },
  });

  const [
    getDailyPassRate,
    { data: dailyPassRateData, loading: isLoadingDailyPassRate, error: dailyPassRateError },
  ] = useCubeLazyQuery<dailyPassRateReturnType>({
    query: dailyPassRateQuery,
    variables: { timeframe: timeframe.value, sourceName: modelName.value },
  });
  const [
    getStatusOverTime,
    { data: statusOverTimeData, loading: isLoadingStatusOverTime, error: statusOverTimeError },
  ] = useCubeLazyQuery<statusOverTimeReturnType>({
    query: statusOverTimeQuery,
    variables: { timeframe: timeframe.value, sourceName: modelName.value },
  });
  const [
    getResultsByColumn,
    { data: resultsByColumnData, loading: isLoadingResultsByColumn, error: resultsByColumnError },
  ] = useCubeLazyQuery<resultsByColumnReturnType>({
    query: resultsByColumnQuery,
    variables: { timeframe: timeframe.value, sourceName: modelName.value },
  });
  const [
    getDailyImpactedRows, {
      data: dailyImpactedRowsData,
      loading: isLoadingDailyImpactedRows,
      error: dailyImpactedRowsError,
    },
  ] = useCubeLazyQuery<dailyImpactedRowsReturnType>({
    query: dailyImpactedRowsQuery,
    variables: { timeframe: timeframe.value, sourceName: modelName.value },
  });
  const [
    getPassRate, { data: passRateData, loading: isLoadingPassRate, error: passRateError },
  ] = useCubeLazyQuery<passRateReturnType>({
    query: passRateQuery,
    variables: { timeframe: timeframe.value, sourceName: modelName.value },
  });
  const [
    getResultsMakeUp,
    { data: resultsMakeUpData, loading: isLoadingResultsMakeUp, error: resultsMakeUpError },
  ] = useCubeLazyQuery<resultsMakeUpReturnType>({
    query: resultsMakeUpQuery,
    variables: { timeframe: timeframe.value, sourceName: modelName.value },
  });
  const [
    getCurrentOverview,
    { data: currentOverviewData, loading: isLoadingCurrentOverview, error: currentOverviewError },
  ] = useCubeLazyQuery<currentOverviewReturnType>({
    query: currentOverviewQuery,
    variables: { timeframe: timeframe.value, sourceName: modelName.value },
  });

  const refetchAllData = () => {
    getDailyPassRate();
    getStatusOverTime();
    getResultsByColumn();
    getDailyImpactedRows();
    getPassRate();
    getResultsMakeUp();
    getCurrentOverview();
  };

  useEffect(() => {
    if (modelName.value !== ' ') {
      refetchAllData();
    }
  }, [timeframe, modelName]);

  useEffect(() => {
    getModelNamesForDropdown();
  }, [timeframe]);

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      verticalGap="dense"
      distribution="packed"
    >
      <FilterRow
        dropdowns={[
          {
            value: timeframe,
            options: timeframes,
            onChange: setTimeframe,
            name: 'timeframe',
            title: 'Select date range',
          },
          {
            value: modelName,
            options: formatModelNamesForDropdown(modelNamesForDropdown),
            onChange: setModelName,
            isLoading: isLoadingModelNamesForDropdown,
            name: 'modelName',
            title: 'Choose a model',
          },
        ]}
      />
      <div style={{
        display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: '16px',
      }}
      >
        <HalfPageChartSection>
          <PieChart
            title="Tests results composition"
            data={formatResultsMakeUpForChart(resultsMakeUpData).data}
            legendLabels={formatResultsMakeUpForChart(resultsMakeUpData).statuses}
            customColourScale={formatResultsMakeUpForChart(resultsMakeUpData).colours}
            isLoading={isLoadingResultsMakeUp}
            hasNoData={resultsMakeUpError || resultsMakeUpData?.cube?.length === 0}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <SingleValueChart
            title="Tests Pass Rate"
            value={(
              getSingleValueChartString(
                passRateData?.cube?.[0]?.testSuccessRateByModel?.testSuccessRate || 0,
                'percent',
              )
            )}
            isLoading={isLoadingPassRate}
            hasNoData={passRateError || passRateData?.cube?.length === 0}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <AreaChart
            title="Daily tests pass rate"
            data={formatDailyPassRateForChart(dailyPassRateData)}
            xAxisLabels={getDailyPassRateXAxisLabels(dailyPassRateData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick, 'compact', 'percent')}
            xLabel="Calendar date"
            yLabel="Pass rate (%)"
            isLoading={isLoadingDailyPassRate}
            hasNoData={dailyPassRateError || dailyPassRateData?.cube?.length === 0}
            yAxisDomain={[0, 1.0]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick, 'compact', 'percent'),
            }}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Tests execution status overtime"
            data={formatStatusOverTimeForChart(statusOverTimeData).data}
            xAxisLabels={getStatusOverTimeXAxisLabels(statusOverTimeData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Calendar date"
            yLabel="Count tests executed"
            legendLabels={formatStatusOverTimeForChart(statusOverTimeData).statuses}
            customColourScale={formatStatusOverTimeForChart(statusOverTimeData).colours}
            isLoading={isLoadingStatusOverTime}
            hasNoData={statusOverTimeError || statusOverTimeData?.cube?.length === 0}
            xAxisDomain={[
              0.5,
              getStatusOverTimeXAxisLabels(statusOverTimeData).length + 1,
            ]}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Tests results by columns"
            data={formatResultsByColumnForChart(resultsByColumnData).data}
            xAxisLabels={getResultsByColumnXAxisLabels(resultsByColumnData)}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Column name"
            yLabel="Count tests executed"
            legendLabels={formatResultsByColumnForChart(resultsByColumnData).statuses}
            customColourScale={formatResultsByColumnForChart(resultsByColumnData).colours}
            isLoading={isLoadingResultsByColumn}
            hasNoData={resultsByColumnError || resultsByColumnData?.cube?.length === 0}
            maxCharsAxisX={6}
            xAxisDomain={[
              0.5,
              getResultsByColumnXAxisLabels(resultsByColumnData).length + 1,
            ]}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <AreaChart
            title="Daily impacted rows by warn/failed tests"
            data={formatDailyImpactedRowsForChart(dailyImpactedRowsData).data}
            xAxisLabels={getDailyImpactedRowsXAxisLabels(dailyImpactedRowsData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Column name"
            yLabel="Count impacted rows"
            legendLabels={formatDailyImpactedRowsForChart(dailyImpactedRowsData).statuses}
            customColourScale={formatDailyImpactedRowsForChart(dailyImpactedRowsData).colours}
            isLoading={isLoadingDailyImpactedRows}
            hasNoData={dailyImpactedRowsError || dailyImpactedRowsData?.cube?.length === 0}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </HalfPageChartSection>
        <FullPageChartSection style={{ height: '100%' }}>
          <>
            <TableChart
              title="Current tests status overview"
              isLoading={isLoadingCurrentOverview}
              hasNoData={currentOverviewError || currentOverviewData?.cube?.length === 0}
              columns={currentOverviewColumns}
              data={formatCurrentOverviewForChart(currentOverviewData)}
              rowExpansionTemplate={(row) => (
                <CodeSnippetCopy codeSnippet={row.testCompiledSql?.trim()} language="sql" />
              )}
              rowHasExpander={(data) => data?.testCompiledSql}
              rowsPerPage={5}
            />
            <TableTooltip target=".test-result-message" mouseTrack mouseTrackLeft={10} style={{ height: 'auto' }} />
          </>
        </FullPageChartSection>
      </div>
    </AutoLayout>
  );
};

export default DetailsTab;
