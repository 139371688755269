import React, { ReactNode } from 'react';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Arcade from '../../Common/Components/Arcade';
import * as S from '../Bolt.styles';

const ConfigureTurboCiJobStep = (): ReactNode => (
  <AutoLayout
    direction="vertical"
    distribution="packed"
    verticalGap="dense"
    padding="none"
    alignment="top-left"
  >
    <Typography tagName="span" type="h6">Configure CI Job</Typography>
    <Typography tagName="span" type="body-small">
      1. Go to Bolt on the home screen and click
      {' '}
      <strong>+ New Schedule</strong>
    </Typography>
    <S.CodeTypography tagName="span" type="body-small">
      2. Select
      {' '}
      <code>turbo-ci</code>
      {' '}
      as the schedule type and fill out the form to set up your Turbo CI schedule..
    </S.CodeTypography>
    <Typography tagName="span" type="body-small">
      3. For more details, watch the demo, and read more about the available fields.
    </Typography>
    <Arcade
      url="https://demo.arcade.software/3HH6UibPRRafR0anUros?embed"
      title="Paradime | Turbo CI UI config"
    />
  </AutoLayout>
);

export default ConfigureTurboCiJobStep;
