import React, {
  FC, useState, createContext,
} from 'react';
import { useParams } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import * as S from '../Common/Layouts/AppPage.styles';
import { RepoProviderType } from '../../stores';
import Sidebar from './Sidebar';
import Dashboards from './Dashboards';
import { RadarTab } from './config';
import { useGetRadarContext } from './hooks/useGetRadarContext';
import PageTitle, { PageTitles } from '../PageTitle';

export const RadarWorkspaceContext = createContext({
  repoProvider: '' as RepoProviderType,
  costWarehouseType: '',
});

const Radar:FC = () => {
  const { pageId } = useParams<{pageId: RadarTab}>();
  const [isLeftPanelOpen, setIsLeftPanelOpen] = useState(false);

  const { isRadarContextLoading, repoProvider, costWarehouseType } = useGetRadarContext();

  return (
    <S.Page
      isLeftPanelOpen={isLeftPanelOpen}
    >
      <PageTitle title={`${PageTitles.RADAR} | ${pageId}`} />
      <S.LeftSidebar>
        <Sidebar
          isLeftPanelOpen={isLeftPanelOpen}
          setIsLeftPanelOpen={setIsLeftPanelOpen}
        />
      </S.LeftSidebar>
      <S.Body>
        <AutoLayout
          direction="vertical"
          padding="expanded"
          horizontalGap="dense"
          distribution="packed"
          alignment="top-left"
        >
          <RadarWorkspaceContext.Provider
            value={{
              repoProvider,
              costWarehouseType,
            }}
          >
            <Dashboards isLoading={isRadarContextLoading} />
          </RadarWorkspaceContext.Provider>
        </AutoLayout>
      </S.Body>
    </S.Page>
  );
};

export default Radar;
