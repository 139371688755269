import { gql } from '@apollo/client';

export interface bugCommitsReturnType {
  cube: {
    githubDailyCommitsByType: {
      commitCount: number,
    }
  }[]
}

export const bugCommitsQuery = gql`
  query bugCommitsQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {github_daily_commits_by_type: {commit_type: {equals: "bug_fix_commit"}, commit_created_at_timestamp: {inDateRange: $timeframe}}}
    ) {
      githubDailyCommitsByType: github_daily_commits_by_type {
        commitCount: commit_count
      }
    }
  }
`;

export interface featureCommitsReturnType {
  cube: {
    githubDailyCommitsByType: {
      commitCount: number,
    }
  }[]
}

export const featureCommitsQuery = gql`
  query featureCommitsQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {github_daily_commits_by_type: {commit_type: {equals: "feature_commit"}, commit_created_at_timestamp: {inDateRange: $timeframe}}}
    ) {
      githubDailyCommitsByType: github_daily_commits_by_type {
        commitCount: commit_count
      }
    }
  }
`;

export interface countCommitTypesReturnType {
  cube: {
    githubDailyCommitsByType: {
      commitCount: number,
      commitType: string,
    }
  }[]
}

export const countCommitTypesQuery = gql`
  query countCommitTypesQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {github_daily_commits_by_type: {commit_created_at_timestamp: {inDateRange: $timeframe}}}
    ) {
      githubDailyCommitsByType: github_daily_commits_by_type {
        commitCount: commit_count
        commitType: commit_type
      }
    }
  }
`;

export const formatCountCommitTypesForChart = (data?: countCommitTypesReturnType) => {
  if (!data) return [];

  return data.cube
    .map(({ githubDailyCommitsByType }) => ({
      x: githubDailyCommitsByType.commitType,
      y: githubDailyCommitsByType.commitCount,
    }));
};

export interface weeklyCommitsReturnType {
  cube: {
    githubDailyCommitsByType: {
      commitCount: number,
      commitType: 'feature_commit' | 'bug_fix_commit',
      commitCreatedAtTimestamp: {
        week: string,
      }
    }
  }[]
}

export const weeklyCommitsQuery = gql`
  query weeklyCommitsQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {github_daily_commits_by_type: {commit_created_at_timestamp: {inDateRange: $timeframe}}}
    ) {
      githubDailyCommitsByType: github_daily_commits_by_type(orderBy: {commit_created_at_timestamp: asc}) {
        commitCount: commit_count
        commitType: commit_type
        commitCreatedAtTimestamp: commit_created_at_timestamp {
          week
        }
      }
    }
  }
`;

export const formatWeeklyCommitsForChart = (data?: weeklyCommitsReturnType) => {
  if (!data) return [];

  const featureCommits = data.cube
    .filter(({ githubDailyCommitsByType }) => githubDailyCommitsByType.commitType === 'feature_commit')
    .map(({ githubDailyCommitsByType }) => ({
      x: githubDailyCommitsByType.commitCreatedAtTimestamp.week,
      y: githubDailyCommitsByType.commitCount,
    }));

  const bugCommits = data.cube
    .filter(({ githubDailyCommitsByType }) => githubDailyCommitsByType.commitType === 'bug_fix_commit')
    .map(({ githubDailyCommitsByType }) => ({
      x: githubDailyCommitsByType.commitCreatedAtTimestamp.week,
      y: githubDailyCommitsByType.commitCount,
    }));

  return [featureCommits, bugCommits];
};

export const getWeeklyCommitsXAxisLabels = (data?: weeklyCommitsReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ githubDailyCommitsByType }) => (
      githubDailyCommitsByType.commitCreatedAtTimestamp.week
    ));
  return Array.from(new Set(dates));
};

export interface topFileContributionsReturnType {
  cube: {
    githubFileChangesByCommitType: {
      distinctCommitCount: number,
      fileName: string,
    }
  }[]
}

export const topFileContributionsQuery = gql`
  query topFileContributionsQuery($timeframe: [String]) {
    cube(
      limit: 10
      where: {github_file_changes_by_commit_type: {commit_created_at_timestamp: {inDateRange: $timeframe}}}
    ) {
      githubFileChangesByCommitType: github_file_changes_by_commit_type(orderBy: {distinct_commit_count: desc}) {
        distinctCommitCount: distinct_commit_count
        fileName: file_name
      }
    }
  }
`;

export const formatTopFileContributionsForChart = (data?: topFileContributionsReturnType) => {
  if (!data) return [];

  return [data.cube
    .map(({ githubFileChangesByCommitType }) => ({
      x: githubFileChangesByCommitType.fileName,
      y: githubFileChangesByCommitType.distinctCommitCount,
    }))];
};

export const getTopFileContributionsXAxisLabels = (data?: topFileContributionsReturnType) => {
  if (!data) return [];

  const fileNames = data.cube
    .map(({ githubFileChangesByCommitType }) => githubFileChangesByCommitType.fileName);
  return Array.from(new Set(fileNames));
};

export interface topBugContributionsReturnType {
  cube: {
    githubFileChangesByCommitType: {
      distinctCommitCount: number,
      fileName: string,
    }
  }[]
}

export const topBugContributionsQuery = gql`
  query topBugContributionsQuery($timeframe: [String]) {
    cube(
      limit: 10
      where: {github_file_changes_by_commit_type: {commit_type: {equals: "bug_fix_commit"}, commit_created_at_timestamp: {inDateRange: $timeframe}}}
    ) {
      githubFileChangesByCommitType: github_file_changes_by_commit_type(orderBy: {distinct_commit_count: desc}) {
        distinctCommitCount: distinct_commit_count
        fileName: file_name
      }
    }
  }
`;

export const formatTopBugContributionsForChart = (data?: topFileContributionsReturnType) => {
  if (!data) return [];

  return [data.cube
    .map(({ githubFileChangesByCommitType }) => ({
      x: githubFileChangesByCommitType.fileName,
      y: githubFileChangesByCommitType.distinctCommitCount,
    }))];
};

export const getTopBugContributionsXAxisLabels = (data?: topFileContributionsReturnType) => {
  if (!data) return [];

  const fileNames = data.cube
    .map(({ githubFileChangesByCommitType }) => githubFileChangesByCommitType.fileName);
  return Array.from(new Set(fileNames));
};
