import React, { FC, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useReactFlow } from 'reactflow';
import { Menu } from '@paradime-io/pragma-ui-kit/lib/components/Menu';
import MenuItem from '@paradime-io/pragma-ui-kit/lib/components/MenuItem';
import MenuDivider from '@paradime-io/pragma-ui-kit/lib/components/MenuDivider';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { CatalogueEnvironment } from '../../client/generated/service-dataEndpoint';
import {
  alertStore, userAuthStore, lineageStore, companyStore,
} from '../../stores';
import CurrentBranchNotCleanToast from './Toasts/CurrentBranchNotCleanToast';
import { useIsCurrentBranchCleanLazyQuery } from '../../client/generated/service';
import {
  hideChildren, hideNode, hideParents, NodeType,
} from './utils';
import { integrationHandlerManager } from '../../integrations';

export interface LineageContextMenuProps {
  activeNode: NodeType & { close: Function },
}

const LineageContextMenu: FC<LineageContextMenuProps> = ({
  activeNode,
}) => {
  const currentUser = userAuthStore((s) => s.currentUser);
  const setNodeToOpen = lineageStore((s) => s.setNodeToOpen);
  const currentBranch = lineageStore((s) => s.currentBranch);
  const setLineageLoading = lineageStore((s) => s.setLineageLoading);
  const currentCommitHash = lineageStore((s) => s.currentCommitHash);
  const currentBranchInEditor = lineageStore((s) => s.currentBranchInEditor);
  const setEdgesToUpdate = lineageStore((s) => s.setEdgesToUpdate);
  const toaster = alertStore((s) => s.toaster);
  const history = useHistory();
  const { id: selection } = useParams<{ id: any }>();

  const location = useLocation();

  const {
    getNodes,
    getEdges,
    deleteElements,
  } = useReactFlow();
  const lineageNodes = getNodes();
  const lineageEdges = getEdges();

  const [checkIfBranchIsClean, { data: branchData, loading }] = useIsCurrentBranchCleanLazyQuery();

  useEffect(() => {
    setLineageLoading(loading);
  }, [loading]);

  const hideParentsFromContextMenu = (node: NodeType) => {
    if (lineageNodes && lineageEdges) {
      const nodesToDelete = hideParents({
        nodes: lineageNodes,
        edges: lineageEdges,
        selectedNode: node,
      });
      deleteElements({ nodes: nodesToDelete });
      activeNode.close();
    }
  };

  const hideChildrenFromContextMenu = (node: NodeType) => {
    if (lineageNodes && lineageEdges) {
      const nodesToDelete = hideChildren({
        nodes: lineageNodes,
        edges: lineageEdges,
        selectedNode: node,
      });
      deleteElements({ nodes: nodesToDelete });
      activeNode.close();
    }
  };

  const hideNodeFromContextMenu = (node: NodeType) => {
    if (lineageNodes && lineageEdges) {
      deleteElements({ nodes: [node] });
      const { edges } = hideNode({
        nodes: lineageNodes,
        edges: lineageEdges,
        selectedNode: node,
      });

      // Because the `setEdges` function doesn't work here
      // Save the edges we would have set to the store
      // And then in Flow.tsx use the edges state to save them properly
      setEdgesToUpdate(edges || []);

      activeNode.close();
    }
  };

  const refocusNodeFromContextMenu = (node: NodeType) => {
    history.push(`/lineage/node/${currentCommitHash}/${encodeURIComponent(btoa(node.data!.id))}${location.search}`);
    activeNode.close();
  };

  useEffect(() => {
    if (branchData?.isCurrentBranchClean?.ok) {
      if (branchData.isCurrentBranchClean.isClean
        || currentBranchInEditor === currentBranch) {
        setNodeToOpen({
          node: activeNode.data!.filePath,
          branch: currentBranch,
        });
        history.push('/editor');
      } else {
        toaster.current?.show({
          message: <CurrentBranchNotCleanToast
            branch={currentBranch}
            file={activeNode.data!.name}
          />,
        });
      }
    }
  }, [branchData]);

  const getTargetCatalogEnv = () => {
    const { defaultBranchName } = companyStore.getState();
    const currentIsDefaultBranch = defaultBranchName === currentBranch;

    if (currentIsDefaultBranch || selection === 'compare') return CatalogueEnvironment.Production;

    return CatalogueEnvironment.Development;
  };

  const onViewDocumentationClick = () => {
    const targetEnv = getTargetCatalogEnv();

    history.push(
      `/catalog/search/from-lineage/${encodeURIComponent(currentBranch!)}/${encodeURIComponent(activeNode.id)}?environment=${targetEnv}`,
      { fromLineage: activeNode.data?.name },
    );
  };

  let integrationMenuItems;
  const chosenHandler = integrationHandlerManager(activeNode.data?.nodeType || '');
  if (chosenHandler) {
    integrationMenuItems = chosenHandler.handlers.lineageContextMenu({
      activeNode,
      accessLevel: currentUser.accessLevel,
      checkIfBranchIsClean,
      onViewDocumentationClick,
    });
  }

  return (
    <div
      style={{
        position: 'absolute',
        right: 0,
        height: '35vh',
      }}
    >
      <Menu color="default" view="raised" type="dense">
        {integrationMenuItems}
        <MenuDivider />
        <MenuItem
          color="default"
          text="Refocus on node"
          type="dense"
          view="smooth"
          onItemClick={() => {
            refocusNodeFromContextMenu(activeNode);
          }}
          eventContext={Contexts.LINEAGE}
          eventObject="node"
          eventAction={Actions.REFOCUSED}
          eventProperties={{ location: 'context-menu' }}
        />
        <MenuItem
          color="default"
          text="Hide node"
          type="dense"
          view="smooth"
          onItemClick={() => {
            hideNodeFromContextMenu(activeNode);
          }}
          eventContext={Contexts.LINEAGE}
          eventObject="node"
          eventAction={Actions.HIDDEN}
          eventProperties={{ location: 'context-menu' }}
        />
        <MenuItem
          color="default"
          text="Hide parents"
          type="dense"
          view="smooth"
          onItemClick={() => {
            hideParentsFromContextMenu(activeNode);
          }}
          eventContext={Contexts.LINEAGE}
          eventObject="parentNodes"
          eventAction={Actions.HIDDEN}
          eventProperties={{ location: 'context-menu' }}
        />
        <MenuItem
          color="default"
          text="Hide children"
          type="dense"
          view="smooth"
          onItemClick={() => {
            hideChildrenFromContextMenu(activeNode);
          }}
          eventContext={Contexts.LINEAGE}
          eventObject="childNodes"
          eventAction={Actions.HIDDEN}
          eventProperties={{ location: 'context-menu' }}
        />
      </Menu>

    </div>
  );
};

export default LineageContextMenu;
