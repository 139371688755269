/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import yaml from 'js-yaml';
import { ScheduleSource, ScheduleType, NotifyCondition } from '../../Bolt';
import {
  useGetScheduleNameQuery,
  GetScheduleNameQuery,
} from '../../../client/generated/service';
import { convertKeysToSnakeCase } from '../../../utilis';

export type GqlScheduleNameType = NonNullable<NonNullable<GetScheduleNameQuery['getScheduleName']>>;

const useGetScheduleName = (scheduleId?: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [scheduleFormData, setScheduleFormData] = useState<ScheduleType>();
  const [scheduleYamlData, setScheduleYamlData] = useState<string>();

  const { data: scheduleData } = useGetScheduleNameQuery({
    variables: {
      limit: 1,
      offset: 0,
      scheduleNameUuid: scheduleId || '',
    },
  });

  const createScheduleObjectData = (s: GqlScheduleNameType) => ({
    uuid: scheduleId,
    entryId: s.meta.scheduleNameEntryId,
    source: 'ui' as ScheduleSource,
    gitBranch: s.gitBranch,
    owner: s.owner,
    name: s.name,
    ...(s.turboCi?.enabled) && {
      turboCi: {
        enabled: true,
        deferredManifestSchedule: s.turboCi.deferredScheduleName,
        successfulRunOnly: s.turboCi.successfulRunOnly,
      },
    },
    ...(s.deferredSchedule?.enabled) && {
      deferredSchedule: {
        enabled: true,
        deferredScheduleName: s.deferredSchedule?.deferredScheduleName,
        successfulRunOnly: s.deferredSchedule.successfulRunOnly,
      },
    },
    schedule: s.schedule,
    commands: s.commands?.filter((c) => c !== null) as string[],
    ...(s.slackOn || s.slackNotify) && {
      slackOn: s.slackOn as unknown as NotifyCondition[],
      slackNotify: s.slackNotify?.filter((c) => c !== null) as string[],
    },
    ...(s.emailOn) && {
      emailOn: s.emailOn as unknown as NotifyCondition[],
      emailNotify: s.emailNotify?.filter((c) => c !== null) as string[],
    },
    ...(s.scheduleTrigger) && {
      scheduleTrigger: {
        enabled: s.scheduleTrigger.enabled,
        scheduleName: s.scheduleTrigger.scheduleName,
        workspaceName: s.scheduleTrigger.workspaceName,
        triggerOn: s.scheduleTrigger.triggerOn,
      },
    },
    suspended: s.suspended,
  });

  const updateScheduleFormData = (s: GqlScheduleNameType) => {
    const scheduleFormDataJson = createScheduleObjectData(s);
    setScheduleFormData(scheduleFormDataJson);

    const {
      uuid,
      entryId,
      source,
      // eslint-disable-next-line camelcase
      owner: owner_email,
      ...scheduleFormDataJsonRest
    } = scheduleFormDataJson;
    const scheduleFormDataSnakeCase = convertKeysToSnakeCase(scheduleFormDataJsonRest);
    const scheduleYamlObjectData = yaml.dump({
      owner_email,
      ...scheduleFormDataSnakeCase,
    });
    setScheduleYamlData(scheduleYamlObjectData);
  };

  useEffect(() => {
    if (scheduleData?.getScheduleName?.ok) {
      updateScheduleFormData(scheduleData.getScheduleName);
    }
  }, [scheduleData]);

  useEffect(() => {
    if (scheduleFormData) {
      setIsLoading(false);
    }
  }, [scheduleFormData]);

  return {
    isLoading,
    scheduleFormData,
    scheduleYamlData,
  };
};

export default useGetScheduleName;
