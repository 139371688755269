import React, {
  FC, useRef, useState,
} from 'react';
import { gql } from '@apollo/client';
import { DateTime } from 'luxon';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import DataTable, { ColumnType } from '@paradime-io/pragma-ui-kit/lib/components/PrimeReactDataTable';
import PageTitle, { PageTitles } from '../../PageTitle';
import ActionBar from './ActionBar';
import useListWorkspaces from '../Workspaces/hooks/useListWorkspaces';
import MetricCard from '../../Common/UserInsights/MetricCard';
import TextCardContent from '../../Common/UserInsights/TextCardContent';
import BarChartCardContent from '../../Common/UserInsights/BarChartCardContent';
import PieChartCardContent from '../../Common/UserInsights/PieChartCardContent';
import { userAuthStore } from '../../../stores';
import { formatCurrency, getFirstDayOfWeek, listDates } from './utils';
import { useGetChartDimensions } from '../../Bolt/Scheduler/ScheduleInsights/useGetChartDimensions';
import { useHasuraQuery } from '../../../app/hooks/useHasuraQuery';

export interface DropdownItem {
  label: string,
  value: string,
}

export interface CostSavingsQueryResponseData {
  date: string,
  companyToken: string,
  workspaceUid: string,
  category: string,
  categoryGroup: string,
  eventName: string,
  eventDescription: string,
  totalTimeSaved: string,
  totalCostSaved: string,
}

interface CostSavingsQueryResponse { // eslint-disable-next-line camelcase
  cost_savings_calculator_per_workspace: CostSavingsQueryResponseData[],
}

export interface dataByTimestamp {
  [timestamp: string]: CostSavingsQueryResponseData,
}

interface numberIndexedByDate {
  [date: string]: number,
}

const CostSavingsQuery = gql`
  query costSavingsPerWorkspaceQuery ($days: Int!) {
    cost_savings_calculator_per_workspace(args: { days: $days }) {
      date: calendar_date
      companyToken: company_token
      workspaceUid: workspace_uid
      category
      categoryGroup: category_group
      eventName: event_name
      eventDescription: event_description
      totalTimeSaved: total_time_saved_seconds
      totalCostSaved: total_cost_saving_in_usd
    }
  }
`;

const timeFrameList = [
  { label: 'Last 7 days', value: '7' },
  { label: 'Last 14 days', value: '14' },
  { label: 'Last 30 days', value: '30' },
  { label: 'Last 6 months', value: '180' },
  { label: 'Last year', value: '365' },
];

const columns = [
  { field: 'category', header: 'Category', sortable: true },
  { field: 'categoryGroup', header: 'Category Group', sortable: true },
  {
    field: 'totalCostSaved',
    header: 'Savings Total',
    type: ColumnType.CURRENCY,
    sortable: true,
  },
];

const Insights: FC = () => {
  const [selectedWorkspace, setSelectedWorkspace] = useState<DropdownItem>({ label: 'all', value: 'all' });
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<DropdownItem>({ label: 'Last 30 days', value: '30' });
  const [allCategoryGroups, setAllCategoryGroups] = useState<string[]>([]);
  const [costSavingsData, setCostSavingsData] = useState<CostSavingsQueryResponseData[]>([]);

  const { joinedWorkspacesList } = useListWorkspaces();

  const { companyName } = userAuthStore((s) => s.currentUser);

  const barChartRef = useRef(null);
  const barChartDimensions = useGetChartDimensions({
    elementRef: barChartRef,
    config: {
      BASE_CHART_WIDTH: 617,
      BASE_CHART_HEIGHT: 100,
      BASE_AXIS_LABEL_FONTSIZE: 6,
      MIN_AXIS_LABEL_FONTSIZE: 1,
      BASE_TICK_LABEL_FONTSIZE: 5,
      MIN_TICK_LABEL_FONTSIZE: 1,
      BASE_TOOLTIP_LABEL_FONTSIZE: 4,
      MIN_TOOLTIP_LABEL_FONTSIZE: 1,
      BASE_TOOLTIP_FLYOUT_WIDTH: 150,
      MIN_TOOLTIP_FLYOUT_WIDTH: 45,
      BASE_TOOLTIP_FLYOUT_HEIGHT: 8,
      MIN_TOOLTIP_FLYOUT_HEIGHT: 2,
    },
  });

  const pieChartRef = useRef(null);
  const pieChartDimensions = useGetChartDimensions({
    elementRef: pieChartRef,
    config: {
      BASE_CHART_WIDTH: 700,
      BASE_CHART_HEIGHT: 300,
      BASE_AXIS_LABEL_FONTSIZE: 6,
      MIN_AXIS_LABEL_FONTSIZE: 1,
      BASE_TICK_LABEL_FONTSIZE: 5,
      MIN_TICK_LABEL_FONTSIZE: 1,
      BASE_TOOLTIP_LABEL_FONTSIZE: 15,
      MIN_TOOLTIP_LABEL_FONTSIZE: 6,
      BASE_TOOLTIP_FLYOUT_WIDTH: 350,
      MIN_TOOLTIP_FLYOUT_WIDTH: 45,
      BASE_TOOLTIP_FLYOUT_HEIGHT: 30,
      MIN_TOOLTIP_FLYOUT_HEIGHT: 15,
    },
  });

  const { loading: isLoadingData } = useHasuraQuery<CostSavingsQueryResponse>({
    query: CostSavingsQuery,
    variables: { days: 365 },
    onCompleted: ({ data }) => {
      if (data.cost_savings_calculator_per_workspace?.length > 0) {
        setCostSavingsData(data.cost_savings_calculator_per_workspace);

        const categoryGroups = Array.from(new Set(
          data.cost_savings_calculator_per_workspace.map(({ categoryGroup }) => categoryGroup),
        ));
        setAllCategoryGroups(categoryGroups);
      }
    },
  });

  const filterToSelectedWorkspaces = () => (
    costSavingsData
      .filter(({ workspaceUid }) => {
        if (selectedWorkspace.value === 'all') {
          return true;
        }
        return workspaceUid === selectedWorkspace.value;
      })
  );

  const getWeekStartDate = (date: string) => {
    const { weekNumber, year } = DateTime.fromFormat(date, 'yyyy-LL-dd');
    return getFirstDayOfWeek(year, weekNumber).toFormat('yyyy-LL-dd');
  };

  const getTotalSaved = () => {
    const onlyDesiredWorkspaces = filterToSelectedWorkspaces();

    const totalSaved = onlyDesiredWorkspaces
      .reduce((currentObject, { date, totalCostSaved }) => {
        const clonedObject = { ...currentObject };

        // Group by week
        const weekStartDate = getWeekStartDate(date);

        clonedObject[weekStartDate] = (clonedObject[weekStartDate] || 0) + Number(totalCostSaved);
        return clonedObject;
      }, {} as numberIndexedByDate);

    const listOfDates = listDates(Number(selectedTimeFrame.value), true);

    const totalSpentOnSelectedDays = listOfDates
      .reduce((currentTotal, date) => currentTotal + (totalSaved[date] || 0), 0);

    return totalSpentOnSelectedDays;
  };

  const getTotalSavedPerCategory = () => {
    const onlyDesiredWorkspaces = filterToSelectedWorkspaces();

    const listOfDates = listDates(Number(selectedTimeFrame.value), false);

    const dataByCategory = allCategoryGroups.map((category) => (
      onlyDesiredWorkspaces
        .filter(({ categoryGroup }) => category === categoryGroup)
        .filter(({ date }) => listOfDates.includes(date))
        .reduce((currentObject, { totalCostSaved }) => ({
          categoryGroup: category,
          totalCostSaved: (currentObject?.totalCostSaved || 0) + Number(totalCostSaved),
        }), {} as { categoryGroup: string, totalCostSaved: number })
    ));

    const withoutEmptyEntries = dataByCategory
      .filter((entry) => Object.keys(entry).length !== 0);

    return withoutEmptyEntries;
  };

  const getStackedCategoryAmountPerWeek = () => {
    const onlyDesiredWorkspaces = filterToSelectedWorkspaces();

    const dataByCategory = allCategoryGroups.map((category) => (
      onlyDesiredWorkspaces
        .filter(({ categoryGroup }) => category === categoryGroup)
        .reduce((currentObject, { date, totalCostSaved }) => {
          const clonedObject = { ...currentObject };

          // Group by week
          const weekStartDate = getWeekStartDate(date);

          clonedObject[weekStartDate] = (clonedObject[weekStartDate] || 0) + Number(totalCostSaved);
          return clonedObject;
        }, {} as numberIndexedByDate)
    ));

    const listOfDates = listDates(Number(selectedTimeFrame.value), true);

    const dataByDate = dataByCategory.map((categoryData) => (
      listOfDates.reduce((currentObject, date) => {
        const clonedObject = { ...currentObject };
        clonedObject[date] = categoryData[date] || 0;
        return clonedObject;
      }, {} as numberIndexedByDate)
    ));

    const dataForChart = dataByDate
      .map((dataItem, index) => (
        Object.entries(dataItem).map(([date, amountSaved]) => ({
          x: date,
          y: amountSaved,
          isoTimestamp: DateTime.fromFormat(date, 'yyyy-LL-dd').toISO(),
          categoryName: allCategoryGroups[index],
        }))
      ));

    return dataForChart;
  };

  const getAmountSavedPerEveryCategoryAndCategoryGroup = () => {
    const onlyDesiredWorkspaces = filterToSelectedWorkspaces();

    const listOfDates = listDates(Number(selectedTimeFrame.value), false);

    const allCategories = Array.from(new Set(
      costSavingsData.map(({ category }) => category),
    ));

    const dataByCategory = allCategories.map((categoryFromList) => (
      onlyDesiredWorkspaces
        .filter(({ category }) => category === categoryFromList)
        .filter(({ date }) => listOfDates.includes(date))
        .reduce((currentObject, { categoryGroup, totalCostSaved }) => ({
          category: categoryFromList,
          categoryGroup,
          totalCostSaved: (currentObject?.totalCostSaved || 0) + Number(totalCostSaved),
        }), {} as { category: string, categoryGroup: string, totalCostSaved: number })
    ));

    const withoutEmptyEntries = dataByCategory
      .filter((entry) => Object.keys(entry).length !== 0);

    return withoutEmptyEntries;
  };

  return (
    <>
      <PageTitle title={PageTitles.INSIGHTS} />
      <AutoLayout
        direction="vertical"
        padding="none"
        verticalGap="expanded"
        distribution="packed"
        alignment="top-left"
        width="extra-large"
      >
        <AutoLayout
          direction="horizontal"
          distribution="space-between"
          padding="none"
        >
          <Typography
            style={{ placeSelf: 'flex-start', margin: 0 }}
            type="h6"
            tagName="span"
            color="default"
          >
            Organization Insights
          </Typography>
        </AutoLayout>
        <Typography
          type="caption"
          tagName="span"
          color="default"
          colorStep="50"
        >
          See how Paradime has saved time and lowered costs across your organization.
        </Typography>
        {isLoadingData
          ? <div style={{ marginTop: '100px' }}><Spinner thin /></div>
          : (
            <AutoLayout
              direction="vertical"
              padding="very-dense"
              verticalGap="expanded"
              alignment="top-left"
            >
              <ActionBar
                selectedWorkspace={selectedWorkspace}
                workspaceList={[{ name: 'all', uid: 'all' }, ...joinedWorkspacesList].map(
                  ({ name, uid }) => ({ label: name, value: uid }),
                )}
                onWorkspaceChanged={setSelectedWorkspace}
                selectedTimeFrame={selectedTimeFrame}
                timeFrameList={timeFrameList}
                onTimeFrameChanged={setSelectedTimeFrame}
              />
              <AutoLayout
                direction="horizontal"
                padding="none"
                horizontalGap="dense"
                distribution="packed"
                style={{ gridTemplateColumns: '1fr 1fr' }}
              >
                <MetricCard
                  title="Overview"
                  hasError={costSavingsData.length === 0}
                  content={(
                    <TextCardContent
                      topSubtitle={`In the ${selectedTimeFrame.label.toLowerCase()} ${companyName ? `${companyName} has` : 'you have'} saved`}
                      mainText={`${formatCurrency(getTotalSaved())}  🎉 `}
                      bottomSubtitle="by using Paradime!"
                    />
                  )}
                  style={{ height: '320px' }}
                />
                <MetricCard
                  ref={pieChartRef}
                  title="Cost Savings per category"
                  hasError={getTotalSavedPerCategory().length === 0}
                  content={(
                    <PieChartCardContent
                      dimensions={pieChartDimensions}
                      data={getTotalSavedPerCategory().map(
                        ({ categoryGroup, totalCostSaved }) => ({
                          x: categoryGroup, y: totalCostSaved,
                        }),
                      )}
                      tooltipLabel={({ datum }) => `${datum.x}: ${formatCurrency(datum.y)}`}
                    />
                  )}
                  style={{ height: '320px' }}
                />
              </AutoLayout>

              <MetricCard
                ref={barChartRef}
                title="Cost Savings"
                hasError={getStackedCategoryAmountPerWeek().length === 0}
                content={(
                  <BarChartCardContent
                    dimensions={barChartDimensions}
                    data={getStackedCategoryAmountPerWeek()}
                    axisDatesList={listDates(Number(selectedTimeFrame.value), true)}
                    dataSortKey="isoTimestamp"
                    tooltipLabel={({ datum }) => {
                      const currencyAmount = formatCurrency(datum.y);
                      const date = DateTime.fromFormat(datum.x, 'yyyy-LL-dd').toFormat('d LLL yy');
                      const categoryName = datum.categoryName.split('_').join(' ');

                      return `${categoryName}: ${currencyAmount} saved on ${date}`;
                    }}
                  />
                )}
                style={{ height: '400px' }}
              />

              <DataTable
                columns={columns}
                data={getAmountSavedPerEveryCategoryAndCategoryGroup()}
                size="small"
                showSearchBar={false}
                usePagination
                rowsPerPage={10}
                rowsPerPageOptions={[10, 25, 50]}
              />

            </AutoLayout>
          )}
      </AutoLayout>
    </>
  );
};

export default Insights;
