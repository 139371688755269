import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Dropdown from '@paradime-io/pragma-ui-kit/lib/components/Dropdown';
import { Menu } from '@paradime-io/pragma-ui-kit/lib/components/Menu';
import MenuItem from '@paradime-io/pragma-ui-kit/lib/components/MenuItem';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import CreateScheduleButton from './CreateScheduleButton';
import { key } from '../../../../utilis';

export interface ActionBarProps {
  showInactive: boolean,
  setShowInactive: (isInactive: boolean) => void,
}

const activeText = 'Active Schedules';
const inactiveText = 'Archived Schedules';

const ActionBar: FC<ActionBarProps> = ({
  showInactive,
  setShowInactive,
}) => (
  <AutoLayout
    direction="horizontal"
    padding="none"
    distribution="space-between"
  >
    <CreateScheduleButton />
    <Dropdown
      view="outlined"
      text={showInactive ? inactiveText : activeText}
      icon="eye-open"
      dense
      content={(
        <Menu type="dense" view="raised" color="default">
          {[activeText, inactiveText].map((entry, index) => (
            <MenuItem
              key={key(`menuitem-${entry}`)}
              type="dense"
              view="raised"
              color="default"
              text={entry}
              onItemClick={() => setShowInactive(index === 1)}
              eventContext={Contexts.BOLT}
              eventObject={`view-${entry.replace(' ', '-')}`}
              eventAction={Actions.SELECTED}
              // The table automatically changes all font weights to 700 inside of the header
              // @ts-ignore - It can't recognise the css variable as a valid font weight
              style={{ fontWeight: 'var(--font_weight_body_small)' }}
            />
          ))}
        </Menu>
      )}
      restrictMenuToButtonWidth={false}
      minMenuWidth="221px"
    />
  </AutoLayout>
);

export default ActionBar;
