export enum BoltFlows {
  TURBO_CI_FLOW = 'flow_w39uOyO56y7ibW3K',
  TURBO_CD_FLOW = 'flow_2RxX72VL4Ha6meEF',
}

export enum RadarFlows {
  SETUP_FLOW = 'flow_8TxdEr5R5H99YueX',
}

export enum HomeFlows {
  ANNOUNCE_FEATURE_BANNER = 'flow_LmzP8p0h',
}
