import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import Callout from '@paradime-io/pragma-ui-kit/lib/components/Callout';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import {
  useGenerateStripeCustomerPortalUrlMutation,
} from '../../../client/generated/service';
import PageTitle, { PageTitles } from '../../PageTitle';
import { PlatformTab } from '../../Platform';
import BillingPage from './BillingPage';
import { useGetBillingData } from './useGetBillingData';
import { key } from '../../../utilis';

const Billing: FC = () => {
  const history = useHistory();
  const {
    billingData, isError, isLoadingBillingData, setIsError,
  } = useGetBillingData();

  const [generateCustomerPortalUrl] = useGenerateStripeCustomerPortalUrlMutation({
    variables: {
      returnUrl: `https://${window.location.hostname}/platform/billing`,
    },
    onError: () => setIsError(true),
  });

  const handleEditBillingDetails = async () => {
    const result = await generateCustomerPortalUrl();
    if (result.data?.generateStripeCustomerPortalUrl?.stripeCustomerPortalUrl) {
      window.open(result.data?.generateStripeCustomerPortalUrl?.stripeCustomerPortalUrl, '_blank');
    }
  };

  return (
    <>
      <PageTitle title={PageTitles.BILLING} />
      {isError && (
        <AutoLayout
          direction="vertical"
          padding="none"
          verticalGap="expanded"
          alignment="top-left"
          width="extra-large"
        >
          <Callout
            icon="warning-sign"
            content={(
              <Typography tagName="span" type="body-bold-small" color="danger">
                We are having trouble fetching your billing details.
                <br />
                Please check back in a few minutes or reach out to us on support@paradime.io
                if you need urgent help.
              </Typography>
            )}
            color="danger"
            view="smooth"
            dense
          />
        </AutoLayout>
      )}
      <AutoLayout
        direction="vertical"
        padding="none"
        verticalGap="expanded"
        distribution="packed"
        alignment="top-left"
        width="extra-large"
      >
        <AutoLayout
          direction="horizontal"
          alignment="center"
          padding="dense"
          distribution="space-between"
          style={{
            padding: '0 8px 8px 8px', alignItems: 'center', borderBottom: '1px solid var(--grey5)',
          }}
        >
          <Typography
            style={{ placeSelf: 'flex-start', padding: '5px 0', margin: 0 }}
            type="h6"
            tagName="span"
          >
            Billing
          </Typography>
          <AutoLayout
            direction="horizontal"
            padding="none"
            horizontalGap="very-dense"
            distribution="packed"
          >
            <DefaultButton
              color="primary"
              type="standard"
              view="smooth"
              dense
              text="Edit billing"
              onClick={handleEditBillingDetails}
              eventContext={Contexts.ACCOUNT_SETTINGS}
              eventObject="editBillingDetails"
              eventAction={Actions.CLICKED}
            />
            <DefaultButton
              type="standard"
              view="gradient-primary"
              dense
              icon="rocket-slant"
              text="Upgrade"
              onClick={() => history.push(`/platform/${PlatformTab.PLANS}`)}
              eventContext={Contexts.ACCOUNT_SETTINGS}
              eventObject="changePlan"
              eventAction={Actions.CLICKED}
            />
          </AutoLayout>
        </AutoLayout>
        <AutoLayout
          direction="vertical"
          padding="very-dense"
          verticalGap="expanded"
          alignment="top-left"
        >
          {isLoadingBillingData && <Spinner />}
          {!isLoadingBillingData && billingData?.length > 0 && (
            <>
              {billingData.map((item, index) => (
                <BillingPage
                  key={key(index.toString())}
                  billingData={item}
                  index={index + 1}
                  hasMoreThanOnePlan={billingData.length > 1}
                />
              ))}
            </>
          )}
        </AutoLayout>
      </AutoLayout>
    </>
  );
};

export default Billing;
