import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import noSearchResults from '../images/noSearchResults.svg';

const NoSearchResultsZeroState: FC = () => (
  <AutoLayout
    direction="vertical"
    padding="none"
    distribution="packed"
    verticalGap="very-dense"
    style={{ paddingTop: '100px' }}
  >
    <img src={noSearchResults} alt="noResults" width="80px" height="80px" style={{ margin: 'auto' }} />
    <Typography font="inter" type="body-bold-small" style={{ textAlign: 'center' }}>
      No schedules matching your criteria
    </Typography>
  </AutoLayout>
);

export default NoSearchResultsZeroState;
