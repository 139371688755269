import React from 'react';
import { gql } from '@apollo/client';
import { ColumnType } from '@paradime-io/pragma-ui-kit/lib/components/PrimeReactDataTable';
import { getTableChips, testStatuses, getStatusColour } from '../utils';
import TestResultMessage from './TestResultsMessage';

export interface modelNamesForDropdownReturnType {
  cube: {
    modelRunStatistics: {
      runResultUniqueId: string,
    }
  }[]
}

export const modelNamesForDropdownQuery = gql`
  query modelNamesForDropdownQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {model_run_statistics: {calendar_date: {inDateRange: $timeframe}}}
    ) {
      modelRunStatistics: model_run_statistics {
        runResultUniqueId: run_result_unique_id
      }
    }
  }
`;

export const formatModelNamesForDropdown = (data?: modelNamesForDropdownReturnType) => {
  if (!data) return [];

  return data.cube
    .map(({ modelRunStatistics }) => ({
      label: modelRunStatistics.runResultUniqueId,
      value: modelRunStatistics.runResultUniqueId,
    }));
};

export interface dailyPassRateReturnType {
  cube: {
    testSuccessRateByModel: {
      testSuccessRate: number,
      calendarDate: {
        day: string,
      }
    }
  }[]
}

export const dailyPassRateQuery = gql`
  query dailyPassRateQuery($timeframe: [String], $modelName: String) {
    cube(
      limit: 5000
      where: {test_success_rate_by_model: {test_related_model_name: {equals: $modelName}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      testSuccessRateByModel: test_success_rate_by_model {
        testSuccessRate: test_success_rate
        calendarDate: calendar_date {
          day
        }
      }
    }
  }
`;

export const formatDailyPassRateForChart = (data?: dailyPassRateReturnType) => {
  if (!data) return [];

  return [data.cube.map(({ testSuccessRateByModel }) => ({
    x: testSuccessRateByModel.calendarDate.day,
    y: testSuccessRateByModel.testSuccessRate,
  }))];
};

export const getDailyPassRateXAxisLabels = (data?: dailyPassRateReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ testSuccessRateByModel }) => testSuccessRateByModel.calendarDate.day);
  return Array.from(new Set(dates));
};

export interface statusOverTimeReturnType {
  cube: {
    testRunsTimelineByModel: {
      testCount: number,
      testResultStatus: 'error' | 'fail' | 'pass' | 'warn',
      calendarDate: {
        day: string,
      }
    }
  }[]
}

export const statusOverTimeQuery = gql`
  query statusOverTimeQuery($timeframe: [String], $modelName: String) {
    cube(
      limit: 5000
      where: {test_runs_timeline_by_model: {test_related_model_name: {equals: $modelName}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      testRunsTimelineByModel: test_runs_timeline_by_model(orderBy: {calendar_date: asc}) {
        testCount: test_count
        testResultStatus: test_result_status
        calendarDate: calendar_date {
          day
        }
      }
    }
  }
`;

const getTestsLegendLabels = (status: string) => {
  const capitalisedStatus = status.charAt(0).toUpperCase() + status.slice(1);
  return `${capitalisedStatus} tests`;
};

export const formatStatusOverTimeForChart = (data?: statusOverTimeReturnType) => {
  if (!data) return { data: [], statuses: [], colours: [] };

  const dataToReturn: { x: string, y: number }[][] = [];
  const legendLabels: string[] = [];
  const legendColours: string[] = [];

  testStatuses.forEach((status) => {
    const tests = data.cube
      .filter(({ testRunsTimelineByModel }) => testRunsTimelineByModel.testResultStatus === status)
      .map(({ testRunsTimelineByModel }) => ({
        x: testRunsTimelineByModel.calendarDate.day,
        y: testRunsTimelineByModel.testCount,
      }));

    if (tests.length > 0) {
      dataToReturn.push(tests);
      legendLabels.push(getTestsLegendLabels(status));
      legendColours.push(getStatusColour(status));
    }
  });

  return { data: dataToReturn, statuses: legendLabels, colours: legendColours };
};

export const getStatusOverTimeXAxisLabels = (data?: statusOverTimeReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ testRunsTimelineByModel }) => testRunsTimelineByModel.calendarDate.day);
  return Array.from(new Set(dates));
};

export interface resultsByColumnReturnType {
  cube: {
    testDetailsByModel: {
      totalTests: number,
      testRelatedColumnName: string,
      testResultStatus: string,
    }
  }[]
}

export const resultsByColumnQuery = gql`
  query resultsByColumnQuery($timeframe: [String], $modelName: String) {
    cube(
      limit: 5000
      where: {test_details_by_model: {test_related_model_name: {equals: $modelName}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      testDetailsByModel: test_details_by_model(
        orderBy: {total_tests: desc, test_related_column_name: desc}
      ) {
        totalTests: total_tests
        testRelatedColumnName: test_related_column_name
        testResultStatus: test_result_status
      }
    }
  }
`;

export const formatResultsByColumnForChart = (data?: resultsByColumnReturnType) => {
  if (!data) return { data: [], statuses: [], colours: [] };

  const dataToReturn: { x: string, y: number }[][] = [];
  const legendLabels: string[] = [];
  const legendColours: string[] = [];

  testStatuses.forEach((status) => {
    const tests = data.cube
      .filter(({ testDetailsByModel }) => testDetailsByModel.testResultStatus === status)
      .map(({ testDetailsByModel }) => ({
        x: testDetailsByModel.testRelatedColumnName,
        y: testDetailsByModel.totalTests,
      }));

    if (tests.length > 0) {
      dataToReturn.push(tests);
      legendLabels.push(getTestsLegendLabels(status));
      legendColours.push(getStatusColour(status));
    }
  });

  return { data: dataToReturn, statuses: legendLabels, colours: legendColours };
};

export const getResultsByColumnXAxisLabels = (data?: resultsByColumnReturnType) => {
  if (!data) return [];

  const columnNames = data.cube
    .map(({ testDetailsByModel }) => testDetailsByModel.testRelatedColumnName);
  return Array.from(new Set(columnNames));
};

export interface dailyImpactedRowsReturnType {
  cube: {
    testDailyFailuresByModel: {
      totalImpactedRows: number,
      testResultStatus: 'fail' | 'warn',
      calendarDate: {
        day: string,
      }
    }
  }[]
}

export const dailyImpactedRowsQuery = gql`
  query dailyImpactedRowsQuery($timeframe: [String], $modelName: String) {
    cube(
      limit: 5000
      where: {test_daily_failures_by_model: {test_result_status: {in: ["fail", "warn"]}, test_related_model_name: {equals: $modelName}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      testDailyFailuresByModel: test_daily_failures_by_model(orderBy: {calendar_date: asc}) {
        totalImpactedRows: total_impacted_rows
        testResultStatus: test_result_status
        calendarDate: calendar_date {
          day
        }
      }
    }
  }
`;

export const formatDailyImpactedRowsForChart = (data?: dailyImpactedRowsReturnType) => {
  if (!data) return { data: [], statuses: [], colours: [] };

  const dataToReturn: { x: string, y: number }[][] = [];
  const legendLabels: string[] = [];
  const legendColours: string[] = [];

  ['warn', 'fail'].forEach((status) => {
    const tests = data.cube
      .filter(({ testDailyFailuresByModel }) => (
        testDailyFailuresByModel.testResultStatus === status
      ))
      .map(({ testDailyFailuresByModel }) => ({
        x: testDailyFailuresByModel.calendarDate.day,
        y: testDailyFailuresByModel.totalImpactedRows,
      }));

    if (tests.length > 0) {
      dataToReturn.push(tests);
      legendLabels.push(getTestsLegendLabels(status));
      legendColours.push(getStatusColour(status));
    }
  });

  return { data: dataToReturn, statuses: legendLabels, colours: legendColours };
};

export const getDailyImpactedRowsXAxisLabels = (data?: dailyImpactedRowsReturnType) => {
  if (!data) return [];

  const columnNames = data.cube
    .map(({ testDailyFailuresByModel }) => testDailyFailuresByModel.calendarDate.day);
  return Array.from(new Set(columnNames));
};

export interface passRateReturnType {
  cube: {
    testSuccessRateByModel: {
      testSuccessRate: number,
    }
  }[]
}

export const passRateQuery = gql`
  query passRateQuery($timeframe: [String], $modelName: String) {
    cube(
      limit: 5000
      where: {test_success_rate_by_model: {is_test_current_status: {equals: "true"}, test_related_model_name: {equals: $modelName}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      testSuccessRateByModel: test_success_rate_by_model {
        testSuccessRate: test_success_rate
      }
    }
  }
`;

export interface resultsMakeUpReturnType {
  cube: {
    testDetailsByModel: {
      totalTests: number,
      testResultStatus: 'error' | 'fail' | 'pass' | 'warn',
    }
  }[]
}

export const resultsMakeUpQuery = gql`
  query resultsMakeUpQuery($timeframe: [String], $modelName: String) {
    cube(
      limit: 5000
      where: {test_details_by_model: {is_test_current_status: {equals: "true"}, test_related_model_name: {equals: $modelName}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      testDetailsByModel: test_details_by_model {
        totalTests: total_tests
        testResultStatus: test_result_status
      }
    }
  }
`;

export const formatResultsMakeUpForChart = (data?: resultsMakeUpReturnType) => {
  if (!data) return { data: [], statuses: [], colours: [] };

  const dataToReturn: { x: string, y: number }[] = [];
  const legendLabels: string[] = [];
  const legendColours: string[] = [];

  const statuses = [
    { status: 'pass', colour: 'var(--green50)' },
    { status: 'warn', colour: 'var(--yellow50)' },
    { status: 'fail', colour: 'var(--red50)' },
    { status: 'error', colour: 'var(--red30)' },
  ];

  statuses.forEach(({ status, colour }) => {
    const runs = data.cube
      .filter(({ testDetailsByModel }) => testDetailsByModel.testResultStatus === status)
      .map(({ testDetailsByModel }) => ({
        x: testDetailsByModel.testResultStatus,
        y: testDetailsByModel.totalTests,
      }));

    if (runs.length > 0) {
      dataToReturn.push(...runs);
      legendLabels.push(status);
      legendColours.push(colour);
    }
  });

  return { data: dataToReturn, statuses: legendLabels, colours: legendColours };
};

export interface currentOverviewReturnType {
  cube: {
    testDetailsByModel: {
      testRelatedColumnName: string,
      testName: string,
      testConfigErrorIf: string,
      testConfigWarnIf: string,
      testExecuteCompletedAt: {
        value: string,
      },
      testResultStatus: 'error' | 'fail' | 'pass' | 'warn',
      testResultMessage: string,
      impactedRows: number,
      testCompiledSql: string,
    }
  }[]
}

export const currentOverviewQuery = gql`
  query currentOverviewQuery($timeframe: [String], $modelName: String) {
    cube(
      limit: 5000
      where: {test_details_by_model: {is_test_current_status: {equals: "true"}, test_related_model_name: {equals: $modelName}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      testDetailsByModel: test_details_by_model(orderBy: {impacted_rows: desc}) {
        testRelatedColumnName: test_related_column_name
        testName: test_name
        testConfigErrorIf: test_config_error_if
        testConfigWarnIf: test_config_warn_if
        testExecuteCompletedAt: test_execute_completed_at {
          value
        }
        testResultStatus: test_result_status
        testResultMessage: test_result_message
        impactedRows: impacted_rows
        testCompiledSql: test_compiled_sql
      }
    }
  }
`;

export const currentOverviewColumns = [
  { field: 'testRelatedColumnName', header: 'Column name' },
  { field: 'testName', header: 'Test name' },
  { field: 'testExecuteCompletedAt', header: 'Latest test run' },
  { field: 'testResultStatus', header: 'Test result status', type: ColumnType.CHIP },
  { field: 'testResultMessage', header: 'Test result message', type: ColumnType.REACT_NODE },
  { field: 'impactedRows', header: 'Impacted rows' },
].map((col) => ({ ...col, sortable: true }));

export const formatCurrentOverviewForChart = (data?: currentOverviewReturnType) => {
  if (!data) return [];

  return data.cube.map(({ testDetailsByModel }) => ({
    ...testDetailsByModel,
    testExecuteCompletedAt: testDetailsByModel.testExecuteCompletedAt.value,
    testResultStatus: getTableChips(testDetailsByModel.testResultStatus),
    testResultMessage: {
      childNode: <TestResultMessage message={testDetailsByModel.testResultMessage} />,
    },
    impactedRows: testDetailsByModel.impactedRows || 0,
  }));
};
