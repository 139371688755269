import React, { ReactNode } from 'react';
import { FrigadeJS } from '@frigade/react';
import { useMarkStepCompleted } from '@paradime/common/lib/common/helpers/frigadeHelperFunctions';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { BoltFlows } from '../../Common/Frigade/flows';
import Arcade from '../../Common/Components/Arcade';
import * as S from '../Bolt.styles';

const AddGithubSecretsStep = (stepData: FrigadeJS.FlowStep): ReactNode => {
  const { primaryButtonTitle, id: stepId } = stepData;
  const { markStepCompleted } = useMarkStepCompleted(BoltFlows.TURBO_CD_FLOW);

  return (
    <AutoLayout
      direction="vertical"
      distribution="packed"
      verticalGap="dense"
      padding="none"
      alignment="top-left"
      style={{ paddingBottom: '16px' }}
    >
      <Typography tagName="span" type="h6">Update Github Secrets</Typography>
      <Typography tagName="span" type="body-small">
        Add the API keys and credentials as secrets in Github.
        {' '}
        Make sure you name the keys as follows:
      </Typography>
      <S.CodeTypography tagName="span" type="body-small">
        <code>PARADIME_API_KEY</code>
      </S.CodeTypography>
      <S.CodeTypography tagName="span" type="body-small">
        <code>PARADIME_API_SECRET</code>
      </S.CodeTypography>
      <S.CodeTypography tagName="span" type="body-small">
        <code>PARADIME_API_ENDPOINT</code>
      </S.CodeTypography>
      <S.CodeTypography tagName="span" type="body-small">
        <code>PARADIME_WORKSPACE_TOKEN</code>
      </S.CodeTypography>
      <Arcade
        url="https://demo.arcade.software/l9JSxG9l3ntrnBJ0FaSP?embed"
        title="Paradime | Github action"
      />
      <DefaultButton
        type="standard"
        view="filled"
        color="primary"
        dense
        text={primaryButtonTitle}
        eventContext={Contexts.LOGS}
        eventObject="markStepComplete"
        eventAction={Actions.CLICKED}
        onClick={() => markStepCompleted(stepId)}
        style={{ width: 'fit-content' }}
      />
    </AutoLayout>
  );
};

export default AddGithubSecretsStep;
