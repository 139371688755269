import React, { FC } from 'react';
import {
  VictoryContainer,
  VictoryLabel,
  VictoryLegend,
  VictoryPie,
  VictoryTooltip,
} from 'victory';
import { useGetChartDimensionsReturnType } from '../../Bolt/Scheduler/ScheduleInsights/useGetChartDimensions';
import { chartColourScale } from './utils';

export interface PieChartCardContentProps {
  data: { x: string, y: number }[],
  tooltipLabel: string[]
    | number[]
    | ((data: any) => string | number | string[] | number[] | null)
    | undefined,
  dimensions: useGetChartDimensionsReturnType,
}

const PieChartCardContent: FC<PieChartCardContentProps> = ({ data, tooltipLabel, dimensions }) => {
  const {
    chartHeight,
    chartWidth,
    tooltipConfig,
  } = dimensions;

  const legendHeight = 90;

  return (
    <div>
      <VictoryPie
        containerComponent={<VictoryContainer responsive />}
        height={chartHeight - legendHeight}
        width={chartWidth}
        labels={tooltipLabel}
        data={data}
        colorScale={chartColourScale}
        events={[
          {
            target: 'data',
            eventHandlers: {
              onMouseOver: () => [
                {
                  target: 'labels',
                  mutation: () => ({ active: true }),
                },
              ],
              onMouseOut: () => [
                {
                  target: 'labels',
                  mutation: () => ({ active: false }),
                },
              ],
            },
          },
        ]}
        labelComponent={(
          <VictoryTooltip
            cornerRadius={4}
            pointerWidth={5}
            pointerLength={5}
            flyoutWidth={tooltipConfig.flyoutWidth}
            flyoutHeight={tooltipConfig.flyoutHeight}
            flyoutStyle={{
              strokeWidth: 0,
              fill: 'var(--grey100)',
            }}
            labelComponent={(
              <VictoryLabel
                style={{
                  fontFamily: 'var(--inter)',
                  letterSpacing: 'var(--letter_spacing_caption)',
                  fill: 'var(--grey30)',
                  fontWeight: 400,
                  fontSize: tooltipConfig.labelFontSize,
                  textAnchor: 'middle',
                  verticalAnchor: 'middle',
                }}
              />
            )}
          />
        )}
        innerRadius={80}
        padAngle={2}
      />
      <VictoryLegend
        colorScale={chartColourScale}
        data={data.map(({ x }) => {
          const longLabelLength = 24;
          const name = x.length > longLabelLength
            ? `${x.substring(0, longLabelLength)}...`
            : x;
          return { name };
        })}
        labelComponent={(
          <VictoryLabel
            style={{
              fontFamily: 'var(--inter)',
              letterSpacing: 'var(--letter_spacing_caption)',
              fill: 'var(--grey100)',
            }}
          />
        )}
        orientation="horizontal"
        gutter={40}
        itemsPerRow={2}
        height={legendHeight}
      />
    </div>
  );
};

export default PieChartCardContent;
