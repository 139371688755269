import styled from 'styled-components';
import { Tooltip } from 'primereact/tooltip';
import Callout from '@paradime-io/pragma-ui-kit/lib/components/Callout';
import TabsDefault from '@paradime-io/pragma-ui-kit/lib/components/TabsDefault';

const Base = styled.div``;

const DashboardCallout = styled(Callout)`
  border: 1px solid var(--grey30);
  padding-bottom: 16px;

  &[class*="callout"] {
    border-radius: 8px;
    background-color: var(--grey5);
  }

  &[class*="callout"] p[class*="heading"] {
    background-color: var(--grey5);
  }
`;

const RadarPageTab = styled(TabsDefault)`
  width: 100% !important;
  border-bottom: 1px solid var(--grey10);
`;

const TableTooltip = styled(Tooltip)`
  height: auto;
  width: 300px;
  font-family: var(--roboto-mono);
  font-weight: var(--font_weight_caption);
  font-size: var(--font_size_caption);
  line-height: var(--line_height_caption);

  & > .p-tooltip-text {
    background: var(--dark-slategrey);
    border: 1px solid var(--grey100);
    color: var(--grey30);
    border-radius: 8px;
  }
`;

const OpenInTooltip = styled(Tooltip)`
  height: auto;
  width: fit-content;
  font-family: var(--inter);
  font-weight: var(--font_weight_caption);
  font-size: var(--font_size_caption);
  line-height: var(--line_height_caption);

  & > .p-tooltip-text {
    background: var(--dark-slategrey);
    border: 1px solid var(--grey100);
    color: var(--grey30);
    border-radius: 8px;
  }
`;

export {
  Base, DashboardCallout, RadarPageTab, TableTooltip, OpenInTooltip,
};
