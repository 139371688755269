import React, { FC } from 'react';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import ToastFactory from '../../Common/Alerts/ToastFactory';
import warningBell from '../../../images/bells/warningBell.svg';

const NewThreadFailedToast : FC = () => (
  <ToastFactory
    icon={warningBell}
    text="Make sure to push your local commit before creating a new thread so you can collaborate with your team."
    title={(
      <Typography
        type="h6"
        tagName="span"
        color="warning"
        colorStep="80"
        style={{
          margin: 'auto 0px',
        }}
      >
        Creating a new thread
      </Typography>
)}
  />
);

export default NewThreadFailedToast;
