import React from 'react';
import { gql } from '@apollo/client';
import { ColumnType } from '@paradime-io/pragma-ui-kit/lib/components/PrimeReactDataTable';
import {
  getAllVars,
  getAllWhere,
} from '../utils';
import { key } from '../../../utilis';
import { selectAllProjects, selectAllUsers } from './BigqueryTab';
import { OpenInTooltip } from '../Radar.styles';

const getOpenInBigQueryLink = (displayString: string, urlToOpen: string) => (
  <>
    <div
      className={`bigquery-job-url-${displayString}`}
      data-pr-tooltip="Open in BigQuery"
      data-pr-position="top"
      data-pr-at="top"
    >
      <a href={urlToOpen} target="_blank" rel="noreferrer">{displayString}</a>
    </div>
    <OpenInTooltip
      target={`.bigquery-job-url-${displayString}`}
      mouseTrack
      mouseTrackLeft={10}
      style={{ height: 'auto' }}
    />
  </>
);

export interface userEmailsForDropdownReturnType {
  cube: {
    bigqueryTotalCostByUser: {
      bigqueryUserEmail: string,
    }
  }[]
}

export const userEmailsForDropdownQuery = gql`
  query userEmailsForDropdownQuery($timeframe: [String]) {
    cube(
      limit: 50000
      where: {bigquery_total_cost_by_user: {bigquery_start_time: {inDateRange: $timeframe}}}
    ) {
      bigqueryTotalCostByUser: bigquery_total_cost_by_user {
        bigqueryUserEmail: bigquery_user_email
      }
    }
  }
`;

export const formatUserEmailsForDropdown = (data?: userEmailsForDropdownReturnType) => {
  if (!data) return [];

  return [
    selectAllUsers,
    ...data.cube
      .map(({ bigqueryTotalCostByUser }) => ({
        label: bigqueryTotalCostByUser.bigqueryUserEmail,
        value: bigqueryTotalCostByUser.bigqueryUserEmail,
      })),
  ];
};

export interface projectIdsForDropdownReturnType {
  cube: {
    bigqueryDailyCostByProject: {
      bigqueryProjectId: string,
    }
  }[]
}

export const projectIdsForDropdownQuery = gql`
  query projectIdsForDropdownQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {bigquery_daily_cost_by_project: {bigquery_start_time: {inDateRange: $timeframe}}}
    ) {
      bigqueryDailyCostByProject: bigquery_daily_cost_by_project {
        bigqueryProjectId: bigquery_project_id
      }
    }
  }
`;

export const formatProjectIdsForDropdown = (data?: projectIdsForDropdownReturnType) => {
  if (!data) return [];

  return [
    selectAllProjects,
    ...data.cube
      .map(({ bigqueryDailyCostByProject }) => ({
        label: bigqueryDailyCostByProject.bigqueryProjectId,
        value: bigqueryDailyCostByProject.bigqueryProjectId,
      })),
  ];
};

export interface topCostModelsReturnType {
  cube: {
    bigqueryDbtCostDetails: {
      totalJobCount: number,
      totalEstimatedCostUsd: number,
      dbtNodePackageName: string,
      dbtNodeName: string,
      bigqueryJobUrl: string,
    }
  }[]
}

export const topCostModelsQuery = (allUserEmails: boolean, allProjectIds: boolean) => gql`
  query topCostModelsQuery($timeframe: [String]${getAllVars(allUserEmails, 'userEmail')}${getAllVars(allProjectIds, 'projectId')}) {
    cube(
      limit: 5000
      where: {bigquery_dbt_cost_details: {bigquery_user_email: ${getAllWhere(allUserEmails, 'userEmail')}, bigquery_project_id: ${getAllWhere(allProjectIds, 'projectId')}, bigquery_start_time: {inDateRange: $timeframe}}}
    ) {
      bigqueryDbtCostDetails: bigquery_dbt_cost_details(
        orderBy: {total_estimated_cost_usd: desc, total_job_count: desc}
      ) {
        totalJobCount: total_job_count
        totalEstimatedCostUsd: total_estimated_cost_usd
        dbtNodePackageName: dbt_node_package_name
        dbtNodeName: dbt_node_name
        bigqueryJobUrl: bigquery_job_url
      }
    }
  }
`;

export const topCostModelsColumns = [
  {
    field: 'dbtNodeName', header: 'dbt™ Node Name', expander: true, type: ColumnType.REACT_NODE,
  },
  { field: 'dbtNodePackageName', header: 'dbt™ Node Package Name' },
  { field: 'totalEstimatedCostUsd', header: 'Bigquery estimated cost usd', type: ColumnType.CURRENCY },
  { field: 'totalJobCount', header: 'Total job count' },
].map((col) => ({ ...col, sortable: true, style: { width: '250px' } }));

export const formatTopCostModelsForChart = (data?: topCostModelsReturnType) => {
  if (!data) return [];

  const nodeNames = Array.from(new Set(
    data.cube.map(({ bigqueryDbtCostDetails }) => (
      bigqueryDbtCostDetails.dbtNodeName
    )),
  ));

  const dataToReturn: { key: string, data: any, children?: any[] }[] = [];

  nodeNames.forEach((nodeName) => {
    const dataInThisGroup = data?.cube
      .filter(({ bigqueryDbtCostDetails }) => bigqueryDbtCostDetails.dbtNodeName === nodeName);

    const totalEstimatedCostUsd = dataInThisGroup?.reduce((currentTotal, datum) => (
      currentTotal + datum.bigqueryDbtCostDetails.totalEstimatedCostUsd
    ), 0) || 0;

    const totalJobCount = dataInThisGroup?.reduce((currentTotal, datum) => (
      currentTotal + datum.bigqueryDbtCostDetails.totalJobCount
    ), 0) || 0;

    const dbtNodePackageName = Array.from(new Set(
      dataInThisGroup.map(({ bigqueryDbtCostDetails }) => (
        bigqueryDbtCostDetails.dbtNodePackageName
      )),
    )).join(', ');

    const childRows = dataInThisGroup
      .map(({ bigqueryDbtCostDetails }) => ({
        key: key(bigqueryDbtCostDetails.bigqueryJobUrl),
        data: {
          ...bigqueryDbtCostDetails,
          dbtNodeName: {
            text: bigqueryDbtCostDetails.dbtNodeName,
            childNode: getOpenInBigQueryLink(
              bigqueryDbtCostDetails.dbtNodeName,
              bigqueryDbtCostDetails.bigqueryJobUrl,
            ),
          },
        },
      }));

    const formattedData = {
      key: key(nodeName),
      data: {
        dbtNodeName: nodeName,
        totalEstimatedCostUsd,
        totalJobCount,
        dbtNodePackageName,
      },
      children: childRows,
    };

    dataToReturn.push(formattedData);
  });

  return dataToReturn;
};

export interface dailyProdSpendReturnType {
  cube: {
    bigqueryDbtProductionCost: {
      totalEstimatedCostUsd: number,
      paradimeScheduleName: string,
      bigqueryStartTime: {
        day: string,
      }
    }
  }[]
}

export const dailyProdSpendQuery = (allUserEmails: boolean, allProjectIds: boolean) => gql`
  query dailyProdSpendQuery($timeframe: [String]${getAllVars(allUserEmails, 'userEmail')}${getAllVars(allProjectIds, 'projectId')}) {
    cube(
      limit: 5000
      where: {bigquery_dbt_production_cost: {bigquery_user_email: ${getAllWhere(allUserEmails, 'userEmail')}, bigquery_project_id: ${getAllWhere(allProjectIds, 'projectId')}, paradime_schedule_name: {notIn: "CUSTOM_PARADIME_DBT_COMPILE_SCHEDULE"}, bigquery_start_time: {inDateRange: $timeframe}}}
    ) {
      bigqueryDbtProductionCost: bigquery_dbt_production_cost(
        orderBy: {bigquery_start_time: asc, total_estimated_cost_usd: asc}
      ) {
        totalEstimatedCostUsd: total_estimated_cost_usd
        paradimeScheduleName: paradime_schedule_name
        bigqueryStartTime: bigquery_start_time {
          day
        }
      }
    }
  }
`;

export const formatDailyProdSpendForChart = (data?: dailyProdSpendReturnType) => {
  if (!data) return { data: [], scheduleNames: [] };

  const scheduleNames = Array.from(new Set(
    data.cube.map(({ bigqueryDbtProductionCost }) => (
      bigqueryDbtProductionCost.paradimeScheduleName
    )),
  ));

  const dataToReturn: { x: string, y: number }[][] = [];

  scheduleNames.forEach((scheduleName) => {
    const formattedData = data.cube
      .filter(({ bigqueryDbtProductionCost }) => (
        bigqueryDbtProductionCost.paradimeScheduleName === scheduleName
      ))
      .map(({ bigqueryDbtProductionCost }) => ({
        x: bigqueryDbtProductionCost.bigqueryStartTime.day,
        y: bigqueryDbtProductionCost.totalEstimatedCostUsd,
      }));

    dataToReturn.push(formattedData);
  });

  return { data: dataToReturn, scheduleNames };
};

export const getDailyProdSpendXAxisLabels = (data?: dailyProdSpendReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ bigqueryDbtProductionCost }) => (
      bigqueryDbtProductionCost.bigqueryStartTime.day
    ));
  return Array.from(new Set(dates));
};

export interface dailyDbtSpendReturnType {
  cube: {
    bigqueryCostDbtDaily: {
      totalEstimatedCostUsd: number,
      bigqueryUserEmail: string,
      bigqueryStartTime: {
        day: string,
      }
    }
  }[]
}

export const dailyDbtSpendQuery = (allUserEmails: boolean, allProjectIds: boolean) => gql`
  query dailyDbtSpendQuery($timeframe: [String]${getAllVars(allUserEmails, 'userEmail')}${getAllVars(allProjectIds, 'projectId')}) {
    cube(
      limit: 5000
      where: {bigquery_cost_dbt_daily: {bigquery_user_email: ${getAllWhere(allUserEmails, 'userEmail')}, bigquery_project_id: ${getAllWhere(allProjectIds, 'projectId')}, bigquery_start_time: {inDateRange: $timeframe}}}
    ) {
      bigqueryCostDbtDaily: bigquery_cost_dbt_daily(
        orderBy: {bigquery_start_time: asc, total_estimated_cost_usd: desc}
      ) {
        totalEstimatedCostUsd: total_estimated_cost_usd
        bigqueryUserEmail: bigquery_user_email
        bigqueryStartTime: bigquery_start_time {
          day
        }
      }
    }
  }
`;

export const formatDailyDbtSpendForChart = (data?: dailyDbtSpendReturnType) => {
  if (!data) return { data: [], userEmails: [] };

  const userEmails = Array.from(new Set(
    data.cube.map(({ bigqueryCostDbtDaily }) => (
      bigqueryCostDbtDaily.bigqueryUserEmail
    )),
  ));

  const dataToReturn: { x: string, y: number }[][] = [];

  userEmails.forEach((scheduleName) => {
    const formattedData = data.cube
      .filter(({ bigqueryCostDbtDaily }) => (
        bigqueryCostDbtDaily.bigqueryUserEmail === scheduleName
      ))
      .map(({ bigqueryCostDbtDaily }) => ({
        x: bigqueryCostDbtDaily.bigqueryStartTime.day,
        y: bigqueryCostDbtDaily.totalEstimatedCostUsd,
      }));

    dataToReturn.push(formattedData);
  });

  return { data: dataToReturn, userEmails };
};

export const getDailyDbtSpendXAxisLabels = (data?: dailyDbtSpendReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ bigqueryCostDbtDaily }) => (
      bigqueryCostDbtDaily.bigqueryStartTime.day
    ));
  return Array.from(new Set(dates));
};

export interface topCostQueriesReturnType {
  cube: {
    bigqueryQueryCostSummary: {
      totalEstimatedCostUsd: number,
      totalJobCount: number,
      bigqueryJobUrl: string,
      bigqueryProjectId: string,
      bigqueryQueryHash: string,
    }
  }[]
}

export const topCostQueriesQuery = (allUserEmails: boolean, allProjectIds: boolean) => gql`
  query topCostQueriesQuery($timeframe: [String]${getAllVars(allUserEmails, 'userEmail')}${getAllVars(allProjectIds, 'projectId')}) {
    cube(
      limit: 5000
      where: {bigquery_query_cost_summary: {bigquery_user_email: ${getAllWhere(allUserEmails, 'userEmail')}, bigquery_project_id: ${getAllWhere(allProjectIds, 'projectId')}, bigquery_start_time: {inDateRange: $timeframe}}}
    ) {
      bigqueryQueryCostSummary: bigquery_query_cost_summary(orderBy: {total_estimated_cost_usd: desc}) {
        totalEstimatedCostUsd: total_estimated_cost_usd
        totalJobCount: total_job_count
        bigqueryJobUrl: bigquery_job_url
        bigqueryProjectId: bigquery_project_id
        bigqueryQueryHash: bigquery_query_hash
      }
    }
  }
`;

export const topCostQueriesColumns = [
  { field: 'bigqueryProjectId', header: 'Bigquery project ID', expander: true },
  { field: 'bigqueryQueryHash', header: 'Bigquery query hash', type: ColumnType.REACT_NODE },
  { field: 'totalEstimatedCostUsd', header: 'Bigquery estimated cost usd', type: ColumnType.CURRENCY },
  { field: 'totalJobCount', header: 'Total job count' },
].map((col) => ({ ...col, sortable: true, style: { width: '250px' } }));

export const formatTopCostQueriesForChart = (data?: topCostQueriesReturnType) => {
  if (!data) return [];

  const queryHashes = Array.from(new Set(
    data.cube.map(({ bigqueryQueryCostSummary }) => (
      bigqueryQueryCostSummary.bigqueryQueryHash
    )),
  ));

  const dataToReturn: { key: string, data: any, children?: any[] }[] = [];

  queryHashes.forEach((queryHash) => {
    const dataInThisGroup = data?.cube
      .filter(({ bigqueryQueryCostSummary }) => (
        bigqueryQueryCostSummary.bigqueryQueryHash === queryHash
      ));

    const totalEstimatedCostUsd = dataInThisGroup?.reduce((currentTotal, datum) => (
      currentTotal + datum.bigqueryQueryCostSummary.totalEstimatedCostUsd
    ), 0) || 0;

    const totalJobCount = dataInThisGroup?.reduce((currentTotal, datum) => (
      currentTotal + datum.bigqueryQueryCostSummary.totalJobCount
    ), 0) || 0;

    const bigqueryProjectId = Array.from(new Set(
      dataInThisGroup.map(({ bigqueryQueryCostSummary }) => (
        bigqueryQueryCostSummary.bigqueryProjectId
      )),
    )).join(', ');

    const childRows = dataInThisGroup
      .map(({ bigqueryQueryCostSummary }) => ({
        key: key(bigqueryQueryCostSummary.bigqueryJobUrl),
        data: {
          ...bigqueryQueryCostSummary,
          bigqueryQueryHash: {
            text: bigqueryQueryCostSummary.bigqueryQueryHash,
            childNode: getOpenInBigQueryLink(
              bigqueryQueryCostSummary.bigqueryQueryHash,
              bigqueryQueryCostSummary.bigqueryJobUrl,
            ),
          },
        },
      }));

    const formattedData = {
      key: key(queryHash),
      data: {
        bigqueryQueryHash: queryHash,
        totalEstimatedCostUsd,
        totalJobCount,
        bigqueryProjectId,
      },
      children: childRows,
    };

    dataToReturn.push(formattedData);
  });

  return dataToReturn;
};

export interface spendPerUserReturnType {
  cube: {
    bigqueryTotalCostByUser: {
      totalEstimatedCostUsd: number,
      bigqueryUserEmail: string,
    }
  }[]
}

export const spendPerUserQuery = (allUserEmails: boolean, allProjectIds: boolean) => gql`
  query spendPerUserQuery($timeframe: [String]${getAllVars(allUserEmails, 'userEmail')}${getAllVars(allProjectIds, 'projectId')}) {
    cube(
      limit: 5000
      where: {bigquery_total_cost_by_user: {bigquery_user_email: ${getAllWhere(allUserEmails, 'userEmail')}, bigquery_project_id: ${getAllWhere(allProjectIds, 'projectId')}, bigquery_start_time: {inDateRange: $timeframe}}}
    ) {
      bigqueryTotalCostByUser: bigquery_total_cost_by_user {
        totalEstimatedCostUsd: total_estimated_cost_usd
        bigqueryUserEmail: bigquery_user_email
      }
    }
  }
`;

export const formatSpendPerUserForChart = (data?: spendPerUserReturnType) => {
  if (!data) return [];

  return data.cube
    .map(({ bigqueryTotalCostByUser }) => ({
      x: bigqueryTotalCostByUser.bigqueryUserEmail,
      y: bigqueryTotalCostByUser.totalEstimatedCostUsd,
    }));
};

export interface queriesPerHourReturnType {
  cube: {
    bigqueryAvgJobCountByHour: {
      avgJobCount: number,
      bigqueryStartHour: number,
    }
  }[]
}

export const queriesPerHourQuery = (allUserEmails: boolean, allProjectIds: boolean) => gql`
  query queriesPerHourQuery($timeframe: [String]${getAllVars(allUserEmails, 'userEmail')}${getAllVars(allProjectIds, 'projectId')}) {
    cube(
      limit: 5000
      where: {bigquery_avg_job_count_by_hour: {bigquery_user_email: ${getAllWhere(allUserEmails, 'userEmail')}, bigquery_project_id: ${getAllWhere(allProjectIds, 'projectId')}, bigquery_start_time: {inDateRange: $timeframe}}}
    ) {
      bigqueryAvgJobCountByHour: bigquery_avg_job_count_by_hour(orderBy: {bigquery_start_hour: asc}) {
        avgJobCount: avg_job_count
        bigqueryStartHour: bigquery_start_hour
      }
    }
  }
`;

export const formatQueriesPerHourForChart = (data?: queriesPerHourReturnType) => {
  if (!data) return [];

  return [data.cube
    .map(({ bigqueryAvgJobCountByHour }) => ({
      x: bigqueryAvgJobCountByHour.bigqueryStartHour.toString(),
      y: bigqueryAvgJobCountByHour.avgJobCount,
    }))];
};

export const getQueriesPerHourXAxisLabels = (data?: queriesPerHourReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ bigqueryAvgJobCountByHour }) => (
      bigqueryAvgJobCountByHour.bigqueryStartHour.toString()
    ));
  return Array.from(new Set(dates));
};

export interface slotUsageReturnType {
  cube: {
    bigquerySlotsUsedByHour: {
      totalSlotsUsed: number,
      usageHourOfDay: number,
      bigqueryUserEmail: string,
      bigqueryProjectId: string,
    }
  }[]
}

export const slotUsageQuery = (allUserEmails: boolean, allProjectIds: boolean) => gql`
  query slotUsageQuery($timeframe: [String]${getAllVars(allUserEmails, 'userEmail')}${getAllVars(allProjectIds, 'projectId')}) {
    cube(
      limit: 5000
      where: {bigquery_slots_used_by_hour: {bigquery_user_email: ${getAllWhere(allUserEmails, 'userEmail')}, bigquery_project_id: ${getAllWhere(allProjectIds, 'projectId')}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      bigquerySlotsUsedByHour: bigquery_slots_used_by_hour(orderBy: {usage_hour_of_day: asc}) {
        totalSlotsUsed: total_slots_used
        usageHourOfDay: usage_hour_of_day
        bigqueryUserEmail: bigquery_user_email
        bigqueryProjectId: bigquery_project_id
      }
    }
  }
`;

export const formatSlotUsageForChart = (data?: slotUsageReturnType) => {
  if (!data) return { data: [], userEmails: [] };

  const userEmails = Array.from(new Set(
    data.cube.map(({ bigquerySlotsUsedByHour }) => (
      bigquerySlotsUsedByHour.bigqueryUserEmail
    )),
  ));

  const dataToReturn: { x: string, y: number }[][] = [];

  userEmails.forEach((email) => {
    const formattedData = data.cube
      .filter(({ bigquerySlotsUsedByHour }) => (
        bigquerySlotsUsedByHour.bigqueryUserEmail === email
      ))
      .map(({ bigquerySlotsUsedByHour }) => ({
        x: bigquerySlotsUsedByHour.usageHourOfDay.toString(),
        y: bigquerySlotsUsedByHour.totalSlotsUsed,
      }));

    dataToReturn.push(formattedData);
  });

  return { data: dataToReturn, userEmails };
};

export const getSlotUsageXAxisLabels = (data?: slotUsageReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ bigquerySlotsUsedByHour }) => (
      bigquerySlotsUsedByHour.usageHourOfDay.toString()
    ));
  return Array.from(new Set(dates));
};

export interface hourlySpendReturnType {
  cube: {
    bigqueryAvgCostByHour: {
      averageEstimatedCostUsd: number,
      bigqueryProjectId: string,
      usageHourOfDay: number,
    }
  }[]
}

export const hourlySpendQuery = (allUserEmails: boolean, allProjectIds: boolean) => gql`
  query hourlySpendQuery($timeframe: [String]${getAllVars(allUserEmails, 'userEmail')}${getAllVars(allProjectIds, 'projectId')}) {
    cube(
      limit: 5000
      where: {bigquery_avg_cost_by_hour: {bigquery_user_email: ${getAllWhere(allUserEmails, 'userEmail')}, bigquery_project_id: ${getAllWhere(allProjectIds, 'projectId')}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      bigqueryAvgCostByHour: bigquery_avg_cost_by_hour(orderBy: {usage_hour_of_day: asc}) {
        averageEstimatedCostUsd: average_estimated_cost_usd
        bigqueryProjectId: bigquery_project_id
        usageHourOfDay: usage_hour_of_day
      }
    }
  }
`;

export const formatHourlySpendForChart = (data?: hourlySpendReturnType) => {
  if (!data) return { data: [], projectIds: [] };

  const projectIds = Array.from(new Set(
    data.cube.map(({ bigqueryAvgCostByHour }) => (
      bigqueryAvgCostByHour.bigqueryProjectId
    )),
  ));

  const dataToReturn: { x: string, y: number }[][] = [];

  projectIds.forEach((projectId) => {
    const formattedData = data.cube
      .filter(({ bigqueryAvgCostByHour }) => (
        bigqueryAvgCostByHour.bigqueryProjectId === projectId
      ))
      .map(({ bigqueryAvgCostByHour }) => ({
        x: bigqueryAvgCostByHour.usageHourOfDay.toString(),
        y: bigqueryAvgCostByHour.averageEstimatedCostUsd,
      }));

    dataToReturn.push(formattedData);
  });

  return { data: dataToReturn, projectIds };
};

export const getHourlySpendXAxisLabels = (data?: hourlySpendReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ bigqueryAvgCostByHour }) => (
      bigqueryAvgCostByHour.usageHourOfDay.toString()
    ));
  return Array.from(new Set(dates));
};

export interface spendPerTypeReturnType {
  cube: {
    bigqueryDailyCostByJobType: {
      totalEstimatedCostUsd: number,
      bigqueryJobType: string,
      bigqueryStartTime: {
        day: string,
      },
    }
  }[]
}

export const spendPerTypeQuery = (allUserEmails: boolean, allProjectIds: boolean) => gql`
  query spendPerTypeQuery($timeframe: [String]${getAllVars(allUserEmails, 'userEmail')}${getAllVars(allProjectIds, 'projectId')}) {
    cube(
      limit: 5000
      where: {bigquery_daily_cost_by_job_type: {bigquery_user_email: ${getAllWhere(allUserEmails, 'userEmail')}, bigquery_project_id: ${getAllWhere(allProjectIds, 'projectId')}, bigquery_start_time: {inDateRange: $timeframe}}}
    ) {
      bigqueryDailyCostByJobType: bigquery_daily_cost_by_job_type(
        orderBy: {bigquery_start_time: asc, total_estimated_cost_usd: asc}
      ) {
        totalEstimatedCostUsd: total_estimated_cost_usd
        bigqueryJobType: bigquery_job_type
        bigqueryStartTime: bigquery_start_time {
          day
        }
      }
    }
  }
`;

export const formatSpendPerTypeForChart = (data?: spendPerTypeReturnType) => {
  if (!data) return { data: [], jobTypes: [] };

  const jobTypes = Array.from(new Set(
    data.cube.map(({ bigqueryDailyCostByJobType }) => (
      bigqueryDailyCostByJobType.bigqueryJobType
    )),
  ));

  const dataToReturn: { x: string, y: number }[][] = [];

  jobTypes.forEach((jobType) => {
    const formattedData = data.cube
      .filter(({ bigqueryDailyCostByJobType }) => (
        bigqueryDailyCostByJobType.bigqueryJobType === jobType
      ))
      .map(({ bigqueryDailyCostByJobType }) => ({
        x: bigqueryDailyCostByJobType.bigqueryStartTime.day,
        y: bigqueryDailyCostByJobType.totalEstimatedCostUsd,
      }));

    dataToReturn.push(formattedData);
  });

  return { data: dataToReturn, jobTypes };
};

export const getSpendPerTypeXAxisLabels = (data?: spendPerTypeReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ bigqueryDailyCostByJobType }) => (
      bigqueryDailyCostByJobType.bigqueryStartTime.day
    ));
  return Array.from(new Set(dates));
};

export interface dailyProjectSpendReturnType {
  cube: {
    bigqueryDailyCostByProject: {
      totalEstimatedCostUsd: number,
      bigqueryProjectId: string,
      bigqueryStartTime: {
        day: string,
      },
    }
  }[]
}

export const dailyProjectSpendQuery = (allUserEmails: boolean, allProjectIds: boolean) => gql`
  query dailyProjectSpendQuery($timeframe: [String]${getAllVars(allUserEmails, 'userEmail')}${getAllVars(allProjectIds, 'projectId')}) {
    cube(
      limit: 5000
      where: {bigquery_daily_cost_by_project: {bigquery_user_email: ${getAllWhere(allUserEmails, 'userEmail')}, bigquery_project_id: ${getAllWhere(allProjectIds, 'projectId')}, bigquery_start_time: {inDateRange: $timeframe}}}
    ) {
      bigqueryDailyCostByProject: bigquery_daily_cost_by_project(
        orderBy: {bigquery_start_time: asc, total_estimated_cost_usd: asc}
      ) {
        totalEstimatedCostUsd: total_estimated_cost_usd
        bigqueryProjectId: bigquery_project_id
        bigqueryStartTime: bigquery_start_time {
          day
        }
      }
    }
  }
`;

export const formatDailyProjectSpendForChart = (data?: dailyProjectSpendReturnType) => {
  if (!data) return { data: [], projectIds: [] };

  const projectIds = Array.from(new Set(
    data.cube.map(({ bigqueryDailyCostByProject }) => (
      bigqueryDailyCostByProject.bigqueryProjectId
    )),
  ));

  const dataToReturn: { x: string, y: number }[][] = [];

  projectIds.forEach((projectId) => {
    const formattedData = data.cube
      .filter(({ bigqueryDailyCostByProject }) => (
        bigqueryDailyCostByProject.bigqueryProjectId === projectId
      ))
      .map(({ bigqueryDailyCostByProject }) => ({
        x: bigqueryDailyCostByProject.bigqueryStartTime.day,
        y: bigqueryDailyCostByProject.totalEstimatedCostUsd,
      }));

    dataToReturn.push(formattedData);
  });

  return { data: dataToReturn, projectIds };
};

export const getDailyProjectSpendXAxisLabels = (data?: dailyProjectSpendReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ bigqueryDailyCostByProject }) => (
      bigqueryDailyCostByProject.bigqueryStartTime.day
    ));
  return Array.from(new Set(dates));
};
