import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PageTemplate from '../Onboarding/SynqOnboarding/Utils/PageTemplate';
import synqWelcome from '../Onboarding/SynqOnboarding/images/synq-welcome.svg';
import OrganisationSelector from './ParadimeOrganisations/OrganisationSelector';
import LandingPageButtons from './LandingPageButtons';
import { graphQLStore } from '../../stores';
import GetStarted from './ParadimeOrganisations/GetStarted';

const JoinOrCreate: FC = () => {
  const history = useHistory();

  const { location: { href } } = window;
  const url = new URL(href);
  const isFromParadimeWebsite = ['chrome-extension', 'main-app'].includes(url.searchParams.get('target') || '');
  const orgNameFromUrl = url.searchParams.get('organisationName');

  const paradimeOrganisationFromStore = graphQLStore((state) => state.paradimeOrganisation);

  useEffect(() => {
    // User is already authenticated
    if (paradimeOrganisationFromStore) {
      const { searchParams } = new URL(href);
      searchParams.delete('organisationName'); // delete it, we don't need it anymore
      let redirectUrl = searchParams.toString() ? `/home?${searchParams.toString()}` : '/home';

      const isLoggingInToTheExtension = url.searchParams.get('origin') === 'chrome-extension';
      if (isLoggingInToTheExtension) {
        redirectUrl = '/extension-login-success';
      }
      history.push(redirectUrl);
    }
  }, []);

  return (
    <PageTemplate
      mainImage={synqWelcome}
      title="Welcome to paradime.io"
      withLogo
      withBox={!isFromParadimeWebsite}
      boxOrientation={isFromParadimeWebsite ? 'horizontal' : 'vertical'}
      boxWidth="484px"
      useThickerBorder={false}
      pageBackgroundColour="var(--grey0)"
      boxContents={
        orgNameFromUrl
          ? <GetStarted />
          : <OrganisationSelector />
      }
      customComponent={isFromParadimeWebsite ? <LandingPageButtons /> : undefined}
    />
  );
};

export default JoinOrCreate;
