/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import pick from 'lodash/pick';
import {
  useNewJoinerDbProfileConfigQuery,
} from '../../../client/generated/service';
import { WarehouseEnv } from './utils';
import { BigqueryFormDataType } from './Definitions/Bigquery/dwhBiqquery';
import { FireboltFormDataType } from './Definitions/Firebolt/dwhFirebolt';
import { RedshiftFormDataType } from './Definitions/Redshift/dwhRedshift';
import { SnowflakeFormDataType } from './Definitions/Snowflake/dwhSnowflake';

export interface getInitialWarehouseDataProps {
  credentialId?: string,
  isNewConnection: boolean,
  env: WarehouseEnv,
  // TODO: Define this type explicitly
  viewEditFormData?: { [key: string]: string | number | undefined },
  formFields: any[],
  initialFormFields?: initialFormDataType,
}

export interface CommonFormDataFields {
  datasetName: string,
  credentialId: string,
  databaseType: string,
  connectionName?: string,
}

export type dwhSpecificFormDataFields = BigqueryFormDataType
  | FireboltFormDataType
  | RedshiftFormDataType
  | SnowflakeFormDataType;

export type initialFormDataType = (CommonFormDataFields & dwhSpecificFormDataFields);

// This is a duplicate of the enum in `../Common/Warehouses/Utils`
// Because the browser seems to have suddenly forgotten that enum exists
// Probably an issue with the ordering - trying to use it before it has been defined
// TODO: Investigate, and remove this duplicate
enum Warehouse {
  'BIGQUERY' = 'bigquery',
  'SNOWFLAKE' = 'snowflake',
  'REDSHIFT' = 'redshift',
  'FIREBOLT' = 'firebolt',
  'DATABRICKS' = 'databricks',
  'DUCK_DB' = 'duckdb',
  'MOTHERDUCK' = 'motherduck',
  'UNKNOWN' = 'unknown'
}

const useGetInitialWarehouseData = ({
  credentialId,
  isNewConnection,
  env,
  viewEditFormData,
  formFields,
  initialFormFields,
}: getInitialWarehouseDataProps) => {
  const [initialFormData, setInitialFormData] = useState<initialFormDataType>();

  const { data: newJoinerData } = useNewJoinerDbProfileConfigQuery({
    variables: { credentialId },
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    onError: (e) => {
      console.info(`error: ${e.name}.\n${e.networkError}.\n${e.message}.\n${e.extraInfo}, white-hamster`); // eslint-disable-line
    },
  });

  const fieldsToPick = ['datasetName', 'credentialId', 'databaseType', 'connectionName'];

  const getDatabaseFromTypename = (data: getInitialWarehouseDataProps['viewEditFormData']) => {
    const typename = data?.__typename?.toString()?.toLowerCase();
    if (typename?.includes('bigquery')) return { databaseType: Warehouse.BIGQUERY };
    if (typename?.includes('firebolt')) return { databaseType: Warehouse.FIREBOLT };
    if (typename?.includes('redshift')) return { databaseType: Warehouse.REDSHIFT };
    if (typename?.includes('snowflake')) return { databaseType: Warehouse.SNOWFLAKE };
    if (typename?.includes('databricks')) return { databaseType: Warehouse.DATABRICKS };
    if (typename?.includes('duckdb')) return { databaseType: Warehouse.MOTHERDUCK };
    return {};
  };

  // For Snowflake, datasetName is called schema
  const schemaForSnowflake = newJoinerData?.newJoinerDbProfileConfig?.datasetName
    ? { schema: newJoinerData.newJoinerDbProfileConfig.datasetName }
    : {};

  const profileName = newJoinerData?.newJoinerDbProfileConfig?.connectionName
    ? { connectionName: newJoinerData.newJoinerDbProfileConfig.connectionName }
    : {};

  useEffect(() => {
    if (newJoinerData && env && env !== WarehouseEnv.DEV) {
      const emptyEntry = {
        credentialId: '',
        databaseType: 'unknown',
        datasetName: 'dbt_prod',
        schema: 'dbt_prod',
        env,
      };

      setInitialFormData({
        ...emptyEntry,
        ...profileName,
        ...initialFormFields,
        ...viewEditFormData,
        ...getDatabaseFromTypename(viewEditFormData),
        credentialId: credentialId || '',
      } as initialFormDataType);
    }
  }, [env, newJoinerData, formFields]);

  useEffect(() => {
    if (newJoinerData && env === WarehouseEnv.DEV) {
      let infoFromNewJoiner = {};
      if (isNewConnection) {
        infoFromNewJoiner = {
          datasetName: newJoinerData.newJoinerDbProfileConfig.datasetName,
          schema: newJoinerData.newJoinerDbProfileConfig.datasetName,
          connectionName: newJoinerData.newJoinerDbProfileConfig.connectionName,
          databaseType: 'unknown',
        };
      } else {
        // Flatten the data by just extracting the config for the current warehouse
        // All other warehouses should have a value of null
        const warehouseConfigs = pick(newJoinerData.newJoinerDbProfileConfig, [
          'bigqueryConfig',
          'fireboltConfig',
          'redshiftConfig',
          'snowflakeConfig',
          'databricksConfig',
          'duckdbConfig',
        ]);
        const newJoinerValues = Object.values(warehouseConfigs);
        infoFromNewJoiner = {
          ...newJoinerValues.find((value) => value !== null),
          ...pick(newJoinerData.newJoinerDbProfileConfig, fieldsToPick),
        };
      }

      setInitialFormData({
        credentialId: '',
        ...initialFormFields,
        ...schemaForSnowflake,
        ...infoFromNewJoiner,
        ...viewEditFormData,
        ...getDatabaseFromTypename(viewEditFormData),
      } as initialFormDataType);
    }
  }, [newJoinerData, formFields]);

  return initialFormData;
};

export default useGetInitialWarehouseData;
