import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import { gql } from '@apollo/client';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import * as S from './CostOptimisations.styles';
import { listDates } from './utils';
import OptimisationTypeDropdown from './OptimisationTypeDropdown';
import BarChartLegend from './BarChartLegend';
import TimescaleButtons from './TimescaleButtons';
import SavingsBarChart, { BarChartPlotItem } from './SavingsBarChart';
import { useGetChartDimensions } from '../../Bolt/Scheduler/ScheduleInsights/useGetChartDimensions';
import { graphQLStore } from '../../../stores';

interface dailyData {
  [timestamp: string]: {
    estimatedSpendAfterSavings: number,
    currentSavings: number,
    estimatedRemainingSavings: number,
  },
}

interface optimisationOverviewItem {
  date: string,
  currentSavings: string,
  estimatedSpendAfterSavings: string,
  estimatedRemainingSavings: string,
  optimizationType: string,
}

interface QueryResponse { // eslint-disable-next-line camelcase
  snowflake_cost_optimization_overview_daily: optimisationOverviewItem[],
}

const timescaleOptions = [7, 14, 30, 60];

const costOptimisationsOverviewQuery = gql`
  query costOptimisationsOverviewQuery {
    snowflake_cost_optimization_overview_daily {
      date: calendar_date
      currentSavings: current_savings
      estimatedSpendAfterSavings: estimated_spend_with_all_savings
      estimatedRemainingSavings: estimated_remaining_savings
      optimizationType: optimization_type
    }
  }
`;

const BarChartCard: FC = () => {
  const [numberOfDays, setNumberOfDays] = useState(timescaleOptions[0]);
  const [optimisationsToInclude, setOptimisationsToInclude] = useState('');
  const [queryResponseData, setQueryResponseData] = useState<optimisationOverviewItem[]>([]);
  const [allOptimisationsList, setAllOptimisationsList] = useState<string[]>([]);

  const { hasuraClient } = graphQLStore.getState();

  const chartRef = useRef(null);
  const dimensions = useGetChartDimensions({
    elementRef: chartRef,
    config: {
      BASE_CHART_WIDTH: 617,
      BASE_CHART_HEIGHT: 170,
      BASE_AXIS_LABEL_FONTSIZE: 6,
      MIN_AXIS_LABEL_FONTSIZE: 1,
      BASE_TICK_LABEL_FONTSIZE: 5,
      MIN_TICK_LABEL_FONTSIZE: 1,
      BASE_TOOLTIP_LABEL_FONTSIZE: 6,
      MIN_TOOLTIP_LABEL_FONTSIZE: 1,
      BASE_TOOLTIP_FLYOUT_WIDTH: 180,
      MIN_TOOLTIP_FLYOUT_WIDTH: 45,
      BASE_TOOLTIP_FLYOUT_HEIGHT: 40,
      MIN_TOOLTIP_FLYOUT_HEIGHT: 15,
    },
  });

  useEffect(() => {
    if (hasuraClient) {
      hasuraClient
        .query({ query: costOptimisationsOverviewQuery })
        .then(({ data }: { data: QueryResponse }) => {
          const queryResponse = data.snowflake_cost_optimization_overview_daily;
          setQueryResponseData(queryResponse);

          const optimisationTypes = Array.from(new Set(
            queryResponse.map(({ optimizationType }) => optimizationType),
          )).sort();
          setAllOptimisationsList(optimisationTypes);
          setOptimisationsToInclude(optimisationTypes[0]);
        });
    }
  }, [hasuraClient]);

  const getData = () => {
    const dataToOutput: BarChartPlotItem[][] = [];
    const dataToInclude = queryResponseData
      .filter(({ optimizationType }) => optimisationsToInclude === optimizationType);

    const datesList = listDates(numberOfDays);

    const dataByDate = dataToInclude
      .reduce((currentObject, dataItem) => {
        const clonedObject = { ...currentObject };

        const estimatedSpendAfterSavings = (
          clonedObject[dataItem.date]?.estimatedSpendAfterSavings || 0
        ) + Number(dataItem.estimatedSpendAfterSavings);
        const currentSavings = (clonedObject[dataItem.date]?.currentSavings || 0)
          + Number(dataItem.currentSavings);
        const estimatedRemainingSavings = (
          clonedObject[dataItem.date]?.estimatedRemainingSavings || 0
        ) + Number(dataItem.estimatedRemainingSavings);

        clonedObject[dataItem.date] = {
          estimatedSpendAfterSavings,
          currentSavings,
          estimatedRemainingSavings,
        };
        return clonedObject;
      }, {} as dailyData);

    const currentSpend = datesList
      .map((date) => ({
        x: date,
        y: dataByDate[date]?.estimatedSpendAfterSavings || 0,
      }));
    dataToOutput.push(currentSpend);

    const estimatedSavings = datesList
      .map((date) => ({
        x: date,
        y: dataByDate[date]?.estimatedRemainingSavings || 0,
      }));
    dataToOutput.push(estimatedSavings);

    const currentSavings = datesList
      .map((date) => ({
        x: date,
        y: dataByDate[date]?.currentSavings || 0,
      }));
    dataToOutput.push(currentSavings);

    return dataToOutput;
  };

  return (
    <div ref={chartRef}>
      <S.Card>
        <AutoLayout
          direction="vertical"
          padding="dense"
          verticalGap="dense"
          distribution="packed"
        >
          <AutoLayout
            direction="horizontal"
            padding="none"
            horizontalGap="dense"
            distribution="space-between"
          >
            <Typography
              font="inter"
              type="body-small"
              color="default"
              colorStep="50"
            >
              Warehouse Savings Across Optimizations
            </Typography>
            <OptimisationTypeDropdown
              selectedOptimisationType={optimisationsToInclude}
              allOptimisationTypes={allOptimisationsList}
              onSelectOptimisation={setOptimisationsToInclude}
            />
          </AutoLayout>
          <AutoLayout
            direction="horizontal"
            padding="none"
            horizontalGap="dense"
            distribution="space-between"
            style={{ margin: '0 20px' }}
          >
            <BarChartLegend
              entries={[
                { color: 'var(--grey20)', text: 'Estimated spend after savings' },
                { color: 'var(--violet50) ', text: 'Estimated savings' },
                { color: 'var(--green50)', text: 'Current savings' },
              ]}
            />
            <TimescaleButtons
              timescaleOptions={timescaleOptions}
              onSelectTimescale={setNumberOfDays}
              selectedTimescale={numberOfDays}
            />
          </AutoLayout>
          {/* Add one to the number of days to add a bit of padding at the end of the chart axis */}
          <SavingsBarChart
            data={getData()}
            axisDatesList={listDates(numberOfDays + 1)}
            dimensions={dimensions}
          />
        </AutoLayout>
      </S.Card>
    </div>
  );
};

export default BarChartCard;
