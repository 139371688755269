import React, { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { StackedBarChart, PieChart } from '@paradime-io/pragma-ui-kit/lib/components/VictoryCharts';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { useCubeLazyQuery } from '../../../app/hooks/useCubeQuery';
import {
  totalRuntimeQuery,
  totalRuntimeReturnType,
  formatTotalRuntimeForChart,
  getTotalRuntimeXAxisLabels,
  dailySlotUseQuery,
  dailySlotUseReturnType,
  formatDailySlotUseForChart,
  getDailySlotUseXAxisLabels,
  spiltBytesQuery,
  spiltBytesReturnType,
  formatSpiltBytesForChart,
  getSpiltBytesXAxisLabels,
  spendPerUserQuery,
  spendPerUserReturnType,
  formatSpendPerUserForChart,
  dailySpendQuery,
  dailySpendReturnType,
  formatDailySpendForChart,
  getDailySpendXAxisLabels,
} from './BigqueryDetailsCubeQueries';
import FilterRow from '../FilterRow';
import {
  timeframes,
  HalfPageChartSection,
  getFormattedYAxisLabel,
  FullPageChartSection,
} from '../utils';
import { useGetFiltersFromUrl } from '../hooks/useGetFiltersFromUrl';

const BigqueryDetailsTab: FC = () => {
  const { getInitialFilterValue } = useGetFiltersFromUrl();

  const [timeframe, setTimeframe] = useState(getInitialFilterValue('timeframe', timeframes[2].value)!);
  const [queryHash, setQueryHash] = useState(getInitialFilterValue('queryHash', ' ')!);

  const isSelectAllQueryHashes = false;

  const variables = {
    timeframe: timeframe.value,
    queryHash: queryHash.value,
  };

  const [
    getTotalRuntime,
    { data: totalRuntimeData, loading: isLoadingTotalRuntime, error: totalRuntimeError },
  ] = useCubeLazyQuery<totalRuntimeReturnType>({
    query: totalRuntimeQuery(isSelectAllQueryHashes),
    variables,
  });
  const [
    getDailySlotUse,
    { data: dailySlotUseData, loading: isLoadingDailySlotUse, error: dailySlotUseError },
  ] = useCubeLazyQuery<dailySlotUseReturnType>({
    query: dailySlotUseQuery(isSelectAllQueryHashes),
    variables,
  });
  const [
    getSpiltBytes, { data: spiltBytesData, loading: isLoadingSpiltBytes, error: spiltBytesError },
  ] = useCubeLazyQuery<spiltBytesReturnType>({
    query: spiltBytesQuery(isSelectAllQueryHashes),
    variables,
  });
  const [
    getSpendPerUser,
    { data: spendPerUserData, loading: isLoadingSpendPerUser, error: spendPerUserError },
  ] = useCubeLazyQuery<spendPerUserReturnType>({
    query: spendPerUserQuery(isSelectAllQueryHashes),
    variables,
  });
  const [
    getDailySpend, { data: dailySpendData, loading: isLoadingDailySpend, error: dailySpendError },
  ] = useCubeLazyQuery<dailySpendReturnType>({
    query: dailySpendQuery(isSelectAllQueryHashes),
    variables,
  });

  const refetchAllData = () => {
    getTotalRuntime();
    getDailySlotUse();
    getSpiltBytes();
    getSpendPerUser();
    getDailySpend();
  };

  useEffect(() => {
    if (queryHash.value !== ' ') {
      refetchAllData();
    }
  }, [timeframe, queryHash]);

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      verticalGap="dense"
      distribution="packed"
    >
      <FilterRow
        dropdowns={[
          {
            value: timeframe,
            options: timeframes,
            onChange: setTimeframe,
            name: 'timeframe',
            title: 'Select date range',
          },
          {
            value: queryHash,
            onChange: setQueryHash,
            name: 'queryHash',
            useInputField: true,
            title: 'Select query hash',
          },
        ]}
      />
      <div style={{
        display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: '16px',
      }}
      >
        <HalfPageChartSection>
          <StackedBarChart
            title="Daily query spend"
            data={formatDailySpendForChart(dailySpendData)}
            xAxisLabels={getDailySpendXAxisLabels(dailySpendData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Calendar date"
            yLabel="Total estimated cost in $"
            isLoading={isLoadingDailySpend}
            hasNoData={dailySpendError || dailySpendData?.cube?.length === 0}
            xAxisDomain={[
              0.5,
              getDailySpendXAxisLabels(dailySpendData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <PieChart
            title="Query spend per user"
            data={formatSpendPerUserForChart(spendPerUserData)}
            isLoading={isLoadingSpendPerUser}
            hasNoData={spendPerUserError || spendPerUserData?.cube?.length === 0}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Processed versus shuffled spilled bytes"
            data={formatSpiltBytesForChart(spiltBytesData)}
            xAxisLabels={getSpiltBytesXAxisLabels(spiltBytesData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            legendLabels={['Bigquery total bytes processed', 'Bigquery total shuffle bytes spilled']}
            xLabel="Calendar date"
            yLabel="Total bytes"
            isLoading={isLoadingSpiltBytes}
            hasNoData={spiltBytesError || spiltBytesData?.cube?.length === 0}
            xAxisDomain={[
              0.5,
              getSpiltBytesXAxisLabels(spiltBytesData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Slot usage per day"
            data={formatDailySlotUseForChart(dailySlotUseData)}
            xAxisLabels={getDailySlotUseXAxisLabels(dailySlotUseData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Calendar date"
            yLabel="Average slots used"
            isLoading={isLoadingDailySlotUse}
            hasNoData={dailySlotUseError || dailySlotUseData?.cube?.length === 0}
            xAxisDomain={[
              0.5,
              getDailySlotUseXAxisLabels(dailySlotUseData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </HalfPageChartSection>
        <FullPageChartSection>
          <StackedBarChart
            title="Query total runtime per day"
            data={formatTotalRuntimeForChart(totalRuntimeData)}
            xAxisLabels={getTotalRuntimeXAxisLabels(totalRuntimeData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Calendar date"
            yLabel="Average runtime in ms"
            isLoading={isLoadingTotalRuntime}
            hasNoData={totalRuntimeError || totalRuntimeData?.cube?.length === 0}
            xAxisDomain={[
              0.5,
              getTotalRuntimeXAxisLabels(totalRuntimeData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </FullPageChartSection>
      </div>
    </AutoLayout>
  );
};

export default BigqueryDetailsTab;
