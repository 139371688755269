import React, { FC, useContext } from 'react';
import { useParams } from 'react-router-dom';
import RadarPage from '../RadarPage';
import SnowflakeTab from './SnowflakeTab';
import { DashboardTabIds } from '../config';
import CostOptimisations from '../costOptimisations';
import BigqueryTab from './BigqueryTab';
import BigqueryDetailsTab from './BigqueryDetailsTab';
import { RadarWorkspaceContext } from '..';
import CalloutNoCostConnection from '../CalloutNoCostConnection';

const Costs: FC = () => {
  const { tabId } = useParams<{tabId: DashboardTabIds}>();

  const { costWarehouseType } = useContext(RadarWorkspaceContext);
  const costConnectionNotSetup = costWarehouseType === 'unknown';

  const getContent = () => {
    if (costConnectionNotSetup) return <CalloutNoCostConnection />;

    switch (tabId) {
      case DashboardTabIds.SNOWFLAKE:
        return <SnowflakeTab />;
      case DashboardTabIds.COST_OPTIMISATIONS:
        return <CostOptimisations />;
      case DashboardTabIds.BIGQUERY:
        return <BigqueryTab />;
      case DashboardTabIds.DETAILED:
        return <BigqueryDetailsTab />;
      default:
        return null;
    }
  };

  return (
    <RadarPage>
      {getContent()}
    </RadarPage>
  );
};

export default Costs;
