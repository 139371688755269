import { GqlParentScheduleTrigger, GqlScheduleTrigger } from '../../../client/generated/service';

export const getOnCompletionRunInfoText = (
  scheduleTrigger: GqlParentScheduleTrigger | GqlScheduleTrigger,
) => (
  `schedule ${scheduleTrigger.scheduleName} in workspace ${scheduleTrigger.workspaceName} on status ${scheduleTrigger.triggerOn?.join(', ')}`
);

const formatter = Intl.NumberFormat('en', { style: 'currency', currency: 'USD' });

export const formatCurrency = (amount: number) => (
  formatter.format(amount)
);
