import React, { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import {
  StackedBarChart,
  PieChart,
  SingleValueChart,
  GroupedBarChart,
} from '@paradime-io/pragma-ui-kit/lib/components/VictoryCharts';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { useCubeLazyQuery } from '../../../app/hooks/useCubeQuery';
import {
  countSchedulesQuery,
  countSchedulesReturnType,
  countMinutesQuery,
  countMinutesReturnType,
  dailyRunStatusQuery,
  dailyRunStatusReturnType,
  formatDailyRunStatusForChart,
  getDailyRunStatusXAxisLabels,
  scheduleRunActorsQuery,
  scheduleRunActorsReturnType,
  formatScheduleRunActorsForChart,
  scheduleOwnersQuery,
  scheduleOwnersReturnType,
  formatScheduleOwnersForChart,
  scheduleRunCronsQuery,
  scheduleRunCronsReturnType,
  formatScheduleRunCronsForChart,
  getScheduleRunCronsXAxisLabels,
  scheduleRunsByHourQuery,
  scheduleRunsByHourReturnType,
  formatScheduleRunsByHourForChart,
  getScheduleRunsByHourXAxisLabels,
  scheduleRunsByErrorQuery,
  scheduleRunsByErrorReturnType,
  formatScheduleRunsByErrorForChart,
  getScheduleRunsByErrorXAxisLabels,
  scheduleRuntimeStatsQuery,
  scheduleRuntimeStatsReturnType,
  formatScheduleRuntimeStatsForChart,
  getScheduleRuntimeStatsXAxisLabels,
} from './OverviewCubeQueries';
import FilterRow from '../FilterRow';
import {
  timeframes,
  HalfPageChartSection,
  getSingleValueChartString,
  getFormattedYAxisLabel,
  singleValueChartHeight,
  FullPageChartSection,
} from '../utils';
import { useGetFiltersFromUrl } from '../hooks/useGetFiltersFromUrl';

const OverviewTab: FC = () => {
  const { getInitialFilterValue } = useGetFiltersFromUrl();

  const [timeframe, setTimeframe] = useState(getInitialFilterValue('timeframe', timeframes[2].value)!);

  const [
    getScheduleCount,
    { data: countScheduleData, loading: isLoadingScheduleCount, error: countScheduleError },
  ] = useCubeLazyQuery<countSchedulesReturnType>({
    query: countSchedulesQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getMinutesCount,
    { data: countMinutesData, loading: isLoadingMinutesCount, error: countMinutesError },
  ] = useCubeLazyQuery<countMinutesReturnType>({
    query: countMinutesQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getRunStatus,
    { data: dailyRunStatusData, loading: isLoadingRunStatus, error: dailyRunStatusError },
  ] = useCubeLazyQuery<dailyRunStatusReturnType>({
    query: dailyRunStatusQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getRunActors,
    { data: scheduleRunActorsData, loading: isLoadingRunActors, error: scheduleRunActorsError },
  ] = useCubeLazyQuery<scheduleRunActorsReturnType>({
    query: scheduleRunActorsQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getScheduleOwners,
    { data: scheduleOwnersData, loading: isLoadingScheduleOwners, error: scheduleOwnersError },
  ] = useCubeLazyQuery<scheduleOwnersReturnType>({
    query: scheduleOwnersQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getCrons,
    { data: scheduleRunCronsData, loading: isLoadingRunCrons, error: scheduleRunCronsError },
  ] = useCubeLazyQuery<scheduleRunCronsReturnType>({
    query: scheduleRunCronsQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getRunHours,
    { data: scheduleRunHoursData, loading: isLoadingRunHours, error: scheduleRunHoursError },
  ] = useCubeLazyQuery<scheduleRunsByHourReturnType>({
    query: scheduleRunsByHourQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getRunErrors,
    { data: scheduleRunErrorsData, loading: isLoadingRunErrors, error: scheduleRunErrorsError },
  ] = useCubeLazyQuery<scheduleRunsByErrorReturnType>({
    query: scheduleRunsByErrorQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getRuntimeStats, {
      data: scheduleRuntimeStatsData,
      loading: isLoadingRuntimeStats,
      error: scheduleRuntimeStatsError,
    },
  ] = useCubeLazyQuery<scheduleRuntimeStatsReturnType>({
    query: scheduleRuntimeStatsQuery,
    variables: { timeframe: timeframe.value },
  });

  const refetchAllData = () => {
    getScheduleCount();
    getMinutesCount();
    getRunStatus();
    getRunActors();
    getScheduleOwners();
    getCrons();
    getRunHours();
    getRunErrors();
    getRuntimeStats();
  };

  useEffect(() => {
    refetchAllData();
  }, [timeframe]);

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      verticalGap="dense"
      distribution="packed"
    >
      <FilterRow
        dropdowns={[
          {
            value: timeframe,
            options: timeframes,
            onChange: setTimeframe,
            name: 'timeframe',
            title: 'Select date range',
          },
        ]}
      />
      <div style={{
        display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: '16px',
      }}
      >
        <HalfPageChartSection height={singleValueChartHeight}>
          <SingleValueChart
            title="Count dbt™ schedules executed"
            subtitle="Monitor dbt™ schedules executed in the selected time period"
            value={(
              getSingleValueChartString(
                countScheduleData?.cube?.[0]?.scheduleTotalRuns?.countBoltScheduleRunId || 0,
              )
            )}
            isLoading={isLoadingScheduleCount}
            hasNoData={countScheduleError || countScheduleData?.cube?.length === 0}
          />
        </HalfPageChartSection>
        <HalfPageChartSection height={singleValueChartHeight}>
          <SingleValueChart
            title="Total minutes of executed dbt™ runs"
            subtitle="Monitor dbt™ schedules executed in the selected time period"
            value={(
              getSingleValueChartString(
                countMinutesData?.cube?.[0]?.scheduleTotalRuntime?.totalRuntimeMinutes || 0,
              )
            )}
            isLoading={isLoadingMinutesCount}
            hasNoData={countMinutesError || countMinutesData?.cube?.length === 0}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Daily dbt™ runs monitoring"
            subtitle="Monitor your dbt™ runs execution status"
            data={formatDailyRunStatusForChart(dailyRunStatusData).data}
            xAxisLabels={getDailyRunStatusXAxisLabels(dailyRunStatusData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Calendar date"
            yLabel="Count dbt™ runs"
            legendLabels={formatDailyRunStatusForChart(dailyRunStatusData).statuses}
            customColourScale={formatDailyRunStatusForChart(dailyRunStatusData).colours}
            isLoading={isLoadingRunStatus}
            hasNoData={dailyRunStatusError || dailyRunStatusData?.cube?.length === 0}
            showDetailsButton
            xAxisDomain={[0.5, getDailyRunStatusXAxisLabels(dailyRunStatusData).length + 1]}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <PieChart
            title="Executed runs by actor"
            subtitle="Identify who is triggering your dbt™ schedules based on the selected time period. Keep manual runs at the minimum as it can leads to human errors breaking production models."
            data={formatScheduleRunActorsForChart(scheduleRunActorsData)}
            isLoading={isLoadingRunActors}
            hasNoData={scheduleRunActorsError || scheduleRunActorsData?.cube?.length === 0}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <PieChart
            title="Current schedules count by Owner"
            subtitle="Identify who are the current owner of your production jobs and how many schedules each owns."
            data={formatScheduleOwnersForChart(scheduleOwnersData)}
            isLoading={isLoadingScheduleOwners}
            hasNoData={scheduleOwnersError || scheduleOwnersData?.cube?.length === 0}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Current schedule config overview"
            data={formatScheduleRunCronsForChart(scheduleRunCronsData)}
            xAxisLabels={getScheduleRunCronsXAxisLabels(scheduleRunCronsData)}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Cron configuration"
            yLabel="Number of schedules"
            isLoading={isLoadingRunCrons}
            hasNoData={scheduleRunCronsError || scheduleRunCronsData?.cube?.length === 0}
            maxCharsAxisX={12}
            xAxisDomain={[0.5, getScheduleRunCronsXAxisLabels(scheduleRunCronsData).length + 1]}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Schedules runs hourly distribution"
            subtitle="Identify hour windows where a high amount of dbt™ runs are executed. Consider trying to increase dwh compute resources during peak hours to limit the amount of queued queries."
            data={formatScheduleRunsByHourForChart(scheduleRunHoursData)}
            xAxisLabels={getScheduleRunsByHourXAxisLabels(scheduleRunHoursData)}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Hour of the day"
            yLabel="Count dbt™ schedules"
            isLoading={isLoadingRunHours}
            hasNoData={scheduleRunHoursError || scheduleRunHoursData?.cube?.length === 0}
            xAxisDomain={[0.5, getScheduleRunsByHourXAxisLabels(scheduleRunHoursData).length + 1]}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Top 5 schedules with most execution errors"
            subtitle="Identify the dbt™ schedules that often complete with errors."
            horizontal
            data={formatScheduleRunsByErrorForChart(scheduleRunErrorsData)}
            xAxisLabels={getScheduleRunsByErrorXAxisLabels(scheduleRunErrorsData)}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Schedule names"
            yLabel="Count errored runs"
            isLoading={isLoadingRunErrors}
            hasNoData={scheduleRunErrorsError || scheduleRunErrorsData?.cube?.length === 0}
            maxCharsAxisX={8}
          />
        </HalfPageChartSection>
        <FullPageChartSection>
          <GroupedBarChart
            title="Schedule run performance"
            subtitle="Monitor the performance of your dbt™ schedules and identify and compare the execution times."
            data={formatScheduleRuntimeStatsForChart(scheduleRuntimeStatsData)}
            xAxisLabels={getScheduleRuntimeStatsXAxisLabels(scheduleRuntimeStatsData)}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            legendLabels={['Min runtime', 'avg runtime', 'max runtime']}
            xLabel="Schedule name"
            yLabel="Runtime in minutes"
            isLoading={isLoadingRuntimeStats}
            hasNoData={scheduleRuntimeStatsError || scheduleRuntimeStatsData?.cube?.length === 0}
            maxCharsAxisX={8}
            xAxisDomain={[
              0.5,
              getScheduleRuntimeStatsXAxisLabels(scheduleRuntimeStatsData).length + 1,
            ]}
          />
        </FullPageChartSection>
      </div>
    </AutoLayout>
  );
};

export default OverviewTab;
