import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import Divider from '@paradime-io/pragma-ui-kit/lib/components/Divider';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { key } from '../../../utilis';

export interface ListRowProps {
  actionName: string,
  url?: string,
  saving?: string,
  withDivider?: boolean,
}

const ListRow: FC<ListRowProps> = ({
  actionName,
  url,
  saving,
  withDivider,
}) => {
  const history = useHistory();

  return (
    <AutoLayout
      key={key(actionName)}
      direction="vertical"
      padding="none"
      verticalGap="very-dense"
      distribution="packed"
    >
      <AutoLayout
        direction="horizontal"
        padding="none"
        distribution="space-between"
      >
        <Typography
          font="inter"
          type="body-small"
          color="default"
          colorStep="100"
        >
          {actionName}
        </Typography>
        {url && (
          <DefaultButton
            type="standard"
            view="filled"
            color="primary"
            dense
            text="Try it"
            onClick={() => history.push(url)}
            eventContext={Contexts.HOME}
            eventObject="tryTimeSavingOpportunity"
            eventAction={Actions.CLICKED}
            eventProperties={{ actionName }}
          />
        )}
        {saving && (
          <Typography
            font="inter"
            type="body-bold-small"
            color="default"
            colorStep="100"
          >
            {saving}
          </Typography>
        )}
      </AutoLayout>
      {withDivider && <Divider style={{ margin: 0 }} />}
    </AutoLayout>
  );
};

export default ListRow;
