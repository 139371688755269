import React, {
  Dispatch, FC, SetStateAction, useEffect,
} from 'react';
import Dropdown from '@paradime-io/pragma-ui-kit/lib/components/Dropdown';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { MenuTemplate } from '@paradime-io/pragma-ui-kit/lib/components/Menu';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import CheckboxList from '@paradime-io/pragma-ui-kit/lib/components/CheckboxList';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { GqlScheduleNameWithWorkspace } from '../../../client/generated/service';
import useListWorkspaces from '../../Platform/Workspaces/hooks/useListWorkspaces';

export interface RunCompletionDropdownProps {
  scheduleWorkspaceName: string,
  setScheduleWorkspaceName: Dispatch<SetStateAction<string>>,
  boltScheduleName: string,
  boltSchedulesForCurrentWorkspace: GqlScheduleNameWithWorkspace[],
  setBoltScheduleName: Dispatch<SetStateAction<string>>,
  scheduleOnTriggerCompleteStatuses: string[],
  setScheduleOnTriggerCompleteStatuses: Dispatch<SetStateAction<string[]>>,
  scheduleNamesByWorkspace: GqlScheduleNameWithWorkspace[],
  setBoltSchedulesForCurrentWorkspace: Dispatch<SetStateAction<GqlScheduleNameWithWorkspace[]>>,
}

export const RunCompletionDropdown: FC<RunCompletionDropdownProps> = (
  {
    scheduleWorkspaceName,
    setScheduleWorkspaceName,
    boltScheduleName,
    boltSchedulesForCurrentWorkspace,
    setBoltScheduleName,
    scheduleOnTriggerCompleteStatuses,
    setScheduleOnTriggerCompleteStatuses,
    scheduleNamesByWorkspace,
    setBoltSchedulesForCurrentWorkspace,
  },
) => {
  const { allWorkspacesList } = useListWorkspaces();

  const handleOnChangeCheckboxList = (status: 'passed' | 'failed', isChecked: boolean) => {
    if (isChecked) {
      setScheduleOnTriggerCompleteStatuses(
        (oldScheduleStatusList) => [...oldScheduleStatusList, status],
      );
    } else {
      setScheduleOnTriggerCompleteStatuses((completeStatuses) => (
        completeStatuses.filter((scheduleStatus) => scheduleStatus !== status)
      ));
    }
  };

  const sortList = (list: any[], property: string) => list.sort((a, b) => a[property]?.localeCompare(b[property], 'en', { numeric: true }));

  useEffect(() => {
    const getBoltSchedulesListByWorkspace = scheduleNamesByWorkspace.filter(
      (schedule) => schedule.workspaceName === scheduleWorkspaceName,
    );
    setBoltSchedulesForCurrentWorkspace(sortList(getBoltSchedulesListByWorkspace, 'scheduleName'));
  }, [scheduleWorkspaceName, scheduleNamesByWorkspace]);

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      distribution="packed"
      alignment="top-left"
      verticalGap="very-dense"
    >
      <Dropdown
        text={scheduleWorkspaceName}
        view="outlined"
        fill
        color="default"
        dense
        height={300}
        label="Workspace name"
        content={(
          <MenuTemplate
            items={sortList(allWorkspacesList, 'name').map((scheduleWorkspace) => ({
              text: scheduleWorkspace.name,
              type: 'item',
              onClick: () => {
                setScheduleWorkspaceName(scheduleWorkspace.name);
                setBoltScheduleName('');
              },
            }))}
            eventContext={Contexts.LOGS}
          />
        )}
      />
      {(scheduleWorkspaceName && boltSchedulesForCurrentWorkspace.length === 0) ? (
        <Typography
          tagName="span"
          type="caption-small"
          color="danger"
          colorStep="60"
        >
          No bolt schedules found in the selected workspace.
        </Typography>
      ) : (
        <Dropdown
          text={boltScheduleName}
          view="outlined"
          fill
          color="default"
          dense
          height={300}
          label="Bolt schedule name"
          content={(
            <MenuTemplate
              items={boltSchedulesForCurrentWorkspace.map((item) => ({
                text: item.scheduleName,
                type: 'item',
                onClick: () => setBoltScheduleName(item.scheduleName),
              }))}
              eventContext={Contexts.LOGS}
            />
          )}
        />
      )}
      <CheckboxList
        subtitle="Bolt schedule complete with status:"
        checkboxes={[
          {
            label: 'Passed',
            onChange: (e) => {
              handleOnChangeCheckboxList('passed', e.currentTarget.checked);
            },
            checked: scheduleOnTriggerCompleteStatuses.includes('passed'),
          },
          {
            label: 'Failed',
            onChange: (e) => {
              handleOnChangeCheckboxList('failed', e.currentTarget.checked);
            },
            checked: scheduleOnTriggerCompleteStatuses.includes('failed'),
          },
        ]}
        eventContext={Contexts.BOLT}
      />
    </AutoLayout>
  );
};
