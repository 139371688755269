import React, { FC, useState, useEffect } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import DefaultInput from '@paradime-io/pragma-ui-kit/lib/components/DefaultInput';
import Switch from '@paradime-io/pragma-ui-kit/lib/components/Switch';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { useManageAlerts } from './hooks/useManageAlerts';

interface AlertRowProps {
  id: string,
  name: string,
  category: string,
  description: string,
  currentStatus: boolean,
  currentThreshold: number,
}

const AlertRow:FC<AlertRowProps> = ({
  id,
  name,
  category,
  description,
  currentStatus,
  currentThreshold,
}) => {
  const [enabled, setEnabled] = useState(currentStatus);
  const [threshold, setThreshold] = useState(currentThreshold);
  const [thresholdError, setThresholdError] = useState(false);
  const { updateAlert, isUpdatingAlert, isUpdatedAlert } = useManageAlerts();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!thresholdError) {
        updateAlert({
          variables: {
            alertId: id,
            enabled,
            threshold,
          },
        });
      }
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [threshold, thresholdError]);

  const handleUpdateAlert = (isChecked: boolean) => {
    setEnabled(isChecked);
    updateAlert({
      variables: {
        alertId: id,
        enabled: isChecked,
        threshold,
      },
    });
  };

  const handleChangeThreshold = (value: number) => {
    if ((value <= 0) || (value > 100)) {
      setThresholdError(true);
    } else {
      setThresholdError(false);
      setThreshold(value);
    }
  };

  return (
    <AutoLayout
      direction="horizontal"
      distribution="space-between"
      padding="none"
      style={{ gridTemplateColumns: '2fr 1fr' }}
    >
      <AutoLayout
        direction="vertical"
        distribution="packed"
        padding="none"
        verticalGap="very-dense"
      >
        <AutoLayout
          direction="horizontal"
          padding="none"
          distribution="packed"
          horizontalGap="very-dense"
        >
          <Typography tagName="span" type="body-bold-small">{name}</Typography>
          <Chips
            color="primary"
            round={false}
            style={{ margin: '4px' }}
            tag={category}
            type="dense"
            view="smooth"
          />
        </AutoLayout>
        <Typography tagName="span" type="caption" colorStep="50">
          {description}
        </Typography>
      </AutoLayout>
      <AutoLayout
        direction="horizontal"
        distribution="packed"
        padding="none"
        horizontalGap="normal"
        style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}
      >
        <AutoLayout
          direction="horizontal"
          distribution="packed"
          padding="none"
          horizontalGap="very-dense"
          style={{ alignItems: 'flex-end' }}
        >
          {
            isUpdatingAlert && (
              <Chips
                color="default"
                round={false}
                tag="Saving"
                type="dense"
                view="smooth"
              />
            )
          }
          {
            isUpdatedAlert && (
              <Chips
                color="success"
                round={false}
                tag="Saved!"
                type="dense"
                view="smooth"
                style={{ marginBottom: '4px' }}
              />
            )
          }
          <DefaultInput
            type="number"
            view="outlined"
            color="default"
            label="Threshold"
            dense
            error={thresholdError}
            errorHelperText="Only number between 0 and 100"
            value={String(threshold)}
            expose={(e) => handleChangeThreshold(Number(e.target.value))}
            style={{
              fontSize: 'var(--font_size_caption)',
              display: 'flex',
              flexDirection: 'column',
              width: '200px',
            }}
          />
        </AutoLayout>
        <Switch
          type="dense"
          view="flat"
          color="primary"
          checked={enabled}
          disabled={thresholdError}
          onChange={(_, isChecked) => handleUpdateAlert(isChecked)}
          eventContext={Contexts.RADAR}
          eventObject="toggleAlertStatus"
          eventAction={Actions.CLICKED}
          eventProperties={{
            name,
            enabled,
          }}
        />
      </AutoLayout>
    </AutoLayout>
  );
};

export default AlertRow;
