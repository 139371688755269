import React, { FC } from 'react';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import { ConnectionStatus } from './SlackNotify';

export interface GithubAppStatusChipProps {
  githubAppConnectionStatus?: ConnectionStatus,
}

const GithubAppStatusChip: FC<GithubAppStatusChipProps> = ({ githubAppConnectionStatus }) => {
  if (githubAppConnectionStatus === ConnectionStatus.ACTIVE) return null;

  return (
    <Chips
      type="dense"
      view="smooth"
      color="danger"
      icon="warning-sign"
      tag="Connect Github App to use this trigger"
    />
  );
};

export default GithubAppStatusChip;
