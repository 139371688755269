import React, {
  Dispatch, FC, SetStateAction,
} from 'react';
import Dropdown from '@paradime-io/pragma-ui-kit/lib/components/Dropdown';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import MenuTemplate from '@paradime-io/pragma-ui-kit/lib/components/Menu/MenuTemplate';

export interface ScheduleSeverityProps {
  severity: string,
  setSeverity: Dispatch<SetStateAction<string>>,
}

const ScheduleSeverity:FC<ScheduleSeverityProps> = ({
  severity,
  setSeverity,
}) => {
  const getSeverityDropdownLabel = () => {
    switch (severity) {
      case 'high-severity@paradimedatalerts.io':
        return 'high';
      case 'medium-severity@paradimedatalerts.io':
        return 'medium';
      case 'low-severity@paradimedatalerts.io':
        return 'low';
      default:
        return 'medium';
    }
  };

  if (severity.indexOf('-severity@paradimedatalerts.io') <= 0) {
    setSeverity('medium-severity@paradimedatalerts.io');
  }

  return (
    <Dropdown
      view="outlined"
      text={getSeverityDropdownLabel()}
      label="Schedule Severity (high / medium / low)"
      content={(
        <MenuTemplate
          dense
          items={[
            {
              text: 'high',
              type: 'item',
              onClick: () => setSeverity('high-severity@paradimedatalerts.io'),
            },
            {
              text: 'medium',
              type: 'item',
              onClick: () => setSeverity('medium-severity@paradimedatalerts.io'),
            },
            {
              text: 'low',
              type: 'item',
              onClick: () => setSeverity('low-severity@paradimedatalerts.io'),
            },
          ]}
          eventContext={Contexts.BOLT}
        />
      )}
      fill
      color="default"
      dense
    />
  );
};

export default ScheduleSeverity;
