import React, { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import {
  StackedBarChart,
  PieChart,
  SingleValueChart,
  TreeTableChart,
  LineChart,
} from '@paradime-io/pragma-ui-kit/lib/components/VictoryCharts';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { useCubeLazyQuery } from '../../../app/hooks/useCubeQuery';
import {
  freshSourcesQuery,
  freshSourcesReturnType,
  warnSourcesQuery,
  warnSourcesReturnType,
  staleSourcesQuery,
  erroredSourcesQuery,
  erroredSourcesReturnType,
  staleSourcesReturnType,
  dailySourceStatusQuery,
  dailySourceStatusReturnType,
  dailySourceStatusColumns,
  formatDailySourceStatusForChart,
  sourceStatusHealthQuery,
  sourceStatusHealthReturnType,
  formatSourceStatusHealthForChart,
  dailyFreshnessStatusQuery,
  dailyFreshnessStatusReturnType,
  formatDailyFreshnessStatusForChart,
  getDailyFreshnessStatusXAxisLabels,
  passRateBySourceQuery,
  passRateBySourceReturnType,
  formatPassRateBySourceForChart,
  getPassRateBySourceXAxisLabels,
} from './OverviewCubeQueries';
import FilterRow from '../FilterRow';
import {
  timeframes,
  FullPageChartSection,
  HalfPageChartSection,
  QuarterPageChartSection,
  getSingleValueChartString,
  getFormattedYAxisLabel,
  singleValueChartHeight,
} from '../utils';
import { useGetFiltersFromUrl } from '../hooks/useGetFiltersFromUrl';

const OverviewTab: FC = () => {
  const { getInitialFilterValue } = useGetFiltersFromUrl();

  const [timeframe, setTimeframe] = useState(getInitialFilterValue('timeframe', timeframes[2].value)!);

  const [
    getFreshSources,
    { data: freshSourcesData, loading: isLoadingFreshSources, error: freshSourcesError },
  ] = useCubeLazyQuery<freshSourcesReturnType>({
    query: freshSourcesQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getWarnSources,
    { data: warnSourcesData, loading: isLoadingWarnSources, error: warnSourcesError },
  ] = useCubeLazyQuery<warnSourcesReturnType>({
    query: warnSourcesQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getStaleSources,
    { data: staleSourcesData, loading: isLoadingStaleSources, error: staleSourcesError },
  ] = useCubeLazyQuery<staleSourcesReturnType>({
    query: staleSourcesQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getErroredSources,
    { data: erroredSourcesData, loading: isLoadingErroredSources, error: erroredSourcesError },
  ] = useCubeLazyQuery<erroredSourcesReturnType>({
    query: erroredSourcesQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getDailySourceStatus, {
      data: dailySourceStatusData,
      loading: isLoadingDailySourceStatus,
      error: dailySourceStatusError,
    },
  ] = useCubeLazyQuery<dailySourceStatusReturnType>({
    query: dailySourceStatusQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getSourceStatusHealth, {
      data: sourceStatusHealthData,
      loading: isLoadingSourceStatusHealth,
      error: sourceStatusHealthError,
    },
  ] = useCubeLazyQuery<sourceStatusHealthReturnType>({
    query: sourceStatusHealthQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getDailyFreshnessStatusHealth, {
      data: dailyFreshnessStatusHealthData,
      loading: isLoadingDailyFreshnessStatusHealth,
      error: dailyFreshnessStatusHealthError,
    },
  ] = useCubeLazyQuery<dailyFreshnessStatusReturnType>({
    query: dailyFreshnessStatusQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getPassRateBySource, {
      data: passRateBySourceData,
      loading: isLoadingPassRateBySource,
      error: passRateBySourceError,
    },
  ] = useCubeLazyQuery<passRateBySourceReturnType>({
    query: passRateBySourceQuery,
    variables: { timeframe: timeframe.value },
  });

  const refetchAllData = () => {
    getFreshSources();
    getWarnSources();
    getStaleSources();
    getErroredSources();
    getDailySourceStatus();
    getSourceStatusHealth();
    getDailyFreshnessStatusHealth();
    getPassRateBySource();
  };

  useEffect(() => {
    refetchAllData();
  }, [timeframe]);

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      verticalGap="dense"
      distribution="packed"
    >
      <FilterRow
        dropdowns={[
          {
            value: timeframe,
            options: timeframes,
            onChange: setTimeframe,
            name: 'timeframe',
            title: 'Select date range',
          },
        ]}
      />
      <div style={{
        display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: '16px',
      }}
      >
        <QuarterPageChartSection height={singleValueChartHeight}>
          <SingleValueChart
            title="✅ Fresh sources"
            subtitle="Count of source tables with Success results"
            value={(
              getSingleValueChartString(
                freshSourcesData?.cube?.[0]?.sourceStatusDaily?.sourcesDistinctCount || 0,
              )
            )}
            isLoading={isLoadingFreshSources}
            hasNoData={freshSourcesError || freshSourcesData?.cube?.length === 0}
          />
        </QuarterPageChartSection>
        <QuarterPageChartSection height={singleValueChartHeight}>
          <SingleValueChart
            title="⚠️ Warn sources"
            subtitle="Count of source tables with Warn results"
            value={(
              getSingleValueChartString(
                warnSourcesData?.cube?.[0]?.sourceStatusDaily?.sourcesDistinctCount || 0,
              )
            )}
            isLoading={isLoadingWarnSources}
            hasNoData={warnSourcesError || warnSourcesData?.cube?.length === 0}
          />
        </QuarterPageChartSection>
        <QuarterPageChartSection height={singleValueChartHeight}>
          <SingleValueChart
            title="🚨 Stale Sources"
            subtitle="Count of source tables with Stale results"
            value={(
              getSingleValueChartString(
                staleSourcesData?.cube?.[0]?.sourceStatusDaily?.sourcesDistinctCount || 0,
              )
            )}
            isLoading={isLoadingStaleSources}
            hasNoData={staleSourcesError || staleSourcesData?.cube?.length === 0}
          />
        </QuarterPageChartSection>
        <QuarterPageChartSection height={singleValueChartHeight}>
          <SingleValueChart
            title="❌ Errored sources"
            subtitle="Count of source tables with Runtime errors"
            value={(
              getSingleValueChartString(
                erroredSourcesData?.cube?.[0]?.sourceStatusDaily?.sourcesDistinctCount || 0,
              )
            )}
            isLoading={isLoadingErroredSources}
            hasNoData={erroredSourcesError || erroredSourcesData?.cube?.length === 0}
          />
        </QuarterPageChartSection>
        <FullPageChartSection style={{ height: '100%' }}>
          <TreeTableChart
            title="Current sources tables status details"
            subtitle="View source tables details and the current freshness status"
            isLoading={isLoadingDailySourceStatus}
            hasNoData={dailySourceStatusError || dailySourceStatusData?.cube?.length === 0}
            columns={dailySourceStatusColumns}
            data={formatDailySourceStatusForChart(dailySourceStatusData)}
            rowsPerPage={5}
            togglerIconRowCollapsed="caret-right"
            togglerIconRowExpanded="caret-down"
            togglerTooltipText="Expand to see more"
          />
        </FullPageChartSection>
        <HalfPageChartSection>
          <PieChart
            title="Current sources status health"
            subtitle="Monitor the current health of your dbt™ sources"
            data={formatSourceStatusHealthForChart(sourceStatusHealthData).data}
            legendLabels={formatSourceStatusHealthForChart(sourceStatusHealthData).statuses}
            customColourScale={formatSourceStatusHealthForChart(sourceStatusHealthData).colours}
            isLoading={isLoadingSourceStatusHealth}
            hasNoData={sourceStatusHealthError || sourceStatusHealthData?.cube?.length === 0}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Source tables freshness status daily"
            subtitle="Monitor you source tables and their freshness status in your dbt™ project."
            data={formatDailyFreshnessStatusForChart(dailyFreshnessStatusHealthData).data}
            xAxisLabels={getDailyFreshnessStatusXAxisLabels(dailyFreshnessStatusHealthData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Calendar date"
            yLabel="Count tables"
            legendLabels={(
              formatDailyFreshnessStatusForChart(dailyFreshnessStatusHealthData).statuses
            )}
            customColourScale={(
              formatDailyFreshnessStatusForChart(dailyFreshnessStatusHealthData).colours
            )}
            isLoading={isLoadingDailyFreshnessStatusHealth}
            hasNoData={(
              dailyFreshnessStatusHealthError || dailyFreshnessStatusHealthData?.cube?.length === 0
            )}
            xAxisDomain={[
              0.5,
              getDailyFreshnessStatusXAxisLabels(dailyFreshnessStatusHealthData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
            showDetailsButton
          />
        </HalfPageChartSection>
        <FullPageChartSection>
          <LineChart
            title="Source freshness pass rate by source name"
            data={formatPassRateBySourceForChart(passRateBySourceData).data}
            xAxisLabels={getPassRateBySourceXAxisLabels(passRateBySourceData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick, 'standard', 'percent')}
            legendLabels={formatPassRateBySourceForChart(passRateBySourceData).sourceNames}
            xLabel="Calendar date"
            yLabel="Pass rate (%)"
            isLoading={isLoadingPassRateBySource}
            hasNoData={passRateBySourceError || passRateBySourceData?.cube?.length === 0}
            yAxisDomain={[0, 1.0]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick, 'standard', 'percent'),
            }}
            showDetailsButton
          />
        </FullPageChartSection>
      </div>
    </AutoLayout>
  );
};

export default OverviewTab;
