import React, {
  Dispatch, FC, SetStateAction, useState,
} from 'react';
import SummariseModels from './SummariseModels';
import ViewModels from './ViewModels';
import { TableRow } from '.';

export interface ModelsTableProps {
  data: TableRow[],
  setData: Dispatch<SetStateAction<TableRow[]>>,
  boltRunId?: number | null,
}

const ModelsTable: FC<ModelsTableProps> = ({
  data,
  setData,
  boltRunId,
}) => {
  const [showSummary, setShowSummary] = useState(false);

  if (showSummary) {
    return (
      <SummariseModels
        data={data}
        setData={setData}
        onGoBackClick={() => setShowSummary(false)}
      />
    );
  }

  return (
    <ViewModels
      data={data}
      setData={setData}
      atLeastOneModelIsSelected={!!data.find(({ isSelected }) => isSelected)}
      onShowSummaryClick={() => setShowSummary(true)}
      boltRunId={boltRunId}
    />
  );
};

export default ModelsTable;
