import React, {
  FC, useState, useContext,
} from 'react';
import { useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import { RadarWorkspaceContext } from '.';
import DashboardHeader from './DashboardHeader';
import Unsupported from './Unsupported';
import { userAuthStore } from '../../stores';
import {
  getRadarPagesConfig,
  upgradeImagePaths,
  supportedCostWarehouses,
  RadarTab,
} from './config';
import { capitalize } from '../../utilis';
import RadarUpgrade from '../Common/Upgrade/RadarUpgrade';
import { useWaitForLDToBeReady } from '../hooks/useWaitForLDToBeReady';
import DataAlerts from './DataAlerts';
import Schedules from './Schedules';
import Models from './Models';
import SourceFreshness from './SourceFreshness';
import Tests from './Tests';
import Work from './Work';
import Costs from './Costs';
import Setup from './Setup';
import Looker from './Looker';

export interface DashboardProps {
  isLoading?: boolean,
}

const Dashboards:FC<DashboardProps> = ({
  isLoading,
}) => {
  const { pageId } = useParams<{pageId: RadarTab}>();
  const { repoProvider, costWarehouseType } = useContext(RadarWorkspaceContext);
  const [isEditMode, setIsEditMode] = useState(false);

  const { ldIsReady } = useWaitForLDToBeReady();
  const { flagAppRadar } = useFlags();

  const config = getRadarPagesConfig()[pageId];
  const { currentUser: { accessLevel } } = userAuthStore();

  const setPage = () => {
    const costWarehouseConnected = (costWarehouseType !== 'unknown');

    // radar not enabled except for work and cost
    if (ldIsReady && !flagAppRadar) {
      return (
        <RadarUpgrade
          pageId={pageId}
          upgradePlanName="Radar Metrics"
        />
      );
    }

    // for work, repo is not github
    if (pageId === RadarTab.WORK && repoProvider !== 'github') {
      return (
        <Unsupported
          pageId={pageId}
          imageSrc={upgradeImagePaths[pageId].path}
          upgradePlanName="Team metrics"
          bodyText={`
            Team work metrics is currently not supported for ${capitalize(repoProvider || '')} repositories.
          `}
        />
      );
    }

    if (pageId === RadarTab.COST) {
      // for cost, connected warehouse for cost is not supported
      if (costWarehouseConnected && !supportedCostWarehouses.includes(costWarehouseType)) {
        return (
          <Unsupported
            pageId={pageId}
            imageSrc={upgradeImagePaths[pageId].path}
            upgradePlanName="Cost metrics"
            bodyText={`
              Cost analytics is currently not supported for ${capitalize(costWarehouseType)} warehouse.
            `}
          />
        );
      }
    }

    switch (pageId) {
      case RadarTab.SETUP:
        return <Setup />;
      case RadarTab.ALERTS:
        return <DataAlerts />;
      case RadarTab.SCHEDULES:
        return <Schedules />;
      case RadarTab.MODELS:
        return <Models />;
      case RadarTab.SOURCES:
        return <SourceFreshness />;
      case RadarTab.TESTS:
        return <Tests />;
      case RadarTab.WORK:
        return <Work />;
      case RadarTab.COST:
        return <Costs />;
      case RadarTab.BI_LOOKER:
        return <Looker />;
      default:
        return null;
    }
  };

  if (isLoading) return <div style={{ marginTop: '200px' }}><Spinner /></div>;

  return (
    <>
      <AutoLayout
        direction="vertical"
        padding="none"
        distribution="packed"
        alignment="top-left"
        verticalGap="normal"
        wrapperWidth="full"
      >
        <DashboardHeader
          customDashboardsIsEnabled
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          accessLevel={accessLevel}
          hasPaywall={!flagAppRadar}
          {...config}
        />
        {setPage()}
      </AutoLayout>
    </>
  );
};

export default Dashboards;
