import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { SYNQ_ONBOARDING_PAGE, SynqOnboardingRoutePrefix } from '../Onboarding/SynqOnboarding';

const JoinOrCreate: FC = () => {
  const history = useHistory();

  const { location: { href } } = window;
  const url = new URL(href);
  const isTargettingChromeExtension = url.searchParams.get('target') === 'chrome-extension';

  const handleClick = (type: 'join' | 'create') => {
    url.searchParams.set('is_signup', (type === 'create').toString());

    let nextPageUrl = '/enter-organisation';
    if (isTargettingChromeExtension && type === 'create') {
      nextPageUrl = `/${SynqOnboardingRoutePrefix}/${SYNQ_ONBOARDING_PAGE.ENTER_ORG}`;
    }
    history.push(`${nextPageUrl}?${url.searchParams.toString()}`);
  };

  return (
    <AutoLayout
      direction="horizontal"
      horizontalGap="expanded"
      padding="dense"
      alignment="center"
    >
      <DefaultButton
        type="standard"
        view="simple"
        color="primary_alt"
        text="Get started"
        fill={false}
        onClick={() => handleClick('create')}
        eventContext={Contexts.ONBOARDING}
        eventObject="createNewCopmany"
        eventAction={Actions.CLICKED}
        style={{ minWidth: '250px', textDecoration: 'none' }}
      />
      <DefaultButton
        type="standard"
        view="simple"
        color="primary"
        text="Join your team"
        fill={false}
        onClick={() => handleClick('join')}
        eventContext={Contexts.ONBOARDING}
        eventObject="createNewCopmany"
        eventAction={Actions.CLICKED}
        style={{ minWidth: '250px', textDecoration: 'none' }}
      />
    </AutoLayout>
  );
};

export default JoinOrCreate;
