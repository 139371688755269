import { useEffect, useState } from 'react';
import { DocumentNode } from 'graphql';
import { ApolloQueryResult } from '@apollo/client';
import { graphQLStore } from '../../stores';

export interface useHasuraLazyQueryProps<ReturnDataType> {
  query: DocumentNode,
  variables?: any,
  onCompleted?: (response: ApolloQueryResult<ReturnDataType>) => void,
}

interface useHasuraQueryReturnType<ReturnDataType> {
  data?: ReturnDataType,
  loading: boolean,
}

type useHasuraLazyQueryReturnType<ReturnDataType> = [
  () => void,
  useHasuraQueryReturnType<ReturnDataType>,
]

export const useHasuraLazyQuery = <ReturnDataType, >({
  query,
  variables,
  onCompleted,
}: useHasuraLazyQueryProps<ReturnDataType>): useHasuraLazyQueryReturnType<ReturnDataType> => {
  const [triggerTheQuery, setTriggerTheQuery] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState<ReturnDataType>();

  const hasuraClient = graphQLStore((s) => s.hasuraClient);

  useEffect(() => {
    if (hasuraClient && triggerTheQuery) {
      setLoading(true);

      hasuraClient
        .query({ query, variables })
        .then((response: ApolloQueryResult<ReturnDataType>) => {
          setResponseData(response.data);

          if (onCompleted) onCompleted(response);

          setLoading(false);
        });
    }
  }, [hasuraClient, triggerTheQuery]);

  return [
    () => setTriggerTheQuery(true),
    { data: responseData, loading },
  ];
};

export const useHasuraQuery = <ReturnDataType, >({
  query,
  variables,
  onCompleted,
}: useHasuraLazyQueryProps<ReturnDataType>): useHasuraQueryReturnType<ReturnDataType> => {
  const [triggerQuery, reponseDetails] = useHasuraLazyQuery<ReturnDataType>({
    query,
    variables,
    onCompleted,
  });

  useEffect(() => {
    triggerQuery();
  }, []);

  return reponseDetails;
};
