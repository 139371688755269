import React, { FC, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Loader from '@paradime-io/pragma-ui-kit/lib/components/Loader';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import { useHistory } from 'react-router-dom';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import DataTable from '@paradime-io/pragma-ui-kit/lib/components/PrimeReactDataTable';
import { SideGraphicType } from '@paradime-io/pragma-ui-kit/lib/components/TextWithIcon';
import { useMarkStepCompleted } from '@paradime/common/lib/common/helpers/frigadeHelperFunctions';
import {
  formatDateTimeToLocalString,
  getTodayIsoString,
  findTimeBetween,
  getHumanReadableCron,
} from '../../../utilis';
import {
  useGetScheduleNamesQuery,
  GetScheduleNamesQuery,
  GetScheduleRunQuery,
  GqlScheduleTrigger,
} from '../../../client/generated/service';
import Errors from './Errors';
import { BoltHelp } from '../../Common/AppHelp/help';
import NoSchedulesZeroState from './NoSchedulesZeroState';
import BoltActionBar from './ActionBar';
import { getScheduleType, getScheduleState } from '../utils';
import { ScheduleType } from '..';
import NoSearchResultsZeroState from './NoSearchResultsZeroState';
import DeferToProdSetting from './DeferToProdSetting';
import useGetColumnsForScheduleTable from './useGetColumnsForScheduleTable';
import { RadarFlows } from '../../Common/Frigade/flows';
import { RadarSetupSteps } from '../../Radar/Setup';
import BoltSchedulesUpgrade from '../../Common/Upgrade/BoltSchedulesUpgrade';
import ParseSchedules from './ParseSchedules';
import { useWaitForLDToBeReady } from '../../hooks/useWaitForLDToBeReady';
import { getOnCompletionRunInfoText } from './utils';

export type ScheduleName = NonNullable<NonNullable<NonNullable<GetScheduleNamesQuery['getScheduleNames']>['scheduleNames']>[number]>;
export type RunErrorType = NonNullable<GetScheduleRunQuery['getScheduleRun']>['errorDetails'];
export type GlobalErrorType = NonNullable<GetScheduleRunQuery['getScheduleRun']>['globalErrorDetails'];

const ScheduleList: FC = () => {
  const { markStepCompleted } = useMarkStepCompleted(RadarFlows.SETUP_FLOW);

  const [scheduleNames, setScheduleNames] = useState<ScheduleName[]>();
  const [showInactive, setShowInactive] = useState(false);
  const [globalError, setGlobalError] = useState<GlobalErrorType | null>();
  const [filteredSchedules, setFilteredSchedules] = useState<ScheduleName[]>([]);
  const RESULTS_PER_PAGE = 1000;

  const history = useHistory();
  const { columns } = useGetColumnsForScheduleTable();
  const { ldIsReady } = useWaitForLDToBeReady();
  const { flagAppBolt } = useFlags();

  useGetScheduleNamesQuery({
    variables: {
      limit: RESULTS_PER_PAGE,
      offset: 0,
      showInactive,
    },
    onCompleted: (data) => {
      if (data.getScheduleNames?.scheduleNames) {
        const scheduleNamesData = data
          .getScheduleNames
          .scheduleNames
          .filter((schedule) => schedule && schedule?.state.text !== 'Removing')
          .map((schedule) => {
            if (schedule?.suspended) {
              return {
                ...schedule,
                state: { text: 'Paused', colorType: 'default' },
                nextRun: null,
              };
            }
            return schedule;
          }) as ScheduleName[];

        setScheduleNames(scheduleNamesData);
        setFilteredSchedules(scheduleNamesData);
        setGlobalError(data.getScheduleNames.globalErrorDetails);

        if (data.getScheduleNames.scheduleNames.length > 0) {
          markStepCompleted(RadarSetupSteps.CREATE_BOLT_SCHEDULE);
        }
      }
    },
  });

  const getScheduleName = (schedule: ScheduleName) => {
    if (schedule.source === 'yaml') {
      return {
        text: schedule.name,
        leftSideGraphic: { type: SideGraphicType.ICON, iconName: 'git-branch' },
      };
    }

    return { text: schedule.name };
  };

  const getTriggerType = (scheduleTrigger: GqlScheduleTrigger) => (scheduleTrigger ? 'On run completion' : 'On schedule');

  const getOnCompletionConfiguration = (scheduleTrigger: GqlScheduleTrigger) => {
    if (scheduleTrigger) {
      return getOnCompletionRunInfoText(scheduleTrigger);
    }
    return '-';
  };

  const todayIsoString = getTodayIsoString();

  const mapDataToColumns = (data: ScheduleName[]) => (
    data.map((schedule) => {
      const {
        owner, lastRun, nextRun, schedule: cron, scheduleTrigger,
      } = schedule;
      return ({
        name: getScheduleName(schedule),
        status: {
          tag: getScheduleState(schedule).text,
          color: getScheduleState(schedule).color,
        },
        owner: owner || '-',
        cronDescription: scheduleTrigger ? '-' : getHumanReadableCron(cron),
        cronConfig: scheduleTrigger ? '-' : cron,
        lastRun: formatDateTimeToLocalString({ dateTimeString: lastRun }),
        timeSinceLastRun: findTimeBetween({ startDate: lastRun, endDate: todayIsoString }),
        nextRun: formatDateTimeToLocalString({ dateTimeString: nextRun }),
        untilNextRun: findTimeBetween({ startDate: todayIsoString, endDate: nextRun }),
        scheduleType: getScheduleType(schedule as ScheduleType),
        triggerType: getTriggerType(scheduleTrigger as GqlScheduleTrigger),
        onCompletionConfiguration: getOnCompletionConfiguration(
          scheduleTrigger as GqlScheduleTrigger,
        ),
      });
    })
  );

  const getSelectedSchedule = (scheduleName: string) => filteredSchedules.find(
    (schedule) => scheduleName === schedule.name,
  );

  if (scheduleNames === undefined) {
    return <Loader fit />;
  }

  if (ldIsReady && !flagAppBolt) {
    return <BoltSchedulesUpgrade />;
  }

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      distribution="packed"
      alignment="top-left"
      verticalGap="normal"
      wrapperWidth="full"
    >
      <AutoLayout
        direction="horizontal"
        padding="none"
        distribution="space-between"
        style={{ alignItems: 'center' }}
      >
        <AutoLayout
          direction="horizontal"
          padding="none"
          distribution="packed"
          horizontalGap="very-dense"
        >
          <Typography type="h6">Schedules</Typography>
          <Chips
            color="primary_alt"
            round={false}
            style={{ margin: '4px', verticalAlign: 'bottom', justifySelf: 'end' }}
            tag="Get help with the UI"
            type="dense"
            view="smooth"
            onClick={() => (
              window.CommandBar.openHelpHub(
                { articleId: BoltHelp.BOLT_HELP_UI },
              )
            )}
          />
        </AutoLayout>
        <ParseSchedules />
      </AutoLayout>
      <Errors globalError={globalError} />

      <DeferToProdSetting />

      {scheduleNames.length === 0 && <NoSchedulesZeroState isInactive={showInactive} />}

      {scheduleNames.length > 0 && filteredSchedules.length === 0 && (
        <NoSearchResultsZeroState />
      )}

      {filteredSchedules.length > 0 && (
        <DataTable
          columns={columns.map((col) => ({ ...col, sortable: true }))}
          data={mapDataToColumns(filteredSchedules)}
          onRowClick={(e) => {
            const selectedSchedule = getSelectedSchedule(e.data.name.text);
            if (selectedSchedule) {
              history.push(`/bolt/${selectedSchedule.meta.uuid}`);
            }
          }}
          allowColumnCustomisation
          tableHeader={(
            <BoltActionBar
              showInactive={showInactive}
              setShowInactive={setShowInactive}
            />
          )}
          showClearFiltersButton
          usePagination={filteredSchedules.length > 20}
          rowsPerPage={20}
          rowsPerPageOptions={[5, 10, 20, 50]}
          eventContext={Contexts.LOGS}
        />
      )}

      {
        filteredSchedules.length > 0 && (
          <Typography
            font="inter"
            type="body-bold-small"
            color="default"
            colorStep="40"
            style={{ margin: 'auto' }}
          >
            {`${filteredSchedules.length} schedule${filteredSchedules.length > 1 ? 's' : ''}`}
          </Typography>
        )
      }
    </AutoLayout>
  );
};

export default ScheduleList;
