import { gql } from '@apollo/client';
import { ColumnType } from '@paradime-io/pragma-ui-kit/lib/components/PrimeReactDataTable';
import { freshnessStatuses, getStatusColour, getTableChips } from '../utils';
import { key } from '../../../utilis';

export interface freshSourcesReturnType {
  cube: {
    sourceStatusDaily: {
      sourcesDistinctCount: number,
    }
  }[]
}

export const freshSourcesQuery = gql`
  query freshSourcesQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {source_status_daily: {is_source_current_status: {equals: "true"}, sources_table_status: {equals: "pass"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      sourceStatusDaily: source_status_daily {
        sourcesDistinctCount: sources_distinct_count
      }
    }
  }
`;

export interface warnSourcesReturnType {
  cube: {
    sourceStatusDaily: {
      sourcesDistinctCount: number,
    }
  }[]
}

export const warnSourcesQuery = gql`
  query warnSourcesQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {source_status_daily: {is_source_current_status: {equals: "true"}, sources_table_status: {equals: "warn"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      sourceStatusDaily: source_status_daily {
        sourcesDistinctCount: sources_distinct_count
      }
    }
  }
`;

export interface staleSourcesReturnType {
  cube: {
    sourceStatusDaily: {
      sourcesDistinctCount: number,
    }
  }[]
}

export const staleSourcesQuery = gql`
  query staleSourcesQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {source_status_daily: {is_source_current_status: {equals: "true"}, sources_table_status: {equals: "error"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      sourceStatusDaily: source_status_daily {
        sourcesDistinctCount: sources_distinct_count
      }
    }
  }
`;

export interface erroredSourcesReturnType {
  cube: {
    sourceStatusDaily: {
      sourcesDistinctCount: number,
    }
  }[]
}

export const erroredSourcesQuery = gql`
  query erroredSourcesQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {source_status_daily: {is_source_current_status: {equals: "true"}, sources_table_status: {equals: "runtime error"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      sourceStatusDaily: source_status_daily {
        sourcesDistinctCount: sources_distinct_count
      }
    }
  }
`;

export interface dailySourceStatusReturnType {
  cube: {
    sourceStatusDaily: {
      sourcesDistinctCount: number,
      sourceLoaderName: string,
      sourceName: string,
      sourceTableName: string,
      sourcesTableStatus: string,
    }
  }[]
}

export const dailySourceStatusQuery = gql`
  query dailySourceStatusQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {source_status_daily: {is_source_current_status: {equals: "true"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      sourceStatusDaily: source_status_daily {
        sourcesDistinctCount: sources_distinct_count
        sourceLoaderName: source_loader_name
        sourceName: source_name
        sourceTableName: source_table_name
        sourcesTableStatus: sources_table_status
      }
    }
  }
`;

export const dailySourceStatusColumns = [
  { field: 'sourceName', header: 'Source name', expander: true },
  { field: 'sourceLoaderName', header: 'Source loader' },
  { field: 'sourcesDistinctCount', header: 'Tables count' },
  { field: 'sourcesTableStatus', header: 'Source table status', type: ColumnType.CHIP },
].map((col) => ({ ...col, sortable: true }));

export const formatDailySourceStatusForChart = (data?: dailySourceStatusReturnType) => {
  if (!data) return [];

  const sourceNames = Array.from(new Set(
    data.cube.map(({ sourceStatusDaily }) => sourceStatusDaily.sourceName),
  ));

  const dataToReturn: { key: string, data: any, children?: any[] }[] = [];

  sourceNames.forEach((sourceName) => {
    const dataInThisGroup = data?.cube
      .filter(({ sourceStatusDaily }) => sourceStatusDaily.sourceName === sourceName);

    const sourceLoaderName = Array.from(new Set(
      dataInThisGroup.map(({ sourceStatusDaily }) => (
        sourceStatusDaily.sourceLoaderName
      )),
    )).join(', ');

    const sourcesDistinctCount = dataInThisGroup?.reduce((currentTotal, datum) => (
      currentTotal + datum.sourceStatusDaily.sourcesDistinctCount
    ), 0) || 0;

    const statuses = Array.from(new Set(
      dataInThisGroup.map(({ sourceStatusDaily }) => (
        sourceStatusDaily.sourcesTableStatus
      )),
    ));

    const sourcesTableStatus = statuses.map((status) => {
      const count = dataInThisGroup
        .filter(({ sourceStatusDaily }) => sourceStatusDaily.sourcesTableStatus === status)
        .length;

      return getTableChips(status, count);
    });

    const childRows = dataInThisGroup
      .map(({ sourceStatusDaily }) => ({
        key: key(sourceStatusDaily.sourceTableName),
        data: {
          sourceName: sourceStatusDaily.sourceTableName,
          sourcesDistinctCount: sourceStatusDaily.sourcesDistinctCount,
          sourcesTableStatus: getTableChips(sourceStatusDaily.sourcesTableStatus),
        },
      }));

    const formattedData = {
      key: key(sourceName),
      data: {
        sourceName,
        sourceLoaderName,
        sourcesDistinctCount,
        sourcesTableStatus,
      },
      children: childRows,
    };

    dataToReturn.push(formattedData);
  });

  return dataToReturn;
};

export interface sourceStatusHealthReturnType {
  cube: {
    sourceStatusDaily: {
      sourcesDistinctCount: number,
      sourcesTableStatus: 'pass' | 'error',
    }
  }[]
}

export const sourceStatusHealthQuery = gql`
  query sourceStatusHealthQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {source_status_daily: {is_source_current_status: {equals: "true"}, calendar_date: {inDateRange: $timeframe}}}
    ) {
      sourceStatusDaily: source_status_daily {
        sourcesDistinctCount: sources_distinct_count
        sourcesTableStatus: sources_table_status
      }
    }
  }
`;

export const formatSourceStatusHealthForChart = (data?: sourceStatusHealthReturnType) => {
  if (!data) return { data: [], statuses: [], colours: [] };

  const dataToReturn: { x: string, y: number }[] = [];
  const legendLabels: string[] = [];
  const legendColours: string[] = [];

  freshnessStatuses.forEach((status) => {
    const tests = data.cube
      .filter(({ sourceStatusDaily }) => sourceStatusDaily.sourcesTableStatus === status)
      .map(({ sourceStatusDaily }) => ({
        x: sourceStatusDaily.sourcesTableStatus,
        y: sourceStatusDaily.sourcesDistinctCount,
      }));

    if (tests.length > 0) {
      dataToReturn.push(...tests);
      legendLabels.push(status);
      legendColours.push(getStatusColour(status));
    }
  });

  return { data: dataToReturn, statuses: legendLabels, colours: legendColours };
};

export interface dailyFreshnessStatusReturnType {
  cube: {
    sourceStatusDaily: {
      sourcesDistinctCount: number,
      sourcesTableStatus: 'error' | 'pass',
      calendarDate: {
        day: string,
      }
    }
  }[]
}

export const dailyFreshnessStatusQuery = gql`
  query dailyFreshnessStatusQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {source_status_daily: {calendar_date: {inDateRange: $timeframe}}}
    ) {
      sourceStatusDaily: source_status_daily(orderBy: {calendar_date: asc}) {
        sourcesDistinctCount: sources_distinct_count
        sourcesTableStatus: sources_table_status
        calendarDate: calendar_date {
          day
        }
      }
    }
  }
`;

export const formatDailyFreshnessStatusForChart = (data?: dailyFreshnessStatusReturnType) => {
  if (!data) return { data: [], statuses: [], colours: [] };

  const dataToReturn: { x: string, y: number }[][] = [];
  const legendLabels: string[] = [];
  const legendColours: string[] = [];

  freshnessStatuses.forEach((status) => {
    const tests = data.cube
      .filter(({ sourceStatusDaily }) => sourceStatusDaily.sourcesTableStatus === status)
      .map(({ sourceStatusDaily }) => ({
        x: sourceStatusDaily.calendarDate.day,
        y: sourceStatusDaily.sourcesDistinctCount,
      }));

    if (tests.length > 0) {
      dataToReturn.push(tests);
      legendLabels.push(status);
      legendColours.push(getStatusColour(status));
    }
  });

  return { data: dataToReturn, statuses: legendLabels, colours: legendColours };
};

export const getDailyFreshnessStatusXAxisLabels = (data?: dailyFreshnessStatusReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ sourceStatusDaily }) => sourceStatusDaily.calendarDate.day);
  return Array.from(new Set(dates));
};

export interface passRateBySourceReturnType {
  cube: {
    sourceStatusDaily: {
      successRate: number,
      sourceName: string,
      calendarDate: {
        day: string,
      }
    }
  }[]
}

export const passRateBySourceQuery = gql`
  query passRateBySourceQuery($timeframe: [String]) {
    cube(
      limit: 5000
      where: {source_status_daily: {calendar_date: {inDateRange: $timeframe}}}
    ) {
      sourceStatusDaily: source_status_daily(orderBy: {calendar_date: asc}) {
        successRate: success_rate
        sourceName: source_name
        calendarDate: calendar_date {
          day
        }
      }
    }
  }
`;

export const formatPassRateBySourceForChart = (data?: passRateBySourceReturnType) => {
  if (!data) return { data: [], sourceNames: [] };

  const sourceNames = Array.from(new Set(
    data.cube.map(({ sourceStatusDaily }) => sourceStatusDaily.sourceName),
  ));

  const dataToReturn: { x: string, y: number }[][] = [];

  sourceNames.forEach((sourceName) => {
    const formattedData = data.cube
      .filter(({ sourceStatusDaily }) => sourceStatusDaily.sourceName === sourceName)
      .map(({ sourceStatusDaily }) => ({
        x: sourceStatusDaily.calendarDate.day,
        y: sourceStatusDaily.successRate,
      }));

    dataToReturn.push(formattedData);
  });

  return { data: dataToReturn, sourceNames };
};

export const getPassRateBySourceXAxisLabels = (data?: passRateBySourceReturnType) => {
  if (!data) return [];

  const dates = data.cube
    .map(({ sourceStatusDaily }) => sourceStatusDaily.calendarDate.day);
  return Array.from(new Set(dates));
};
