import React, { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { StackedBarChart, TableChart } from '@paradime-io/pragma-ui-kit/lib/components/VictoryCharts';
import { useCubeLazyQuery } from '../../../app/hooks/useCubeQuery';
import {
  activeDashboardUsageQuery,
  activeDashboardUsageReturnType,
  activeDashboardUsageColumns,
  formatActiveDashboardUsageForChart,
  top10SpendQuery,
  top10SpendReturnType,
  formatTop10SpendForChart,
  getTop10SpendXAxisLabels,
  dailySpendQuery,
  dailySpendReturnType,
  formatDailySpendForChart,
  getDailySpendXAxisLabels,
} from './DashboardsCubeQueries';
import {
  timeframes,
  HalfPageChartSection,
  FullPageChartSection,
} from '../utils';
import FilterRow from '../FilterRow';
import { useGetFiltersFromUrl } from '../hooks/useGetFiltersFromUrl';

const DashboardsTab: FC = () => {
  const { getInitialFilterValue } = useGetFiltersFromUrl();

  const [timeframe, setTimeframe] = useState(getInitialFilterValue('timeframe', timeframes[2].value)!);

  const [
    getActiveDashboardUsage, {
      data: activeDashboardUsageData,
      loading: isLoadingActiveDashboardUsage,
      error: activeDashboardUsageError,
    },
  ] = useCubeLazyQuery<activeDashboardUsageReturnType>({
    query: activeDashboardUsageQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getTop10Spend, { data: top10SpendData, loading: isLoadingTop10Spend, error: top10SpendError },
  ] = useCubeLazyQuery<top10SpendReturnType>({
    query: top10SpendQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getDailySpend, { data: dailySpendData, loading: isLoadingDailySpend, error: dailySpendError },
  ] = useCubeLazyQuery<dailySpendReturnType>({
    query: dailySpendQuery,
    variables: { timeframe: timeframe.value },
  });

  const refetchAllData = () => {
    getActiveDashboardUsage();
    getTop10Spend();
    getDailySpend();
  };

  useEffect(() => {
    refetchAllData();
  }, [timeframe]);

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      verticalGap="dense"
      distribution="packed"
    >
      <FilterRow
        dropdowns={[
          {
            value: timeframe,
            options: timeframes,
            onChange: setTimeframe,
            name: 'timeframe',
            title: 'Select date range',
          },
        ]}
      />
      <div style={{
        display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: '16px',
      }}
      >
        <HalfPageChartSection>
          <StackedBarChart
            title="Daily Dashboards spend"
            data={formatDailySpendForChart(dailySpendData)}
            xAxisLabels={getDailySpendXAxisLabels(dailySpendData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            xLabel="calendar date"
            yLabel="Total query cost in $"
            isLoading={isLoadingDailySpend}
            hasNoData={dailySpendError || dailySpendData?.cube?.length === 0}
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Top 10 Looker dashboards by query spend"
            horizontal
            data={formatTop10SpendForChart(top10SpendData)}
            xAxisLabels={getTop10SpendXAxisLabels(top10SpendData)}
            xLabel="Dashboard title"
            yLabel="Total query cost in $"
            isLoading={isLoadingTop10Spend}
            hasNoData={top10SpendError || top10SpendData?.cube?.length === 0}
          />
        </HalfPageChartSection>
        <FullPageChartSection style={{ height: '100%' }}>
          <TableChart
            title="Active Looker Dashboard usage"
            isLoading={isLoadingActiveDashboardUsage}
            hasNoData={activeDashboardUsageError || activeDashboardUsageData?.cube?.length === 0}
            columns={activeDashboardUsageColumns}
            data={formatActiveDashboardUsageForChart(activeDashboardUsageData)}
            rowsPerPage={5}
          />
        </FullPageChartSection>
      </div>
    </AutoLayout>
  );
};

export default DashboardsTab;
