import React, { FC } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useHistory } from 'react-router-dom';
import { Contexts, Actions } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { userAuthStore } from '../../../../stores';
import { GqlParadimeAccountType } from '../../../../client/generated/service';
import { userHasBoltCreateScheduleAccess } from '../../../../utilis/PermissionsService';

const CreateScheduleButton: FC = () => {
  const history = useHistory();
  const { flagBoltAllowScheduleCreateFromUi } = useFlags();

  const { currentUser } = userAuthStore();
  const { accessLevel } = currentUser as { accessLevel: GqlParadimeAccountType };
  const isRoleWhoCanCreateNewSchedule = userHasBoltCreateScheduleAccess(accessLevel);

  const handleAddNewSchedule = () => {
    history.push('/bolt/template-schedules');
  };

  if (flagBoltAllowScheduleCreateFromUi) {
    return (
      <DefaultButton
        type="standard"
        color="primary"
        view="filled"
        icon="plus"
        dense
        disabled={!isRoleWhoCanCreateNewSchedule}
        text="New Schedule"
        eventContext={Contexts.LOGS}
        eventObject="addNewSchedule"
        eventAction={Actions.CLICKED}
        onClick={handleAddNewSchedule}
        tooltipConfig={!isRoleWhoCanCreateNewSchedule ? {
          content: 'Contact your admin to request access to this action',
        } : undefined}
      />
    );
  }

  return null;
};

export default CreateScheduleButton;
