import React, { FC } from 'react';
import Dialog from '@paradime-io/pragma-ui-kit/lib/components/Dialog';
import DefaultInput from '@paradime-io/pragma-ui-kit/lib/components/DefaultInput';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';

export interface SaveDialogProps {
  isOpen: boolean;
  shareUrl: string;
  onLeftButtonClick: () => void;
  onRightButtonClick: (title: string) => void;
  onClose: () => void;
}

const SaveDialog: FC<SaveDialogProps> = ({
  isOpen,
  shareUrl,
  onLeftButtonClick,
  onRightButtonClick,
  onClose,
}) => {
  const [title, setTitle] = React.useState('');

  return (
    <Dialog
      isOpen={isOpen}
      type="dense"
      view="raised"
      color="default"
      title="Save your recording"
      showLeftButton
      leftButtonText="Cancel"
      showRightButton
      rightButtonText="Save"
      onLeftButtonClick={onLeftButtonClick}
      onRightButtonClick={() => onRightButtonClick(title)}
      onClose={onClose}
      leftButtonEventData={{
        eventContext: Contexts.EXTENSION,
        eventObject: 'cancelRecording',
        eventAction: Actions.CANCELLED,
      }}
      rightButtonEventData={{
        eventContext: Contexts.EXTENSION,
        eventObject: 'saveRecording',
        eventAction: Actions.SAVED,
      }}
    >
      <DefaultInput
        clearIcon="cross"
        color="default"
        label="Recording title"
        type="text"
        view="outlined"
        dense
        full
        value={title}
        expose={(e) => {
          setTitle(e.target.value);
        }}
      />
      <DefaultInput
        clearIcon="cross"
        color="default"
        label="Recording URL"
        type="text"
        view="outlined"
        dense
        full
        value={shareUrl}
        disabled
      />
    </Dialog>
  );
};

export default SaveDialog;
