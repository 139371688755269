import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';
import { ScheduleType } from '../..';
import { userAuthStore } from '../../../../stores';
import useGetSchedulesAndBranches from '../../hooks/useGetSchedulesAndBranches';
import dbtLogo from '../../../AccountSettings/Integrations/images/dbt.svg';
import elementaryLogo from '../../../AccountSettings/Integrations/images/elementary.svg';

export enum TemplateCategory {
  STANDARD = 'Standard run',
  DEFERRED = 'Deferred run',
  TURBO_CI = 'Turbo CI',
  ELEMENTARY = 'Elementary',
}

export const useGetTemplateData = () => {
  const [templateSchedule, setTemplateSchedule] = useState<ScheduleType>();
  const [elementaryCalloutSetupSteps, setElementaryCalloutSetupSteps] = useState<string[]>([]);
  const [elementaryCalloutHelpLink, setElementaryCalloutHelpLink] = useState('');

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const templateId = searchParams.get('template');

  const { isTurboCiDefined } = useGetSchedulesAndBranches();

  const currentUser = userAuthStore((s) => s.currentUser);
  const notifyCurrentUser = [currentUser.email];

  const deDuplicatedScheduleName = (scheduleName: string) => {
    const now = DateTime.now().toFormat('yyyy-LL-dd hh:mm:ss');
    return `${scheduleName} ${now}`;
  };

  const defaultCron = '0 */2 * * *';
  const defaultNotifyOn = ['failed', 'passed'];

  const templateData = [
    {
      id: 'source-freshness',
      icon: dbtLogo,
      name: 'Snapshot source data freshness',
      category: TemplateCategory.STANDARD,
      description: 'Use this template to check the freshness of your data sources.',
      scheduleName: 'source data freshness check',
      scheduleCommands: ['dbt source freshness'],
      scheduleGitBranch: '', // Empty will use the default branch
      scheduleCron: defaultCron,
      scheduleEmailOn: defaultNotifyOn,
      scheduleEmailNotify: notifyCurrentUser,
    },
    {
      id: 'run-test-all-models',
      icon: dbtLogo,
      name: 'Run and test all your dbt™️ models',
      category: TemplateCategory.STANDARD,
      description: 'Use this template to execute and test all the dbt™️ models in your project.',
      scheduleName: 'run all models',
      scheduleCommands: ['dbt run', 'dbt test'],
      scheduleGitBranch: '', // Empty will use the default branch
      scheduleCron: defaultCron,
      scheduleEmailOn: defaultNotifyOn,
      scheduleEmailNotify: notifyCurrentUser,
    },
    {
      id: 'turbo-ci',
      icon: dbtLogo,
      name: 'Test code changes on pull request opening',
      category: TemplateCategory.TURBO_CI,
      description: 'Use this template to run and test modified models and their downstream dependencies when your pull request opens.',
      disabled: isTurboCiDefined,
      tooltip: isTurboCiDefined ? 'This workspace may only have one active Turbo CI schedule.' : undefined,
      scheduleName: 'turbo ci run',
      scheduleCommands: ['dbt build --select state:modified+'],
      scheduleGitBranch: '', // Empty will use the default branch
      scheduleCron: 'OFF',
      scheduleEmailOn: defaultNotifyOn,
      scheduleEmailNotify: notifyCurrentUser,
      isTurboCi: true,
    },
    {
      id: 'deferred',
      icon: dbtLogo,
      name: 'Build and test models with new source data',
      category: TemplateCategory.DEFERRED,
      description: 'Use this template to execute models only when new data has landed in your source tables and reduce unnecessary model runs.',
      scheduleName: 'dbt models production run',
      scheduleCommands: ['dbt source freshness', 'dbt build --select source_status:fresher+ state:modified+ result:error+ result:fail+'],
      scheduleGitBranch: '', // Empty will use the default branch
      scheduleCron: defaultCron,
      scheduleEmailOn: defaultNotifyOn,
      scheduleEmailNotify: notifyCurrentUser,
      isDeferred: true,
    },
    {
      id: 'elementary-slack',
      icon: elementaryLogo,
      name: 'Send Elementary alerts to Slack',
      category: TemplateCategory.ELEMENTARY,
      description: 'Use this template to send Elementary alerts to a Slack channel in your Slack workspace.',
      scheduleName: 'elementary slack alerts',
      scheduleCommands: ['dbt test', 'edr monitor --slack-token $SLACK_TOKEN --slack-channel-name <slack_channel_to_post_at>'],
      scheduleGitBranch: '', // Empty will use the default branch
      scheduleCron: defaultCron,
      scheduleEmailOn: defaultNotifyOn,
      scheduleEmailNotify: notifyCurrentUser,
      elementaryCalloutSetupSteps: [
        'configure the $SLACK_TOKEN environment variable',
        'replace <slack_channel_to_post_at> with the Slack channel name',
      ],
      elementaryCalloutHelpLink: 'https://docs.paradime.io/app-help/integrations/elementary-data/sending-alerts/slack-alerts',
    },
    {
      id: 'elementary-teams',
      icon: elementaryLogo,
      name: 'Send Elementary alerts to MS Teams',
      category: TemplateCategory.ELEMENTARY,
      description: 'Use this template to send Elementary alerts to a MS teams channel.',
      scheduleName: 'elementary MS teams alerts',
      scheduleCommands: ['dbt test', 'edr monitor --teams-webhook $CHANNEL_WEBHOOK'],
      scheduleGitBranch: '', // Empty will use the default branch
      scheduleCron: defaultCron,
      scheduleEmailOn: defaultNotifyOn,
      scheduleEmailNotify: notifyCurrentUser,
      elementaryCalloutSetupSteps: [
        'configure the $CHANNEL_WEBHOOK environment variable',
      ],
      elementaryCalloutHelpLink: 'https://docs.paradime.io/app-help/integrations/elementary-data/sending-alerts/microsoft-teams-alerts',
    },
  ];

  const formatScheduleData = (scheduleData: any): ScheduleType => ({
    name: deDuplicatedScheduleName(scheduleData?.scheduleName),
    deferredSchedule: scheduleData?.isDeferred
      ? { enabled: true, deferredScheduleName: scheduleData?.scheduleName }
      : undefined,
    turboCi: scheduleData?.isTurboCi
      ? { enabled: true, deferredManifestSchedule: scheduleData?.scheduleName }
      : undefined,
    commands: scheduleData?.scheduleCommands,
    gitBranch: scheduleData?.scheduleGitBranch,
    owner: undefined,
    schedule: scheduleData?.scheduleCron,
    slackOn: undefined,
    slackNotify: undefined,
    emailOn: scheduleData?.scheduleEmailOn,
    emailNotify: scheduleData?.scheduleEmailNotify,
    scheduleTrigger: undefined,
    source: 'ui',
  });

  const getScheduleTemplate = () => {
    if (!templateId) return undefined;

    const selectedTemplate = templateData.find(({ id }) => id?.toString() === templateId);
    if (!selectedTemplate) return undefined;

    if (selectedTemplate?.elementaryCalloutSetupSteps) {
      setElementaryCalloutSetupSteps(selectedTemplate.elementaryCalloutSetupSteps);
    }
    if (selectedTemplate?.elementaryCalloutHelpLink) {
      setElementaryCalloutHelpLink(selectedTemplate.elementaryCalloutHelpLink);
    }

    return formatScheduleData(selectedTemplate);
  };

  useEffect(() => {
    setTemplateSchedule(getScheduleTemplate());
  }, [templateId]);

  return {
    templateData,
    getScheduleTemplate,
    templateSchedule,
    elementaryCalloutSetupSteps,
    elementaryCalloutHelpLink,
  };
};
