import React, { FC } from 'react';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { CSSProperties } from 'styled-components';

export interface GetAllModelsButtonProps {
  onGetAllModelsClick: () => void,
  style?: CSSProperties,
  buttonType?: 'get' | 'refresh',
}

const GetAllModelsButton: FC<GetAllModelsButtonProps> = ({
  onGetAllModelsClick,
  style,
  buttonType = 'get',
}) => (
  <DefaultButton
    type="standard"
    view="filled"
    dense
    text={buttonType === 'get'
      ? 'Get all models'
      : 'Refresh models'}
    onClick={onGetAllModelsClick}
    eventContext={Contexts.BOLT}
    eventObject="getAllThoughtSpotModels"
    eventAction={Actions.CLICKED}
    style={style}
  />
);

export default GetAllModelsButton;
