import styled from 'styled-components';

export const CardContainer = styled.div`
  width: 100%;
  background: var(--violet100);
  border-radius: 8px;
  padding: 12px 16px;
`;

export const MetricCard = styled.div`
  width: 100%;
  height: 100%;
  background: var(--white);
  border-radius: 8px;
`;
