import React, { FC } from 'react';
import useGetScheduleName from '../hooks/useGetScheduleName';
import ScheduleCode from './ScheduleCode';

export interface ShowScheduleCodeProps {
  scheduleId: string,
  isVisible: boolean,
  onHide: () => void,
}

const ShowScheduleCode:FC<ShowScheduleCodeProps> = ({
  scheduleId,
  isVisible,
  onHide,
}) => {
  const {
    isLoading,
    scheduleYamlData: scheduleCode,
    scheduleFormData,
  } = useGetScheduleName(scheduleId);

  return (
    <ScheduleCode
      scheduleName={scheduleFormData?.name}
      scheduleCode={scheduleCode}
      isVisible={isVisible}
      onHide={onHide}
      isLoading={isLoading}
    />
  );
};

export default ShowScheduleCode;
