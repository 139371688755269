import React, { FC } from 'react';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import warningBell from '../../../images/bells/warningBell.svg';
import ToastFactory from '../../Common/Alerts/ToastFactory';

export interface CurrentBranchNotCleanToastProps {
  branch: string,
  file: string
}

const CurrentBranchNotCleanToast: FC<CurrentBranchNotCleanToastProps> = ({ branch, file }) => (
  <ToastFactory
    title={(
      <Typography
        tagName="h1"
        type="h6"
      >
        Hold on!
      </Typography>
    )}
    text={(
      <>
        <Typography type="body" tagName="span">Unable to checkout the requested branch</Typography>
        <Typography type="body-bold" tagName="span">{` ${branch} `}</Typography>
        <Typography type="body" tagName="span">and view</Typography>
        <Typography type="body-bold" tagName="span">{` ${file} `}</Typography>
        <Typography type="body" tagName="span">since you have uncommited changes in your editor.</Typography>
      </>
    )}
    icon={warningBell}
  />
);

export default CurrentBranchNotCleanToast;
