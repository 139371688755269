import React, {
  createContext,
  FC,
  useEffect,
  useState,
} from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import ModalContainer from '@paradime-io/pragma-ui-kit/lib/components/ModalContainer';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events/Contexts';
import { HelpDocs } from '@paradime-io/pragma-ui-kit/lib/components/utils';
import Warehouse, { WareHouseModalSource } from '../../Common/Warehouses';
import {
  WarehouseEnv,
  warehouseCompleteConfig,
  warehouseIncompleteConfig,
  WarehouseOptions,
  BigButtonsOptions,
} from '../../Common/Warehouses/utils';
import { key, genericObject } from '../../../utilis';
import ConnectionsHeader from './ConnectionsHeader';
import CompleteConnectionRow from './CompleteConnectionRow';
import IncompleteConnectionRow from './IncompleteConnectionRow';
import { EditableContext } from '.';
import { GqlParadimeAccountType } from '../../../client/generated/service';
import { userHasAccountSettingsConnectionsAdminAccess } from '../../../utilis/PermissionsService';

interface ConnectionFactoryProps {
  title: string,
  help?: HelpDocs
  completeConfigs?: warehouseCompleteConfig,
  incompleteConfigs?: warehouseIncompleteConfig,
  getConfig: Function,
  /**
   * list of user levels whi can edit the
   * info in the CollapsibleButton
   */
  canEdit: boolean,
  accessLevel: GqlParadimeAccountType,
  env: WarehouseEnv,
  warehouseOptions: WarehouseOptions,
  bigButtons: BigButtonsOptions,
  /**
   * Hides the CollapsibleButton to non-admins if the admin
   * haven't boarded the connection yet.
   */
  adminFirst?: boolean,
}

export interface NewConnectionModalContextProps {
  showNewConnectionModal: boolean,
  setShowNewConnectionModal: (showModal: boolean) => void,
  initialModalData?: genericObject,
  setInitialModalData: (modalData: genericObject) => void,
}

export const NewConnectionModalContext = createContext<NewConnectionModalContextProps>({
  showNewConnectionModal: false,
  setShowNewConnectionModal: () => {},
  initialModalData: undefined,
  setInitialModalData: () => {},
});

const ConnectionFactory: FC<ConnectionFactoryProps> = ({
  title,
  help,
  accessLevel,
  completeConfigs,
  canEdit,
  env,
  warehouseOptions,
  bigButtons,
  adminFirst,
  incompleteConfigs,
}) => {
  const [showNewConnectionModal, setShowNewConnectionModal] = useState(false);
  const [currentCompleteData, setCurrentCompleteData] = useState(completeConfigs);
  const [currentIncompleteData, setCurrentIncompleteData] = useState(incompleteConfigs);
  const [initialModalData, setInitialModalData] = useState<genericObject>();

  useEffect(() => {
    if (completeConfigs) setCurrentCompleteData(completeConfigs);
  }, [completeConfigs]);

  useEffect(() => {
    if (incompleteConfigs) setCurrentIncompleteData(incompleteConfigs);
  }, [incompleteConfigs]);

  const isEmpty = () => {
    if (currentCompleteData) {
      const atLeastOneComplete = currentCompleteData.length > 0;
      const atLeastOneIncomplete = Boolean(
        currentIncompleteData && currentIncompleteData.length > 0,
      );
      if (currentCompleteData) { return !atLeastOneComplete && !atLeastOneIncomplete; }
      return true;
    }
    return false;
  };

  if (isEmpty() && adminFirst && !canEdit) {
    return null;
  }

  return (
    <NewConnectionModalContext.Provider
      value={{
        showNewConnectionModal,
        setShowNewConnectionModal,
        initialModalData,
        setInitialModalData,
      }}
    >
      <AutoLayout
        direction="vertical"
        padding="none"
        verticalGap="very-dense"
        distribution="packed"
        alignment="top-left"
      >
        <ConnectionsHeader
          title={title}
          showButton={userHasAccountSettingsConnectionsAdminAccess(accessLevel)}
          eventContext={Contexts.ACCOUNT_SETTINGS}
          buttonText="Add new"
          buttonIcon="plus"
          onClick={() => {
            setShowNewConnectionModal(true);
            setInitialModalData(undefined);
          }}
          helpText={help?.text}
          onHelpClick={help?.onClick}
        />
        <AutoLayout
          direction="vertical"
          padding="none"
          horizontalGap="dense"
          distribution="packed"
          alignment="top-left"
        >
          {
              currentCompleteData
              && (currentCompleteData.length > 0)
              && currentCompleteData.map((datum) => (
                <CompleteConnectionRow
                  datum={datum}
                  accessLevel={accessLevel}
                  env={env}
                  key={key(datum.credentialId)}
                />
              ))
            }
          {currentIncompleteData && currentIncompleteData.map((datum) => (
            <IncompleteConnectionRow
              datum={datum}
              accessLevel={accessLevel}
              env={env}
              key={datum.credentialId}
            />
          ))}
        </AutoLayout>
        { /* istanbul ignore next */ showNewConnectionModal
            && (
              <EditableContext.Provider
                value={{
                  isEditable: canEdit,
                  setIsEditable: () => { },
                }}
              >
                <ModalContainer
                  states={[
                    { hasPrevious: false, node: Warehouse },
                  ]}
                  showModal={showNewConnectionModal}
                  usePortal
                  canOutsideClickClose
                  style={{
                    transform: 'translate(-50%, -50%)',
                    top: '50%',
                    left: '50%',
                    position: 'fixed',
                  }}
                  onClose={() => setShowNewConnectionModal(false)}
                  initialData={{
                    env,
                    reload: () => setShowNewConnectionModal(false),
                    warehouseOptions,
                    bigButtons,
                    close: () => setShowNewConnectionModal(false),
                    initialDatabaseType: 'unknown',
                    credentialId: undefined,
                    source: WareHouseModalSource.ACCOUNT_SETTINGS,
                    newConnection: true,
                    accessLevel,
                    ...initialModalData,
                  }}
                />
              </EditableContext.Provider>
            )}
      </AutoLayout>
    </NewConnectionModalContext.Provider>
  );
};

export default ConnectionFactory;
