import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Dropdown from '@paradime-io/pragma-ui-kit/lib/components/Dropdown';
import { MenuTemplate } from '@paradime-io/pragma-ui-kit/lib/components/Menu';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { Typography } from '@paradime-io/pragma-ui-kit';

export interface OptimisationTypeDropdownProps {
  selectedOptimisationType: string,
  allOptimisationTypes: string[],
  onSelectOptimisation: (optimisationType: string) => void,
}

const OptimisationTypeDropdown: FC<OptimisationTypeDropdownProps> = ({
  selectedOptimisationType,
  allOptimisationTypes,
  onSelectOptimisation,
}) => (
  <AutoLayout
    direction="horizontal"
    padding="none"
    horizontalGap="dense"
    distribution="packed"
  >
    <Typography
      font="inter"
      type="body-small"
      color="default"
      colorStep="50"
    >
      Optimization Type
    </Typography>
    <Dropdown
      view="outlined"
      dense
      text={selectedOptimisationType}
      content={(
        <MenuTemplate
          eventContext={Contexts.RADAR}
          dense
          items={allOptimisationTypes.map((optimisationType) => (
            { text: optimisationType, type: 'item', onClick: () => onSelectOptimisation(optimisationType) }
          ))}
        />
      )}
      style={{ width: '260px' }}
    />
  </AutoLayout>
);

export default OptimisationTypeDropdown;
