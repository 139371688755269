import { Toaster as ToasterSource } from '@blueprintjs/core';
import { DialogProps } from '@paradime-io/pragma-ui-kit/lib/components/Dialog';
import { ToasterType } from '@paradime-io/pragma-ui-kit/lib/components/Toaster';
import { MutableRefObject } from 'react';
import create from 'zustand';
import { combine } from 'zustand/middleware';

export const alertStore = create(
  combine(
    {
      currentAlert: undefined as DialogProps | undefined,
      toaster: { current: undefined } as MutableRefObject<ToasterSource | undefined>,
      toasterType: 'neutral' as ToasterType,
    },
    (set) => ({
      openAlert: (alert: DialogProps) => set(() => ({
        currentAlert: { ...alert, isOpen: true },
      })),
      closeAlert: () => set((state) => ({
        currentAlert: { ...state.currentAlert, isOpen: false },
      })),
      setToaster: (newToaster: ToasterSource | undefined) => set(() => ({
        toaster: { current: newToaster },
      })),
      changeToasterType: (newToasterType: ToasterType) => set(() => ({
        toasterType: newToasterType,
      })),
    }),
  ),
);
