import React, { ReactNode } from 'react';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { FrigadeJS } from '@frigade/react';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import CodeSnippetCopy from '@paradime/common/lib/common/helpers/CodeSnippetCopy';
import { useMarkStepCompleted } from '@paradime/common/lib/common/helpers/frigadeHelperFunctions';
import * as S from '../Bolt.styles';
import { BoltFlows } from '../../Common/Frigade/flows';

const CreateCDJobStep = (stepData: FrigadeJS.FlowStep): ReactNode => {
  const { primaryButtonTitle, id: stepId } = stepData;
  const { markStepCompleted } = useMarkStepCompleted(BoltFlows.TURBO_CD_FLOW);

  const code = `
...
  - name: cd_run # the name of your CD job
    deferred_schedule:
      enabled: true # true to enabled this Turbo CI job to run on pull request
      deferred_schedule_name: hourly_run #the name of the bolt schedule where the CD job will look for the most recent successful run manifest.json for state comparison
    schedule: "OFF" # set the schedule configuration to not run on a schedule (to be used for PR only)
    environment: production #the environment used to run the schedule -> this is always production
    commands:
      - dbt run --select state:modified+ #the dbt™️ command you want to run after the pull request is merged
...
`;

  return (
    <AutoLayout
      direction="vertical"
      distribution="packed"
      verticalGap="dense"
      padding="none"
      alignment="top-left"
      style={{ paddingBottom: '16px' }}
    >
      <Typography tagName="span" type="h6">Create a CD Job schedule</Typography>
      <S.CodeTypography tagName="span" type="body-small">
        To start create a new Bolt schedule and make sure to add the
        {' '}
        <code>deferred_schedule</code>
        {' '}
        configuration.
        {' '}
        You can choose to defer to another Bolt job, or
        {' '}
        defer back to the last run of the same CD job.
      </S.CodeTypography>
      <CodeSnippetCopy codeSnippet={code} language="yaml" />
      <DefaultButton
        type="standard"
        view="filled"
        color="primary"
        dense
        text={primaryButtonTitle}
        eventContext={Contexts.BOLT}
        eventObject="markStepComplete"
        eventAction={Actions.CLICKED}
        onClick={() => markStepCompleted(stepId)}
        style={{ width: 'fit-content' }}
      />
    </AutoLayout>
  );
};

export default CreateCDJobStep;
