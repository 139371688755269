import ClickOutsideWrapper from '@paradime-io/pragma-ui-kit/lib/components/ClickOutsideWrapper';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import React, {
  FC, useContext, useMemo, useState,
} from 'react';
import EditDeleteSuccessfulToast from './toasts/EditDeleteSuccessfulToast';
import { alertStore } from '../../../stores';
import {
  useDeleteDevelopmentDwhMutation,
  useDeleteProductionDwhMutation,
  useSetDefaultDevelopmentDwhMutation,
  useSetDefaultProductionDwhMutation,
} from '../../../client/generated/service';
import { postMessage } from '../../../utilis';
import { WarehouseEnv } from './utils';
import EditModal from './EditModal';
import { EnvName } from '../../../client/service.graphql';
import { ConfigurationsContext } from '../../../components/AccountSettings/Connections/hooks/useGetConnectionsConfig';

interface EditMenuProps {
  credentialId: string,
  env: WarehouseEnv,
  disabled?: boolean,
  connectionName?: string,
  closeModal: () => any,
}

const EditMenu: FC<EditMenuProps> = ({
  credentialId,
  env,
  disabled = false,
  connectionName = '',
  closeModal,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [initialDeleteLoading, setInitialDeleteLoading] = useState(false);
  const [initialSetDefaultLoading, setInitialSetDefaultLoading] = useState(false);

  const [deleteDevelopmentDwh] = useDeleteDevelopmentDwhMutation();
  const [deleteProductionDwh] = useDeleteProductionDwhMutation();
  const [setDefaultDevelopmentSwh] = useSetDefaultDevelopmentDwhMutation();
  const [setDefaultProductionSwh] = useSetDefaultProductionDwhMutation();

  const toaster = alertStore((s) => s.toaster);

  const throwSuccessfulToast = (operation: string) => {
    toaster.current?.show({
      message: <EditDeleteSuccessfulToast operation={operation} />,
    });
  };

  const triggerAndUpdate = async (fn: () => Promise<any>, operation: string) => {
    await fn();
    throwSuccessfulToast(operation);
    postMessage('reload-connections', env);
    closeModal();
  };

  const deleteFunction = env === WarehouseEnv.DEV
    ? () => {
      triggerAndUpdate(() => deleteDevelopmentDwh({ variables: { credentialId } }), 'Connection deleted');
      setInitialDeleteLoading(true);
      setIsOpen(true);
    }
    : () => {
      triggerAndUpdate(() => deleteProductionDwh({ variables: { productionEnvName: EnvName.PRODUCTION, credentialId } }), 'Connection deleted');
      setInitialDeleteLoading(true);
      setIsOpen(true);
    };

  const updateFunction = env === WarehouseEnv.DEV
    ? () => {
      triggerAndUpdate(() => setDefaultDevelopmentSwh({ variables: { credentialId } }), 'Updated default connection');
      setInitialSetDefaultLoading(true);
      setIsOpen(true);
    }
    : () => {
      triggerAndUpdate(() => setDefaultProductionSwh({ variables: { productionEnvName: EnvName.PRODUCTION, credentialId } }), 'Updated default connection');
      setInitialSetDefaultLoading(true);
      setIsOpen(true);
    };

  const { configurations } = useContext(ConfigurationsContext);

  const cannotBeDeleted = useMemo(() => {
    const configurationLength = configurations?.getDwhProductionConfigs.configurations.length;
    const isLastProductionEnv = env === WarehouseEnv.PROD && configurationLength === 1;

    return disabled && !isLastProductionEnv;
  }, [disabled, env, configurations]);

  return (
    <div style={{ position: 'relative' }}>
      <DefaultButton
        type="standard"
        icon="more"
        eventAction={Actions.OPENED}
        eventContext={Contexts.WAREHOUSE}
        eventObject="connectionEditRequest"
        dense
        onClick={() => setIsOpen(true)}
        view="flat"
        style={{ margin: '9px -5px 0 0', transform: 'rotate(90deg)' }}

      />
      <div style={{ position: 'absolute', zIndex: 100 }}>
        {
          isOpen && (
            <ClickOutsideWrapper onClickOutside={() => setIsOpen(false)}>
              <EditModal
                initialDeleteLoading={initialDeleteLoading}
                initialSetDefaultLoading={initialSetDefaultLoading}
                disabled={cannotBeDeleted}
                deleteFunction={deleteFunction}
                updateFunction={updateFunction}
                connectionName={connectionName}
                env={env === WarehouseEnv.DEV
                  ? 'Development'
                  : 'Production'}
              />
            </ClickOutsideWrapper>
          )
        }
      </div>
    </div>
  );
};

export default EditMenu;
