/*
  This file is copied from pragma > Toaster > ToastMessage
  with some small modifications to calculate colours and types based on props
  rather than using the toasterContext
*/
import React, { FC } from 'react';
import styled from 'styled-components';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import checkCircle from '@paradime-io/pragma-ui-kit/lib/components/Toaster/ToastMessage/images/check-circle.svg';
import alertCircle from '@paradime-io/pragma-ui-kit/lib/components/Toaster/ToastMessage/images/alert-circle.svg';
import infoCircle from '@paradime-io/pragma-ui-kit/lib/components/Toaster/ToastMessage/images/info-circle.svg';
import alarmLight from '@paradime-io/pragma-ui-kit/lib/components/Toaster/ToastMessage/images/alarm-light.svg';
import { TriggerToastProps } from './alerts';

const ToastContainer = styled.div<{ color: string}>`
  & .toast-icon {
    display: flex;
    margin-left: 0 !important;
    background: ${(props) => `var(--${props.color}10) !important`};
  }

  & .toast-icon > svg {
    margin: auto;
  }

  & .toast-icon > img {
    margin: auto;
  }
`;

const ToastMessage: FC<TriggerToastProps> = ({ header, message, type }) => {
  const getTypeDetails = () => {
    switch (type) {
      case 'neutral':
        return {
          intentName: 'default',
          colorName: 'grey',
          icon: infoCircle,
        };
      case 'positive':
        return {
          intentName: 'success',
          colorName: 'green',
          icon: checkCircle,
        };
      case 'attention':
        return {
          intentName: 'warning',
          colorName: 'yellow',
          icon: alertCircle,
        };
      case 'warning':
        return {
          intentName: 'danger',
          colorName: 'red',
          icon: alarmLight,
        };
      default:
        return {
          intentName: 'default',
          colorName: 'grey',
          icon: infoCircle,
        };
    }
  };

  return (
    <ToastContainer color={getTypeDetails().colorName}>
      <AutoLayout
        direction="vertical"
        padding="none"
        verticalGap="very-dense"
        distribution="packed"
        alignment="top-center"
      >
        <AutoLayout
          alignment="top-left"
          direction="horizontal"
          distribution="packed"
          padding="very-dense"
          horizontalGap="dense"
          style={{ padding: 0, alignItems: 'center', columnGap: '12px' }}
        >
          <div className="toast-icon">
            <img src={getTypeDetails().icon} alt={type} />
          </div>
          <Typography
            tagName="span"
            type="body-bold"
            color={getTypeDetails().intentName as colorType}
            colorStep="80"
            style={{ overflowWrap: 'break-word', maxWidth: '268px', marginRight: '24px' }}
          >
            {header}
          </Typography>
        </AutoLayout>
        {
          message
          && (
            <Typography
              tagName="span"
              type="body-small"
              style={{ overflowWrap: 'break-word', maxWidth: '312px', marginRight: '16px' }}
            >
              {message}
            </Typography>
          )
        }
      </AutoLayout>
    </ToastContainer>
  );
};

export default ToastMessage;
