import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { ScheduleType } from '.';
import { ScheduleName } from './Scheduler/ScheduleList';

export const getScheduleType = (schedule?: ScheduleType) => {
  if (schedule?.turboCi?.enabled) return 'turbo_ci';
  if (schedule?.deferredSchedule?.enabled) return 'deferred';
  return 'standard';
};

export const getScheduleState = (schedule: ScheduleName) => {
  let textAndColor = {
    color: schedule.state.colorType as colorType,
    text: schedule.state.text,
  };
  if (schedule.state.text === 'Finished') {
    textAndColor = {
      color: schedule.lastRunState.colorType as colorType,
      text: schedule.lastRunState.text,
    };
  }
  return textAndColor;
};
