import React, { FC, ReactNode } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { AutoLayout } from '@paradime-io/pragma-ui-kit';
import { useGetRadarTabsData, RadarTab, DashboardTabIds } from './config';
import * as S from './Radar.styles';

export interface RadarPageProps {
  children: ReactNode,
}

const RadarPage: FC<RadarPageProps> = ({
  children,
}) => {
  const { pageId, tabId } = useParams<{pageId: RadarTab, tabId: DashboardTabIds}>();

  const history = useHistory();

  const radarTabsData = useGetRadarTabsData();

  if (!tabId) {
    const firstVisibleTab = radarTabsData[pageId].filter(({ visible }) => visible)?.[0];
    if (firstVisibleTab) history.push(`/radar/${pageId}/${firstVisibleTab.id}`);
  }

  return (
    <AutoLayout
      direction="vertical"
      distribution="packed"
      padding="none"
      verticalGap="normal"
      alignment="top-left"
    >
      <S.RadarPageTab
        type="dense"
        view="flat"
        color="default"
        fill={false}
        data={radarTabsData[pageId]}
        eventContext={Contexts.RADAR}
        currentTabId={tabId}
        exposeOnChange={(newTabId) => {
          history.push(`/radar/${pageId}/${newTabId}`);
        }}
      />
      {children}
    </AutoLayout>
  );
};

export default RadarPage;
