import { useState } from 'react';
import {
  useSnowflakeApplyCostAgentMutation,
  useSnowflakeRemoveCostAgentMutation,
} from '../../../client/generated/service';
import { OptimisationHookProps, optimisationListItem } from '.';
import {
  triggerActivateErrorToast,
  triggerActivateSuccessToast,
  triggerPauseErrorToast,
  triggerPauseSuccessToast,
} from './utils';

const optimisationName = 'AI agent optimizer';

export const useGetCostAgentOptimisations = ({
  onApplyPauseSuccess,
}: OptimisationHookProps): optimisationListItem => {
  const [currentWarehouseName, setCurrentWarehouseName] = useState('');

  const [
    applyOptimisation, { loading: isLoadingApplyOptimisation },
  ] = useSnowflakeApplyCostAgentMutation({
    onCompleted: (data) => {
      if (data.snowflakeApplyCostAgent?.ok) {
        triggerActivateSuccessToast({ optimisationName, warehouseName: currentWarehouseName });
        onApplyPauseSuccess();
      } else {
        triggerActivateErrorToast({ optimisationName, warehouseName: currentWarehouseName });
      }
    },
  });

  const handleApply = (warehouseName: string) => {
    setCurrentWarehouseName(warehouseName);
    applyOptimisation({ variables: { warehouseName } });
  };

  const [
    pauseOptimisation, { loading: isLoadingPauseOptimisation },
  ] = useSnowflakeRemoveCostAgentMutation({
    onCompleted: (data) => {
      if (data.snowflakeRemoveCostAgent?.ok) {
        triggerPauseSuccessToast({ optimisationName, warehouseName: currentWarehouseName });
        onApplyPauseSuccess();
      } else {
        triggerPauseErrorToast({ optimisationName, warehouseName: currentWarehouseName });
      }
    },
  });

  const handlePause = (warehouseName: string) => {
    setCurrentWarehouseName(warehouseName);
    pauseOptimisation({ variables: { warehouseName } });
  };

  return {
    applyOptimisation: handleApply,
    isLoadingApplyOptimisation,
    pauseOptimisation: handlePause,
    isLoadingPauseOptimisation,
  };
};
