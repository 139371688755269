import React, { FC } from 'react';
import { DateTime } from 'luxon';
import {
  VictoryChart,
  VictoryStack,
  VictoryBar,
  VictoryAxis,
  VictoryTheme,
  VictoryContainer,
  VictoryLabel,
  VictoryTooltip,
} from 'victory';
import { key } from '../../../utilis';
import { formatCurrency } from '../../Platform/Insights/utils';
import { useGetChartDimensionsReturnType } from '../../Bolt/Scheduler/ScheduleInsights/useGetChartDimensions';
import { chartColourScale } from './utils';

export interface BarChartCardContentProps {
  data: { x: string, y: number }[][],
  axisDatesList: string[],
  dataSortKey?: string,
  tooltipLabel: string[]
    | number[]
    | ((data: any) => string | number | string[] | number[] | null)
    | undefined,
  dimensions: useGetChartDimensionsReturnType,
}

const tickLabelStyle = (fontSize: number) => ({
  fontFamily: 'var(--inter)',
  color: 'var(--grey70)',
  fontWeight: 'var(--font_weight_caption)',
  fontSize: fontSize.toFixed(0),
  lineHeight: 'var(--line_height_caption)',
  letterSpacing: 'var(--letter_spacing_caption)',
});

const axisStyle = ({ withGridLines }: { withGridLines: boolean }) => ({
  axis: { stroke: 'var(--grey10)' },
  ticks: { stroke: 'var(--grey10)', size: 2 },
  grid: { stroke: withGridLines ? 'var(--grey5)' : 'none' },
});

const BarChartCardContent: FC<BarChartCardContentProps> = ({
  data,
  axisDatesList,
  dataSortKey,
  tooltipLabel,
  dimensions,
}) => {
  const {
    chartHeight,
    axisConfig,
    tooltipConfig,
  } = dimensions;

  // When the list gets long, only display every other label
  const formatDateTickLabels = () => {
    const isLongList = axisDatesList.length > 50;

    return axisDatesList.map((date, index) => {
      const isEvenIndex = index % 2 === 0;
      return isLongList && isEvenIndex
        ? ''
        : DateTime.fromFormat(date, 'yyyy-LL-dd').toFormat('d LLL');
    });
  };

  return (
    <VictoryChart
      theme={VictoryTheme.material}
      scale={{ x: 'time', y: 'linear' }}
      height={chartHeight}
      domainPadding={{ x: 2, y: 2 }}
      padding={{
        left: 30,
        right: 8,
        top: 8,
        bottom: 20,
      }}
      containerComponent={(
        <VictoryContainer
          responsive
        />
      )}
    >
      <VictoryStack
        colorScale={chartColourScale}
      >
        {data.map((plotItem) => (
          <VictoryBar
            key={key(plotItem?.[0]?.x)}
            data={plotItem}
            barRatio={1}
            labels={tooltipLabel}
            sortKey={dataSortKey}
            events={[
              {
                target: 'data',
                eventHandlers: {
                  onMouseOver: () => [
                    {
                      target: 'labels',
                      mutation: () => ({ active: true }),
                    },
                  ],
                  onMouseOut: () => [
                    {
                      target: 'labels',
                      mutation: () => ({ active: false }),
                    },
                  ],
                },
              },
            ]}
            labelComponent={(
              <VictoryTooltip
                cornerRadius={4}
                pointerWidth={5}
                pointerLength={5}
                flyoutWidth={tooltipConfig.flyoutWidth}
                flyoutHeight={tooltipConfig.flyoutHeight}
                flyoutStyle={{
                  strokeWidth: 0,
                  fill: 'var(--grey100)',
                }}
                labelComponent={(
                  <VictoryLabel
                    style={{
                      fontFamily: 'var(--inter)',
                      letterSpacing: 'var(--letter_spacing_caption)',
                      fill: 'var(--grey30)',
                      fontWeight: 400,
                      fontSize: tooltipConfig.labelFontSize,
                      textAnchor: 'middle',
                      verticalAnchor: 'middle',
                    }}
                  />
                )}
              />
            )}
          />
        ))}
      </VictoryStack>
      <VictoryAxis
        dependentAxis
        tickFormat={(tick) => formatCurrency(tick)}
        tickLabelComponent={(
          <VictoryLabel
            dx={4}
            style={tickLabelStyle(axisConfig.tickLabelFontSize)}
          />
        )}
        style={axisStyle({ withGridLines: true })}
      />
      <VictoryAxis
        scale="time"
        tickFormat={formatDateTickLabels()}
        tickCount={axisDatesList.length}
        tickValues={axisDatesList}
        tickLabelComponent={(
          <VictoryLabel
            angle={-45}
            dy={-4}
            dx={3}
            style={tickLabelStyle(axisConfig.tickLabelFontSize)}
          />
        )}
        style={axisStyle({ withGridLines: false })}
      />
    </VictoryChart>
  );
};

export default BarChartCardContent;
