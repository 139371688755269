import { useEffect, useState } from 'react';
import { ColumnProps, ColumnType, CustomFilterType } from '@paradime-io/pragma-ui-kit/lib/components/PrimeReactDataTable';
import { useBoltRunStatusesLazyQuery, GqlState } from '../../../client/generated/service';

const formatStatusData = (statuses?: (GqlState | null)[]) => (
  (statuses || []).map((status) => {
    if (status) {
      return { tag: status.text, color: status.colorType };
    }
    return null;
  })
);

const useGetColumnsForScheduleTable = () => {
  const [getRunStatuses, { data }] = useBoltRunStatusesLazyQuery({
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    getRunStatuses();
  }, []);

  useEffect(() => {
    if (data?.boltRunStatuses?.statuses) {
      setColumns((cols) => cols.map((c) => {
        if (c.field === 'status') {
          return {
            ...c,
            filterOptions: formatStatusData(data.boltRunStatuses?.statuses),
          };
        }
        return c;
      }));
    }
  }, [data]);

  const [columns, setColumns] = useState<ColumnProps[]>([
    {
      field: 'name',
      header: 'Name',
      isStatic: true,
      type: ColumnType.TEXT_WITH_ICON,
      filterPlaceholder: 'Search by name',
      frozen: true,
      filterField: 'name.text',
      style: { maxWidth: '300px' },
    },
    {
      field: 'status',
      header: 'Status',
      isStatic: true,
      type: ColumnType.CHIP,
      filterPlaceholder: 'Search by status',
      filter: true,
      filterField: 'status.tag',
      showFilterMatchModes: false,
      customFilterType: CustomFilterType.MULTISELECT,
      filterOptions: [],
      filterOptionType: ColumnType.CHIP,
    },
    {
      field: 'owner',
      header: 'Owner',
      filterPlaceholder: 'Search by owner',
      filter: true,
    },
    {
      field: 'cronDescription',
      header: 'Cron Description',
      filterPlaceholder: 'Search by cron',
      style: { minWidth: '222px' },
      filter: true,
    },
    {
      field: 'cronConfig',
      header: 'Cron Configuration',
      filterPlaceholder: 'Search by cron',
      style: { minWidth: '230px' },
      filter: true,
    },
    {
      field: 'lastRun',
      header: 'Last Run',
      filterPlaceholder: 'Search by last run',
      style: { minWidth: '222px' },
    },
    {
      field: 'timeSinceLastRun',
      header: 'Time Since Last Run',
      filterPlaceholder: 'Search by last run',
      style: { minWidth: '250px' },
    },
    {
      field: 'nextRun',
      header: 'Next Run',
      filterPlaceholder: 'Search by next run',
      style: { minWidth: '222px' },
    },
    {
      field: 'untilNextRun',
      header: 'Until Next Run',
      filterPlaceholder: 'Search by next run',
      style: { minWidth: '222px' },
    },
    {
      field: 'scheduleType',
      header: 'Schedule Type',
      filterPlaceholder: 'Search by schedule type',
      style: { minWidth: '222px' },
    },
    {
      field: 'triggerType',
      header: 'Trigger type',
      filterPlaceholder: 'Search by trigger type',
      style: { minWidth: '222px' },
    },
    {
      field: 'onCompletionConfiguration',
      header: 'On Completion Configuration',
      filterPlaceholder: 'Search by on completion configuration',
      style: { minWidth: '222px' },
    },
  ]);

  return { columns };
};

export default useGetColumnsForScheduleTable;
