import React, { ReactNode } from 'react';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { FrigadeJS } from '@frigade/react';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import CodeSnippetCopy from '@paradime/common/lib/common/helpers/CodeSnippetCopy';
import { useMarkStepCompleted } from '@paradime/common/lib/common/helpers/frigadeHelperFunctions';
import * as S from '../Bolt.styles';
import { BoltFlows } from '../../Common/Frigade/flows';

const ConfigureCustomSchemaStep = (stepData: FrigadeJS.FlowStep): ReactNode => {
  const { primaryButtonTitle, id: stepId } = stepData;
  const { markStepCompleted } = useMarkStepCompleted(BoltFlows.TURBO_CI_FLOW);

  const macroCode = `
  {% macro generate_schema_name(custom_schema_name, node) -%}

    {%- set default_schema = target.schema -%}
    {%- if target.name == 'prod' -%}

        {{ custom_schema_name | trim }}

    {%- elif target.name == 'ci' -%}

        {{ default_schema }}

    {%- else -%}

        {%- if custom_schema_name is none -%}

            {{ default_schema }}

        {%- else -%}

            {{ default_schema }}_{{ custom_schema_name | trim }}

        {%- endif -%}

    {%- endif -%}

  {%- endmacro %}
        `;

  return (
    <AutoLayout
      direction="vertical"
      distribution="packed"
      verticalGap="dense"
      padding="none"
      alignment="top-left"
      style={{ paddingBottom: '16px' }}
    >
      <Typography tagName="span" type="h6">Configure custom schema</Typography>
      <S.CodeTypography tagName="span" type="body-small">
        In your dbt™️ project, create a macro called
        {' '}
        <code>generate_schema_name.sql</code>
        {' '}
        and setup a macro. When a Bolt run is executed using the
        {' '}
        <code>ci</code>
        {' '}
        target, then all models will be built using the
        {' '}
        <code>paradime_turbo_ci_pr</code>
        {' '}
        prefix.
      </S.CodeTypography>
      <CodeSnippetCopy codeSnippet={macroCode} language="javascript" />
      <DefaultButton
        type="standard"
        view="filled"
        color="primary"
        dense
        text={primaryButtonTitle}
        eventContext={Contexts.LOGS}
        eventObject="markStepComplete"
        eventAction={Actions.CLICKED}
        onClick={() => markStepCompleted(stepId)}
        style={{ width: 'fit-content' }}
      />
    </AutoLayout>
  );
};

export default ConfigureCustomSchemaStep;
