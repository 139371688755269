import React, { FC } from 'react';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import ToastFactory from '../../Common/Alerts/ToastFactory';
import warningBell from '../../../images/bells/warningBell.svg';

const FileIgnoredToast : FC = () => (
  <ToastFactory
    icon={warningBell}
    text="Threads can be created only for files tracked in git. This file is part of your .gitignore."
    title={(
      <Typography
        type="h6"
        tagName="span"
        color="warning"
        colorStep="80"
        style={{
          margin: 'auto 0px',
        }}
      >
        Creating a new thread
      </Typography>
)}
  />
);

export default FileIgnoredToast;
