import React, { FC, ReactNode } from 'react';
import { Icon, IconName } from '@blueprintjs/core';
import Autolayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Divider from '@paradime-io/pragma-ui-kit/lib/components/Divider';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import { HelpDocs } from '@paradime-io/pragma-ui-kit/lib/components/utils';

interface SectionHeaderProps {
  icon: IconName,
  header: string,
  description: string,
  help?: HelpDocs,
  customRightComponent?: ReactNode,
}

const SectionHeader:FC<SectionHeaderProps> = ({
  icon,
  header,
  description,
  help,
  customRightComponent,
}) => (
  <Autolayout
    direction="vertical"
    padding="none"
    distribution="packed"
    alignment="top-left"
    verticalGap="ultra-dense"
  >
    <Autolayout
      direction="horizontal"
      padding="none"
      distribution="space-between"
      style={{ alignItems: 'center' }}
    >
      <Autolayout
        direction="horizontal"
        horizontalGap="very-dense"
        padding="none"
        distribution="packed"
      >
        <Icon size={20} icon={icon} />
        <Typography tagName="span" type="body-bold">
          {header}
        </Typography>
      </Autolayout>
      {
        help && (
          <Chips
            icon="info-sign"
            color="primary_alt"
            round={false}
            tag={help?.text}
            type="dense"
            view="smooth"
            onClick={() => help?.onClick()}
          />
        )
      }
      {customRightComponent && customRightComponent}
    </Autolayout>
    <Typography tagName="span" type="body-bold-small" colorStep="30">
      {description}
    </Typography>
    <Divider color="default" colorStep="5" />
  </Autolayout>
);

export default SectionHeader;
