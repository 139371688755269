import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import Divider from '@paradime-io/pragma-ui-kit/lib/components/Divider';
import AlertRow from './AlertRow';
import { useManageAlerts } from './hooks/useManageAlerts';
import { key } from '../../utilis';

const DataAlerts:FC = () => {
  const { alertConfigs, isLoadingAlertConfigs } = useManageAlerts();

  if (isLoadingAlertConfigs) {
    return (
      <Spinner width={32} height={32} thin />
    );
  }

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      distribution="packed"
      verticalGap="dense"
      style={{ paddingTop: '24px' }}
    >
      {alertConfigs?.map((alert) => (
        <>
          <AlertRow
            key={key(alert.alertId)}
            id={alert.alertId}
            name={alert.name}
            description={alert.description}
            category={alert.category}
            currentStatus={alert.enabled}
            currentThreshold={alert.threshold}
          />
          {
            (alertConfigs.length > 1) && (
              <Divider colorStep="10" size={1} />
            )
          }
        </>
      ))}
    </AutoLayout>
  );
};

export default DataAlerts;
