import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import Callout from '@paradime-io/pragma-ui-kit/lib/components/Callout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { Info } from './Run';

interface DeferredCalloutProps {
  deferredDetails: Info['deferredDetails']
}

const DeferredCallout: FC<DeferredCalloutProps> = ({
  deferredDetails,
}) => {
  if (deferredDetails) {
    const content = (
      <Typography
        tagName="span"
        type="body-small"
        color="default"
      >
        This run executed in deferred mode using artifacts from the schedule
        {' '}
        <Typography
          tagName="span"
          type="body-bold-small"
        >
          <Link
            to={`/bolt/${deferredDetails.scheduleNameUuid}`}
            style={{ color: 'var(--deeplink-blue)' }}
            target="_blank"
          >
            {deferredDetails.scheduleName}
          </Link>
        </Typography>
        .
        The run deferred to is
        {' '}
        <Typography
          tagName="span"
          type="body-bold-small"
        >
          <Link
            to={`/bolt/run_id/${deferredDetails.scheduleRunId}`}
            style={{ color: 'var(--deeplink-blue)' }}
            target="_blank"
          >
            {deferredDetails.scheduleRunId}
          </Link>
        </Typography>
      </Typography>
    );

    return (
      <Callout
        color="default"
        title="Deferred details"
        icon="info-sign"
        content={content}
        type="dense"
        view="smooth"
        dense
      />
    );
  }

  return null;
};

export default DeferredCallout;
